import React, { Component } from "react";
import { StyleSheet, View, Image, TouchableOpacity, Dimensions } from "react-native";
import { BlockComponent } from "framework/src/BlockComponent";
import Scale from "../../Scale";
import VideoPlayer from "react-native-video-player";
import { ic_comment, ic_flag, ic_menu, ic_profile, ic_share, ic_tick } from "../../../../blocks/newsfeed/src/assets";
import { getRelativePollTime } from "../../../../blocks/utilities/src/TimeUtilities";
import { CText } from "../CText/CText";
import CAvatar from "../CAvatar/CAvatar";
import CYouTubeVideo from "../CYouTubeVideo/CYouTubeVideo";
import CLike from "../CLike/CLike";
import Icon2 from 'react-native-vector-icons/MaterialCommunityIcons';
import CTooltip from '../CTooltip/CTooltip';
import CComment from "../CComment/CComment";
import RNFetchBlob from 'rn-fetch-blob';
import Share from 'react-native-share';
import { deviceWidth } from "framework/src/Utilities";
import CIcon from "../CIcon/CIcon";
import { shareIcon } from "../../assets";

interface Props {
  testID?: any;
  likeTestId?: any;
  commentTestId?: any;
  post: any;
  onPress?: any;
  toolTipVisible?: boolean;
  onTapTooltip?: any;
  onPressComment?: any;
  onSelectHeart?: any;
  onHeartInitialStatus?: any;
  downloadfunction: (videoData: any, imageData: any) => void;
}

interface S {
  imageHeight: number;
}

interface SS {

}

export default class CNewsFeedCard extends Component<Props, S> {
  state = {
    imageHeight: Scale(386),
  };

  componentDidMount() {
    // Example image URL
    const { post } = this.props;
    const imageUrl = post.type === "post" && post.attributes?.images?.data[0]?.attributes?.image;
    if (imageUrl) {


      Image.getSize(imageUrl, (width, height) => {
        const maxWidth = Dimensions.get('window').width;
        const maxHeight = Scale(386);

        const aspectRatio = width / height;
        const containerHeight = Math.min(maxHeight, maxWidth / aspectRatio);

        this.setState({ imageHeight: containerHeight });
      }, () => { console.log("failure") });
    }
  }

  render() {
    const { post, testID = '', onTapTooltip, toolTipVisible } = this.props;
    const imageData = post.type === "post" && post.attributes?.images?.data[0]?.attributes?.image;
    const videoData = post.type === "post" && post?.attributes?.videos;
    let profile_name = post?.attributes?.created_by === "user" ?
      post.attributes.account_name?.length > 20 ? post.attributes.account_name.substring(0, 25) + '...'
        : post.attributes.account_name
      : "UTS"
    let profile_photo = post?.attributes?.account_photo
    let isAdminPost = post?.attributes?.created_by === "admin";
    let isPlayerPost = post?.attributes?.created_by === "player";
    return (
      <View style={styles.postCard}>

        <View style={{ width: "100%" }}>
          <View style={styles.pollCardHeaderView}>
            <View style={styles.pollCardProfileView}>
              {isAdminPost || isPlayerPost ?
                <CAvatar
                  image={{ size: 'base', path: profile_photo }}
                  icon={{ size: 'sm', path: { uri: ""} }}
                  title={{ children: profile_name, size: 'sm', weight: 'bold', style: 'white' }}
                  time={{ children: getRelativePollTime(post.attributes?.created_at), size: 'xs', weight: 'light', style: 'gray' }}
                  isGradient
                  isTimeIncluded
                />
                :
                <CAvatar
                  image={{ size: 'base', path: profile_photo }}
                  title={{ children: profile_name, size: 'sm', weight: 'bold', style: 'white', numberOfLines: 2 }}
                  time={{ children: getRelativePollTime(post.attributes?.created_at), size: 'xs', weight: 'light', style: 'gray' }}
                  isGradient
                  isTimeIncluded
                />
              }
            </View>
            <View style={toolTipVisible ? styles.postView1ToolTip : styles.postView1}>
              {/* <CTooltip
                onTapTooltip={onTapTooltip}
                icons={[
                  { icon: 'dots-three-vertical', iconColor: '#9BA2B7', iconSize: 24 }
                ]}
                menuOptionContainerStyle={styles.optionContainerMenu}
              >
                <View style={styles.innerTooltipView}>
                  {!post.attributes.is_own_post ?
                    <>
                      <Icon2 name='flag-outline' style={styles.iconFlag} size={25} color="white" />
                      <CText style="white" size="base" weight="light">
                        {"Report"}
                      </CText>
                    </>
                    :
                    <>
                      <Icon2 name="delete-outline" style={styles.iconFlag} size={25} color="red" />
                      <CText style="red" size="base" weight="light">
                        {"Delete"}
                      </CText>
                    </>
                  }
                </View>
              </CTooltip> */}
            </View>
          </View>
          <View style={{ width: "100%" }}>
            {videoData && videoData.data[0].attributes?.video_type === "youtube" && !imageData && (
              // <CYouTubeVideo
              //   youTubeVideo={videoData.data[0].attributes.video_url}
              // />
              <></>
            )}
            {videoData && videoData.data[0].attributes?.video_type !== "youtube" && !imageData && (
              // <VideoPlayer
              //   video={{ uri: videoData.data[0].attributes?.video }}
              //   ignoreSilentSwitch="ignore"
              //   style={{width: deviceWidth, height:"auto", minHeight:Scale(208)}}
              //   resizeMode="cover"
              // />
              <></>
            )}
            {imageData && (
              <View style={[styles.container, { height: this.state.imageHeight }]}>
                <Image source={{ uri: imageData }} style={styles.image} resizeMode="cover" />
              </View>
            )}
          </View>
          <View style={styles.postView2}>
            <CText style="white" size="xl" weight="light">
              {post.attributes?.description}
            </CText>

          </View>
          <View style={styles.postView3}>
            <View style={styles.socialIcon}>
              <CLike
                testId={this.props.likeTestId}
                onPress={this.props.onSelectHeart}
                initialStatus={this.props.onHeartInitialStatus}
                likeCount={post.attributes?.likes_count}
              />
            </View>
            <View
              testID={this.props.commentTestId}
              style={styles.socialIcon}>
              <CComment commentCount={post.attributes?.comments_count_post} onPress={this.props.onPressComment} />
            </View>

            <TouchableOpacity style={styles.socialIcon} onPress={() => { this.props.downloadfunction(videoData, imageData) }}>
              <Image source={shareIcon} style={{ height: Scale(18), width: Scale(18), resizeMode: "contain" }} />
            </TouchableOpacity>
          </View>
        </View>

      </View>
    );
  }
}

const styles = StyleSheet.create({
  postCard: {
    elevation: 1,
    backgroundColor: "rgba(27, 27, 58, 1)",
    borderWidth: 1,
    marginVertical: 6,
    borderRadius: 8,
  },
  pollCardHeaderView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: Scale(12),
    alignItems: 'center',
  },
  pollCardProfileView: {
    marginLeft: Scale(12),
  },
  icon: {
    height: Scale(40),
    width: Scale(40),
  },
  iconFlag: {
    marginLeft: Scale(10),
    marginRight: Scale(10)
  },
  imageView2: {
    height: Scale(22),
    width: Scale(22),
    position: "relative",
    left: 13,
    bottom: 2,
  },
  newsFeedView1: {
    height: 80,
    marginLeft: 10,
    paddingBottom: 8,
    flex: 1,
  },
  postView1: {
    marginRight: 10,
    alignItems: "center",
  },
  postView1ToolTip: {
    marginRight: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  postView2: {
    marginTop: Scale(8),
    marginHorizontal: Scale(12),
    marginBottom: Scale(12)
  },
  postView3: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: Scale(18),
    marginBottom: Scale(20),
  },
  socialIcon: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginRight: Scale(18),
  },
  optionContainerMenu: {
    marginTop: Scale(50),
    backgroundColor: '#2C314B',
    borderColor: '#000000',
    borderRadius: 10,
    borderWidth: 1,
    paddingTop: Scale(10),
    paddingBottom: Scale(10)
  },
  innerTooltipView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  image: {
    width: deviceWidth,
    height: '100%',
  },
  container: {
    width: deviceWidth,
    justifyContent: 'flex-start', // Align the image at the top
    alignItems: 'center',
    overflow: 'hidden', // C
  },

});