import React, { useEffect, useState } from "react";
import {
    View,
    Text,
    StyleSheet,
    Image,
    TouchableOpacity,
    Platform,
} from "react-native";
import { Input } from "react-native-elements";
import Scale from "../../Scale";
import { correctIcon, passwordSeen, passwordVisible } from "../../assets";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { getFontFamily } from "framework/src/Utilities";


interface MyProps {
    autoCapitalize?: any;
    isValid?: any;
    ref?: any;
    value?: any;
    label?: any;
    keyboardType?: any;
    defaultValue?: any;
    onChangeText?: any;
    secureTextEntry?: any;
    maxLength?: any;
    placeholder?: any;
    editable?: any;
    placeholderTextColor?: any;
    errorText?: any;
    onEndEditing?: any;
    autoFocus?: any;
    onFocus?: any;
    onBlur?: any;
    multiline?: any;
    numberOfLines?: any;
    returnKeyType?: any;
    plus?: any;
    countryCode?: any;
    imgStyle?: any;
    passwordOnPress?: any;
    imgSrc?: any;
    testID?: any;
    image?: any
    otherImages?: any;
    button?: any;
    buttonOnPress?: any;
    dropdownCondent?: any;
    dropdown?: any
    toggleDropdown?: any;
    inputStyle?: any;
    containerStyle?: any;
    inputContainerStyle?: any;
    pwdOnPress?: any;
    hidePassword?: any
    isValidEmail?: any
    checkStyle?: any
    type?: any
    isValidPassword?: any

}

const CInput = (props: MyProps) => {
    const [IsFocus, setIsFocus] = useState(false);
    const [HidePassword, setHidePassword] = useState(true);
    const [dropdown, setDropDown] = useState(props?.toggleDropdown)

    useEffect(() => {
        setDropDown(props?.toggleDropdown)
    }, [props?.toggleDropdown]);

    const handleOnFocus = () => {
        setIsFocus(true);
    };
    const handleOnBlur = () => {
        setIsFocus(false);
    };

    const onSelectDropdown = () => {
        if (props.dropdown) {
            setDropDown(!dropdown)
        } else {
            props.buttonOnPress()
        }
    }
    const showHidePassword = () => {
        setHidePassword(!HidePassword)
    }

    const otherImages = () => {
        return props.type == 'editField' ?
            <Icon name="pencil" size={Scale(20)} color={'#9BA2B7'} />
            :
            <>{props.otherImages}</>
    }

    return (
        <View>
            {props.button ? (<>
                <View style={styles.buttonContainerViewSty}>
                    <Text style={[styles.textInputTitle]}>{props.label}</Text>
                    <TouchableOpacity
                        {...props}
                        activeOpacity={1}
                        // onPressIn={() => { onSelectDropdown() }}
                        onPress={() => { onSelectDropdown() }}
                        style={[styles.buttonContainerSty, { opacity: props.editable === false ? 0.4 : 1 }]}
                    >
                        <View style={styles.buttonInterViewSty}>
                            <Text style={[styles.buttonVlaueTxtSty, { color: props.value ? '#fff' : 'rgba(105,105,105,0.9)' }]} numberOfLines={1}>{props.value ? props.value : props.placeholder}</Text>
                            <TouchableOpacity
                                hitSlop={{ top: Scale(24), bottom: Scale(24), left: Scale(24), right: (24) }}
                                style={styles.showHide}
                                onPress={() => props.passwordOnPress()}>
                                {props.image ? <Image style={props.imgStyle ? props.imgStyle : styles.imgSty} source={props.imgSrc} /> : otherImages()}
                            </TouchableOpacity>
                        </View>
                    </TouchableOpacity>
                </View>
                {dropdown ?
                    <View style={styles.dropdownViewSty}>
                        {props.dropdownCondent}
                    </View>
                    : null}
            </>) : (<>
                <Input
                    {...props}
                    ref={props.ref}
                    testID={props.testID}
                    value={props.value}
                    label={props.label}
                    autoCapitalize={"none"}
                    keyboardType={props.keyboardType}
                    defaultValue={props.defaultValue}
                    onChangeText={props.onChangeText}
                    secureTextEntry={props.type == "password" ? HidePassword : false}
                    maxLength={props.maxLength}
                    labelStyle={[styles.textInputTitle]}
                    placeholder={props.placeholder}
                    containerStyle={[props.containerStyle ?? styles.containerStyle, { opacity: props.editable === false ? 0.4 : 1 }]}
                    inputContainerStyle={[props.inputContainerStyle ?? styles.inputContainerStyle, { borderColor: props.isValid ? "red" : "#5d637c" }]}
                    inputStyle={[props.inputStyle ?? (props.value ? styles.inputStyle : styles.placeholderStyle), { textTransform: props.type == "email" ? "lowercase" : "none" }]}
                    placeholderTextColor={'rgba(105,105,105,0.9)'}
                    errorMessage={props.errorText}
                    errorStyle={styles.errorText}
                    onEndEditing={props.onEndEditing}
                    autoFocus={props.autoFocus}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    editable={props.editable}
                    multiline={props.multiline}
                    numberOfLines={props.numberOfLines}
                    returnKeyType={props.returnKeyType}
                    scrollEnabled={false}
                    leftIcon={<><Text style={[styles.plusMobileInput, { paddingLeft: props.plus ? '2%' : 0 }]}>{props.countryCode}</Text></>}
                    rightIcon={<>
                        <TouchableOpacity style={styles.showHide} onPress={props.passwordOnPress} hitSlop={{ top: 20, bottom: 20, left: 20, right: 20 }}>
                            {props.image ?
                                <Image style={props.imgStyle ? props.imgStyle : styles.imgSty} source={props.imgSrc} /> :
                                <>{props.otherImages}</>}
                        </TouchableOpacity>
                    </>}

                />
                {props.type == "editField" &&
                    <View style={styles.seenIcon} >
                        <Icon name="pencil" size={Scale(20)} color={'#9BA2B7'} />
                    </View>}

                {props.type == "email" &&
                    <View
                        style={styles.seenIcon} >
                        <Image source={props.isValidEmail && correctIcon} />
                    </View>}

                {
                    (props.type == "password")
                    && <TouchableOpacity
                        onPress={() => showHidePassword()}
                        style={styles.seenIcon} >
                        <Icon name={HidePassword ? "eye-off-outline" : "eye-outline"} size={Scale(25)} color="#9BA2B7" />
                    </TouchableOpacity>
                }
            </>
            )
            }
        </View>
    );
};

const styles = StyleSheet.create({

    textInputTitle: {
        opacity: 1,
        backgroundColor: "transparent",
        includeFontPadding: false,
        padding: Scale(0),
        color: "#fff",
        textAlign: "left",
        textAlignVertical: "top",
        fontSize: Scale(18),
        marginTop: Scale(16),
        fontFamily: getFontFamily('bold'),
        fontWeight: 'bold'
    },

    errorText: {
        fontSize: Scale(12),
        color: "rgba(220, 96, 104, 1)",
        fontWeight: '700',
        marginTop: Scale(5)
    },

    plusMobileInput: {
        fontSize: Scale(14),
        fontWeight: '500',
        color: "#000",
        includeFontPadding: false,
    },
    showHide: {
        alignSelf: "center",
        paddingRight: '3%',
    },
    imgSty: {
        height: Scale(20),
        width: Scale(20),
        resizeMode: 'contain'
    },
    containerStyle: {
        padding: 0,
        marginBottom: Scale(10),
    },
    inputContainerStyle: {
        marginTop: Scale(10),
        borderRadius: Scale(10),
        borderColor: '#313351',
        borderWidth: Scale(1.5),
        paddingVertical: Scale(5),
        color: '#fff',
        padding: 0,
        backgroundColor: "#1B1B3A",
    },
    inputStyle: {
        fontSize: Scale(15),
        fontFamily: getFontFamily('bold'),
        fontWeight: '700',
        color: "#fff",
        marginLeft: '-3%',
    },
    placeholderStyle: {
        fontSize: Scale(18),
        fontFamily: getFontFamily('light'),
        color: "#fff",
        marginLeft: '-3%',
    },
    buttonContainerSty: {
        height: Scale(60),
        marginTop: Scale(10),
        borderRadius: Scale(10),
        borderColor: '#5d637c',
        borderWidth: Scale(1.5),
        paddingVertical: Scale(5),
        color: '#fff',
        justifyContent: 'center',
        backgroundColor: "#1B1B3A",
    },
    buttonContainerViewSty: {
        marginHorizontal: Scale(10),
    },
    buttonVlaueTxtSty: {
        fontSize: Scale(18),
        fontFamily: getFontFamily('bold'),
        fontWeight: '700',
        color: "#fff",
        width: '70%',
        marginLeft: '7%'
    },
    buttonInterViewSty: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    dropdownViewSty: {
        // flex: 1,
        top: 100,
        height: 200,
        width: '95%',
        position: 'absolute',
        backgroundColor: '#fff',
        overflow: 'visible',
        zIndex: 999999,
        borderRadius: 10,
        alignSelf: 'center'
    },
    seenIcon: {
        zIndex: 10,
        position: "absolute",
        right: 16,
        top: 60
    },

});

export default CInput;