import React from "react";

// Customizable Area Start
import { View, StyleSheet, ScrollView, TouchableOpacity, Animated } from "react-native";
import {
  CButton,
  CInput,
  CText,
  CTButton,
  CAppBackground,
  CValidatePassword,
  CStatusBar,
  CGradientCheckBox
} from "../../../components/src/custom-components";
import Scale from "../../../components/src/Scale";
import { isdisabled } from "../../../framework/src/Utilities";
import { Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/Entypo";

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const {
      email,
      password,
      isValid,
      validPassword,
      isCheckedBox
    } = this.state;
    let isDisabledSignUp = isdisabled(
      email,
      password,
      isValid,
      validPassword,
      isCheckedBox
    );

    const isTermsCheckBoxDisabled = () => {
      if (email == "" || password == "" || !isValid || !validPassword) {
        return true;
      } else {
        return false;
      }
    };

    const toggleCheckBox = () => {
      this.setState({ isCheckedBox: !this.state.isCheckedBox });
    };
    let translateY = this.state.animation.interpolate({
      inputRange: [0, 1],
      outputRange: [300, 0],
    });

    return (
      <View style={styles.SafeAreaView}>
        <CAppBackground>
          <CStatusBar
            backgroundColor="rgba(0, 0, 36, 0)"
            barStyle="light-content"
          />
          <ScrollView
            contentContainerStyle={{ flexGrow: 1 }}
            style={styles.subContainer}
          >
            <View style={styles.headerView}>
              <View style={styles.row}>
                <View style={styles.signUpTextWrapper}>
                  <CText style="white" size="xl4" weight="bold">
                    Sign Up
                  </CText>
                </View>
              </View>
            </View>
            <CInput
              type={"email"}
              testID={"txtInputEmail"}
              label={"Email"}
              placeholder={"example@example.com"}
              keyboardType="email-address"
              onChangeText={(text: string) => this.onEmailChange(text)}
              value={this.state.email}
              isValidEmail={this.state.isValid}
              isValid={this.state.isErrorMsg}
            />
            <CInput
              type={"password"}
              testID={"txtInputPassword"}
              label={"Password"}
              placeholder={"Create your password"}
              onChangeText={(text: string) => this.onPasswordChange(text)}
              value={this.state.password}
              isValid={this.state.isErrorMsg}
            />
            {this.state.password.length > 0 && (
              <CValidatePassword
                testID="validatePassword"
                password={this.state.password}
                onPasswordValidated={this.handlePasswordValidation}
              />
            )}
            <View style={styles.termsCond}>
              <CGradientCheckBox
                testID="btnTermCond"
                disabled={isTermsCheckBoxDisabled()}
                isCheckedBox={this.state.isCheckedBox}
                onPress={() => toggleCheckBox()}
              />
              <View style={styles.termCondView}>
                <CText style="white" size="base" weight="light">
                  {"I agree to the "}
                </CText>
                <TouchableOpacity
                  testID="btnSeeTermCond"
                  onPress={() => {
                    this.props.navigation.navigate("TermsConditions");
                  }}
                >
                  <CText style="primary" size="base" weight='bold' >
                    Terms & Conditions
                  </CText>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
          <View style={styles.loginContainer}>
            <CButton
              testID={"btnSIgnUp"}
              type="button"
              disabled={isDisabledSignUp ? true : false}
              onPress={() => this.signUpApi()}
              style={isDisabledSignUp ? "secondary" : "primary"}
              size="big"
            >
              Sign Up
            </CButton>
            <View style={styles.loginText}>
              <CText style="white" size="base" weight="light">
                Already have an account?
              </CText>
              <TouchableOpacity
                testID={"btnLogIn"}
                onPress={() =>
                  this.props.navigation.navigate("EmailAccountLoginBlock")
                }
              >
                <CText style="primary" size="base" weight="bold">
                  {" "}Login
                </CText>
              </TouchableOpacity>
            </View>

            <View style={styles.orWrapper}>
              <View style={{ height: 1, flex: 1, backgroundColor: "#4C5064", marginRight: Scale(16) }} />
              <CText style="gray" size="sm" weight="bold" lineHeight={22}>
                OR
              </CText>
              <View style={{ height: 1, flex: 1, backgroundColor: "#4C5064", marginLeft: Scale(16) }} />
            </View>

            <TouchableOpacity testID="btnGuestTextSignUp"
              style={{ alignItems: "center", justifyContent: "center" }}
              onPress={() => {
                this.handleGuestPopup();
              }}
            >
              <CText style="primary" size="base" weight="bold" lineHeight={24}>
                LOGIN AS A GUEST
              </CText>
            </TouchableOpacity>
          </View>
          {/* @ts-ignore */}
          <Overlay testID="overlay"
            isVisible={this.state.showGUestPopup}
            overlayStyle={styles.guestOverlayStyle}
            onShow={this.slideUp}
          >
            <Animated.View style={[{ transform: [{ translateY }] }]}>
              <View testID="guestPopupWrapper" style={{ backgroundColor: "#ffffff", borderTopRightRadius: Scale(24), }}>
                <TouchableOpacity
                  testID="guestPopupSignUpClose"
                  onPress={() => {
                    this.handleGuestPopup();
                  }}
                  style={styles.stickyIcon}
                >
                  <Icon name="cross" size={24} color="#000024" />
                </TouchableOpacity>
                <View style={styles.popupMainWrapperSignUp}>
                  <View style={styles.popupTitleWrapperSignUp}>
                    <CText style="blue" size="xxl" weight="bold" textAlign="center">
                      Proceeding as a guest
                    </CText>
                  </View>
                  <View style={styles.popupSubTitleWrapperSignUp}>
                    <CText style="blue" size="base" weight="light" textAlign="center">
                      As a guest, you'll have access to view details for all UTS events.
                      Some features such as comments likes, polls, and other interactive content, are reserved for registered members only.
                    </CText>
                  </View>
                  <CButton
                    testID={"btnGuestSignUp"}
                    type="button"
                    onPress={() => {
                      this.handleGuestNavigation();
                    }}
                    style={"primary"}
                    size="big"
                  >
                    Continue as a guest
                  </CButton>
                </View>
              </View>
            </Animated.View>
          </Overlay>
        </CAppBackground>
      </View>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }
}

const styles = StyleSheet.create({
  // Customizable Area Start
  SafeAreaView: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 36, 1)",
    padding: Scale(10)
  },
  keyboardPadding: {
    flex: 1
  },
  container: {
    backgroundColor: "rgba(0, 0, 36, 1)",
    flex: 1
  },
  subContainer: { flex: 1, paddingBottom: Scale(10), },
  subContainer1: { paddingHorizontal: Scale(10) },
  image: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)"
  },
  termTxt: { marginLeft: Scale(5) },
  termCondView: { flexDirection: "row", alignItems: 'center', marginLeft: Scale(5), },
  headerView: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    marginHorizontal: Scale(10)
  },
  row: {
    flexDirection: "row",
    marginVertical: Scale(10)
  },

  loginContainer: {
    paddingHorizontal: Scale(10),
    paddingBottom: Scale(20),
    paddingTop: Scale(5),
    justifyContent: "center",
    marginBottom: Scale(10)
  },
  loginText: {
    alignSelf: "center",
    flexDirection: "row",
    marginTop: Scale(16),
    alignItems: "center"
  },
  forgotPwdText: { alignSelf: "flex-end", marginRight: Scale(10) },
  errorMsg: { color: "#000024" },
  termsCond: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: Scale(10)
  },
  passwordCheck: {
    marginLeft: Scale(18),
    justifyContent: "space-between"
  },
  checkPwd: { marginVertical: 5, flexDirection: "row", alignItems: "center" },
  img: { marginRight: 4 },
  checkBoxSty: {
    width: Scale(16),
    height: Scale(16),
    borderRadius: Scale(20),
    marginHorizontal: Scale(10)
  },
  checkedImage: {
    marginRight: Scale(10),
    height: Scale(20),
    width: Scale(20)
  },
  signUpTextWrapper: { flex: 1, justifyContent: "center" },
  guestOverlayStyle: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "auto",
    backgroundColor: "transparent",
    borderTopRightRadius: Scale(24),
    elevation: 5
  },
  stickyIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 100
  },
  justifyCenter: { justifyContent: "center" },
  popupMainWrapperSignUp: { padding: 24 },
  popupTitleWrapperSignUp: { marginTop: 24, marginBottom: 10 },
  popupSubTitleWrapperSignUp: { marginBottom: 40 },
  orWrapper: {
    flexDirection: "row",
    marginVertical: Scale(24),
    alignItems: 'center',
    marginHorizontal: Scale(10)
  },
  // Customizable Area End
});
