//@ts-nocheck
import React, { Component } from 'react';
import {
    StyleSheet,
    TouchableOpacity,
    Text
} from "react-native";
import Scale from '../../Scale';
import { getFontFamily } from 'framework/src/Utilities';
interface Props {
    onPress?: any;
    testID?: string
    disabled?: boolean
    children?: any;
    type?: string;
    color?: string;
    size?: string;
    weight?: string;
    style?: any
}

export default class CTButton extends Component<Props> {
    getStyles() {
        const { color, size, weight } = this.props;
        return {

            textStyles: {
                ...styles[color],
                ...styles[size],
                ...styles[weight],
            }
        }
    }

    render() {
        const { children, onPress, testID, disabled, style } = this.props;
        const { textStyles } = this.getStyles()
        return <TouchableOpacity
            testID={testID}
            disabled={disabled}
            onPress={onPress} style={style}>
            <Text style={textStyles}>{children}</Text>
        </TouchableOpacity>
    }
}

const styles: any = StyleSheet.create({
    primary: {
        color: '#FF751B'
    },
    red: {
        color: '#FF002F',
    },
    blue: {
        color: "#000024"
    },
    white: {
        color: "#FFF"
    },
    xl3: {
        fontSize: Scale(32)
    },
    xl: {
        fontSize: Scale(22)
    },
    sm: {
        fontSize: Scale(16)
    },
    base: {
        fontSize: Scale(18)
    },
    bold: {
        fontWeight: 'bold',
        fontFamily: getFontFamily("bold"),
    },
    light: {
        fontFamily: getFontFamily('light'),
        fontWeight: '300'
    },
});
