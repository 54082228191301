import React from "react";
// Customizable Area Start
import {
  View,
  StyleSheet,
  TouchableWithoutFeedback,
} from "react-native";
// import OTPTextView from "react-native-otp-textinput";

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start

// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import OTPInputAuthController, { Props } from "./OTPInputAuthController";
import Scale from "../../../components/src/Scale";
import {
  CAppBackground,
  CToast,
  CButton,
  CText,
  CTButton,
  CStatusBar,
  CHeader
} from "../../../components/src/custom-components";
import { configJSON } from "./ForgotPasswordController";

export default class OTPinputCheck extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <View style={styles.SafeAreaView}>
        <View style={styles.container}>
          <CAppBackground>
            <CStatusBar backgroundColor="rgba(0, 0, 36, 0)" barStyle="light-content" />
            <CHeader
              testID='headerView'
              icons={[
                { testID: 'btnHeader', type: 'icon', icon: 'back', position: 'left', onPress: () => this.props.navigation.goBack() },
                { type: 'text', title: `${configJSON.emailVerification}`, position: 'center', titleSize: 'xl3', titleWeight: 'bold', bottom: 10 },
              ]} />
            <TouchableWithoutFeedback
              testID={"btnHideKeyboard"}
              onPress={() => {
                this.hideKeyboard();
              }}
            >
              <View style={styles.subContainer}>
                <CText style="white" size="sm" weight="light">
                  Please enter the 4-digit verification code sent to your email
                  address.
                </CText>
                <View style={styles.otpView}>
                  {/* <OTPTextView
                    ref={e => (this.otpInput = e)}
                    testIDPrefix={"otpTextInput"}
                    handleTextChange={(code: any) =>
                      this.setState({ oneTimeOTP: code, errorMessage: "" })
                    }
                    defaultValue={this.state.oneTimeOTP}
                    inputCount={4}
                    keyboardType={"numeric"}
                    textInputStyle={styles.OTPinputStyle}
                    tintColor={"#3CB371"}
                    containerStyle={styles.OTPcontainerStyle}
                  /> */}
                  <View style={styles.otpViewSty}>
                    <View>
                      {this.state.seconds === 0 ? (
                        <CTButton
                          testID={"btnResendCode"}
                          onPress={() => {
                            this.handleResendOtp()
                          }}
                          color="primary"
                          size="sm"
                          weight="bold"
                        >
                          Resend Code
                        </CTButton>
                      ) : null}
                    </View>
                    <View>
                      <CText style="white" size="sm" weight="bold">
                        {" "}
                        {this.state.seconds > 0 ? (
                          <CText style="white" size="sm" weight="bold">
                            00:
                            {this.state.seconds < 10
                              ? "0" + this.state.seconds
                              : this.state.seconds}
                          </CText>
                        ) : (
                          "00:00"
                        )}
                      </CText>
                    </View>
                  </View>
                </View>
                <CButton
                  testID={"btnVerifyOtp"}
                  onPress={() => {
                    this.handleOtpVerify()
                  }}
                  type="button"
                  style={this.state.oneTimeOTP == "" ? "secondary" : "primary"}
                  size="big"
                  disabled={this.state.oneTimeOTP == "" ? true : false}
                >
                  Verify
                </CButton>
              </View>
            </TouchableWithoutFeedback>

            <CToast
              testID="msgTst"
              msg={this.state.errorMessage}
              type='fail'
              position="center"
              callback={() => this.setState({ errorMessage: '' })} />
          </CAppBackground>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  subContainer: { flex: 1, marginTop: 40, padding: 20 },
  SafeAreaView: {
    flex: 1,
  },
  container: {
    backgroundColor: "rgba(0, 0, 36, 1)",
    flex: 1,
  },
  otpView: { bottom: 30, justifyContent: "center", flex: 1 },
  otpViewSty: {
    flexDirection: 'row',
    margin: Scale(20),
    justifyContent: "space-between"
  },

  OTPcontainerStyle: {
    marginHorizontal: Scale(20),
    height: Scale(60)
  },
  OTPinputStyle: {
    borderRadius: Scale(10),
    borderWidth: Scale(1),
    borderBottomWidth: Scale(1),
    borderColor: '#747B92',
    color: '#fff',
    fontSize: Scale(17),
    height: Scale(50)
  },
  mt60: { marginTop: Scale(60) }
});
