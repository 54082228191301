Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";

exports.btnExampleTitle = "CLICK ME";
exports.helpcentreAPIEndPoint = "/help_centre/question_type";
exports.httpGetType = "GET";

exports.GET = 'GET'
exports.POST = "POST";
exports.DELETE = "DELETE";
exports.createPostEndPoint = '/bx_block_communityforum/posts'
exports.getAllTagesApiEndPoint = '/bx_block_hashtags/hashtags'

exports.frontRow = "Front Row";
exports.comingSoon = "Coming Soon";
exports.playerInteractionHeaderTxt = "AMBASSADOR PROGRAM";
exports.submitQuestionHeaderTxt = "FRONT ROW EXPERIENCE"
exports.submitQuestion = "Interview your favourite player";
exports.playerInteractionsListApiEndpoint = "bx_block_player_interaction/player_interactions";
exports.queDescription = "Ask any question to your favorite player and get featured in live stream";
exports.queNote = "Note: Video can be up to 30 seconds only!";
exports.playerLabel = "Please select a player";
exports.selectPlayer = "Select Player";
exports.questionTitle = "Question title";
exports.questionTitlePlaceHolder = "Write here....";
exports.startRecordingBtnLabel = "Start recording";
exports.submitQuestionPlayerListEndpoint = "bx_block_player_interaction/players";
exports.playerInteractionFilterListEndpoint = "bx_block_player_interaction/player_filters";
exports.headerTitle = "COMMUNITY FORUM";
exports.getSubscriptionsDataFrontRowApiEndPoint = "bx_block_custom_user_subs/subscriptions";
exports.getAllThreadsApiEndpoint = "bx_block_threads/threads?";
exports.createNewThread = "/bx_block_threads/threads";
exports.deleteThread = "/bx_block_threads/threads/";
exports.createThreadTitle = "Create new thread";
exports.cancel = "Cancel";
exports.create = "Create";
exports.inputPlaceholder = 'Write the question here....';
exports.inputTitle = 'Add a question or title';
exports.newThreadToast = "New thread created!";
exports.deleteThreadTitle = "Delete Thread?";
exports.delete = "Delete";
exports.deleteThreadMessage = "Are you sure you want to delete this thread?";
exports.deleteThreadToast = "Thread deleted successfully";
exports.threadLikeDislikeAPIEndPoint = "bx_block_threads/thread_likes";
exports.threadLikeDislikeAPIContentType = "application/json";
exports.submit = "Submit";
exports.contentTypeApisubmitQuestion = 'multipart/form-data; ';
exports.submitQuestionAPIEndPoint = "bx_block_player_interaction/player_interactions";
exports.submitQuestionError = "Failed to submit, please try again!";
exports.submitQuestionSuccess = "Your question has been sent for review.";
exports.searchThreadAPIEndpoint = "bx_block_threads/threads/search_thread?query=";
exports.playerFilterListInForum = "/bx_block_threads/threads/player_listing";
exports.liveStreamingDataEndPoint = "bx_block_player_interaction/player_interaction_streamings";
exports.videoNotAvailable = "Video not available";
exports.GetUserProfileApiEndPoint = "bx_block_profile/user_profile";
exports.infoTag = "Join the community to engage in exciting conversations";
exports.videoMaxTimeInSec = 30;
// Customizable Area End
