import React, { Component } from 'react';
import {
    StyleSheet,
    ImageBackground
} from "react-native";
import { loginBackgroundImg } from '../../assets';

interface Props {
    children: any
}

export default class CAppBackground extends Component<Props> {
    render() {
        const { children } = this.props
        return <ImageBackground
            style={styles.image}
            source={loginBackgroundImg}
        >
            {children}
        </ImageBackground>
    }
}

const styles: any = StyleSheet.create({

    image: {
        flex: 1,
        backgroundColor: "rgba(0, 0, 0, 0)",
        resizeMode: "cover"
    },
});
