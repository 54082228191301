import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from '../../../framework/src/Utilities';
import _ from "lodash";
export const configJSON = require('./config');
let config = require('../../../framework/src/config')
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  userType: string;
  token: string;
  threadFlatlistItemSelect: any[];
  refreshing: boolean;
  newThreadText: string;
  isCreateThreadModalVisible: boolean;
  toastMessage: string;
  isDeleteThreadModalVisible: boolean;
  allThreadFlatlistData: any[];
  currentPage: number;
  perPageData: number;
  showErrorMsgForEmptyThreads: string;
  visibleFilterModal: boolean;
  nextPageFromAPI: number;
  filterListData: any[];
  appliedFilterDataItems: any[];
  filterListPage: number;
  headerViewHeight: number;
  isDeleteToast: boolean;
  deleteThreadId: number;
  deleteToolTipVisible: any;
  refreshingThreadList: boolean;
  accountId: number;
  isFromPagination: boolean;
  isInfoModalVisible: boolean;
  statusBarHeight: number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllCreatedThreadApiCallId: any;
  createNewThreadApiCallId: any;
  deleteThreadApiCallId: any;
  playerFilterApiCallId: any;
  abortController: any;
  abortSignal: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    // Create an instance of AbortController
    this.abortController = new AbortController();
    this.abortSignal = this.abortController.signal;

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      userType: "",
      token: '',
      refreshing: false,
      threadFlatlistItemSelect: [],
      newThreadText: "",
      isCreateThreadModalVisible: false,
      toastMessage: "",
      isDeleteThreadModalVisible: false,
      allThreadFlatlistData: [],
      currentPage: 1,
      perPageData: 10,
      showErrorMsgForEmptyThreads: "",
      visibleFilterModal: false,
      filterListData: [],
      appliedFilterDataItems: [],
      filterListPage: 1,
      headerViewHeight: 0,
      isDeleteToast: false,
      deleteThreadId: 0,
      deleteToolTipVisible: "",
      refreshingThreadList: false,
      accountId: -1,
      nextPageFromAPI: 1,
      isFromPagination: false,
      isInfoModalVisible: false,
      statusBarHeight: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog('Message Recived', message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getAllCreatedThreadApiCallId) {
          this.setAllThreadListData(responseJson)
        } else if (apiRequestCallId === this.createNewThreadApiCallId) {
          this.setCreateThreadData(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);

        } else if (apiRequestCallId === this.deleteThreadApiCallId) {
          this.setDeleteThreadData(responseJson)
          this.parseApiCatchErrorResponse(errorReponse);

        } else if (apiRequestCallId === this.playerFilterApiCallId) {
          if (!responseJson.message) {
            this.setState({ filterListData: [...responseJson.admins, ...responseJson.players] })
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleLoadMoreThreadListData = () => {
    const { currentPage }: any = this.state;
    this.setState({ currentPage: currentPage + 1 }, () => {
      this.getAllCreatedThreads();
    });
  }

  onRefreshThreadListData = () => {
    this.setState({ refreshingThreadList: true });
    setTimeout(() => {
      this.setState({ currentPage: 1, allThreadFlatlistData: [], refreshingThreadList: false }, () => {
        this.getAllCreatedThreads();
      });
    }, 2000);
  }

  setAllThreadListData = (responseJson: any) => {
    if (!responseJson.message && responseJson?.data.length) {
      this.setState({
        isLoading: false,
        isFromPagination: false,
        allThreadFlatlistData: this.state.isFromPagination ?
          [...this.state.allThreadFlatlistData, ...responseJson.data] : responseJson.data,
        nextPageFromAPI: responseJson?.meta?.pagination?.next_page
      });
    } else {
      this.setState({ isLoading: false, isFromPagination: false, allThreadFlatlistData: [], showErrorMsgForEmptyThreads: responseJson.message });
    }
  }

  trimWhitespaceAndNewlines = (str: string) => {
    // Use a regular expression to match trailing whitespace and newlines
    // only if there's no text after them
    const trimmed = str.replace(/\s+(?![^\s])/g, '');
    return trimmed;
  }

  setCreateThreadData = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        isLoading: false,
        toastMessage: configJSON.newThreadToast,
        newThreadText: ""
      }, () => {
        this.getAllCreatedThreads();
        this.getPlayerFilterList();
      })
    } else {
      this.setState({
        isLoading: false
      });
      alert(responseJson.error)
    }
  }

  setDeleteThreadData = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({
        isLoading: false,
        toastMessage: configJSON.deleteThreadToast,
        isDeleteToast: true
      }, () => {
        this.getAllCreatedThreads();
        this.getPlayerFilterList();
      });
    } else {
      this.setState({
        isLoading: false
      });
      alert(responseJson.error)
    }
  }

  getAllCreatedThreads = async () => {
    const { currentPage, token, appliedFilterDataItems, nextPageFromAPI, isFromPagination } = this.state;
    if (!isFromPagination) {
      this.setState({ isLoading: true })
    }
    if (nextPageFromAPI !== null) {
      const selectedAdminIdArray: number[] = [];
      const selectedPlayerIdArray: number[] = [];

      appliedFilterDataItems.forEach((item: any) => {
        if (item?.type === "admin_user") {
          selectedAdminIdArray.push(Number(item?.id))
        } else {
          selectedPlayerIdArray.push(Number(item?.id))
        }
      })

      const header = {
        token,
        "Content-Type": configJSON.threadLikeDislikeAPIContentType,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getAllCreatedThreadApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getAllThreadsApiEndpoint}per_page=${this.state.perPageData}&page=${currentPage}&admin_ids=[${selectedAdminIdArray}]&profile_ids=[${selectedPlayerIdArray}]`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.GET
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  likeOnThread = (item: any, status: boolean) => {
    if (status === false) {
      this.handleDislikeOnThread(item.id)
    } else {
      this.handleLikeOnThread(item.id)
    }
  }

  handleDislikeOnThread = _.debounce(async (threadId: any) => {
    const token = await getStorageData('TOKEN');

    try {
      // Abort the previous fetch request if it exists
      this.abortController.abort();

      this.abortController = new AbortController();
      this.abortSignal = this.abortController.signal;

      const apiUrl = `${configJSON.threadLikeDislikeAPIEndPoint}/${Number(threadId)}`;
      const headers = {
        token: token
      };
      const method = configJSON.DELETE
      const fullURL = apiUrl.indexOf('://') === -1 ? config.baseURL + '/' + apiUrl : apiUrl
      const fetchOptions = {
        signal: this.abortSignal,
        method,
        headers,
      };
      const response = await fetch(fullURL, fetchOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseJson = await response.json();
      if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Fetch request was aborted');
      } else {
        console.error('Fetch request failed:', error);
      }
    }
  }, 500);

  handleLikeOnThread = _.debounce(async (threadId: any) => {
    const token = await getStorageData('TOKEN');

    try {
      // Abort the previous fetch request if it exists
      this.abortController.abort();

      this.abortController = new AbortController();
      this.abortSignal = this.abortController.signal;

      const apiUrl = `${configJSON.threadLikeDislikeAPIEndPoint}`;
      const headers = {
        token: token,
        "Content-Type": configJSON.threadLikeDislikeAPIContentType,
      };
      const requestBody = {
        id: Number(threadId),
      };
      const method = configJSON.POST
      const fullURL = apiUrl.indexOf('://') === -1 ? config.baseURL + '/' + apiUrl : apiUrl
      const fetchOptions = {
        signal: this.abortSignal,
        method,
        headers,
        body: JSON.stringify(requestBody),
      };
      const response = await fetch(fullURL, fetchOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseJson = await response.json();
      if (responseJson.errors) {
        this.parseApiErrorResponse(responseJson);
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Fetch request was aborted');
      } else {
        console.error('Fetch request failed:', error);
      }
    }
  }, 500);

  createThread = async () => {
    this.setState({ currentPage: 1, isFromPagination: false, nextPageFromAPI: 1 });
    const token = await getStorageData("TOKEN");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const body = {
      "thread": {
        "title": this.state.newThreadText
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createNewThreadApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createNewThread
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPlayerFilterList = async () => {
    const token = await getStorageData('TOKEN');
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.playerFilterApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.playerFilterListInForum
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteThread = async (threadId: number) => {
    this.setState({ currentPage: 1, isFromPagination: false, nextPageFromAPI: 1 });
    const token = await getStorageData("TOKEN");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteThreadApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteThread + `${threadId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.DELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  renderCreateThreadModalHide = () => {
    this.setState({
      isCreateThreadModalVisible: false,
      newThreadText: ""
    });
  }

  renderCreateThreadModalShow = () => {
    this.setState({
      isCreateThreadModalVisible: true,
      newThreadText: ""
    });
  }

  onSubmitCreateThread = () => {
    this.setState({
      isCreateThreadModalVisible: false
    });
    this.createThread();
  }

  handleToastMessage = () => {
    this.setState({ toastMessage: "", isDeleteToast: false })
  }

  renderDeleteThreadModalHide = () => {
    this.setState({
      isDeleteThreadModalVisible: false
    });
  }

  onDeleteThread = (threadId: number) => {
    this.setState({
      isDeleteThreadModalVisible: false
    });
    this.deleteThread(threadId);
  }

  applyFilterData = (filterData: any) => {
    this.setState({
      visibleFilterModal: false, appliedFilterDataItems: filterData, currentPage: 1, allThreadFlatlistData: [], isFromPagination: false, nextPageFromAPI: 1
    }, () => {
      this.getAllCreatedThreads();
    });
  }

  handleLoadMoreFilterList = () => {
    // console.log("Need to implement later")
  };

  onRefreshFilterList = () => {
    // console.log("Need to implement later")
  }

  renderDeleteModalShow = (threadId: any) => {
    this.setState({
      deleteToolTipVisible: threadId
    });
  }

  renderDeleteModalHide = () => {
    this.setState({
      deleteToolTipVisible: ""
    });
  }

  onPressDeleteTooltip = (threadId: number) => {
    this.setState({
      isDeleteThreadModalVisible: true,
      deleteThreadId: threadId,
      deleteToolTipVisible: ""
    });
  }

  handleInfoOpenMenu = () => {
    this.setState({
      isInfoModalVisible: !this.state.isInfoModalVisible
    })
  }

  // Customizable Area End


}