import React from "react";
// Customizable Area Start
import { View, Image, TouchableOpacity, FlatList } from "react-native";
import styles from "./EventsStyle";
import { championship, } from "./assets";
import { configJSON } from "./EventsController"
import { CButton, CText, CToggleComponent } from "../../../components/src/custom-components";
import moment from "moment";
import Icon from "react-native-vector-icons/Octicons"
// Customizable Area End

import EventsController, { Props } from "./EventsController";

export default class Events extends EventsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderEventCellContainer = (item: any) => {
        return (
            <View
                testID={"btnEventItem"}
                style={styles.eventCellView}>
                <Image style={styles.posterImage} source={
                    item.attributes?.event_pic_resize ? { uri: item.attributes?.event_pic_resize } : require('../assets/poster.png')
                } />
                <View style={styles.locationView}>
                    <View style={styles.eventHeadingView}>
                        <CText numberOfLines={2} style="white" size="base18" weight='bold'>{item.attributes.name}</CText>
                        <View style={styles.dateView}>
                            <CText style="white" size="base" weight='light' >{moment(item.attributes.event_start_date).format('MMMM DD')} - {moment(item.attributes.event_end_date).format('MMMM DD')}</CText>
                        </View>
                    </View>
                    <View style={styles.btnView}>
                        <CButton
                            testID="saveBtn"
                            style="primary"
                            type="button"
                            size="medium"
                            disabled={false}
                            onPress={() => { this.handleRedirection(item.attributes.status, item.id) }}>
                            {configJSON.viewDetails}
                        </CButton>
                    </View>
                </View>
                <View style={styles.addressView}>
                    <CText style="white" size="base" weight='light'>{item.attributes.address}</CText>
                </View>
            </View>
        );
    };

    renderEventListContainer = () => {
        return (
            <View style={{ flex: 1, paddingBottom: 50 }}>
                <FlatList testID="eventData"
                    data={this.state.selectedBtn === 1 ? this.state.allEventData : this.state.upcomingEventData}
                    renderItem={({ item }) => this.renderEventCellContainer(item)}
                />
            </View>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <View style={styles.container}>

                <View style={styles.headerContainer}>
                    <View style={styles.labelView}>
                        <CText style="white" size="xl3" weight='bold'>{configJSON.labelTitleText}</CText>
                        <TouchableOpacity testID="allRanks" onPress={() => {
                            this.props.navigation.navigate("EventOverAllRanking");
                        }} style={styles.championshipButton}>
                            <Image style={styles.championshipIcon} source={championship} />
                            <View style={styles.rankView}>
                                <CText style="primary" size="sm" weight='bold'>{configJSON.rankingLabel}</CText>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity testID="dropdown" style={styles.locationButton} onPress={this.handleDropDown}>
                        <Icon style={styles.locationIcon} name="location" size={20} color={"#FF751B"} />
                        {
                            !this.state.locationSelected ?
                                <CText style="white" size="base" weight='light'>{configJSON.locationText}</CText>
                                : <CText style="white" size="base" weight='light'>Dubai</CText>
                        }
                        {/* <Image style={styles.downArrowIcon} source={dropDown} /> */}
                    </TouchableOpacity>
                </View>
                <CToggleComponent testID={'toggledata'} array={this.state.tab}
                    onSelect={(data: any) => { this.setState({ selectedBtn: data }) }} />
                <View style={styles.eventNumberText}>
                    <CText style="gray" size="base" weight='bold' >{this.state.selectedBtn == 1 ? this.state.allEventData?.length : this.state.upcomingEventData?.length} {configJSON.eventNumber}</CText>
                </View>
                {this.renderEventListContainer()}
            </View>
        );
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
}
