import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

interface NearbyFriend {
  business_name: string;
  business_image_url: string,
  business_location: string | null,
  rating: string;
  total_review_count: string;
  distance: number,
  business_gender_type: string;
  business_category: string;
}

interface GeoData {
  latitude:number,
  longitude:number
}

interface Notification {
  account_id: number;
  push_notificable_type: string;
  push_notificable_id: number;
  remarks: string;
  is_read: boolean;
  created_at: string;
  updated_at: string;
}
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filterData: Array<NearbyFriend>;
  searchnearLocation: string;
  address: string;
  enterTextLatLng: { latitude: number, longitude: number }
  liveLatLng: { latitude: number, longitude: number };
  notifications: Array<Notification>
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class NearbyFriendsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  serviceProviderScheduleApiId: string = ''
  interval: NodeJS.Timer | number | undefined
  notificationApiId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      filterData: [],
      searchnearLocation: '',
      address: '',
      enterTextLatLng: { latitude: 0, longitude: 0 },
      liveLatLng: { latitude: 0, longitude: 0 },
      notifications: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if ((responseJson) && (!responseJson.errors) && (apiRequestCallId !== null) &&
        (apiRequestCallId == this.serviceProviderScheduleApiId)) {
        this.setState({ filterData: responseJson.data })
      }
      if (apiRequestCallId === this.notificationApiId) {
        this.setState({ notifications: responseJson.notifications })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start 
  handleChange = (address: string) => {
    this.setState({ address });
  };

  handleSelect = (address: string) => {
    geocodeByAddress(address)
      .then((results: google.maps.GeocoderResult[] = []) => getLatLng(results[0]))
      .then((myData: {lat:number,lng:number}) => this.setState({
        enterTextLatLng: { ...this.state.enterTextLatLng, latitude: myData.lat, longitude: myData.lng }
      }))
    this.setState({ address: address })
  };

  handleLocation = () => {
    let latitude = null;
    let longitude = null;

    if (this.state.enterTextLatLng.latitude === 0 || this.state.enterTextLatLng.longitude === 0) {
      let location = null;
      if (window.navigator && window.navigator.geolocation) {
        location = window.navigator.geolocation
      }
      if (location) {
        location.getCurrentPosition((position) => {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;
          this.nearByFriendsApiCall({ latitude, longitude })
        })
      }
    } else {
      latitude = this.state.enterTextLatLng.latitude
      longitude = this.state.enterTextLatLng.longitude
      this.nearByFriendsApiCall({ latitude, longitude })
    }
    this.setState({
      enterTextLatLng: { latitude: 0, longitude: 0 },
      address: ''
    })
  }

  handleLogOut = () => {
    localStorage.removeItem('NearbyFriendstoken')
    localStorage.removeItem('NearbyFriendsUserType')
  }

  fetchNearbyFriends = () => {
    this.getNotificationApi(this.state.liveLatLng);
  };

  currentLocation = () => {
    let location = null;
    if (window.navigator && window.navigator.geolocation) {
      location = window.navigator.geolocation
    }
    if (location) {
      location.getCurrentPosition((position) => {
        const liveLatLng = { latitude: position.coords.latitude, longitude: position.coords.longitude };
        this.setState({
          liveLatLng: liveLatLng
        })
      })
    }
  }
  async componentDidMount() {
    super.componentDidMount();
    let token = localStorage.getItem('NearbyFriendstoken')
    if (!!token) {
      this.currentLocation()
      this.interval = setInterval(this.fetchNearbyFriends, 5000)
    } else {
      this.handleNavigationLogin()
    }
  }

  async componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleNavigationLogin = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationToLoginWeb)
    );
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  };
  
  getNotificationApi = async (myData: GeoData) => {
    let token = localStorage.getItem("NearbyFriendstoken") || ""
     const { latitude, longitude } = myData;
     const method = configJSON.getApiMethodType
     const endPoint = `${configJSON.nearByFriendApiEndPoint}?latitude=${latitude}&longitude=${longitude}&manual=false`
     const header = {
       "Content-Type": configJSON.applicationJsonApiContentType,
       token:token
     };
 
     const requestMessage = new Message(
       getName(MessageEnum.RestAPIRequestMessage)
     );
 
     this.notificationApiId = requestMessage.messageId;
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIResponceEndPointMessage),
       endPoint
     );
 
     requestMessage.addData(
       getName(MessageEnum.RestAPIRequestMethodMessage),
       method
     );
 
     runEngine.sendMessage(requestMessage.id, requestMessage);
   }

  nearByFriendsApiCall = async (myData: GeoData) => {
   let token = localStorage.getItem("NearbyFriendstoken") || ""
    const { latitude, longitude } = myData;
    const method = configJSON.getApiMethodType
    const endPoint = `${configJSON.nearByFriendApiEndPoint}?latitude=${latitude}&longitude=${longitude}`
    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceProviderScheduleApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}

