import React from "react";
// Customizable Area Start
import {
  Box,
  Container,
  Typography,
  Input,
  IconButton,
  InputAdornment,
  Grid,
  Button,
} from "@material-ui/core";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete';
import { Link } from "react-router-dom";
import { locationImg, bellIcon } from "./assets";
import '../assets/Styles/NearbyFriends.css'
import moment from "moment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import NearbyFriendsController, {
  Props,
} from "./NearbyFriendsController.web";

export default class NearbyFriends extends NearbyFriendsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <header>
          <div className="btn">
            <Link to="/NearbyFriends/login" style={{ textDecoration: "none" }}>
              <Button
                className="request-demo-button"
                data-test-id="logOut"
                onClick={() => this.handleLogOut()}
              >
                Log out
              </Button>
            </Link>
          </div>
          <div className="icons">
            <div className="notification">
              <div className="notBtn">
                <div className="bellIcon" data-test-id="notification">
                  <img style={{ height: 25, width: 25 }} src={bellIcon} alt="bellIcon" />
                </div>
                <div className="box">
                  <div className="display">
                    <div className="nothing">
                      <div className="cent">Looks Like your all caught up!</div>
                    </div>
                    <div className="cont">
                      {this.state.notifications && this.state.notifications.map((notification: {
                        account_id: number;
                        push_notificable_type: string;
                        push_notificable_id: number;
                        remarks: string;
                        is_read: boolean;
                        created_at: string;
                        updated_at: string;
                      }) => (
                        <>
                          <div className="sec new">
                            <div className="txt">{notification.remarks}</div>
                            <div className="txt sub">{moment(notification.created_at).format("DD/MM/yyy")}</div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <ThemeProvider theme={theme}>
          <Container maxWidth={'sm'}>
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              data-test-id={"autocomplete"}
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }:
                { getInputProps: Function, suggestions: readonly Suggestion[], getSuggestionItemProps: Function, loading: boolean }) => (
                <Box>
                  <Box>
                    <Box sx={webStyle.header}>
                      <Box sx={webStyle.inputStyle}>
                        <Input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className: 'location-search-input',
                          })}
                          data-test-id={"txtInput"}
                          type='search'
                          fullWidth={true}
                          disableUnderline={true}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleLocation}
                                edge="end"
                                data-test-id={"addBtn"}
                              >
                                <img src={locationImg} style={{ height: 25, width: 25, cursor: "pointer" }} />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Box>
                    </Box>
                  </Box>
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion: {
                      active: boolean;
                      description: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
                    }) => {
                      const className = suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item';
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                      return (
                        <div style={{ display: "flex", justifyContent: 'center' }}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </Box>
              )}
            </PlacesAutocomplete>
            <Box>
              <Typography>
                Search Results ({this.state.filterData !== undefined && this.state.filterData.length || 0})
              </Typography>
            </Box>
            {this.state.filterData !== undefined && this.state.filterData.length > 0 && this.state.filterData.map((element: {
              business_name: string;
              business_image_url: string,
              business_location: string | null,
              rating: string;
              total_review_count: string;
              distance: number,
              business_gender_type: string;
              business_category: string;
            }, count: number) => {
              return (
                <>
                  <Box key={count} style={{ margin: '10px 0px' }}>
                    <img src={element.business_image_url ? element.business_image_url : `https://picsum.photos/200/100`}
                      style={{ height: 200, width: '100%', borderRadius: 25 }} />                </Box>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography>Radius : {element.distance}kms</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Name : {element.business_name || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Rating : {element.rating || '-'}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Totle review :{element.total_review_count || '-'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography>Location : {element.business_location || '-'}</Typography>
                    </Grid>
                  </Grid>
                </>
              )
            })}
          </Container>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    border: "2px solid",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "25px",
    padding: "5px 10px",
    marginTop: "20px"
  },
  header: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'baseline'
  },
};
// Customizable Area End
