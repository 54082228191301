import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { CountryItem } from '../types';
// Customizable Area End

export interface ReasonItem {
  "id": number;
  "name": string;
  "created_at": string;
  "updated_at": string;
}

export interface Props {
  testId: string;
  // Customizable Area Start
  type: "post" | "comment";
  commentId: string;
  visible: boolean;
  setVisible: () => void;
  setToastMessage: () => void;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  reasonList: ReasonItem[];
  reportText: string;
  selectedReasons: number[]
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CReportPopupController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getReasonDataAPICallId: string = '';
  reportPostCommentsApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      reasonList: [],
      reportText: "",
      selectedReasons: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson && apiRequestCallId === this.getReasonDataAPICallId && responseJson.length > 0) {
        this.setState({ reasonList: responseJson, })

      } else if (apiRequestCallId && responseJson && apiRequestCallId === this.reportPostCommentsApiCallId) {
        console.log("responseJson", responseJson)
        this.handleCloseBtnPress();
        if (responseJson.message === "Reported") {
          this.props.setToastMessage();
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getReasonList();
  }

  getReasonList = async () => {
    const token = await getStorageData('TOKEN');
    const header = {
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReasonDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_content_management/reasons_list"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleReasonSelection = (id: number) => {
    let selectedIds = this.state.selectedReasons;
    let isAvailable = selectedIds.filter((item: number) => item === id)
    if (this.state.selectedReasons.length === 0) {
      this.setState({ selectedReasons: [id] })
    } else if (isAvailable[0] === id) {
      let extraData = selectedIds.filter((item: number) => item !== id);
      this.setState({ selectedReasons: extraData })
    } else {
      selectedIds.push(id);
      this.setState({ selectedReasons: selectedIds });
    }
  }

  handleValidation = () => {
    if (this.state.selectedReasons.length > 0 && this.state.reportText.trimEnd().length > 1) {
      return false
    } else {
      return true
    }
  }

  handleClearBtnPress = () => {
    this.setState({ selectedReasons: [], reportText: "" });
    this.hideKeyboard();
  }

  handleCloseBtnPress = () => {
    this.handleClearBtnPress();
    this.props.setVisible();
  }

  reportPostComment = async () => {
    let token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestBody = {
      "report": {
        "reportable_id": this.props.commentId,
        "reportable_type": "BxBlockComments::Comment",
        "report_reason": this.state.reportText,
        "reason_ids": this.state.selectedReasons
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportPostCommentsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_content_management/create_report"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
