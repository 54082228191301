Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.commentsContentType = "application/json";
exports.commentsApiMethodType = "GET";
exports.commentPOSTAPiMethod = "POST";
exports.commentDELETEAPiMethod = "DELETE";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "COMMENTS";
exports.labelBodyText = "Comments Body";
exports.hintCountryCode = "Select Country";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.likeCommentEndPoint = "/comments/like_comment"
exports.commentsCreationTitle = "COMMENTS";
exports.commentEndPoint = "bx_block_posts/posts/"
exports.createCommentEndPoint = "bx_block_comments/comments"
exports.postLikeEndPoint = 'bx_block_like/likes';
exports.postDisLikeEndPoint = 'bx_block_like/destroy';
// Customizable Area End
