import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import Scale from '../../Scale';
import { getFontFamily } from 'framework/src/Utilities';

const CMultiToggle =
  ({ testID, options, handleMultiToggleChange, selectedIndex, setSelectedIndex }:
    { testID: string, options: string[]; handleMultiToggleChange: any, selectedIndex: number, setSelectedIndex: any }) => {

    const handleToggle = (index: any) => {
      setSelectedIndex(index);
    };

    return (
      <>
        <View style={styles.container}>
          {options.map((option: string, index: number) => (
            <TouchableOpacity
              testID={testID}
              key={option}
              style={[
                styles.toggleButton,
                options.length === 2 ? { paddingHorizontal: 50 } : { paddingHorizontal: 24 },
                index === selectedIndex && styles.selectedToggleButton,
              ]}
              onPress={() => handleToggle(index)}
            >
              <Text
                style={[
                  styles.toggleText,
                  index === selectedIndex && styles.selectedToggleText,
                ]}
              >
                {option}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        {handleMultiToggleChange()}
      </>
    );
  };

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    backgroundColor: "#1B1B3A",
    justifyContent: 'space-around',
    alignItems: 'center',
    borderRadius: 50,
    width: "85%",
  },
  toggleButton: {
    paddingVertical: 13,
    borderRadius: 50,
  },
  selectedToggleButton: {
    backgroundColor: '#FFFFFF',
  },
  toggleText: {
    color: '#F1F4F9',
    fontSize: Scale(18),
    fontWeight: '700',
    fontFamily: getFontFamily("bold"),
    lineHeight: Scale(22),
  },
  selectedToggleText: {
    color: '#FF751B',
    fontSize: Scale(18),
    fontWeight: '700',
    fontFamily: getFontFamily("bold"),
    lineHeight: Scale(22)
  },
});

export default CMultiToggle;
