import React from "react";
// Customizable Area Start



//@ts-ignore
import PostCreationController from "./PostCreationCommonController";
import Scale from "../../../components/src/Scale";
import { CButton, CHeader, CInputPost, CStatusBar, CText } from "../../../components/src/custom-components";
import { configJSON } from "./PostCreationController";
// import BottomSheet from "@gorhom/bottom-sheet";
import Camera from "react-native-vector-icons/Feather"
import Icon from "react-native-vector-icons/MaterialCommunityIcons"
import {
  TouchableOpacity,
  Text,
  View,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  Image,
  StyleSheet,
} from 'react-native';
// import VideoPlayer from "react-native-video-player";
import { getFontFamily } from "framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostCreation extends PostCreationController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    // Customizable Area Start
    const _renderBackDrop = () => {
      return <TouchableOpacity
        activeOpacity={1}
        style={styles.backDropBottomSheet} />;
    };
    return (
      <View style={styles.safeAreaView}>
        <CStatusBar backgroundColor="rgba(0, 0, 36, 0)" barStyle="light-content" />

        <KeyboardAvoidingView behavior={Platform.OS == "ios" ? 'padding' : "height"} style={{ flex: 1, }}>
          <CHeader
            testID='headerView'
            icons={[
              { type: 'text', title: `${configJSON.headerTitle}`, position: 'center', titleStyle: 'primary', titleSize: 'base', titleWeight: 'bold' },
              { testID: 'closeBtn', type: 'icon', icon: 'close', position: 'right', iconColor: '#FF751B', onPress: () => this.props.navigation.goBack() },
            ]} />

          <ScrollView contentContainerStyle={styles.scrollViewSty}
            ref={this.scrollViewRef}
          >
            {Boolean(this.state.imageData.path) ?
              <>
                {
                  this.state.imageData?.mime.startsWith('image') ?
                    <Image
                      source={{ uri: this.state.imageData.path }}
                      style={styles.imgSty}
                      resizeMode="cover"
                    />
                    :
                    <View style={styles.imgSty}>
                      {/* <VideoPlayer
                        video={{ uri: this.state.imageData.path }}
                        style={{ marginBottom: 15, width: "100%", height: Scale(400), borderRadius: 8, }}
                      /> */}
                    </View>
                }
                <TouchableOpacity
                  testID="btnEditPhoto"
                  style={styles.editAvatarView}
                  onPress={() => this.clearImageData()}
                >
                  <Icon name="close" size={20} />
                </TouchableOpacity>
              </>
              :
              <TouchableOpacity
                testID="uploadImg"
                onPress={() => { this.onEditPhoto() }}
                style={styles.dashViewSty}>
                <Camera name="camera" size={18} color={"white"} />
                <CText style="white" size="sm" weight='bold' >
                  Upload image / video
                </CText>
                <CText style="white" size="sm" weight='light' >Max size 10 MB / up to 60 seconds</CText>
              </TouchableOpacity>}
            <CText style="white" size="sm" weight='bold'>Add caption</CText>

            <CInputPost
              testID={"captionInput"}
              onChange={(text: string) => this.handlePostText(text)}
              value={this.state.postText}
              style={styles.inputSty}
              placeholder={"Write here..."}
            />
            <View style={{ alignSelf: "flex-end", marginTop: 5 }}>
              <CText style="white" size="sm" weight='light'>{this.state.postText.length}/100</CText>
            </View>

            {/* <BottomSheet
              data-testid='bottomSheet'
              ref={this.bottomSheetRef}
              index={0}
              snapPoints={this.snapPoints}
              backgroundComponent={_renderBackDrop}>
              <View style={styles.bottomSheet}>
                <View style={styles.wrapItemBottomSheet}>
                  <TouchableOpacity
                    testID="btnOpenCamera"
                    onPress={() => this.onSelectCamera()}>
                    <Text style={styles.editPhotoItem}>
                      {configJSON.takePhoto}
                    </Text>
                  </TouchableOpacity>
                  <View style={styles.divider} />
                  <TouchableOpacity
                    testID="btnPhotoGallary"
                    onPress={() => this.onSelectImage()}>
                    <Text style={styles.editPhotoItem}>
                      {configJSON.photoLibrary}
                    </Text>
                  </TouchableOpacity>
                  <View style={styles.divider} />
                  <TouchableOpacity
                    testID="btnPickVideo"
                    onPress={() => this.onSelectVideo()}>
                    <Text style={styles.editPhotoItem}>
                      {configJSON.addVideo}
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  testID="btnCancel"
                  onPress={() => this.closeBottomSheet()}>
                  <View style={styles.wrapItemCancelBottomSheet}>
                    <Text style={styles.editPhotoItem}>
                      {configJSON.cancel}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </BottomSheet> */}
          </ScrollView>
        </KeyboardAvoidingView>
        <CButton
          testID="btnPostButton"
          onPress={() => this.handlePost()}
          type="button"
          style={this.postButtonStyle()}
          size="big"
          disabled={this.isPostDisabled() || this.state.isLoading}
          isLoading={this.state.isLoading}
          loaderColor='#fff'
          loaderSize='small'>
          <CText style="white" size="base" weight='bold' textAlign='center'>Post</CText>
        </CButton>
      </View>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  safeAreaView: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 36, 1)",
    padding: 20,
  },
  scrollViewSty: { flexGrow: 1, },
  imgSty: { marginBottom: 15, width: "100%", height: Scale(400), borderRadius: 8, },
  dashViewSty: { marginBottom: 15, height: Scale(400), borderRadius: 8, borderStyle: "dashed", borderWidth: 1, borderColor: "white", justifyContent: "center", alignItems: "center" },
  inputSty: {
    fontFamily: getFontFamily('light'), textAlignVertical: 'top',
    color: "#fff", marginTop: 10, padding: 5, backgroundColor: "#1B1B3A", borderRadius: 8, minHeight: 80, borderWidth: 0.5, borderColor: "white",
  },
  divider: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    height: 1
  },
  bottomSheet: {
    paddingHorizontal: 8
  },
  editPhotoLabel: {
    padding: 8,
    textAlign: "center",
  },

  editPhotoItem: {
    padding: 12,
    textAlign: "center",
    fontWeight: "600",
    color: "black"
  },
  backDropBottomSheet: {
    backgroundColor: "rgba(0, 0, 36, 1)"
  },
  wrapItemBottomSheet: {
    backgroundColor: "white",
    borderRadius: 12
  },
  wrapItemCancelBottomSheet: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 8,
    marginTop: 6
  },
  editPic: {
    width: Scale(18),
    height: Scale(18)
  },
  editAvatarView: {
    width: Scale(32),
    height: Scale(32),
    borderRadius: Scale(32 / 2),
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: Scale(10),
    right: Scale(10),
    padding: 5,
  },
});
// Customizable Area End
