import React, { Component } from 'react';
import { View, TouchableOpacity, Image, StyleSheet, FlatList, TextStyle } from 'react-native';
import Scale from '../../Scale';
import { CText } from "../CText/CText";
import CIcon from '../CIcon/CIcon';
import CGradient from '../CGradient/CGradient';

export type DataType = {
  id: number | string,
  label: string,
  amount: number | string,
  offer: number | string,
  isSelected: boolean,
  productIdIos: string,
  productIdAndroid: string,
  isActive?: boolean,
}

interface Props {
  data: any;
  returnData: (value: DataType[]) => void
  testID?: string
  showBottomText?: boolean
}

export default class SubscriptionItem extends Component<Props> {

  onItemClick(e: any, i: number) {
    const { data, returnData } = this.props;
    let itemsArray = [...data];
    itemsArray.forEach((item, index) => {
      item.isSelected = Boolean(index == i);
      return item;
    })
    returnData(itemsArray)
  };

  renderItem = (item: DataType, index: number) => {
    const { showBottomText } = this.props;
    const textColor = item?.isSelected ? 'primary' : 'white'
    return <>
      <TouchableOpacity testID={`itemId_${index}`} onPress={() => this.onItemClick(item, index)} >
        <CGradient colors={item?.isSelected ? ['#FF002F', '#FF751B'] : ['#4C5064', '#4C5064']}
          style={[item?.isSelected ? styles.gradientStyle : styles.unSelectedGradientStyle]}>
          <View style={[item?.isSelected ? styles.itemContainer : styles.unselectedItemContainer]}>
            <View style={{ flexDirection: 'row', flex: 1,alignItems:'center' }}>
              <Image style={styles.checkBoxStyle}
                resizeMode='contain'
                source={item?.isSelected ? require("../../selected_rd.png") : require("../../unselected_rd.png")} />
              <View>
                <CText style={textColor} size={'base'} weight={'bold'} >{item?.label}</CText>
                {item?.isActive && <CText style={textColor} size={'sm'} weight={'light'}>Current Plan</CText>}
              </View>
            </View>
            <View>
              <CText style={textColor} size={'base'} weight={'bold'} >{item?.amount}</CText>
              {!showBottomText && <CText style={textColor} size={'sm'} weight={'light'} textAlign='right' >{item?.offer ? `${item?.offer} off` : String(item?.label).toLocaleLowerCase()} </CText>}
            </View>
          </View>
        </CGradient>
      </TouchableOpacity >
    </>
  }

  render() {
    const { data, testID } = this.props;
    return (
      <>
        <FlatList
          data={data}
          testID={testID}
          style={styles.flatListStyle}
          keyExtractor={(item, index) => item + String(index)}
          renderItem={({ item, index }: any) => this.renderItem(item, index)} />
      </>
    );
  }
};


const styles = StyleSheet.create({
  flatListStyle: { marginTop: Scale(10), flexGrow: 0 },
  checkBoxStyle: {
    height: Scale(30),
    width: Scale(30),
    marginRight: Scale(10)
  },
  gradientStyle: {
    marginBottom: Scale(10),
    borderRadius: Scale(10),
    padding: 2
  },
  unSelectedGradientStyle: {
    marginBottom: Scale(10),
    borderRadius: Scale(10),
    padding: 1
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Scale(20),
    paddingVertical: Scale(15),
    backgroundColor: "#000024",
    borderRadius: Scale(10),
  },
  unselectedItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: Scale(20),
    paddingVertical: Scale(15),
    backgroundColor: "#000024",
    borderRadius: Scale(10),
  },
})
