export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const like = require('../assets/like.png')
export const comments = require('../assets/comments.png')
export const settings = require('../assets/Settings.png')
export const searchImg = require('../assets/searchImg.png')
export const profile = require('../assets/profile.jpeg')
export const Profile1 = require('../assets/Profile1.jpeg')
export const Profile2 = require('../assets/Profile2.jpeg')
export const threeDots = require('../assets/threeDots.png')
export const share = require('../assets/share.png')
export const Prodcut = require('../assets/Product1.jpeg');
export const browser = require("../assets/browser.png");
export const redHeart = require("../assets/redHeart.png");
export const imageGiveaway = require("../assets/imageGiveaway.png");
export const backArrow = require("../assets/backArrow.png");
export const check = require("../assets/check.png");
export const back = require("../assets/backArrow.png");
export const image_settings = require('../assets/image_settings.png')
export const coverPage_1 = require("../assets/coverPage_1.png");
export const coverPage_2 = require("../assets/coverPage_2.png");
export const cameraStop = require("../assets/cameraStop.png");
export const cameraStart = require("../assets/cameraStart.png");
export const videoCameraIcon = require("../assets/videoCameraIcon.png");
