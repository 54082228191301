import React, { Component } from 'react';
import { StyleSheet, View, Image, ViewProps } from "react-native";
import { CText } from '../CText/CText';
import Scale from '../../Scale';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { wanringImg } from '../../assets';

interface Props {
  msg: string,
  type: 'success' | 'fail' | 'delete',
  position: 'top' | 'center' | 'bottom',
  callback: () => void,
  toastPosition?: any,
  isWarning?: any,
  testID?: string,
  children?: any;
  stay?: boolean;
}
interface S { isShow: boolean }

export default class CToast extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    this.state = { isShow: false };
  }

  getStyles() {
    const { position, toastPosition } = this.props;
    return {
      viewStyles: {
        ...styles[position],
        ...styles.errorView,
        ...toastPosition
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevProps.msg !== this.props.msg && this.props.msg !== '') {
      this.setState({ isShow: true })
      setTimeout(() => {
        if (this.props.stay === true) {
          this.props.callback()
        } else {
          this.setState({ isShow: false })
          this.props.callback()
        }
      }, 2000);

    }
  }

  render() {
    const { isShow } = this.state
    const { type, msg, isWarning = true, children } = this.props
    const { viewStyles } = this.getStyles()
    const isSuccess = type == 'success'
    const isDelete = type == 'delete'
    return isShow && typeof msg != 'undefined' && msg != '' && <View style={viewStyles}>
      {isWarning ? <Icon name={isSuccess ? "check-circle" :(isDelete ? 'delete-circle' : "close-circle")}
        size={Scale(20)}
        color={isSuccess ? "#34D399" : '#FF002F'}
        style={styles.iconStyle} />
        : <Image source={wanringImg} style={styles.iconStyle} />}
      <View style={{ flex: 1 }}>
        {children ?
          children
          :
          <CText style="blue" size="sm" weight='light' >{msg}</CText>
        }


      </View>
    </View>
  }
}

const styles = StyleSheet.create({
  errorView: {
    alignItems: "center",
    alignSelf: 'center',
    flexDirection: "row",
    backgroundColor: "#fff",
    borderRadius: Scale(8),
    padding: Scale(10),
    position: 'absolute',
    width: '90%',
    zIndex: 1000
  },
  iconStyle: {
    marginLeft: Scale(5),
    height: Scale(20),
    width: Scale(20),
    marginRight: Scale(10)
  },
  top: {
    top: Scale(40)
  },
  bottom: {
    bottom: Scale(40)
  },
  center: {
    bottom: Scale(240)
  }
});
