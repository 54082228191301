import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface PollingOptionData {
  label: string,
  isSelected: boolean,
  index: number,
  id: number | string,
  value: number
}

interface ApiDataNew {
  contentType: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Body {
  [key: string]: string | number[]
}

interface Attribute {
  [key: string]: string | number | string[]
}

interface PollListResponse {
  id: string;
  type: string;
  attributes: Attribute
}

interface ApiData {
  contentType: string;
  method: string;
  endPoint: string;
  body?: Body;
  type?: string;
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  selectedOption: string[];
  showError: boolean;
  isLoading: boolean;
  pollListResponse: PollListResponse[];
  question: string | number | string[];
  options: string[];
  currentQuestionIndex: number;
  selectedAnswers: string[][];
  isSubmitted: boolean;
  rightAnswerCount: number;
  isNext: boolean;
  pollingOption: PollingOptionData[];
  pollDetailsResponse: any
  toastMessage: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface Option {
  number: string;
  text: string;
}

export default class PollingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPollingListId: string;
  submitPollId: string;
  pollDetailsId: string;
  submitPollAnswerId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.getPollingListId = "";
    this.submitPollId = "";

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "",
      enableField: false,
      selectedOption: [],
      showError: false,
      isLoading: false,
      pollListResponse: [],
      currentQuestionIndex: 0,
      question: "",
      options: [],
      selectedAnswers: [],
      isSubmitted: false,
      rightAnswerCount: 0,
      isNext: true,
      pollingOption: [],
      pollDetailsResponse: {},
      toastMessage: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.pollDetailsId = "";
    this.submitPollAnswerId = "";
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiSuccessResponse(message);
      this.handleApiErrorResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getPollDetails(this.props?.navigation?.state?.params?.pollId);
  }

  apiCall = async (data: ApiDataNew) => {
    const { contentType, method, endPoint, body, type } = data
    const token = await getStorageData('TOKEN');
    const header = {
      'Content-Type': contentType,
      token,
    }
    const apiCallMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    apiCallMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : apiCallMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(apiCallMessage.id, apiCallMessage);
    return apiCallMessage.messageId;
  }

  getPollingListSuccessCallBack = (pollList: PollListResponse[]) => {
    this.setState({
      isLoading: false,
      pollListResponse: pollList,
      question: pollList[0].attributes.question,
      options: this.getOptionList(pollList[0].attributes),
    })
  };

  getPollDetails = async (id: string | number) => {
    this.setState({ isLoading: true });
    this.pollDetailsId = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.apiEndPointGetPollDetails + id,
    })
  };

  getPollDetailsSuccessCallBack = (response: any) => {
    let data: PollingOptionData[] = []
    response?.poll?.polling_options.map((item: any, index: number) => {
      let obj = {
        label: item?.text,
        isSelected: item?.is_submitted ?? false,
        index: index,
        id: item?.id,
        value: item?.answer_percentage ?? 0 //if null then take default 0 as no one has selected answer
      }
      data.push(obj)
    })

    this.setState({
      isLoading: false,
      pollDetailsResponse: response,
      pollingOption: data,
    })
  };

  getPollDetailsFailureCallBack = (errorResponse: string) => {
    this.setState({ isLoading: false, })
    this.parseApiCatchErrorResponse(errorResponse);
  };

  submitPollAnswer = async () => {
    let filtered = this.state.pollingOption.filter((el, index) => { return el.isSelected === true; });
    const setData = {
      poll_id: this.props?.navigation?.state?.params?.pollId,
      // poll_id: 228,
      polling_option_id: filtered[0].id,
    };
    const httpBody = { answer: setData };
    this.submitPollAnswerId = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.apiEndPointSubmitPollAnswer,
      body: httpBody,
    })
  };

  submitPollAnswerSuccessCallBack = (responseJson: any) => {
    console.log('submitPollAnswerSuccessCallBack >>>>>>>>>>>> ', JSON.stringify(responseJson))
    this.setState({ isLoading: false, toastMessage: 'Your response has been submitted!' })
    this.getPollDetails(this.props?.navigation?.state?.params?.pollId);
  };

  submitPollAnswerFailureCallBack = (errorResponse: string) => {
    console.log('submitPollAnswerFailureCallBack >>>>>>>>>>>> ', JSON.stringify(errorResponse))
    this.setState({ isLoading: false, })
    this.parseApiCatchErrorResponse(errorResponse);
  };

  submitPollFailureCallBack = (errorReponse: string) => {
    this.setState({ isLoading: false });
    this.parseApiCatchErrorResponse(errorReponse);
  };

  goHome = () => {
    this.props.navigation.goBack()
  }

  getOptionList = (data: Attribute) => {
    const options: string[] = [];
    Object.keys(data).forEach((keyName: string) => {
      if (typeof keyName === "string" && keyName.includes("option") && data[keyName] !== "") {
        options.push(data[keyName].toString());
      }
      return keyName;
    })
    return options;
  }

  getApiCommonResponseDetail = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    return { apiRequestCallId, responseJson, errorReponse }
  }

  handleApiSuccessResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorReponse } = this.getApiCommonResponseDetail(message);
    if (responseJson && !responseJson.errors && !errorReponse) {
      if (apiRequestCallId === this.getPollingListId) {
        this.getPollingListSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.pollDetailsId) {
        this.getPollDetailsSuccessCallBack(responseJson?.data)
      }
      if (apiRequestCallId === this.submitPollAnswerId) {
        this.submitPollAnswerSuccessCallBack(responseJson)
      }
    }
  }

  handleApiErrorResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorReponse } = this.getApiCommonResponseDetail(message);
    if (responseJson?.errors || !errorReponse?.errors) {
      if (apiRequestCallId === this.getPollingListId) {
        this.submitPollFailureCallBack(responseJson?.errors || errorReponse?.errors)
      }
      if (apiRequestCallId === this.pollDetailsId) {
        this.getPollDetailsFailureCallBack(responseJson?.errors || errorReponse?.errors)
      }
      if (apiRequestCallId === this.submitPollAnswerId) {
        this.submitPollAnswerFailureCallBack(responseJson?.errors || errorReponse?.errors)
      }
    }
  }

  setPollingOption = (val: PollingOptionData[]) => {
    this.setState({ pollingOption: val })
  }

  isSubmitButtonDisabled = () => {
    let filtered = this.state.pollingOption.filter((el, index) => { return el.isSelected === true; });
    return filtered.length == 0
  }

  handleToast = () => {
    this.setState({ toastMessage: '' })
  }
  // Customizable Area End
}
