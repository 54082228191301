import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
// import RNFetchBlob from 'rn-fetch-blob'; // search resposen.blob
import { Linking, Platform } from 'react-native';
// import RNFS  from 'react-native-fs' // use fs
// import Share from 'react-native-share' // react-share
// import VersionCheck from "react-native-version-check" // not required in web
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  newsFeedData: any[];
  utsFeedData: any[];
  pollsData: any[];
  postData: any[];
  getReasonsFromApi: any[];
  postId: any;
  reportReview: any;
  isReasonSelectedIndex?: any;
  isNewsFeedLoading: boolean;
  isUtsLoading: boolean;
  visible: boolean;
  isAnySelect: boolean;
  filterTabs: any;
  tab: any;
  selectedBtn: number;
  page: any;
  utsPage: any;
  feedPagination: any;
  utsFeedPagination: any;
  refreshing: boolean;
  isGuestUser: boolean;
  guestActionType: "Post" | "Comments" | "Like" | "Share" | "Poll";
  isGuestPopupOpen: boolean;
  isToastVisible?: any;
  downloading: boolean;
  progress: number;
  isShare: boolean;
  isFilterOpen: boolean;
  hashTag: { name: string; link: string };
  showAppUpdateModal: boolean
  appUpdateData: any
  bottomLoader: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NewsFeedWebController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getNewsFeedDataCallId: string = "";
  allPostsApiCallId: string = "";
  showPostsApiCallId: string = "";
  activeFeedsApiCallId: string = "";
  activeutsFeedsApiCallId: string = "";
  postLikeApiCallId: string = "";
  reportThisPostApiCallId: string = "";
  postLikeDislikeApiCallId: string = "";
  getReasonsApiCallId: string = "";
  getHashTagApiCallId: string = "";
  appUpdateCallId: string = "";
  skipAppUpdateCallId: string = "";
  focusListener: any;
  deletePostApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      newsFeedData: [],
      utsFeedData: [],
      postData: [],
      pollsData: [],
      getReasonsFromApi: [],
      postId: "",
      reportReview: "",
      isNewsFeedLoading: true,
      isUtsLoading: true,
      filterTabs: configJSON.filterTabs,
      tab: [
        { id: 1, title: 'Feed' },
        { id: 2, title: 'UTS' }
      ],
      selectedBtn: 1,
      page: 1,
      utsPage: 1,
      isAnySelect: false,
      isReasonSelectedIndex: {},
      visible: false,
      feedPagination: [],
      utsFeedPagination: [],
      refreshing: false,
      isGuestUser: false,
      guestActionType: "Post",
      isGuestPopupOpen: false,
      isToastVisible: "",
      downloading: false,
      progress: 0,
      isShare: false,
      isFilterOpen: false,
      hashTag: { name: "", link: "" },
      showAppUpdateModal: false,
      appUpdateData: {},
      bottomLoader: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson && !responseJson?.errors) {
        if (apiRequestCallId === this.activeFeedsApiCallId) {
          Boolean(responseJson?.all_data?.length > 0) && this.setState({
            newsFeedData: [...this.state.newsFeedData, ...responseJson.all_data],
            isNewsFeedLoading: true,
            feedPagination: responseJson.meta.pagination
          });
          Boolean(responseJson?.all_data?.length == 0) && this.setState({
            isNewsFeedLoading: false,
          });
          this.setState({ bottomLoader: false })
        } else if (apiRequestCallId === this.activeutsFeedsApiCallId) {
          Boolean(responseJson?.data?.length > 0) && this.setState({
            utsFeedData: [...this.state.utsFeedData, ...responseJson.data],
            isUtsLoading: true,
            utsFeedPagination: responseJson.meta.pagination
          });
          Boolean(responseJson?.data?.length == 0) && this.setState({
            isUtsLoading: false,
          });
          this.setState({ bottomLoader: false })
        } else if (apiRequestCallId === this.deletePostApiCallId) {
          this.onRefresh();
        } else if (apiRequestCallId === this.reportThisPostApiCallId) {
          let newArray = [...this.state.getReasonsFromApi];
          let finalArray = newArray.map((ele, i) => {
            return { ...ele, isSelected: false };
          });
          this.setState({
            visible: false,
            getReasonsFromApi: finalArray,
            reportReview: '',
            isToastVisible: "Post has been reported."
          });
        } else if (apiRequestCallId === this.getReasonsApiCallId) {
          this.setState({
            getReasonsFromApi: responseJson
          });
        } else if (apiRequestCallId === this.postLikeDislikeApiCallId && responseJson) {
          console.log("postLikeApiCallId===========>", JSON.stringify(responseJson));
        } else if (apiRequestCallId === this.getHashTagApiCallId && responseJson && responseJson && responseJson.message) {
          console.log("getHashTagApiCallId===========>", JSON.stringify(responseJson));
        } else if (apiRequestCallId === this.getHashTagApiCallId && responseJson && responseJson.data && responseJson.data.length > 0) {
          this.setState({
            hashTag: { name: responseJson.data[0].attributes.name, link: responseJson.data[0].attributes.link }
          })
        } else if (apiRequestCallId === this.appUpdateCallId && responseJson && responseJson) {
          this.handleAppUpdatesRes(responseJson)
        } else if (apiRequestCallId === this.skipAppUpdateCallId && responseJson && responseJson) {
          this.handleSkipAppUpdatesRes(responseJson)
        }
      } else if (responseJson?.errors) {
        this.parseApiErrorResponse(responseJson);
      } else if (errorReponse) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    // this.focusListener = this.props.navigation.addListener("didFocus", async () => {
      // this.handleAppUpdateApi()
    // })
    const token = await getStorageData('USERTYPE');
    token === "guest" && this.setState({ isGuestUser: true });
    this.getReasonsList();
    this.getHashTag();
    if(this.isPlatformWeb()){
      this.onRefresh() ;
      this.onutsRefresh()
    }
  }

  async componentWillUnmount() {
    // this.focusListener.remove();
  }

  handleAppUpdatesRes = (responseJson: any) => {
    if (!responseJson.message) {
      this.setState({ showAppUpdateModal: true, appUpdateData: responseJson })
    }
  }

  handleSkipAppUpdatesRes = (responseJson: any) => {
    if (responseJson.message) {
      this.setState({ showAppUpdateModal: false, appUpdateData: [] })
    }
  }

  goToCommunity = () => {
    this.props.navigation.navigate('CommunityForum')
  }

  handleTabSelect = (data: any) => {
    this.setState({ selectedBtn: data }, () => {
      if (data === 1) {
        this.onRefresh()
      }
      if (data === 2) {
        this.onutsRefresh()
      }
    })
  };

  handleLoadMore = () => {
    const { page } = this.state;
    console.log("activeFeed", page, ' activeFeedData >> ', this.state.newsFeedData.length, 'isNewsFeedLoading >>', this.state.isNewsFeedLoading)
    if (!this.state.isNewsFeedLoading) { return null; }
    this.setState({ page: page + 1, isNewsFeedLoading: false, bottomLoader: true }, () => {
      this.handleActiveFeed();
    });
  };

  handleLoadMoreUTS = () => {
    const { utsPage } = this.state;
    console.log("utsPage", utsPage, ' utsFeedData >>> ', this.state.utsFeedData.length, 'isUtsLoading >>> ', this.state.isUtsLoading)
    if (!this.state.isUtsLoading) { return null; }
    this.setState({ utsPage: utsPage + 1, isUtsLoading: false, bottomLoader: true }, () => {
      this.fetchUTSFeed();
    });
  };

  likeOnPost = (item: any, status: boolean) => {
    if (status === false) {
      this.handleDisLikeOnPost(item.id);
    } else if (status === true) {
      this.handleLikeOnPost(item.id);
    }
  };

  gotoPollScreen = (poll: any) => {
    this.props.navigation.navigate('Polling', { pollId: poll })
  }

  handleActiveFeed = async () => {
    //let token = await getStorageData('TOKEN');
    let token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activeFeedsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.activeFeedsApiEndPoint}${this.state.page}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  fetchUTSFeed = async () => {
    //let token = await getStorageData('TOKEN');
    let token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    const header = {
      "Content-Type": "application/json",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activeutsFeedsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.activeUtsFeedApiEndPoint}${this.state.utsPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleLikeOnPost = async (postId: any) => {
    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append("id", postId);
    formData.append("type", "post");

    this.postLikeDislikeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postLikeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // handleAppUpdateApi = async () => {
  //   const token = await getStorageData('TOKEN');
  //   // let appVersion = VersionCheck?.getCurrentVersion();
  //   const header = {
  //     "Content-Type": "multipart/form-data",
  //     token: token
  //   };

  //   const requestMessage = new Message(
  //     getName(MessageEnum.RestAPIRequestMessage)
  //   );

  //   const formData = new FormData();
  //   formData.append("os_type", Platform.OS == "ios" ? "ios" : "android");
  //   formData.append("version", appVersion);

  //   this.appUpdateCallId = requestMessage.messageId;
  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIResponceEndPointMessage),
  //     configJSON.appUpdateEndPoint
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestHeaderMessage),
  //     JSON.stringify(header)
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestBodyMessage),
  //     formData
  //   );

  //   requestMessage.addData(
  //     getName(MessageEnum.RestAPIRequestMethodMessage),
  //     "POST"
  //   );
  //   runEngine.sendMessage(requestMessage.id, requestMessage);
  //   return true;
  // }

  handleSkipAppUpdateApi = async () => {
    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append("id", this.state.appUpdateData.id);

    this.skipAppUpdateCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.skipAppUpdateEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleDisLikeOnPost = async (postId: any) => {

    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append("id", postId);
    formData.append("type", "post");

    this.postLikeDislikeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDisLikeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;

  }
  handleAppUpdate = () => {
    let url = this.state.appUpdateData.url
    Linking.canOpenURL(url)
      .then((supported: boolean) => (supported && Linking.openURL(url)))
  }
  handleSoftUpdateBtn = () => {
    if (this.state.appUpdateData.soft_update) {
      return 'Not now'
    }
  }
  handleSoftUpdateCloseBtn = () => {
    if (this.state.appUpdateData.soft_update) {
      return true
    } else {
      return false
    }
  }
  reportThisPost = async () => {
    const filterForSelectedReasonId = this.state.getReasonsFromApi.filter((i: any) => i.isSelected).map((item: any) => item.id);
    //let token = await getStorageData('TOKEN'); 
    let token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const body = {
      "report": {
        "reportable_id": this.state.postId,
        "reportable_type": configJSON.reportableType,
        "report_reason": this.state.reportReview,
        "reason_ids": filterForSelectedReasonId
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportThisPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportThisPostApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getReasonsList = async () => {
    //let token = await getStorageData('TOKEN');
    let token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReasonsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReasonsListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  onRefresh = () => {
    // setTimeout(() => {
    this.setState({ page: 1, isNewsFeedLoading: false, newsFeedData: [], refreshing: false }, () => {
      this.handleActiveFeed();
    });
    // }, 2000);
  }

  onutsRefresh = () => {
    this.setState({ utsPage: 1, isUtsLoading: false, utsFeedData: [], refreshing: false }, () => {
      this.fetchUTSFeed();
    });
  }

  handleGuestPopup = (type?: "Post" | "Comments" | "Like" | "Share" | "Poll") => {
    this.setState({ guestActionType: type ?? "Post", isGuestPopupOpen: !this.state.isGuestPopupOpen });
  }

  handleGuestSignUpNavigation = () => {
    this.setState({ isGuestPopupOpen: !this.state.isGuestPopupOpen });
    this.props.navigation.replace("EmailAccountRegistration");
  }

  // downloadAndShareImage = async (videos: any, images: any, post: any) => {
  //   let fileType = ""
  //   let downloadFile = ""
  //   if (videos) {
  //     if (videos.data[0].attributes?.video_type === "youtube") {
  //       fileType = "youtube"
  //       downloadFile = videos.data[0].attributes.video_url
  //     } else {
  //       downloadFile = videos.data[0].attributes?.video;
  //       fileType = "video/mp4"
  //     }
  //   } else if (images) {
  //     downloadFile = images
  //     fileType = "image/jpg"
  //   }
  //   const fileName = `utsPost${post.id}.${fileType.split("/")[1]}`;
  //   const { dirs } = RNFetchBlob.fs;
  //   let path = ''
  //   if (Platform.OS == 'ios') {
  //     path = RNFS.DocumentDirectoryPath + '/UtsLive';
  //     await RNFS.exists(path)
  //       .then((exists:any) => {
  //         if (!exists) {
  //           return RNFS.mkdir(path);
  //         } else {
  //           console.log('Folder already exists.', path);
  //         }
  //       })
  //       .then(() => {
  //         path = `${path}/${fileName}`
  //         console.log('Folder created successfully.', path);
  //       })
  //       .catch((error:any) => {
  //         console.error('Error creating folder:', error);
  //       });
  //   } else {
  //     path = `${dirs.DownloadDir}/${fileName}`;
  //   }

  //   if (fileType !== "youtube" && path !== '') {
  //     try {
  //       await RNFS.exists(path).then((exists) => {
  //         if (exists) {
  //           console.log(`File ${path} exists.`);
  //           const options = { type: fileType, url: Platform.OS === 'ios' ? path : `file://${path}` };
  //           setTimeout(() => { this.shareMedia(options); }, 500);
  //         } else {
  //           console.log('RNFetchBlob  >>>> ', path)
  //           this.handleDownload(true);
  //           RNFetchBlob.config({
  //             // fileCache: true,
  //             path
  //           })
  //             .fetch('GET', downloadFile, {})
  //             .progress((received, total) => {
  //               const currentProgress = (received / total) * 100;
  //               if (currentProgress === 100) {
  //                 this.handleDownload(false);
  //               }
  //               console.log("progress:", currentProgress)
  //               this.handleProgress(currentProgress)
  //             })
  //             .then(response => {
  //               this.handleDownload(false);
  //               this.handleProgress(0)
  //               const filePath = response.path();
  //               console.log("response:", response.path())
  //               const options = { type: fileType, url: Platform.OS === 'ios' ? filePath : `file://${filePath}` };
  //               setTimeout(() => { this.shareMedia(options); }, 500);
  //               // Now you can do something with the downloaded file path
  //             })
  //             .catch((error: any) => {
  //               this.handleDownload(false);
  //               this.handleProgress(0)
  //               console.log('Error fetching the blob:', JSON.stringify(error));
  //             });
  //         }
  //       }).catch((error) => {
  //         console.error(`Error checking file existence: ${error}`);
  //       });
  //     } catch (error) {
  //       console.log('Error downloading file:', JSON.stringify(error));
  //       // this.handleDownload(false);
  //       // this.handleProgress(0)
  //     }
  //   } else {
  //     const options = { url: downloadFile, };
  //     setTimeout(() => { this.shareMedia(options); }, 500);
  //   }
  // }

  // shareMedia = (options: any) => {
  //   if (!this.state.isShare) {
  //     this.setState({ isShare: true }, () =>
  //       Share.open(options)
  //         .then(() => {
  //           console.log('Shared successfully');
  //           this.setState({ isShare: false })
  //         })
  //         .catch(error => {
  //           this.setState({ isShare: false })
  //           console.log('Share error:', error);
  //         })
  //     )
  //   }
  // }

  handleDownload = (status: boolean) => {
    this.setState({ downloading: status })
  }

  handleProgress = (progress: number) => {
    this.setState({ progress: progress })
  }

  getHashTag = async () => {
    //let token = await getStorageData('TOKEN');
    let token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHashTagApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getHashTagApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  openWebsiteLink = async () => {
    const url = this.state.hashTag.link;

    try {
      const supported = await Linking.canOpenURL(url);

      if (supported) {
        await Linking.openURL(url);
      } else {
        console.error("Don't know how to open URI: " + url);
      }
    } catch (error) {
      console.error('An error occurred: ' + error);
    }
  };

  deletePost = async () => {
    //let token = await getStorageData('TOKEN');
    let token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    const header = {
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deletePostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postDeleteEndPoint + "/" + this.state.postId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postDelAPIMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
