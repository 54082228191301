import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, } from 'react-native';
import Scale from '../../Scale';

interface Props {
  showIndicator: boolean
}

export default class FooterIndicator extends Component<Props> {
  render() {
    const { showIndicator } = this.props;
    if (showIndicator) {
      return (<ActivityIndicator style={styles.activityIndicator} color='#FF002F' />);
    }
    return null;
  }
};

const styles = StyleSheet.create({
  activityIndicator: { margin: Scale(15) }
})
