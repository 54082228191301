import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  StatusBar,
  ActivityIndicator,
  Alert
} from "react-native";

// import BottomSheet from "@gorhom/bottom-sheet";
import {
  CButton,
  CCountryDropDown,
  CGenderPicker,
  CIcon,
  CInput,
  CProfileAvatar,
  CStatusBar,
  CText
} from "../../../components/src/custom-components";
import {
  backArrow,
  cameraIcon,
  editPencilIconBlack,
} from "./assets";
import Scale from "../../../components/src/Scale";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { configJSON } from "./UserProfileBasicController";
import { getStorageData } from "../../../framework/src/Utilities";
import CCoverPhoto from "../../../components/src/custom-components/CCoverPhoto/CCoverPhoto";
import CountryCodeComponent from "../../../components/src/CustomCountryCode/CustomCountryCode";
import CustomDropdown from "../../../components/src/CustomDropDown/CustomDropdown";
import { CountryItem, GenderItem } from "../../../components/src/custom-components/types";

const EditProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .label(configJSON.labelFirstName)
    .test("alphabets", configJSON.errorFName, value => {
      return /^[a-zA-Z]+$/.test(value);
    }).required("Required"),
  lastName: Yup.string()
    .label(configJSON.lastName)
    .test("alphabets", configJSON.errorLastName, value => {
      return /^[a-zA-Z]+$/.test(value);
    }).required("Required"),
  gender: Yup.string().label(configJSON.gender).required("Required"),
  phoneNumber: Yup.string().label(configJSON.phoneNumber).required("Required")
    .min(10, "Should be 10 digits only")
    .max(10, "Should be 10 digits only")
    .test("digits", "Only Digits", value => {
      return /^[0-9]+$/.test(value);
    }),
  countryCode: Yup.string()
    .required("Required"),
  city: Yup.string().label(configJSON.city)
    .test("alphabets", configJSON.errorLastName, value => {
      return /^[a-zA-Z]+$/.test(value);
    }).required("Required"),
  country: Yup.string().label(configJSON.country).required("Required"),
  email: Yup.string()
    .label(configJSON.email)
    .email(configJSON.errorValidEmail)
    .required(configJSON.errorEmailRequired)
    .required("Required")
});

const EditProfilePlayerSchema = Yup.object().shape({
  firstName: Yup.string()
    .label(configJSON.labelFirstName)
    .test("alphabets", configJSON.errorFName, value => {
      return /^[A-Za-z]+$/.test(value);
    }).required("Required"),
  lastName: Yup.string()
    .label(configJSON.lastName)
    .test("alphabets", configJSON.errorLastName, value => {
      return /^[A-Za-z]+$/.test(value);
    }).required("Required"),
  gameName: Yup.string()
    .label(configJSON.gameName)
    .test("alphabets", configJSON.errorGameName, value => {
      return /^[A-Za-z ]+$/.test(value);
    }).required("Required"),
  country: Yup.string().label(configJSON.country)
    .required("Required"),
  email: Yup.string()
    .label(configJSON.email)
    .email(configJSON.errorValidEmail)
    .required(configJSON.errorEmailRequired)
    .required("Required")
});
// Customizable Area End

import UserProfileBasicController, {
  Props
} from "./UserProfileBasicController";

export default class UserProfileBasicBlock extends UserProfileBasicController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  _renderBackDrop = () => {
    return <View style={styles.backDropBottomSheet} />;
  };

  renderHeader = () => {
    return (
      <>
        {this.state.userType === "player" ? (
          <>
            <View style={styles.headerWrapper}>
              <View style={styles.headerPlayerWrapper}>
                <TouchableOpacity
                  style={styles.backIconWrapper}
                  testID="backButton"
                  onPress={() => this.props.navigation.goBack()}
                >
                  <CIcon
                    name={"arrow-back"}
                    size={Scale(24)}
                    type={"MaterialIcons"}
                    color={"#FF751B"}
                  />
                </TouchableOpacity>
                <CText style="primary" size="sm" weight="bold">
                  {"EDIT PROFILE"}
                </CText>
              </View>
            </View>
            <View style={styles.playerImageComponent}>
              {this.state.userProfileData.player_photo !== null ? (
                <Image
                  source={{ uri: this.state.userProfileData.player_photo }}
                  style={styles.playerImage}
                />
              ) : (
                <View style={styles.playerImageWrapper} />
              )}
              <TouchableOpacity
                style={styles.playerEditImageText}
                onPress={() => {
                  this.onEditPhoto("playerProfile");
                }}
                testID="buttonEditPhotoPlayer"
              >
                <CIcon
                  type="MaterialCommunityIcons"
                  name={"camera-outline"}
                  color="#FFFFFF"
                  size={24}
                />
                <CText
                  style="white"
                  size="sm"
                  weight="bold"
                  children={"Edit photo"}
                  lineHeight={22}
                />
              </TouchableOpacity>
            </View>
          </>
        ) : (
          <>
            <View style={styles.opacity30}>
              <CCoverPhoto testID="coverPhoto"
                coverPhoto={
                  this.state.bgPath
                    ? this.state.bgPath
                    : this.state.userProfileData.background_image
                }
                coverPhotoHeight={Scale(209)}
                icons={[
                  {
                    path: backArrow,
                    position: "topLeft",
                    style: "normal",
                    onPress: () => this.props.navigation.goBack()
                  },
                  {
                    path: cameraIcon,
                    position: "bottomRight",
                    style: "normal",
                    title: configJSON.editBG,
                    onPress: () => {
                      this.onEditPhoto("bg");
                    }
                  }
                ]}
              />
            </View>

            <CProfileAvatar image={this.getAvatarUri().uri === null ? null : this.getAvatarUri().uri} isCenter={false} />

            <TouchableOpacity
              style={styles.editAvatarView}
              onPress={() => {
                this.onEditPhoto("profile");
              }}
              testID="buttonEditPhoto"
            >
              <Image source={editPencilIconBlack} style={styles.editPic} />
            </TouchableOpacity>
          </>
        )}
      </>
    );
  };

  renderFormComponent = (
    handleChange: FormikProps<any>["handleChange"],
    handleBlur: FormikProps<any>["handleBlur"],
    setFieldValue: FormikProps<any>["setFieldValue"],
    values: any,
    errors: any
  ) => {
    return (
      <>
        {this.state.userType !== "player" ? (
          <>
            <CGenderPicker
              label={configJSON.genderLabel}
              testId="GenderDropDown"
              onChangeGender={(data: GenderItem) => {
                handleChange("gender")(`${data.id}`);
              }}
              value={values.gender}
            />
            <View style={styles.countryDropdn}>
              <CountryCodeComponent
                testID={"county-code-select"}
                lable={"Phone Number"}
                selectDropdown={''}
                values={values.phoneNumber}
                onChangeText={handleChange("phoneNumber")}
                onBlur={handleBlur('phoneNumber')}
                onseleted={(data: any) => { }}
                countryCode={(data: any) => { setFieldValue("countryCode", data) }}
                selectedCountry={this.state.userProfileData?.country_code ?? 0}
              />
            </View>

            <CInput
              testID="city"
              label={configJSON.city}
              placeholder={configJSON.city}
              value={values.city}
              editable={true}
              onChangeText={handleChange("city")}
              onBlur={handleBlur("city")}
              type={"text"}
            />
          </>
        ) : (
          <CInput
            testID="gameName"
            label={"Game Name"}
            placeholder={"Game Name"}
            value={values.gameName}
            editable={true}
            onChangeText={handleChange("gameName")}
            onBlur={handleBlur("gameName")}
            type={"text"}
          />
        )}
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    let initialValues = {
      firstName: this.state.userProfileData.first_name,
      lastName: this.state.userProfileData.last_name,
      gender: this.state.userProfileData.gender?.id,
      gameName: this.state.userProfileData.user_name,
      phoneNumber: this.state.userProfileData.phone_number,
      city: this.state.userProfileData.city,
      country: this.state.userProfileData.country?.id,
      email: this.state.userProfileData.email,
      countryCode: this.state.userProfileData.country_code
    };
    let countryLabel = this.state.userType !== "player" ? configJSON.country : configJSON.Nationality
    return (
      //Required for all blocks
      <KeyboardAvoidingView testID="key"
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.flex1}
      >
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          {this.state.userType === "player" ?
            <CStatusBar backgroundColor="#1B1B3A" barStyle="light-content" />
            :
            <StatusBar translucent barStyle="light-content" />}
          <TouchableWithoutFeedback
            testID="hideKeyBoard"
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            <View>
              {this.renderHeader()}
              {this.state.isLoading ? (
                <ActivityIndicator size={"large"} color="white" />
              ) : (
                <Formik
                  // innerRef={formikRef}
                  validationSchema={
                    this.state.userType === "player"
                      ? EditProfilePlayerSchema
                      : EditProfileSchema
                  }
                  initialValues={initialValues}
                  validateOnBlur={true}
                  validateOnChange={true}
                  onSubmit={this.validateAndUpdateProfile}
                >
                  {({ handleChange, handleBlur, setFieldValue, values, errors, touched, dirty, handleSubmit, isValid }) => {
                    return (
                      <View style={styles.p20}>
                        <CInput
                          testID="txtInputFirstName"
                          label={configJSON.labelFirstName}
                          placeholder={configJSON.labelFirstName}
                          value={values.firstName}
                          editable={true}
                          onChangeText={handleChange("firstName")}
                          onBlur={handleBlur("firstName")}
                          type={"text"}
                        />

                        <CInput
                          testID="txtInputLastName"
                          label={configJSON.lastName}
                          placeholder={configJSON.lastName}
                          value={values.lastName}
                          editable={true}
                          onChangeText={handleChange("lastName")}
                          onBlur={handleBlur("lastName")}
                          type={"text"}
                        />

                        {this.renderFormComponent(
                          handleChange,
                          handleBlur,
                          setFieldValue,
                          values,
                          errors
                        )}

                        <CCountryDropDown
                          label={countryLabel}
                          testId="CountryDropDown"
                          onChangeCountry={(data: CountryItem) => {
                            handleChange("country")(`${data.id}`);
                          }}
                          value={values.country}
                        />

                        <CInput
                          testID="email"
                          label={configJSON.email}
                          placeholder={configJSON.labelEmail}
                          value={values.email}
                          onChangeText={handleChange("email")}
                          editable={true}
                          errorText={errors.email ? errors.email : ""}
                          keyboardType="email-address"
                          type={"editField"}
                          passwordOnPress={() => {
                            this.setState({
                              edtEmailEnabled: !this.state.edtEmailEnabled
                            });
                          }}
                        />
                        {this.state.userProfileData.email !== values.email ? (
                          <View style={styles.btnView}>
                            <CButton
                              testID="verifyEmailBtn"
                              style={
                                errors.email ||
                                  this.state.userProfileData.email === values.email
                                  ? "secondary"
                                  : "primary"
                              }
                              type="button"
                              size="44"
                              onPress={() =>
                                this.verifyUpdatedEmail(values.email)
                              }
                              disabled={
                                errors.email ||
                                  this.state.userProfileData.email === values.email
                                  ? true
                                  : false
                              }
                            >
                              {configJSON.verifyLabel}
                            </CButton>
                          </View>
                        ) : null}

                        <TouchableWithoutFeedback>
                          <CInput
                            testID="changePassword"
                            label={configJSON.btnTextChangePassword}
                            placeholder={configJSON.btnTextChangePassword}
                            value={"••••••"}
                            passwordOnPress={() => this.props.navigation.navigate('ChangePassword')}
                            button={true}
                            buttonOnPress={() => { }}
                            type={"editField"}
                          />
                        </TouchableWithoutFeedback>
                        <View style={styles.saveBtnView}>
                          <CButton
                            testID="saveBtn"
                            style={!isValid || !dirty ? "secondary" : "primary"}
                            type="button"
                            size="big"
                            onPress={handleSubmit}
                            disabled={!isValid || !dirty}
                          >
                            {configJSON.saveLabel}
                          </CButton>
                        </View>
                      </View>
                    )
                  }}
                </Formik>
              )}
            </View>
          </TouchableWithoutFeedback>
        </ScrollView>
        {/* <BottomSheet
          data-testid="bottom-sheet"
          ref={this.bottomSheetRef}
          index={0}
          snapPoints={this.snapPoints}
          backgroundComponent={this._renderBackDrop}
        > */}
          <View style={styles.bottomSheet}>
            <View style={styles.wrapItemBottomSheet}>
              <Text style={styles.editPhotoLabel}>
                {this.state.editType === "bg"
                  ? "Edit background picture"
                  : configJSON.editProfileMessage}
              </Text>
              <View style={styles.divider} />
              <TouchableOpacity testID="openCamara" onPress={this.openCamera}>
                <Text style={styles.editPhotoItem}>{configJSON.takePhoto}</Text>
              </TouchableOpacity>
              <View style={styles.divider} />
              <TouchableOpacity testID="choosePhoto" onPress={this.onChosseImage}>
                <Text style={styles.editPhotoItem}>
                  {configJSON.photoLibrary}
                </Text>
              </TouchableOpacity>
              <View style={styles.divider} />
              {((this.state.editType === "bg" && this.state.userProfileData.background_image) ||
                (this.state.editType === "profile" && this.state.userProfileData.photo) ||
                (this.state.editType === "playerProfile" && this.state.userProfileData.player_photo)) &&
                <TouchableOpacity testID="removeProfile" onPress={this.requestRemoveUserProfile}>
                  <Text style={styles.editPhotoItem}>
                    {configJSON.removePhoto}
                  </Text>
                </TouchableOpacity>
              }
            </View>
            <TouchableOpacity onPress={this.closeBottomSheet}>
              <View style={styles.wrapItemCancelBottomSheet}>
                <Text style={styles.editPhotoItem}>{configJSON.cancel}</Text>
              </View>
            </TouchableOpacity>

          </View>
        {/* </BottomSheet> */}
      </KeyboardAvoidingView>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleAPICall();
    // Customizable Area End
  }

  // Customizable Area Start
  handleAPICall = async () => {
    const token = await getStorageData("TOKEN");
    const userType = await getStorageData("USERTYPE");
    this.setState({ token: token, userType: userType }, () => {
      this.getUserProfile();
    });
  };
  // Customizable Area End
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: "#000024"
  },
  inputContainerStyle: {
    marginTop: Scale(10),
    borderRadius: Scale(10),
    borderColor: "#313351",
    borderWidth: Scale(1.5),
    color: "#fff",
    backgroundColor: "#1B1B3A"
  },
  image: {
    height: Scale(190),
    width: "100%",
    backgroundColor: "#0085FF",
    paddingHorizontal: Scale(20)
  },
  backViewStyle: {
    marginTop: Scale(20),
    paddingVertical: Scale(10),
    paddingEnd: Scale(10),
    width: Scale(53)
  },
  backImageStyle: {
    height: Scale(30),
    width: Scale(30)
  },
  bgMainView: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: -Scale(110),
    marginHorizontal: Scale(20)
  },
  avatarView: {
    width: Scale(128),
    height: Scale(128),
    borderRadius: Scale(100),
    justifyContent: "center",
    backgroundColor: "#E2E6F0",
    overflow: "hidden",
    marginTop: -Scale(100),
    marginLeft: Scale(20)
  },
  editCoverPageView: { alignItems: "center" },
  editTxt: {
    color: "#fff",
    marginTop: Scale(5),
    fontWeight: "700",
    fontSize: Scale(16)
  },
  p20: {
    padding: Scale(20)
  },
  editRightTouchable: {
    paddingHorizontal: Scale(18),
    paddingVertical: Scale(8)
  },
  editImage: {
    height: Scale(18),
    width: Scale(18)
  },
  btnView: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginEnd: Scale(5)
  },
  saveBtnView: {
    marginTop: Scale(24),
    marginHorizontal: Scale(10),
    marginBottom: Scale(75)
  },
  editAvatarView: {
    width: Scale(32),
    height: Scale(32),
    borderRadius: Scale(32 / 2),
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: Scale(205),
    left: Scale(110)
  },
  editPic: {
    width: Scale(18),
    height: Scale(18)
  },
  divider: {
    width: "100%",
    backgroundColor: "#f5f5f5",
    height: 1
  },
  bottomSheet: {
    paddingHorizontal: 8,
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  editPhotoLabel: {
    padding: 8,
    textAlign: "center"
  },
  editPhotoItem: {
    padding: 12,
    textAlign: "center",
    fontWeight: "600",
    color: "black"
  },
  backDropBottomSheet: {
    backgroundColor: "red"
  },
  wrapItemBottomSheet: {
    backgroundColor: "white",
    borderRadius: 12
  },
  wrapItemCancelBottomSheet: {
    backgroundColor: "white",
    borderRadius: 12,
    padding: 8,
    marginTop: 6
  },
  stickyIcon: {
    position: "absolute",
    top: 10,
    right: 10
  },
  headerWrapper: {
    flexDirection: "row",
    paddingTop: Scale(24),
    padding: Scale(24),
    backgroundColor: "#1B1B3A",
    alignItems: "center",
    borderBottomRightRadius: 24,
    zIndex: 100
  },
  backIconWrapper: {
    marginRight: Scale(24)
  },
  playerImageComponent: {
    flex: 1
  },
  playerImage: {
    height: Scale(204),
    marginHorizontal: Scale(24),
    marginVertical: Scale(16),
    borderRadius: Scale(8),
    opacity: 0.48
  },
  playerImageWrapper: {
    height: Scale(204),
    marginHorizontal: Scale(24),
    marginVertical: Scale(16),
    borderRadius: Scale(8),
    backgroundColor: "#2C314B",
    opacity: 0.48
  },
  playerEditImageText: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  headerPlayerWrapper: { flex: 1, flexDirection: "row" },
  flex1: { flex: 1 },
  avatarPlaceholderWrapper: {
    overflow: "hidden",
    marginTop: -Scale(100),
    marginLeft: Scale(20),
    height: 128, width: 128, borderRadius: 100, backgroundColor: "#1B1B3A", justifyContent: "center",
    alignItems: "center"
  },
  avatarPlaceHolderView: { height: 83, width: 83, borderRadius: 100, borderWidth: 5, borderColor: "#FF751B", alignItems: "center", justifyContent: "center" },
  avatarPlaceholderImage: { height: 40, width: 40, resizeMode: "contain" },
  countryDropdn: { zIndex: 1, marginHorizontal: 9 },
  opacity30: { opacity: 1 }
});
// Customizable Area End
