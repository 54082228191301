import React, { useState } from "react";
import { Icon, ListItem } from "react-native-elements";
import Scale from "../Scale";
import { Text, TouchableOpacity, View, StyleSheet, 
  // Image,
  FlatList, TextInput, TouchableWithoutFeedback } from "react-native";
import { India, Germany, Canada, Europe, Uk, France, USA } from "../assets";
import { getFontFamily } from "framework/src/Utilities";
interface Props {
  onBlur: any;
  testID?: any;
  values?: any;
  onChangeText?: any;
  lable?: string;
  onseleted?: any;
  downloadCondent?: any;
  selectDropdown?: any;
  borderStatus?: any;
  countryCode?: any;
  selectedCountry?: any;
}

export default function CustomCountryCode(props: Props) {
  const { countryCode, } = props
  const { onseleted, selectDropdown } = props
  const [dropdown, setDropdown] = useState(false)
  const [selectedValues, setSelectedValues] = useState('')
  const [selectedImage, setSelectedImage] = useState('')

  React.useEffect(() => {
    setDropdown(dropdown);

    const country: any = COUNTRYData.find((country: any) => country.countryCode === props.selectedCountry);
    setSelectedImage(country?.imageSrc || "");
    if (country?.title) {
      setSelectedValues(country.title)
    }
    console.log("user:", selectedImage, "+", selectedValues, "+", props.selectedCountry)

  }, [])
  const COUNTRYData: any = [
    // { title: "Germany", id: 1, countryCode: 49, imageSrc: Image.resolveAssetSource(Germany).uri },
    // { title: "Canada", id: 4, countryCode: 1, imageSrc: Image.resolveAssetSource(Canada).uri },
    // { title: "India", id: 2, countryCode: 91, imageSrc: Image.resolveAssetSource(India).uri },
    // { title: "United kingdom", id: 3, countryCode: 44, imageSrc: Image.resolveAssetSource(Uk).uri },
    // { title: "France", id: 5, countryCode: "33", imageSrc: Image.resolveAssetSource(France).uri },
    // { title: "United States", id: 6, countryCode: 1, imageSrc: Image.resolveAssetSource(USA).uri },

  ];
  const COUNTRY_ARR = [
    { title: "Canada", id: 4, countryCode: '1', imageSrc: Canada },
    { title: "France", id: 5, countryCode: "33", imageSrc: France },
    { title: "Germany", id: 1, countryCode: '49', imageSrc: Germany },
    { title: "India", id: 2, countryCode: '91', imageSrc: India },
    { title: "United kingdom", id: 3, countryCode: '44', imageSrc: Uk },
    { title: "United States", id: 6, countryCode: 1, imageSrc: USA },

  ];
  const _renderItems = ({ item }: any) => {
    return (
      <TouchableOpacity onPress={() => { setSelectedValues(item.title), setDropdown(!dropdown), onseleted(item), setSelectedImage(""), countryCode(item.countryCode) }}>
        <ListItem
          containerStyle={styles.containerStyle}
          // leftElement={<><Image source={item.imageSrc} style={styles.dropdownimgSty} /></>}
          title={<><Text style={styles.dropdownTextSty}>{item.title}</Text></>}
        />
      </TouchableOpacity>
    )
  }
  const _itemSeperated = () => { return (<View style={styles.itemSepertedView} />) }
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.textInputTitle}>{props.lable}</Text>
        <ListItem
          containerStyle={styles.buttonContainerSty}
          leftElement={
            <View style={{ flex: 0.3 }}>
              <TouchableOpacity
                testID={props.testID}
                activeOpacity={0.2}
                onPress={() => { setDropdown(!dropdown) }}
              >
                <View style={styles.imgInnerViewSty}>
                  {/* {selectedValues ? <Image source={{ uri: selectedImage }} style={styles.imgSty} /> : <Text style={styles.ctySty}>{'Country'}</Text>} */}
                  <Icon type={'MaterialIcons'} name={dropdown ? 'arrow-drop-up' : "arrow-drop-down"} color="#747B92" size={Scale(30)} />
                </View>
              </TouchableOpacity>
            </View>}
          title={<View style={styles.inputViewSty}>
            <TextInput
              testID={props.testID}
              placeholder={'Number'}
              maxLength={10}
              style={props.values ? styles.inputSty : styles.placeholderStyle}
              keyboardType={'number-pad'}
              placeholderTextColor={'rgba(105,105,105,0.9)'}
              onChangeText={props.onChangeText}
              value={props.values}
              onBlur={props.onBlur}
            />
          </View>}
        />
        {dropdown ?
          <View style={styles.dropdownViewSty}>
            <View style={styles.inteldropdownViewSty}>
              <FlatList
                data={COUNTRY_ARR}
                extraData={COUNTRY_ARR}
                nestedScrollEnabled={true}
                overScrollMode={'auto'}
                renderItem={(item) => _renderItems(item)}
                keyExtractor={(item, index) => index.toString()}
                ItemSeparatorComponent={() => _itemSeperated()}
              />
            </View>
          </View>
          : null}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
  },
  textInputTitle: {
    opacity: 1,
    backgroundColor: "transparent",
    includeFontPadding: false,
    padding: Scale(0),
    color: "#fff",
    textAlign: "left",
    textAlignVertical: "top",
    marginTop: Scale(10),
    fontFamily: getFontFamily('bold'),
    fontSize: Scale(16),
    fontWeight: '600'
  },
  dropdownViewSty: {
    height: Scale(200),
    width: '100%',
    backgroundColor: "#1B1B3A",
    overflow: 'visible',
    borderRadius: 10,
    alignSelf: 'center'
  },
  buttonContainerSty: {
    height: Scale(55),
    marginTop: Scale(10),
    borderRadius: Scale(10),
    borderColor: '#313351',
    borderWidth: Scale(1.5),
    paddingVertical: Scale(5),
    color: '#fff',
    justifyContent: 'center',
    backgroundColor: "#1B1B3A",

  },
  imgSty: {
    height: Scale(25),
    width: Scale(25),
    resizeMode: 'cover',
    alignSelf: 'center',
    paddingHorizontal: Scale(20)
  },

  containerStyle: {
    backgroundColor: '#313351',
    borderRadius: Scale(10),
    zIndex: 999,
  },
  imgInnerViewSty: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  inputSty: {
    color: "#fff",
    fontSize: Scale(16),
    fontFamily: getFontFamily('bold'),
    marginLeft: '-3%',
  },
  inputViewSty: {
    flex: 1,
    justifyContent: 'center',
  },
  itemSepertedView: {
    padding: Scale(5)
  },
  inteldropdownViewSty: {
    padding: Scale(5)
  },
  dropdownimgSty: {
    height: Scale(12.5),
    width: Scale(25),
    resizeMode: 'cover',
  },
  dropdownTextSty: {
    color: '#ffff',
    fontSize: Scale(15),
    fontWeight: '500'
  },
  ctySty: {
    fontSize: Scale(16),
    color: 'rgba(105,105,105,1)',
    fontFamily: getFontFamily("light"),
    alignSelf: 'center'
  },
  flag: {
    color: 'white',
    fontSize: 20
  },
  placeholderStyle: {
    fontSize: Scale(16),
    fontFamily: getFontFamily('light'),
    color: "rgba(105,105,105,0.9)",
    marginLeft: '-3%',
  },
})

