import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import { getStorageData } from "../../../../framework/src/Utilities";
import { CountryItem } from '../types';
// Customizable Area End


export interface Props {
  testId: string;
  // Customizable Area Start
  value: string;
  onChangeCountry: (item: CountryItem) => void;
  label?: string
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  countryList: CountryItem[];
  countryPickerShow: boolean;
  defaultValue: string;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CCountryDropDownController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCountryDataAPICallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      countryList: [],
      countryPickerShow: false,
      defaultValue: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson && apiRequestCallId === this.getCountryDataAPICallId && responseJson.length > 0) {
        const foundCountryItem = responseJson.find((item: any) => item.id == this.props.value);
        this.setState({ countryList: responseJson, defaultValue: foundCountryItem?.name })

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    this.getCountryList();
  }

  getCountryList = async () => {
    const token = await getStorageData('TOKEN');
    const header = {
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryDataAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_profile/countries"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  // Customizable Area End
}
