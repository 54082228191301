import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert } from "react-native";
import _ from "lodash"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  threadCommentsData: any;
  isLoading: boolean;
  comment: string;
  reportsReasonList: any[],
  isModalVisible: boolean,
  reportToolTipVisible: any,
  commentReportReview: any,
  commentId: string;
  toastMessage: string;
  accountId: number;
  modalIsOpen: boolean
  selectedFilter: 'likes' | 'comments' | 'clear'
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Communityforum4Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCommentsByThreadId: any;
  threadCommentLikeDislikeApiCallId: any;
  createCommentsOnThreadCallId: any;
  getReportReasonsListApiCallId: any;
  commentReportRequestApiCallId: any;
  flatListRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedFilter: 'clear',
      modalIsOpen: false,
      threadCommentsData: [],
      isLoading: true,
      comment: "",
      reportsReasonList: [],
      isModalVisible: false,
      reportToolTipVisible: "",
      commentReportReview: "",
      commentId: "",
      toastMessage: "",
      accountId: -1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.apiGetCommentsByThreadId:
            this.setCommentsResponse(responseJson);
            break;
          case this.createCommentsOnThreadCallId:
            this.setCommentsResponse(responseJson);
            break;
          case this.getReportReasonsListApiCallId:
            this.setReportReasonsData(responseJson);
            break;
          case this.commentReportRequestApiCallId:
            this.setCreateCommentReportData(responseJson);
            break;
          case this.threadCommentLikeDislikeApiCallId:
            this.handleLikeDislikeResponse(responseJson)
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const accountId = await getStorageData("ACCOUNT_ID")
    this.setState({ accountId });
    this.getCommentsByThreadId(false);
  }
  handleOpenMenu = () => {
    this.setState({
      modalIsOpen: true
    })
  }
  handleCloseMenu = () => {
    this.setState({
      modalIsOpen: false
    })
  }

  apiCallForThreadComments = async (mostLikedParam: boolean) => {
    const token = await getStorageData('TOKEN');
    const threadId = this?.props?.navigation?.state?.params?.threadId;
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCommentsByThreadId = requestMessage.messageId;
    if (mostLikedParam) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCommentsByThreadId}${Number(threadId)}?most_likes=true`
      );
    } else {
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCommentsByThreadId}${Number(threadId)}`
      );
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCommentsByThreadId = (mostLikedParam: boolean) => {
    this.setState({
      selectedFilter: mostLikedParam ? 'likes' : 'comments',
    }, () => this.apiCallForThreadComments(mostLikedParam))
  }

  onComment = (values: any) => {
    this.setState({ comment: values })
  }

  trimWhitespaceAndNewlines = (str: string) => {
    // Use a regular expression to match trailing whitespace and newlines
    // only if there's no text after them
    const trimmed = str.replace(/\s+(?![^\s])/g, '');
    return trimmed;
  }

  createCommentOnThread = async () => {
    const threadId = this?.props?.navigation?.state?.params?.threadId;
    const { comment } = this.state;
    const commentData = this.trimWhitespaceAndNewlines(comment)

    if (comment.length === 0) {
      return false;
    }

    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": configJSON.threadCommentLikeDislikeAPIContentType,
      token: token,
    };
    const bodyData = {
      id: Number(threadId),
      comment: commentData
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createCommentsOnThreadCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.commentOnThreadEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodyData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ comment: "" });
  };

  postComment = () => {
    const { comment } = this.state;
    this.hideKeyboard();
    if (!this.trimWhitespaceAndNewlines(comment)) {
      Alert.alert("Please write a comment before submitting")
    } else {
      this.createCommentOnThread();
    }
  }

  likeOnThreadComment = _.debounce((item: any, status: boolean) => {
    this.handleLikeDislikeOnThread(item.id, status)
  }, 1000);

  handleLikeDislikeOnThread = async (commentId: any, status: boolean) => {
    const token = await getStorageData('TOKEN');
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.threadCommentLikeDislikeApiCallId = requestMessage.messageId;
    if (status === false) {
      const header = {
        token: token
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.threadCommentLikeDislikeAPIEndPoint}/${Number(commentId)}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.DELETE
      );
    } else {
      const likeAPIHeader = {
        "Content-Type": configJSON.threadCommentLikeDislikeAPIContentType,
        token: token
      };
      const bodyData = {
        id: Number(commentId),
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.threadCommentLikeDislikeAPIEndPoint}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(likeAPIHeader)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.POST
      );
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleLikeDislikeResponse = (responseJson: any) => {
    if (responseJson.errors) {
      return this.parseApiErrorResponse(responseJson);
    }
  }

  scrollToTop = () => {
    this.flatListRef?.current?.scrollToIndex({ animated: true, index: 0 });
  }

  setCommentsResponse = (responseJson: any) => {
    this.setState({ isLoading: false });
    if (!responseJson.errors && responseJson.data) {
      this.setState({
        threadCommentsData: responseJson?.data?.attributes
      }, () => this.scrollToTop())
    } else {
      //Check Error Response
      this.parseApiCatchErrorResponse(responseJson.errors[0]);
    }
  }

  getReportReasonsList = async () => {
    let token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getReportReasonsListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReportReasonList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.GET
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setReportReasonsData = (responseJson: any) => {
    this.setState({
      isLoading: false,
      reportsReasonList: responseJson
    });
  }

  createReportThreadComment = async () => {
    const selectCommentReasonList = this.state.reportsReasonList.filter((element: any) => element.isSelected).map((item: any) => item.id);
    let token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": "application/json",
      token: token
    };
    const body = {
      "report": {
        "reportable_id": this.state.commentId,
        "reportable_type": configJSON.reportableType,
        "report_reason": this.state.commentReportReview,
        "reason_ids": selectCommentReasonList
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.commentReportRequestApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCommentReportAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.POST
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  setCreateCommentReportData = (responsesJson: any) => {
    let reasonArray = [...this.state.reportsReasonList];
    let reasonFinalArray = reasonArray.map((item, i) => {
      return { ...item, isSelected: false };
    });
    this.setState({
      isLoading: false,
      isModalVisible: false,
      reportsReasonList: reasonFinalArray,
      commentReportReview: '',
      toastMessage: "Comment has been reported."
    });
  }
  // Customizable Area End
}
