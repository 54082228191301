import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { deviceWidth, scaledSize } from "framework/src/Utilities";
import { CText } from '../CText/CText';
import Scale from '../../Scale';

interface AvatarRequiredProps {
  color: string,
  icon: string,
  iconColor: string,
  msg: string
}

export default class CustomToastAlert extends Component<AvatarRequiredProps> {

  render() {
    const { color, icon, iconColor, msg } = this.props;
    return (
      <View style={[styles.body, { backgroundColor: color }]}>
        <MaterialCommunityIcons name={icon} color={iconColor} size={scaledSize(24)} />
        <View style={styles.text}>
          <CText style='#000024' size='base' weight='light' children={msg} />
        </View>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  body: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: deviceWidth - scaledSize(20),
    paddingLeft: scaledSize(10),
    borderRadius: scaledSize(8),
    backgroundColor: "#b6f8c4",
    height: Scale(42)
  },
  text: {
    flex: 1,
    marginHorizontal: scaledSize(10)
  }
});
