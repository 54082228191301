import React, { Component } from 'react';
import { View, Image, StyleSheet, Platform } from 'react-native';
import { CText } from '../CText/CText';
import { checkGreenIcon, checkGreyIcon } from '../../assets';
import Scale from '../../Scale';

interface Props {
    password: string;
    onPasswordValidated?: (isValid: boolean) => void;
    testID?:string;
}

interface State {
    isPasswordValid: boolean;
}
const regNumber = /^(?=.*\d)/;
const regUpper = /^((?=.*[A-Z]))/;
const regLower = /^((?=.*[a-z]))/;
const regMinmum8 = /^(?=.{8,16})/;
const regSpecialChar = /^(?=.*[!@#$%^&*])/;
export default class CValidatePassword extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isPasswordValid: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.password !== this.props.password) {
            this.checkPasswordValidity();
        }
    }

    checkPasswordValidity() {
        const { password } = this.props;
        const isNumberValid = regNumber.test(password);
        const isUpperValid = regUpper.test(password);
        const isLowerValid = regLower.test(password);
        const isMinLengthValid = regMinmum8.test(password);
        const isSpecialCharValid = regSpecialChar.test(password);

        const isPasswordValid =
            isNumberValid && isUpperValid && isLowerValid && isMinLengthValid && isSpecialCharValid;

        this.setState({ isPasswordValid }, () => {
            const { onPasswordValidated } = this.props;
            onPasswordValidated?.(isPasswordValid);
        });
    }

    renderCheckField(title: string, isVerified: boolean) {
        return (
            <View style={styles.checkPwd}>
                {isVerified ? <Image source={checkGreenIcon} style={styles.img} /> : <Image source={checkGreyIcon} style={styles.img} />}
                <CText size="sm" style="white" weight="light">
                    {title}
                </CText>
            </View>
        );
    }

    render() {
        const { password } = this.props;

        return (

            <View style={styles.passwordCheck}>
                <View style={{ marginBottom: 8 }}>
                    <CText style="white" size="base" weight="light">
                        Your password must contain
                    </CText>
                </View>
                {this.renderCheckField('At least one capital letter', regUpper.test(password))}
                {this.renderCheckField('At least one lowercase letter', regLower.test(password))}
                {this.renderCheckField('At least one number', regNumber.test(password))}
                {this.renderCheckField('Minimum character length is 8 characters', regMinmum8.test(password))}
                {this.renderCheckField('At least one special character', regSpecialChar.test(password))}
            </View>

        );
    }
}

const styles: any = StyleSheet.create({
    passwordCheck: {
        marginVertical: Scale(12),
        marginHorizontal:Scale(20),
        justifyContent: 'space-between',
    },
    checkPwd: { marginVertical: 4, flexDirection: 'row',alignItems:'center' },
    img: { marginRight: Scale(5), marginVertical: Platform.OS == "ios" ? 2 : 6 ,height:Scale(20),width:Scale(20)},
});
