import React, { Component } from 'react';
import { View, StyleSheet, FlatList, } from 'react-native';
import Scale from '../../Scale';
import { CText } from "../CText/CText";
import CIcon from '../CIcon/CIcon';
import CGradient from '../CGradient/CGradient';

interface Props {
  data: string[];
  testID?: string,
  containerStyle?: any
  showSeparator?: boolean
  itemStyle?: any
  innerStyle?: any
}

export default class SubscriptionNote extends Component<Props> {

  getStyles() {
    const { containerStyle, itemStyle, innerStyle } = this.props;
    return {
      containerStyles: {
        ...styles.flatListStyle,
        ...containerStyle
      },
      innerStyles: {
        ...styles.containerStyle,
        ...innerStyle
      },
      itemStyles: {
        ...styles.itemContainer,
        ...itemStyle
      },
    }
  }

  itemSeparator() {
    return <View style={styles.separatorStyle} />
  };

  render() {
    const { data, testID, showSeparator } = this.props;
    const { containerStyles, itemStyles,innerStyles } = this.getStyles()
    return (
      <>
        <FlatList
          data={data}
          testID={testID}
          contentContainerStyle={innerStyles}
          style={containerStyles}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item, index }) =>
            <>
              <View style={itemStyles}>
                <CGradient style={styles.iconContainer}>
                  <CIcon type='MaterialCommunityIcons' color={'white'} name={'check'} size={Scale(15)} />
                </CGradient>
                <CText style={'white'} size={'sm'} weight={'bold'} lineHeight={24} >{item}</CText>
              </View>
            </>
          }
          ItemSeparatorComponent={showSeparator ? this.itemSeparator : null}
        />
      </>
    );
  }
};

const styles = StyleSheet.create({
  flatListStyle: { flexGrow: 0 },
  containerStyle: { backgroundColor: '#1B1B3A', padding: Scale(20), borderRadius: Scale(10), justifyContent: 'center', },
  itemContainer: { flexDirection: 'row', alignItems: 'center' },
  iconContainer: { height: Scale(22), width: Scale(22), borderRadius: Scale(22), justifyContent: 'center', alignItems: 'center', marginRight: Scale(10) },
  separatorStyle: { height: 1, backgroundColor: '#FFFFFF', marginVertical: Scale(10) }
})
