import React, { Component } from "react";
import { View, StyleSheet, Image, Text, Dimensions, ImageStyle, TextStyle, FlatList, ScrollView, TouchableOpacity } from "react-native";
import Scale from "../../Scale";
import { CText } from "../CText/CText";
import { profileIconPlaceholder } from "../../../../blocks/events/src/assets";
import CGradient from "../CGradient/CGradient";


interface imageProps {
    path: any,
}

// Required props
interface AvatarRequiredProps {
    image: imageProps;
    title: string;
    titleStyle?: any;
    bgContainer?: any;
    text?: string;
    txtStyle?: TextStyle;
    numberOfLines?: number;
    tot?: number | any;
    isNumber?: boolean;
    playerNum?: number | any;
    scoreOfEvents?: any;
    id: any
    navigation: any
}
let screenWidth = Dimensions.get("window").width;


export default class CAvatarPlayer extends Component<AvatarRequiredProps> {

    getStyles() {
        const { titleStyle, txtStyle, bgContainer, } = this.props;
        return {

            containerStyles: {
                ...styles.containerStyle,
            },
            innerContainerStyles: {
                ...styles.innerContainerStyle,
                ...bgContainer
            },
            txtTitle: {
                ...styles.titleStyle,
                ...titleStyle
            },
            txtstyle: {
                ...styles.txtstyle,
                ...txtStyle
            }

        }
    }

    render() {
        const { image, title, tot, isNumber, playerNum, scoreOfEvents, id, navigation } = this.props;
        const { containerStyles, innerContainerStyles } = this.getStyles()
        return <View style={containerStyles}>
            <TouchableOpacity
                onPress={() => navigation.navigate("ViewPlayerProfile", {
                    comesFrom: "other", id: id
                })}
                style={[innerContainerStyles,]}>
                {isNumber ? <View style={styles.numberView}>
                    <CText size="sm" style="white" weight='light' numberOfLines={1}>
                        {playerNum}
                    </CText>
                </View> : <></>}
                <CGradient style={styles.gradientBorder}>
                    <Image
                        source={image?.path ? { uri: image?.path } : profileIconPlaceholder}
                        style={styles.playerPhotoView}
                    />
                </CGradient>
                <View style={{ width: screenWidth * 0.3, }}>
                    <CText size="base" style="white" weight='bold' numberOfLines={2}>
                        {title}
                    </CText>
                </View>
            </TouchableOpacity>
        </View>
    }
}

const styles = StyleSheet.create({
    containerStyle: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    gradientBorder: {
        height: Scale(40), width: Scale(40),
        borderRadius: Scale(100),
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
        marginHorizontal: Scale(10),
    },
    playerPhotoView: {
        width: 34,
        height: 34,
        borderRadius: 16,
        alignSelf: "center"
    },
    iconStyle: {
        marginLeft: Scale(10)
    },
    innerContainerStyle: {
        height: 64,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#38761D',
        width: screenWidth * 0.45
    },
    sm: {
        height: Scale(32),
        width: Scale(32),
        borderRadius: Scale(32)
    },
    base: {
        height: Scale(44),
        width: Scale(44),
        borderRadius: Scale(44)
    },
    lg: {
        height: Scale(56),
        width: Scale(56),
        borderRadius: Scale(56)
    },
    xl: {
        height: Scale(132),
        width: Scale(132),
        borderRadius: Scale(132)
    },
    xxl: {
        height: Scale(156),
        width: Scale(156),
        borderRadius: Scale(156)
    },
    txtstyle: {
        color: '#FFFFFF',
        fontSize: 10,
    },
    titleStyle: {
        width: screenWidth * 0.32,
        color: '#FFFFFF'
    },
    viewPoint: {
        flexDirection: 'row',
        alignItems: 'center',
        width: screenWidth * 0.45,
        paddingHorizontal: 15
    },
    viewTotal: {
        width: screenWidth * 0.1,
        paddingLeft: Scale(10)
    },
    numberView: {
        borderRadius: 5,
        marginLeft: Scale(10),
        height: screenWidth * 0.055,
        width: screenWidth * 0.055,
        backgroundColor: '#FF002F',
        alignItems: 'center'
    },
    scoreView: {
        width: screenWidth * 0.115,
        paddingLeft: Scale(5)
    }
});