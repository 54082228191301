import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { sendAPIRequest } from "../../../framework/src/Utilities";
import { Platform } from "react-native";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: any;
  userDetails: any;
  isLoading: boolean;
  userType: string;
}

interface SS {
  id: any;
}

export default class ViewPlayerProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  userProfileGetApiCallId: any;
  _unsubscribe: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      userDetails: {},
      isLoading: false,
      userType: ""
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (
        apiRequestCallId &&
        responseJson &&
        apiRequestCallId === this.userProfileGetApiCallId
      ) {
        this.getUserDetails(responseJson, errorReponse);
      }
    }
    // Customizable Area End
  }

  getUserDetails = (response: any, errorRes: any) => {
    if (!response.errors && response.data && response.data.attributes) {
      if (
        response.data.attributes !== null &&
        response.data.attributes !== undefined
      ) {
        this.setState({
          userDetails: response.data.attributes,
          isLoading: false
        });
      }
    } else {
      //Check Error Response
      this.setState({ isLoading: false });
      if (Platform.OS !== "web") {
        this.handleError(response);
      }
    }

    this.parseApiCatchErrorResponse(errorRes);
  };

  handleError = (responseJson: any) => {
    if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].token
    ) {
      this.showAlert("Session Expired", "Please Log in again.");
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  getPlayerProfile() {

    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.state.token
    };
    const apiEndPoint = "/account_block/accounts/show_player_profile?id=" + this.props.navigation?.state?.params.id;
    const method = configJSON.methodTypeApiGetPlayerProfile;

    this.userProfileGetApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }

  async componentWillUnmount() {
    if (this._unsubscribe) {
      this._unsubscribe();
    }
  }
}
