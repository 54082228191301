import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native';
import { CText } from '../CText/CText';
import Scale from '../../Scale';
import CIcon from '../CIcon/CIcon';
import { commentIcon } from '../../assets';

interface Props {
  testId?: any;
  onPress?: any;
  commentCount?: any;
  isUsedInForum?: boolean;
}

interface S {

}

export default class CComment extends Component<Props, S> {
  state = {
    commentCount: this.props.commentCount,
    isGuest: false,
  };

  toggleHeart = () => {
    this.props.onPress();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.commentCount !== this.props.commentCount) {
      this.setState({ commentCount: this.props.commentCount });
    }
  };

  render() {
    return (
      <View style={styles.iconViewSty}>
        <TouchableOpacity
          testID={this.props.testId}
          onPress={this.toggleHeart}
          style={styles.iconViewSty}
        >
          <Image source={commentIcon} style={{ height: Scale(20), width: Scale(20), resizeMode: "contain", marginRight: Scale(6) }} />
          <CText style={this.props.isUsedInForum ? "white" : "gray"} size={"xs"} weight={this.props.isUsedInForum ? "light" : "bold"} lineHeight={26}>
            {this.state.commentCount}
          </CText>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles: any = StyleSheet.create({
  iconViewSty: {
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center"
  },
});
