import React from 'react'
import { StyleSheet, View, Animated, Easing, ViewStyle, Dimensions, StyleProp } from 'react-native'
import { heightFromPercentage, widthFromPercentage } from '../../../../framework/src/Utilities'
import Scale from '../../Scale'
import { CText } from '../CText/CText'
import CIcon from '../CIcon/CIcon'

const WIDTH = Dimensions.get('screen').width

interface ProgressProp {
  max?: number,
  activeColor?: string,
  duration?: number,
  width?: number,
  height?: number,
  value: number,
  borderRadius?: number,
  spaceVertical?: number,
  style?: StyleProp<ViewStyle>,
  testID?: string
  title?: string
  isOnlyProgress?: boolean;
  isSelected: boolean;
}

const CProgressBar = (props: ProgressProp) => {
  // Customizable Area Start
  return (
    <View testID={props.testID} style={[styles.mainContainer, { opacity: props.isSelected ? 1 : 0.5 }]}>

      <View style={styles.subMainWrapper}>
        <View style={styles.titleWrapper}>
          <CText style={props.isSelected ? "primary" : "white"} size="sm" weight='bold' >{props?.title}</CText>
          {props.isSelected &&
            <View style={styles.iconWrapper}>
              <CIcon color='#ffffff' name={"check-circle-outline"} size={20} type='MaterialCommunityIcons' />
            </View>}
        </View>
        <View style={styles.valueContainer}>
          <CText style="primary" size="sm" weight='bold' >{props?.value}%</CText>
        </View>
      </View>
      <View style={styles.fullBar}>
        <View style={[styles.responseBae, { width: `${props?.value}%` }]} />
      </View>

    </View>
  )
  // Customizable Area End
}

export default CProgressBar

const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    borderRadius: 50,
    alignItems: 'flex-start',
    flex: 1
  },
  view: {
    backgroundColor: 'white',
    width: "100%",
    height: "100%",
    justifyContent: 'center',
    paddingLeft: Scale(10),
  },
  mainContainer: {
    alignItems: "center",
    marginBottom: Scale(16)
  },
  valueContainer: {
    marginLeft: Scale(15)
  },
  subMainWrapper: { flexDirection: "row", marginBottom: Scale(10) },
  titleWrapper: { flex: 1, flexDirection: "row", alignItems: "center" },
  iconWrapper: { marginLeft: Scale(10) },
  fullBar: {
    width: '100%',
    height: Scale(16),
    backgroundColor: '#1B1B3A',
    borderRadius: 50,
    overflow: 'hidden',
  },
  responseBae: {
    height: '100%',
    backgroundColor: '#FF751B',
    borderRadius: 50,
  }

  // Customizable Area End
})