import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, View, } from "react-native";
// import LinearGradient from 'react-native-linear-gradient';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import Scale from '../../Scale';

interface Props {
	isCheckedBox: boolean;
	onPress: any;
	disabled?: any;
	testID?:string
}

export default class CGradientCheckBox extends Component<Props> {
	render() {
		const { isCheckedBox, onPress, disabled, testID } = this.props
		return (
			<>
				<TouchableOpacity
					testID={testID}
					activeOpacity={0.8}
					hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
					disabled={disabled}
					onPress={onPress}>
					{/* {isCheckedBox ?
						<LinearGradient colors={['#FF002F', '#FF751B']}
							start={{ x: 0.25, y: 0.75 }} end={{ x: 1, y: 0.35 }}
							style={styles.gradientStyle}>
							<Icon name="check" size={Scale(20)} color={'#fff'} />
						</LinearGradient>
						: <View style={styles.gradientStyle}>
							<Icon name='checkbox-blank-outline' size={Scale(25)} color={'#9BA2B7'} style={{}} />
						</View>} */}
				</TouchableOpacity>
			</>
		)
	}
}

const styles: any = StyleSheet.create({
	gradientStyle: {
		height: Scale(22),
		width: Scale(22),
		borderRadius: Scale(6),
		justifyContent: 'center',
		alignItems: 'center',
		// marginLeft: Scale(10),
	}
});
