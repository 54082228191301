import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
} from "react-native";
import DashboardController, { Props, configJSON } from "./DashboardController";
import { CEventDetail, CHeader, CMatchSchedule, CPlayerList, CPlayerRanking, CStatusBar, CToggleComponent, CEventImage } from "../../../components/src/custom-components";
import Loader from "../../../components/src/Loader";


// Customizable Area End
export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  handleToggleChange = (selectedIndex: number) => {
    this.setState({ activeToggleButton: selectedIndex });
  }

  handleModal = () => {
    this.setState({ openModal: !this.state.openModal })
  }
  renderViewBasedOnSelectedToggleButton = () => {
    const { activeToggleButton, eventMatchData, eventPlayerRanking, eventPlayersData } = this.state;
    const { navigation } = this.props
    if (activeToggleButton === 2) {
      return <CMatchSchedule navigation={navigation} matchData={eventMatchData} isFromDashboard={true} />
    } else if (activeToggleButton === 3) {
      return <CPlayerRanking navigation={navigation} rankingData={eventPlayerRanking} />
    } else {
      return <CPlayerList navigation={navigation} playerData={eventPlayersData} />
    }
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { toggleOptions, isLoading, eventDetailsData, openModal } = this.state;
    return (
      //Merge Engine DefaultContainer
      <View style={styles.eventDetailContainer} >
        <CStatusBar
          backgroundColor="rgba(0, 0, 36, 1)"
          barStyle="light-content"
        />

        <View style={styles.mainContainerStyle}>
          <View style={styles.gray900Background}>
            <CHeader
              testID='headerView'
              icons={[
                { testID: 'closeBtn', type: 'icon', icon: 'back', iconColor: '#FF751B', position: 'left', onPress: () => this.props.navigation.goBack() },
                { type: 'text', title: `${configJSON.ongoingEventDetailScreenHeaderTitle}`, titleStyle: 'primary', position: 'center', titleSize: 'base', titleWeight: 'bold' },
                { type: 'icon', icon: 'eventHeaderImg', position: 'right', onPress: () => { this.handleModal() } },
              ]} />
          </View>
          {
            isLoading ?
              <Loader loading={isLoading} />
              : <ScrollView showsVerticalScrollIndicator={false}>
                <CEventDetail
                  eventDetailData={eventDetailsData}
                  isFromDashboard={true}
                />
                <View style={styles.toggleContainer}>
                  <CToggleComponent
                    testID={"btnToggle"}
                    array={toggleOptions}
                    onSelect={this.handleToggleChange}
                    customVerticalPadding
                    customFont="base"
                  />
                  {this.renderViewBasedOnSelectedToggleButton()}
                  <CEventImage modalState={openModal} url={eventDetailsData?.event_image} onClick={this.handleModal} />
                </View>
              </ScrollView>}
        </View>
      </View>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  eventDetailContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 36, 1)"
  },
  mainContainerStyle: {
    backgroundColor: "rgba(0, 0, 36, 1)",
    flex: 1,
  },
  gray900Background: {
    backgroundColor: "#000024",
  },

  toggleContainer: {
    backgroundColor: "#000024",
    alignItems: "center",
    justifyContent: "center",
  },
});
// Customizable Area End
