import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { validateEmail } from "framework/src/Utilities";
import { setStorageData } from "../../../framework/src/Utilities";
import { NavigationActions } from 'react-navigation';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email: string;
  placeHolderEmail: string;
  labelHeader: string;
  btnTxtLogin: string;
  termsCond: boolean;
  isErrorMsg: boolean;
  showErrorMsg: string;
  isValid: boolean;
  isCheckedBox: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class GuestLoginController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGuestLoginCallId: string = "";
  emailReg: RegExp;
  labelTitle: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      email: "",
      placeHolderEmail: configJSON.placeHolderEmail,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      isErrorMsg: false,
      showErrorMsg: "",
      termsCond: true,
      isValid: false,
      isCheckedBox: false
    };

    this.emailReg = new RegExp("");
    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevState.email != this.state.email) {
      const testEmail = validateEmail("Email", this.state.email)
      if (testEmail.message == "") {
        this.setState({ isValid: true, isErrorMsg: false, });
      } else {
        this.setState({ isValid: false, isErrorMsg: false, });
      }
    }
  }

  onEmailChange = (text: string) => {
    this.setState({ email: text });
  };


  emailLogIn = () => {
    const header = {
      "Content-Type": "application/json",
    };
    const body = {
      "email": this.state.email,
      "activated": "true",
      "user_type": "guest"
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGuestLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // configJSON.loginApiUrl
      "bx_block_login/guest_login"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  toggleCheckBox = () => {
    this.setState({ isCheckedBox: !this.state.isCheckedBox })
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiGuestLoginCallId) {
          if (responseJson.token && responseJson.user_type) {
            this.setState({ isErrorMsg: false, showErrorMsg: "" });
            setStorageData('TOKEN', responseJson.token)
            setStorageData('USERTYPE', responseJson.user_type)
            this.props.navigation.reset([NavigationActions.navigate({ routeName: 'BottomTab' })], 0)
          } else {
            this.setState({ isErrorMsg: true, showErrorMsg: "Email already registered. Please " });
          }
        }
      }
    }
    // Customizable Area End
  }
}
