import React, { Component } from "react";
import { View, StyleSheet, Image } from "react-native";
import Scale from "../../Scale";
import { CText, CTextProps } from '../CText/CText'
// import LinearGradient from "react-native-linear-gradient";
const person1 = require("../../assets/defaultProfile.png");

interface ImageProps {
  path: any,
  size: 'sm' | 'base' | 'lg' | 'xl' | 'xxl';
}

// Required props
interface AvatarRequiredProps {
  image: ImageProps;
  title: CTextProps;
  text?: CTextProps;
  icon?: ImageProps;
  isGradient?: boolean;
  isTimeIncluded?: boolean;
  time?: CTextProps;
  openFromForum?: boolean
}

export default class CAvatar extends Component<AvatarRequiredProps> {

  getStyles() {
    const { image, icon } = this.props;
    return {
      imageStyles: {
        ...styles.imgStyle,
        ...styles[image.size],
      },
      iconStyles: {
        ...styles.iconStyle,
        ...styles[icon ? icon.size : 'sm'],
      },
      containerStyles: {
        ...styles.containerStyle,
      },
      innerContainerStyles: {
        ...styles.innerContainerStyle,
      },
      avatarPlaceholderWrapper: {
        ...styles.avatarPlaceholderWrapper,
        //@ts-ignore
        ...styles[`${image.size}InnerWrapper`]
      },
      avatarPlaceholderImage: {
        //@ts-ignore
        ...styles[`${image.size}PlaceholderImage`]
      }
    }
  }
  renderProfileImage = (path: string, imgStyle: any) => {
    return (
      <>
        {path ?
          <Image style={imgStyle} source={{ uri: path }} />
          : (
            <View style={[this.getStyles().imageStyles, { alignItems: "center", justifyContent: "center" }]}>
              <View style={this.getStyles().avatarPlaceholderWrapper}>
                {/* <View style={{ alignItems: "center", justifyContent: "center" }}> */}
                <Image
                  style={this.getStyles().avatarPlaceholderImage}
                  source={person1}
                />
              </View>
            </View>
          )}
      </>
    )
  }

  render() {
    const { image, title, text, icon, isGradient, isTimeIncluded, time } = this.props;
    const { imageStyles, iconStyles, containerStyles, innerContainerStyles } = this.getStyles()
    return (


      <View style={containerStyles}>
        {/* {isGradient ?
          <LinearGradient
            colors={['#FF002F', '#FF751B']}
            style={styles.gradientBorder}
          >
            {this.renderProfileImage(image.path, styles.gradientImage)}

          </LinearGradient>
          :
          this.renderProfileImage(image.path, imageStyles)
        } */}
        <View style={this.props.openFromForum && styles.marginLeft}>
          <View style={[innerContainerStyles, this.props.openFromForum && styles.justifyContent]}>

            <CText style={title.style} size={title.size} weight={title.weight}>{title.children}</CText>

            {isTimeIncluded && time &&
              <>
                <View style={styles.timeWrapper} />
                <CText style={time?.style} size={time?.size} weight={time.weight}>{time.children}</CText>
              </>
            }
            {icon && <Image style={iconStyles} source={icon.path} />}
          </View>
          {text &&
            <View style={[styles.marginHorizontal, this.props.openFromForum && styles.justifyContent]}>
              <CText style={text.style} size={text.size} weight={text.weight}>{text.children}</CText>
            </View>
          }
        </View>
      </View>

    )

  }
}

const styles = StyleSheet.create({
  containerStyle: { flexDirection: 'row', alignItems: 'center', justifyContent: "center" },
  imgStyle: {},
  iconStyle: { marginLeft: Scale(6) },
  innerContainerStyle: {
    marginHorizontal: Scale(5),
    marginBottom: Scale(0),
    flexDirection: 'row',
    alignItems: 'center',
  },
  sm: { height: Scale(32), width: Scale(32), borderRadius: Scale(32) },
  smInnerWrapper: { height: Scale(20), width: Scale(20), borderRadius: Scale(100) },
  smPlaceholderImage: { height: Scale(10), width: Scale(10), resizeMode: "contain" },
  base: { height: Scale(44), width: Scale(44), borderRadius: Scale(44) },
  baseInnerWrapper: { height: Scale(38), width: Scale(38), borderRadius: Scale(100) },
  basePlaceholderImage: { height: Scale(14), width: Scale(14), resizeMode: "contain" },
  lg: { height: Scale(56), width: Scale(56), borderRadius: Scale(56) },
  lgInnerWrapper: { height: Scale(38), width: Scale(38), borderRadius: Scale(100) },
  lgPlaceholderImage: { height: Scale(19), width: Scale(19), resizeMode: "contain" },
  xl: { height: Scale(132), width: Scale(132), borderRadius: Scale(132) },
  xlInnerWrapper: { height: Scale(85), width: Scale(85), borderRadius: Scale(100) },
  xlPlaceholderImage: { height: Scale(41), width: Scale(41), resizeMode: "contain" },
  xxl: { height: Scale(156), width: Scale(156), borderRadius: Scale(156) },
  xxlInnerWrapper: { height: Scale(101), width: Scale(101), borderRadius: Scale(100) },
  xxlPlaceholderImage: { height: Scale(48.75), width: Scale(48.75), resizeMode: "contain" },

  gradientBorder: {
    padding: 2,
    height: Scale(44), width: Scale(44), borderRadius: Scale(100),
    marginRight: Scale(8),
    marginTop: Scale(5),
    alignSelf: 'flex-start',
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center"
  },
  gradientImage: { height: "100%", width: "100%", resizeMode: "cover", borderRadius: Scale(100) },
  timeWrapper: { marginHorizontal: Scale(6), height: Scale(4), width: Scale(4), backgroundColor: "#CBD1E1", alignSelf: "center", borderRadius: 100 },
  avatarPlaceholderWrapper: {
    overflow: "hidden",
    borderRadius: 100, backgroundColor: "#1B1B3A", justifyContent: "center",
    alignItems: "center"
  },
  avatarPlaceHolderView: { alignItems: "center", justifyContent: "center" },
  marginLeft: {
    marginLeft: Scale(10)
  },
  justifyContent: {
    justifyContent: 'flex-start'
  },
  marginHorizontal: {
    marginHorizontal: Scale(5)
  }
});