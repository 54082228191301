import React from "react";

import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
  Button,
  Platform,
  // Customizable Area Start
  FlatList,
  // Customizable Area End
} from "react-native";

// Customizable Area Start
import PollingController, {
  Props,
  configJSON,
} from "./PollingController";
import RadioButton from "../../../components/src/RadioButton";
import Scale from "../../../components/src/Scale";
import { deviceWidth, getFontFamily, widthFromPercentage } from "framework/src/Utilities";
import { CButton, CText, CProgressBar, CStatusBar, CToast, CHeader, CIcon } from "../../../components/src/custom-components";
import CCountDownTimer from "../../../components/src/custom-components/CCountDownTimer/CCountDownTimer";
import Loader from "../../../components/src/Loader";
import { pollPlaceholder } from "../../../components/src/assets";
// Customizable Area End

export default class Polling extends PollingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderItems = ({ item, index }: any) => {
    return (
      <>
        <CProgressBar
          value={item?.value}
          title={item?.label}
          width={deviceWidth - Scale(48)}
          height={Scale(16)}
          isSelected={item?.isSelected}
        />
      </>
    )
  }

  renderPollData = (isPollLiveAndNotSubmitted: boolean, isPollSubmitted: boolean, isPollEnd: boolean) => {
    return (
      <>
        {isPollLiveAndNotSubmitted ? <View
          style={styles.ViewContainer329468083}
        >
          <RadioButton
            testID="radio_button"
            data={this.state.pollingOption}
            returnData={this.setPollingOption}
            isMultiple={false}
          />
        </View>
          : (isPollSubmitted || isPollEnd) &&
          <FlatList
            testID="radioButtonList"
            data={this.state.pollingOption}
            contentContainerStyle={styles.flatListContainerStyle}
            renderItem={this.renderItems}
          />}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { pollDetailsResponse, toastMessage } = this.state;
    const isPollEnd = String(pollDetailsResponse?.poll?.poll_status).toLowerCase() == 'closed'
    const isPollSubmitted = pollDetailsResponse?.poll_given
    const endTime = pollDetailsResponse?.poll?.end_date_time
    const isPollLiveAndNotSubmitted = !isPollEnd && !isPollSubmitted
    return (
      <View style={styles.SafeAreaView}>
        <CStatusBar backgroundColor="#1B1B3A" barStyle="light-content" />
        <CHeader
          testID='headerView'
          icons={[
            { testID: 'btnBack', type: 'icon', icon: 'back', position: 'left', iconColor: '#FF751B', onPress: () => this.props.navigation.goBack() },
            { type: 'text', title: `POLL DETAILS`, position: 'center', titleStyle: 'primary', titleSize: 'sm', titleWeight: 'bold' },
          ]}
          headerColor="#1B1B3A"
        />

        {this.state.isLoading ? (<Loader loading={this.state.isLoading} />) : (
          <>
            <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
              <Image
                source={this.state.pollDetailsResponse?.poll?.image?.file_path ? { uri: this.state.pollDetailsResponse?.poll?.image?.file_path } : pollPlaceholder}
                style={styles.pollHeaderImage}
              />
              <View style={styles.pollMainTextWrapper}>
                <CText style="white" size="xl3" weight='bold' lineHeight={41} >{pollDetailsResponse?.poll?.title}</CText>
                {!isPollEnd ?
                  <View style={styles.ViewContainer329468076} >
                    <View style={styles.fdRow}>
                      <View style={styles.pollTitle}>
                        <CText style="primary" size="xl" weight='bold' >Poll is open!</CText>
                      </View>
                      <View style={styles.ViewContainer329468078}>
                        <CCountDownTimer time={endTime} />
                      </View>
                    </View>
                  </View> :
                  <View style={styles.pollCloseTitleWrapper}>
                    <CText style="white" size="xs" weight='bold' lineHeight={18} >Poll is closed!</CText>
                  </View>
                }
              </View>


              {this.renderPollData(isPollLiveAndNotSubmitted, isPollSubmitted, isPollEnd)}

            </ScrollView>
            {
              !isPollEnd &&
              <View style={styles.buttonContainer}>
                <CButton
                  testID='submit_bt'
                  onPress={this.submitPollAnswer}
                  type="button"
                  style={isPollSubmitted ? "secondary" : "primary"}
                  size="big"
                  disabled={isPollSubmitted ? true : Boolean(this.isSubmitButtonDisabled())}
                >
                  {isPollLiveAndNotSubmitted ? "Submit" : isPollSubmitted && "Response already submitted"}
                </CButton>
              </View>
            }
          </>)}

        <CToast
          msg={toastMessage}
          type='success'
          position="bottom"
          callback={this.handleToast} />
      </View>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    // padding: Scale(20),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "rgba(0, 0, 36, 1)",
  },
  SafeAreaView: {
    flex: 1,
    backgroundColor: "#000024",
  },
  ViewContainer329468076: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Scale(10)
  },
  ViewContainer329468078: {
    zIndex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ViewContainer329468083: {
    zIndex: 4,
    marginTop: Scale(40),
    marginHorizontal: Scale(24)
  },
  TouchableOpacity329468113: {
    zIndex: 7,
    minWidth: Scale(327),
    minHeight: Scale(60),
    backgroundColor: "rgba(255, 0, 47, 1)",
    borderRadius: Scale(58),
    justifyContent: "center",
    alignItems: "center",
  },
  buttonTextStyleI3294681131843677: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: Scale(18),
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: Scale(24),
    letterSpacing: 0,
  },
  backImage: {
    height: Scale(30),
    width: Scale(30),
    paddingLeft: Scale(20),
    marginRight: Scale(30),
  },
  header: {
    flexDirection: 'row',
    padding: Scale(20),
    backgroundColor: "rgba(0, 0, 36, 1)",
    alignItems: 'center',
  },
  flatListContainerStyle: { marginTop: Scale(40), marginHorizontal: Scale(24) },
  pollTitle: { flex: 1 },
  buttonContainer: { padding: Scale(20), width: '100%', },
  fdRow: { flexDirection: 'row' },
  pollHeaderImage: { height: 280, width: deviceWidth, resizeMode: "cover" },
  pollMainTextWrapper: {
    padding: Scale(24), backgroundColor: "#1B1B3A", flex: 1,
  },
  pollCloseTitleWrapper: {
    marginTop: Scale(10),
    backgroundColor: "#FF002F",
    alignItems: "center",
    paddingHorizontal: Scale(8),
    paddingVertical: Scale(7),
    borderRadius: Scale(40),
    alignSelf: 'flex-start',
  }

});
// Customizable Area End
