export const person1 = require("../../../components/src/assets/defaultProfile.png");
export const attachImage = require("../assets/attachImage.png");
export const plusLeftArrow = require("../assets/plusLeftArrow.png");
export const edit = require("../assets/edit.png");
export const bgImage = require("../assets/bg.png");
export const backArrow = require("../assets/backArrow.png");
export const downArrow = require("../assets/downArrow.png");
export const cameraIcon = require("../assets/cameraIcon.png");
export const clock = require("../assets/clock.png");
export const deleteImage = require("../assets/delete.png");
export const logout = require("../assets/logout.png");
export const notification = require("../assets/notification.png");
export const subscription = require("../assets/subscription.png");
export const editPencilIconBlack = require("../assets/editPencilIconBlack.png");
export const player = require("../assets/player.png");