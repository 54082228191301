import React, { Component } from 'react';
import {
    StyleSheet,
    ImageBackground,
    Image,
    TouchableOpacity,
    View,
    Text
} from "react-native";
import { backgroundImg } from '../../assets';
import Scale from '../../Scale';
import { bgImage } from '../../../../blocks/user-profile-basic/src/assets';
import { CText } from '../CText/CText';

interface Props {
    icons?: Icons[],
    coverPhoto: any;
    coverPhotoHeight: any;
    testID?:string;
}

interface Icons {
    testID?: string,
    path: any,
    position: string,
    onPress: any,
    style?: any,
    title?: string,
};

export default class CCoverPhoto extends Component<Props> {
    getStyles(item: any) {
        const { style, position } = item;
        const defaultPosition = 'topLeft';
        return {
            containerStyles: {
                ...styles[position ?? defaultPosition],
            },
            iconStyle: {
                ...styles[style],
            }
        }
    }
    render() {
        const { icons, coverPhoto, coverPhotoHeight } = this.props;
        return <ImageBackground
            style={[styles.image, { height: coverPhotoHeight, backgroundColor: coverPhoto ? "#fff" : '#0085FF', }]}
            source={coverPhoto ? { uri: coverPhoto } : bgImage}>
            {icons?.map((item) => {
                const { containerStyles, iconStyle } = this.getStyles(item)
                return (
                    <View key={item.testID} style={containerStyles}>
                        <TouchableOpacity testID={item.testID}
                            onPress={() => item.onPress()}
                            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                            style={styles.touchableView}
                        >
                            <View style={iconStyle}>
                                <Image source={item.path} style={styles.iconStyle} />
                            </View>
                            {item?.title &&
                                <View style={styles.editTxt}>
                                    <CText style="white" size="sm" weight="bold" children={item.title} />
                                </View>}
                        </TouchableOpacity>
                    </View>
                )
            })}
        </ImageBackground>
    }
}

const styles: any = StyleSheet.create({
    normal: {
        marginTop: Scale(20),
    },
    bright: {
        marginTop: Scale(20),
        width: Scale(32),
        height: Scale(32),
        borderRadius: Scale(32 / 2),
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    topLeft: {
        position: 'absolute',
        left: Scale(20),
        top: Scale(20)
    },
    topRight: {
        position: 'absolute',
        right: Scale(20),
        top: Scale(20)
    },
    bottomRight: {
        position: 'absolute',
        right: Scale(20),
        bottom: Scale(20),
    },
    image: {
        width: "100%",
        borderBottomRightRadius: Scale(35),
        overflow: 'hidden',
    },
    touchableView: {
        flex: 1,
        alignItems: 'center',
        alignSelf: 'center'
    },
    iconStyle: {
        height: Scale(24),
        width: Scale(24)
    },
    editTxt: {
        marginTop: Scale(5),
    },
});
