export const backarrow1 = require("./newbackArrow.png");
export const eyeOpen = require("./eyeopen.png");
export const eyeClose = require("./image_hidden.png");
export const backgroundImg = require('./assets/backgroundImg.png')
export const loginBackgroundImg = require('./assets/loginBackground.png')
export const closeIcon = require('./assets/closeIcon.png')
export const correctIcon = require('./assets/correctIcon.png')
export const crossIcon = require('./assets/crossIcon.png')
export const passwordInVisible = require('./assets/ic_password_invisible.png')
export const passwordVisible = require('./assets/ic_password_visible.png')
export const passwordSeen = require('./assets/passwordSeen.png')
export const rightArrow = require("./assets/rightArrow.png");
export const locationPin = require("./assets/locationPin.png");
export const checkGreyIcon = require('./assets/checkGreyIcon.png')
export const checkGreenIcon = require('./assets/checkGreenIcon.png')
export const backArrow = require("./assets/backArrow.png");
export const resetPassword = require("./assets/resetPassword.png");
export const India = require("./assets/india.png");
export const Germany = require("./assets/germany.png");
export const Europe = require("./assets/europe.png");
export const Canada = require("./assets/canada.png");
export const France = require("./assets/france.png");
export const Uk = require("./assets/uk.png");
export const USA = require("./assets/USA.png");
export const AddText = require("./assets/addText.png");
export const addVideo = require("./assets/addVideo.png");
export const addPhoto = require("./assets/addPhoto.png");
export const dummyImg = require("./assets/dummyImg.png");
export const wanringImg = require("./custom-components/warning_image.png");
export const utsHomeScreenLogo = require("./assets/utsHomeScreenLogo.png");
export const timer = require("./assets/timer.png");
export const pollPlaceholder = require("./assets/pollPlaceholder.png");
export const eventHeaderImg = require("./assets/eventHeaderImg.png");

export const commentIcon = require("./assets/Comment.png");
export const heartBordrIcon = require("./assets/HeartBorder.png");
export const heartFilledIcon = require('./assets/HeartFilled.png');
export const shareIcon = require("./assets/Share.png");
export const eventPlaceHolder = require("./assets/eventPlaceholder.png");
export const liveTV = require("./assets/live_tv.png")
