import React from "react";
// Customizable Area Start
import { Dimensions, StyleSheet, ImageBackground } from "react-native";
import MergeEngineUtilities from "../../utilities/src/MergeEngineUtilities";
import { CStatusBar } from "../../../components/src/custom-components";

// Merge Engine - import assets - Start
import { imgSplash } from "./assets";
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End
import SplashscreenController, { Props } from "./SplashscreenController";
// Customizable Area End

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    Dimensions.addEventListener("change", () => {
      MergeEngineUtilities.init(
        artBoardHeightOrg,
        artBoardWidthOrg,
        Dimensions.get("window").height,
        Dimensions.get("window").width
      );
    });
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <ImageBackground
        style={styles.container}
        source={imgSplash}
        resizeMode="stretch"
      >
        <CStatusBar
          backgroundColor="rgba(0, 0, 36, 0)"
          barStyle="light-content"
        />
      </ImageBackground>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center"
  },
  splashScreenView: {
    flex: 1,
    width: MergeEngineUtilities.deviceBasedDynamicDimension(335, false, 1),
    height: MergeEngineUtilities.deviceBasedDynamicDimension(699, false, 1),
    marginTop: MergeEngineUtilities.deviceBasedDynamicDimension(-40, true, 1),
    marginLeft: MergeEngineUtilities.deviceBasedDynamicDimension(-20, true, 1),
    alignSelf: "center",
    backgroundColor: "#030a24"
  },
  safeAreaView: {
    backgroundColor: "#030a24",
    flex: 1
  }
});
// Customizable Area End
