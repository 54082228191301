import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Alert } from "react-native";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities"
import React, { RefObject } from "react";
import BottomSheet from "@gorhom/bottom-sheet";
// import ImagePicker from "react-native-image-crop-picker"
import { Keyboard, ScrollView } from "react-native"
// import RNFetchBlob from "rn-fetch-blob";
import { Platform } from "react-native";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  PostData: any;
  token: string;
  name: string;
  description: string;
  price: any;
  currency: string;
  category_id: string;
  image: any;
  uploadedImages: any;
  AllCategory: any[];
  id: any;
  refresh: boolean;
  file: any;
  profileImageData: any;
  selectedCategory: any;
  allCategories: any[];
  imageData: any
  postText: string
  isLoading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PostCreationCommonController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiPostItemCallId: string = "";
  apiGetCategoryCallID: string = "";
  PostApiCallId: string = "";
  DeleteApiCallId: any;
  addpostApiCallId: any;
  updatePostApiCallId: any;
  bottomSheetRef: RefObject<BottomSheet>;
  snapPoints: string[];
  postCreationApiCallID: string = ""
  scrollViewRef = React.createRef<ScrollView>();

  keyboardDidShowListener: any;
  keyboardDidHideListener: any;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.bottomSheetRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      token: "",
      PostData: [],
      name: "",
      description: "",
      price: "",
      currency: "$",
      category_id: "",
      image: "",
      id: "",
      uploadedImages: [],
      AllCategory: [],
      file: "",
      refresh: false,
      profileImageData: {},
      selectedCategory: "",
      allCategories: [],
      imageData: {},
      postText: "",
      isLoading: false
    };
    this.snapPoints = ["0.0%", "38%"];
    // Customizable Area End
    console.disableYellowBox = true;
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._scrollToBottom);
    this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._scrollToTop);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson) {
        if (apiRequestCallId === this.postCreationApiCallID) {
          this.postCreationSuccessBlock(responseJson)
        }
      }

    }
  }
  postCreationSuccessBlock = (responseJson: any) => {

    if (responseJson?.data && !responseJson?.data?.attributes?.errors) {
      this.setState({ isLoading: false }, () => this.props.navigation.navigate("NewsFeed"))
    } else {
      this.setState({ isLoading: false }, () => Alert.alert(responseJson?.data?.attributes?.errors?.base[0]))

    }
  }

  async componentWillUnmount() {
    this.keyboardDidShowListener.remove();
    this.keyboardDidHideListener.remove();
  }

  _scrollToBottom = () => {
    this.scrollViewRef.current?.scrollToEnd({ animated: true });
  };

  _scrollToTop = () => {
    this.scrollViewRef.current?.scrollTo({ x: 0, y: 0, animated: true });
  };

  handlePostText = (text: string) => {
    this.setState({ postText: text.trim() })
  }
  isPostDisabled = () => {
    if (this.state.postText == "" || this.state.imageData?.path == undefined) { return true }
    return false
  }
  postButtonStyle = () => {
    if (this.state.postText == "" || this.state.imageData?.path == undefined) { return "secondary" }
    return "primary"
  }
  onEditPhoto = () => {
    if (this.bottomSheetRef.current) {
      this.bottomSheetRef.current.expand();
    }
  };
  onSelectImage = () => {
    this.closeBottomSheet();
    this.openGallery()
  }
  onSelectCamera = () => {
    this.closeBottomSheet();
    this.openCamera()
  }
  onSelectVideo = () => {
    this.closeBottomSheet();
    this.openVideo()
  }
  clearImageData = () => {
    this.setState({ imageData: {} })
  }
  closeBottomSheet = () => {
    this.bottomSheetRef.current?.close();
  };
  pickerObject = () => {
    return Platform.OS == "ios" ? {
      cropping: true,
      width: 2000,
      height: 2000,
      mediaType: 'photo',
    } : { cropping: true, mediaType: 'photo', }
  }
  openGallery = () => {
    // let pickerObj: any = this.pickerObject()
    // ImagePicker.openPicker(pickerObj).then(async (image: any) => {
    //   this.handleImageResponse(image, "Image")
    // });
  }

  openCamera = () => {
    // let pickerObj: any = this.pickerObject()
    // ImagePicker.openCamera(pickerObj
    // ).then((image: any) => {
    //   this.handleImageResponse(image, "Photo")
    // });
  }
  openVideo = () => {
    // ImagePicker.openPicker({
    //   mediaType: 'video'
    // }).then((image: any) => {
    //   this.handleImageResponse(image, "Video")
    // });
  }
  handleImageResponse = async (image: any, media: string) => {
    // let size: any
    // if (Platform.OS == "ios") {
    //   const { sourceURL } = image;
    //   let filePath = sourceURL?.replace('file://', '');
    //   const dataFromOriginalFile: any = await RNFetchBlob?.fs?.stat(filePath)
    //   size = dataFromOriginalFile?.size
    // } else {
    //   size = image.size
    // }
    // const maxFileSizeBytes = 10 * 1024 * 1024;
    // if (size <= maxFileSizeBytes) { this.setState({ imageData: image }) }
    // else {
    //   Alert.alert(`${media} size max upto 10 MB`)
    // }
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": contentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  handlePost = async () => {
    this.setState({ isLoading: true })
    var formdata = new FormData();
    formdata.append("name", "something");
    formdata.append("body", this.state.postText);
    formdata.append("created_by", "user");
    if (this.state.imageData?.mime && this.state.imageData?.mime.startsWith('image')) {

      formdata.append("attachments_attributes[0][image]", {
        uri: this.state.imageData?.path,
        type: this.state.imageData?.mime,
        name: "photo",
      });
    }
    else {
      let videoData: any = {
        uri: this.state.imageData?.path,
        type: this.state.imageData?.mime,
        name: "video",
      }
      formdata.append("attachment_videos_attributes[0][video]", videoData);
    }

    this.postCreationApiCallID = await this.apiCall({
      contentType: configJSON.formDataContentDataType,
      method: configJSON.PostAPiMethod,
      endPoint: configJSON.postCreationApiURL,
      body: formdata,
      type: 'formData'
    })
  }


  // }
  // Customizable Area End
}
