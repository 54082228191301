import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  FlatList,
  KeyboardAvoidingView,
  Alert,
} from "react-native";
import CCommentsView from "../../../components/src/custom-components/CCommentsView/CCommentsView.web";
import CInputCommentField from "../../../components/src/CComments/CInputCommentField";
import { CHeader, CReportPopup, CStatusBar, CToast } from "../../../components/src/custom-components";
import Scale from "../../../components/src/Scale";
import { trimWhitespaceAndNewlines } from "framework/src/Utilities";
// Customizable Area End

import ReplyCommentController, { Props } from "./ReplyCommentsController";

export default class ReplyComments extends ReplyCommentController {

  // Customizable Area Start
  renderSubCommentItem = ({ item, isParent }: { item: any, isParent?: boolean }) => {

    return (
      <CCommentsView
        testId={'subComment'}
        created_at={item?.attributes?.created_at}
        account_photo={item?.attributes?.account_photo}
        commentText={item?.attributes?.comment}
        userName={item?.attributes?.account_name}
        onSelectHeart={(status: boolean) => this.likeOnComment(item, status, isParent ?? false)}
        likeCount={item?.attributes?.likes_count}
        commentCount={item?.attributes?.comments_count}
        onHeartInitialStatus={item?.attributes?.is_liked}
        onPressComment={() => { }}
        isCommentCountVisible={isParent ?? false}
        backgroundColor={isParent ? "LightGray" : "Gray"}
        paddingLeft={isParent ? "paddingLeft24" : "paddingLeft48"}
        timerPosition="name"
        btnVisible={isParent ? false : this.handleReport(item.attributes.account_id)}
        isUsedInForumScreen={false}
        separator={true}
        onBtnPressOpenMenu={() => { this.setState({ reportVisible: true, subCommentId: item.id, reportToolTipVisible: item.id, }) }}
        onBtnPressCloseMenu={() => { this.setState({ reportVisible: false, subCommentId: "", reportToolTipVisible: "", }) }}
        onTapToolTip={() => { this.setState({ reportPopupVisible: true, reportToolTipVisible: "" }) }}
        toolTipVisible={this.state.reportToolTipVisible}
        menuItemText={"Report"}
        menuItemIcon="flag-outline"
        menuItemTextStyle={"white"}
        itemId={item.id}
      />
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : "height"}
        style={styles.mainContainer}
      >
        <View style={{ flex: 1, backgroundColor: "#1B1B3A", }}>
          <CStatusBar backgroundColor="#1B1B3A" barStyle="light-content" />
          <CHeader
            testID='headerView'
            icons={[
              { testID: 'btnBack', type: 'icon', icon: 'back', position: 'left', iconColor: '#FF751B', onPress: () => this.props.navigation.goBack() },
              { type: 'text', title: `COMMENTS`, position: 'center', titleStyle: 'primary', titleSize: 'sm', titleWeight: 'bold' },
            ]}
            borderBottomRightRadius={30}
          />
          <ScrollView keyboardShouldPersistTaps="always" style={styles.mainContainer}>
            <View style={{ backgroundColor: "#1B1B3A" }}>
              {this.state.commentData && this.state.loading &&
                this.renderSubCommentItem({ item: this.state.commentData, isParent: true })
              }
            </View>
            <TouchableWithoutFeedback testID="hideKeys"
              style={{ flex: 1 }}
              onPress={() => {
                this.hideKeyboard();
              }}
            >
              <FlatList
                testID={"CommentFlatList"}
                accessibilityLabel="CommentFlatList"
                style={styles.list}
                data={this.state.commentData?.attributes?.comments?.data}
                keyExtractor={(item: any) => item.id}
                renderItem={(item) => this.renderSubCommentItem(item)}
              />
            </TouchableWithoutFeedback>
          </ScrollView>
        </View>
        <View style={{ backgroundColor: "#000024", marginBottom: Scale(16) }}>
          <CInputCommentField
            testID={"textInputComment"}
            value={this.state.comment}
            placeholder={"Write here..."}
            onChange={(values: any) => { this.onComment(values) }}
            onSendMessage={() => {
              this.hideKeyboard();
              if (!trimWhitespaceAndNewlines(this.state.comment)) {
                Alert.alert("Please write a comment before submitting")
              } else {
                this.createSubComment()
              }
            }}
          />
        </View>
        <CReportPopup type="comment" commentId={this.state.subCommentId} setToastMessage={() => this.setState({ commentReportedToastMessage: "Comment has been reported" })} testId={"report"} visible={this.state.reportPopupVisible} setVisible={() => { this.setState({ reportPopupVisible: false, reportVisible: false }) }} />
        <CToast
          msg={this.state.commentReportedToastMessage}
          type="fail"
          position="bottom"
          isWarning={false}
          toastPosition={{ bottom: 40 }}
          callback={() => this.setState({ commentReportedToastMessage: "" })}
        />
      </KeyboardAvoidingView>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: "#000024",
  },
  list: {
    marginTop: 15,
    marginBottom: 20,
    backgroundColor: "#FFFFFF",
  },
});
// Customizable Area End
