import React, { FC, useState } from "react";
import { StyleSheet, View, TouchableOpacity, Text, Image } from "react-native";
import { Icon, ListItem } from "react-native-elements";
import Scale from "../Scale";
import { CInputPost } from "../custom-components";
import { getFontFamily } from "framework/src/Utilities";

interface myProps {
  onChangeText?: any;
  placeholder?: any;
  placeholderTextColor?: any;
  onEndEditing?: any;
  onFocus?: any;
  onBlur?: any;
  testID?: any;
  onSendMessage?: any;
  onChange?: any;
  value?: any;
  style?: any;
  inputtestId?: any;
  disabled?: any;
  maxLength?: any;
}

export default function CInputCommentField(props: myProps) {

  const { onChange, value, placeholder, maxLength } = props;

  return (
    <View style={styles.container}>
      <ListItem
        containerStyle={styles.listItemContainer}
        title={
          <CInputPost
            testID={props.inputtestId}
            value={value}
            style={styles.preSty}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
          />
        }
        rightElement={
          <TouchableOpacity
            testID={props.testID}
            disabled={props.disabled}
            onPress={props.onSendMessage}>
            <View style={styles.sendViewStyle}>
              <Image source={require("./send.png")} style={styles.iconStyle} />
            </View>
          </TouchableOpacity>
        }
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#1b1b3a',
    borderColor: '#fff',
    borderRadius: Scale(10),
    borderWidth: Scale(2),
    marginHorizontal: Scale(10),
    marginVertical: Scale(10)

  },
  listItemContainer: {
    padding: 5,
    marginHorizontal: Scale(10),
    backgroundColor: '#1b1b3a',
  },
  sendViewStyle: {
    backgroundColor: '#fff',
    borderRadius: Scale(44),
    padding: Scale(10),
    justifyContent: 'center',
    alignItems: 'flex-end'
    //position: 'absolute'
  },
  inputContainerStyle: {
    marginHorizontal: 0,
    borderBottomWidth: 0,
    color: '#fff',
    padding: 0,
    backgroundColor: '#1b1b3a',
    justifyContent: 'center',
  },
  inputStyle: {
    fontSize: Scale(17),
    fontFamily: getFontFamily('bold'),
    fontWeight: '700',
    color: "#fff",
  },
  iconStyle: {
    height: Scale(24),
    width: Scale(24)
  },
  preSty: {
    fontFamily: getFontFamily('light'),
    color: "#fff",
    marginVertical: 10,
    padding: 5,
    backgroundColor: "#1b1b3a",
    borderRadius: 8,
    minHeight: "5%",
  },
});
