import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  FlatList,
  KeyboardAvoidingView,
  Alert,
} from "react-native";
import CCommentsView from "../../../components/src/custom-components/CCommentsView/CCommentsView.web";
import CInputCommentField from "../../../components/src/CComments/CInputCommentField";
import { CHeader, CReportPopup, CStatusBar, CToast } from "../../../components/src/custom-components";
import Scale from "../../../components/src/Scale";
import { getFontFamily, trimWhitespaceAndNewlines } from "framework/src/Utilities";
import { NavigationEvents } from "react-navigation";
// Customizable Area End

import CommentController, { Props, configJSON } from "./CommentsController";

export default class Comments extends CommentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCommentItem = ({ item }: any) => {
    return (
      <CCommentsView
        testId={'comment'}
        account_photo={item?.attributes?.account_photo}
        created_at={item?.attributes?.created_at}
        userName={item?.attributes?.account_name}
        commentText={item?.attributes?.comment}
        likeCount={item?.attributes?.likes_count}
        onSelectHeart={(status: boolean) => { !this.apiDisabled && this.likeOnComment(item, status) }}
        onHeartInitialStatus={item?.attributes?.is_liked}
        commentCount={item?.attributes?.comments_count}
        isCommentCountVisible={true}
        onPressComment={() => { this.props.navigation.navigate('ReplyComments', ({ commentId: item.id })) }}
        timerPosition="name"
        btnVisible={this.handleReport(item.attributes.account_id)}
        isUsedInForumScreen={false}
        separator={true}
        onBtnPressOpenMenu={() => { this.setState({ reportVisible: true, commentId: item.id,reportToolTipVisible: item.id }) }}
        onBtnPressCloseMenu={() => { this.setState({ reportVisible: false, commentId: "", reportToolTipVisible: "" }) }}
        onTapToolTip={() => { this.setState({ reportPopupVisible: true }) }}
        toolTipVisible={this.state.reportToolTipVisible}
        menuItemText={"Report"}
        menuItemIcon="flag-outline"
        menuItemTextStyle={"white"}
        itemId={item.id}
      />
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.container}
      >
        <View style={{ flex: 1, backgroundColor: "#000024", }}>
          <CStatusBar backgroundColor="#000024" barStyle="light-content" />
          {/* <NavigationEvents testID="nativeEvent" onWillFocus={() => this.getCommentData()} /> */}
          <CHeader
            testID='headerView'
            icons={[
              { testID: 'btnBack', type: 'icon', icon: 'back', position: 'left', iconColor: '#FF751B', onPress: () => {} },
              { type: 'text', title: `COMMENTS`, position: 'center', titleStyle: 'primary', titleSize: 'sm', titleWeight: 'bold' },
            ]} />
          <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
            <TouchableWithoutFeedback testID="hdingKey"
              style={{ flex: 1 }}
              onPress={() => {
                this.hideKeyboard();
              }}
            >
              {/* Merge Engine UI Engine Code */}
              <View style={{ flex: 1 }}>
                <FlatList
                  testID={"CommentFlatList"}
                  style={styles.list}
                  data={this.state.commentData}
                  keyExtractor={(item: any) => item.id}
                  renderItem={(item) => this.renderCommentItem(item)}
                />
              </View>
              {/* Merge Engine UI Engine Code */}
            </TouchableWithoutFeedback>
          </ScrollView>
          <View style={{ backgroundColor: "#000024", marginBottom: Scale(16) }}>
            <CInputCommentField
              testID={"textInputComment"}
              value={this.state.comment}
              placeholder={"Write here..."}
              onChange={(values: any) => { this.onComment(values) }}
              onSendMessage={() => {
                this.hideKeyboard();
                if (!trimWhitespaceAndNewlines(this.state.comment)) {
                  Alert.alert("Please write a comment before submitting")
                } else {
                  this.createComment()
                }
              }}
            />
          </View>
          <CReportPopup  type="comment" commentId={this.state.commentId} testId={"report"} visible={this.state.reportPopupVisible} setToastMessage={() => this.setState({ commentReportedToastMessage: "Comment has been reported" })} setVisible={() => { this.setState({ reportPopupVisible: false, reportVisible: false }) }} />
          <CToast
            msg={this.state.commentReportedToastMessage}
            type="fail"
            position="bottom"
            isWarning={false}
            toastPosition={{ bottom: 40 }}
            callback={() => {this.setState({ commentReportedToastMessage: "" })}}
          />
        </View>
      </KeyboardAvoidingView>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    backgroundColor: "#000024",
  },
  commentInputStyle: {
    fontFamily: getFontFamily('light'),
    color: "#fff",
    marginVertical: 10,
    marginHorizontal: 10,
    padding: 5,
    backgroundColor: "#1B1B3A",
    borderRadius: 8,
    minHeight: "15%",
    borderWidth: 0.5,
    borderColor: "white",
  },
  title: {
    fontSize: 15,
    textAlign: "left",
    flexWrap: "wrap",
    width: "97%",
  },
  comment: {
    fontSize: 14,
    color: "#252837",
    paddingLeft: 5,
  },
  viewBtn: {
    backgroundColor: "blue",
    paddingVertical: 8,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "blue",
  },
  viewBtnText: {
    color: "#fff",
    textAlign: "center",
    fontSize: 16,
  },
  list: {
    marginTop: 15,
    marginBottom: 20,
    backgroundColor: "#FFFFFF",
  },
  separator: {
    marginTop: 10,
    marginBottom: 10,
  },
});
// Customizable Area End
