import React from "react";
import {
    View,
    StyleSheet,
    TouchableOpacity,
} from "react-native";
import { Input } from "react-native-elements";
import Scale from "../../Scale";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { getFontFamily } from "framework/src/Utilities";

interface MyProps {
    autoCapitalize?: any;
    isValid?: any;
    ref?: any;
    value?: any;
    label?: any;
    keyboardType?: any;
    defaultValue?: any;
    onChangeText?: any;
    secureTextEntry?: any;
    maxLength?: any;
    placeholder?: any;
    editable?: any;
    placeholderTextColor?: any;
    onEndEditing?: any;
    autoFocus?: any;
    onFocus?: any;
    onBlur?: any;
    multiline?: any;
    numberOfLines?: any;
    returnKeyType?: any;
    closeIconOnPress?: any;
    testID?: any;
    image?: any
    otherImages?: any;
    button?: any;
    buttonOnPress?: any;
    inputStyle?: any;
    containerStyle?: any;
    inputContainerStyle?: any;
    checkStyle?: any
}

const CSearchInput = (props: MyProps) => {
    return (
        <View>
            <View style={styles.buttonContainerViewSty}>
                <Input
                    {...props}
                    ref={props.ref}
                    testID={props.testID}
                    value={props.value}
                    autoCapitalize={"none"}
                    keyboardType={props.keyboardType}
                    defaultValue={props.defaultValue}
                    onChangeText={props.onChangeText}
                    maxLength={props.maxLength}
                    labelStyle={[styles.textInputTitle]}
                    placeholder={props.placeholder}
                    containerStyle={styles.containerStyle}
                    inputContainerStyle={[props.inputContainerStyle ?? styles.inputContainerStyle]}
                    inputStyle={[props.inputStyle ?? (props.value ? styles.inputStyle : styles.placeholderStyle)]}
                    placeholderTextColor={'rgba(105,105,105,0.9)'}
                    onEndEditing={props.onEndEditing}
                    autoFocus={props.autoFocus}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    editable={props.editable}
                    multiline={props.multiline}
                    numberOfLines={props.numberOfLines}
                    returnKeyType={props.returnKeyType}
                    scrollEnabled={false}
                    leftIcon={
                        <TouchableOpacity hitSlop={{ top: 20, bottom: 20, left: 10, right: 10 }}>
                            {props.image ?
                                <Icon name="magnify" style={styles.searchIcon} size={Scale(24)} color="#9BA2B7" /> 
                                :
                                <>{props.otherImages}</>}
                        </TouchableOpacity>}
                    rightIcon={<>
                        <TouchableOpacity onPress={props.closeIconOnPress} hitSlop={{ top: 20, bottom: 20, left: 10, right: 10 }}>
                            {props.image ?
                                <Icon name="close" style={styles.closeIcon} size={Scale(24)} color="#9BA2B7" /> 
                                :
                                <>{props.otherImages}</>}
                        </TouchableOpacity>
                    </>}

                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({

    textInputTitle: {
        opacity: 1,
        backgroundColor: "transparent",
        includeFontPadding: false,
        padding: Scale(0),
        color: "#fff",
        textAlign: "left",
        textAlignVertical: "top",
        marginTop: Scale(25),
        fontFamily: getFontFamily('light'),
        fontSize: Scale(16),
    },
    containerStyle: {
        padding: 0,
    },
    inputContainerStyle: {
        marginTop: Scale(16),
        borderRadius: Scale(10),
        borderColor: '#FF751B',
        borderWidth: Scale(1),
        paddingVertical: Scale(8),
        color: '#fff',
        paddingHorizontal: Scale(8),
        backgroundColor: "#1B1B3A",
    },
    inputStyle: {
        fontSize: Scale(18),
        fontFamily: getFontFamily('light'),
        color: "#fff",
        lineHeight:Scale(24),
    },
    placeholderStyle: {
        fontSize: Scale(18),
        fontFamily: getFontFamily('light'),
        color: "#fff",
    },
    buttonContainerViewSty: {
        marginHorizontal: Scale(10),
    },
    searchIcon: { 
        marginRight: Scale(15)
    },
    closeIcon: {
        marginLeft: Scale(8),
        marginRight:Scale(10) 
    }
});

export default CSearchInput;