import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Scale from '../../Scale';
import { Overlay } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Entypo';
import { CButton, CText } from "..";

interface CGuestPopupProps {
    isVisible: boolean;
    isVisibleFunction: () => void;
    onPressSignUpFunction: () => void;
    subject: string;
    testID?:string;
}

class CGuestPopup extends Component<CGuestPopupProps> {
    render() {
        const { isVisible, isVisibleFunction, onPressSignUpFunction, subject } = this.props;
        return (
            <View>
                <Overlay
                    isVisible={isVisible}
                    overlayStyle={styles.guestOverlayStyle}
                >
                    <View>
                        <TouchableOpacity
                            testID="guestCloseIcon"
                            onPress={isVisibleFunction}
                            style={styles.stickyIcon}
                        >
                            <Icon name="cross" size={24} color="#000024" />
                        </TouchableOpacity>
                        <View style={styles.popupTextWrapper}>
                            <View style={styles.mainGuestTitle}>
                                <CText style="blue" size="xl" weight='bold' textAlign='center'>
                                    Maximize Your UTS Experience!
                                </CText>
                            </View>
                            <View style={styles.subGuestTitle}>
                                <CText style="blue" size="base" weight="light" textAlign='center' >
                                    Create an account in order to post photos, videos, and engage in comments, likes, polls on the UTS Live app.
                                </CText>
                            </View>
                            <CButton
                                testID={"btnGuestPoll"}
                                type="button"
                                onPress={onPressSignUpFunction}
                                style={"primary"}
                                size="big">
                                Sign Up
                            </CButton>
                            <TouchableOpacity
                                testID="btnpollGuestClose"
                                style={styles.guestNotnow} onPress={isVisibleFunction}>
                                <CText style="primary" size="base" weight='bold'>Not now</CText>
                            </TouchableOpacity>
                        </View>
                    </View>
                </Overlay >

            </View>

        )
    }
}

export { CGuestPopup }

const styles: any = StyleSheet.create({
    guestOverlayStyle: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: "auto",
        backgroundColor: 'white',
        borderTopRightRadius: Scale(24),
        elevation: 5,
    },
    stickyIcon: {
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 100
    },
    popupTextWrapper: { padding: 24, },
    mainGuestTitle: { marginTop: 24, marginBottom: 10, alignItems: "center" },
    subGuestTitle: { marginBottom: 40, alignItems: "center", justifyContent: "center" },
    guestNotnow: { marginVertical: 24, alignItems: "center" },
});
