import React, { Component } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
import Scale from '../../Scale';
import { Overlay } from 'react-native-elements';
import CTButton from '../CTButton/CTButton';
import CButton from '../CButton/CButton';
import CFilterListItem from './CFilterListItem';
import { CText } from '../CText/CText';

interface ImageProps {
    type: 'svg' | 'image',
    path: any,
    style?: any
}

interface Props {
    modalShow: boolean;
    onPressClose?: any;
    closeTestID?: string;
    onPressButton?: () => void;
    buttonTestID?: string;
    onPressBottomButton?: () => void;
    bottomButtonTestID?: string;
    image?: ImageProps;
    children?: any;
    renderView?: any;
    filterListData?: any;
    onPressCheckBox?: any;
    handleLoadMore?: any;
    onRefreshList?: any;
    onPressCloseModal?: any;
    headerHeight?: any;
    openedFromForum?: boolean;
    onMomentumScrollBegin?: any;
    selectedData?: any;
    statusBarHeight?: any;
}
interface S {
    listDataArr?: any;
    selectedDataArr: any[];
    page: number;
    refreshing: boolean;
    isLoading: boolean;
}
export default class CFilterModal extends Component<Props, S> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            listDataArr: this.props.filterListData,
            selectedDataArr: [],
            page: 1,
            refreshing: false,
            isLoading: true,
        }
        // Customizable Area End
    }

    componentDidMount(): void {
        let updatedFilterList = this.props.filterListData?.map((item: any) => {
            const selectedData = this.props.selectedData?.filter((i: any) => item.id == i?.id);
            if (selectedData?.length > 0) {
                return {
                    ...item,
                    isSelected: true
                };
            } else {
                return {
                    ...item
                }
            }
        });
        this.setState({ listDataArr: updatedFilterList })
    }

    componentDidUpdate(prevProps: Props) {
        if (this.state.listDataArr.length !== this.props.filterListData.length) {
            this.setState({ listDataArr: this.props.filterListData });
        }
    }

    toggleCheckBox = (index: any) => {
        let filterData = this.state.listDataArr.map((item: any, itemIndex: number) => {
            if (index === itemIndex) {
                return {
                    ...item,
                    isSelected: !item.isSelected
                }
            } else {
                return {
                    ...item
                }
            }
        })
        this.setState({ listDataArr: filterData });
    }

    applyFilterData = () => {
        const filterDataId: any = [];
        this.state.listDataArr.map((item: any) => {
            if (item?.isSelected) {
                filterDataId.push(item)
            }
        });
        this.props.onPressClose(filterDataId);
        this.clearSlectedFilterData();
    }

    clearSlectedFilterData = () => {
        let filterData = this.state.listDataArr.map((item: any) => {
            if (item?.isSelected) {
                return {
                    ...item,
                    isSelected: false
                }
            } else {
                return {
                    ...item
                }
            }
        })
        this.setState({ listDataArr: filterData });
    }

    render() {
        const { modalShow, onPressCloseModal, headerHeight, openedFromForum, onMomentumScrollBegin, statusBarHeight } = this.props;
        return (
            <Overlay
                isVisible={modalShow}
                overlayStyle={[styles.modalContainer,
                { marginTop: Platform.OS === 'android' ? headerHeight : headerHeight + (statusBarHeight || 0) }
                ]}
                onBackdropPress={onPressCloseModal}>
                <>
                    <CFilterListItem
                        filterListData={this.state.listDataArr}
                        onPressCheckBox={(index: any) => this.toggleCheckBox(index)}
                        handleLoadMoreData={() => { this.props.handleLoadMore() }}
                        onRefreshData={() => this.props.onRefreshList()}
                        openedFromForum={openedFromForum}
                        onMomentumScrollBegin={onMomentumScrollBegin}
                    />
                    {this.state.listDataArr.length !== 0 && <View style={styles.bottomButtonView}>
                        <CTButton
                            testID="btnForgotPassword"
                            onPress={() => this.clearSlectedFilterData()}
                            style={styles.clearButtonText}
                            color="red" size="sm" weight='bold'>Clear
                        </CTButton>
                        <CButton
                            testID={"btnGuestPoll"}
                            type="button"
                            onPress={() => this.applyFilterData()}
                            style={"primary"}
                            size="big"
                        >
                            <CText style='white' size='base' weight='bold' children={'Apply'} />
                        </CButton>
                    </View>}
                </>
            </Overlay>
        )
    }
}

const styles: any = StyleSheet.create({
    modalContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: "auto",
        backgroundColor: '#2C314B',
        borderRadius: Scale(8),
        elevation: 5,
    },
    closeIcon: {
        position: 'absolute',
        top: Scale(15),
        right: Scale(15),
        zIndex: 100
    },
    bottomButtonView: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingVertical: Scale(10),
    },
    filterItemContainer: {
        flexDirection: 'row',
        paddingHorizontal: Scale(10),
        paddingTop: Scale(20),
        paddingBottom: Scale(10)
    },
    clearButtonText: { paddingRight: Scale(20) },
    sepratorView: { borderWidth: Scale(1), borderColor: 'gray' },
    checkBoxViewContainer: { justifyContent: 'center', right: 0 }
});