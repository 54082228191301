import React from 'react';
import { View, StyleSheet, Image, TouchableOpacity, } from 'react-native';
import { CText } from '../CText/CText';
import { profileIconPlaceholder } from '../../../../blocks/events/src/assets';
import Scale from '../../Scale';
import CGradient from '../CGradient/CGradient';

const CPlayerCell =
    ({ playerData, navigation, isCompleted, percentage, showPercentage, index }:
        { playerData: any, navigation: any, isCompleted?: any, percentage?: any, showPercentage: boolean, index?: any }) => {
        return (
            <>
                {index != null &&
                    <View style={styles.countView}>
                        {
                            index < 2 ?
                                <CGradient colors={['#FF002F', '#FF751B']}
                                    style={styles.count}>
                                    <CText textAlign="center" style="white" size="sm" weight={index < 2 ? "bold" : "light"}>
                                        {index + 1}
                                    </CText>
                                </CGradient>
                                :
                                <View style={styles.count}>
                                    <CText textAlign="center" style="white" size="sm" weight={"light"}>
                                        {index + 1}
                                    </CText>
                                </View>
                        }
                    </View>
                }
                <TouchableOpacity onPress={() => navigation.navigate("ViewPlayerProfile", {
                    comesFrom: "other", id: playerData?.id?.toString()
                })} style={styles.flexDirectionRow}>
                    <CGradient style={styles.gradientBorder}>
                        <Image
                            source={playerData?.photo_url_resize ? { uri: playerData?.photo_url_resize } : profileIconPlaceholder}
                            style={styles.playerPhotoView}
                        />
                    </CGradient>
                    <View style={styles.playerCellNameContainer}>
                        {index == null && <View style={{ width: showPercentage ? 120 : "100%" }}>
                            <CText style="primary" size="sm" weight="bold">
                                {playerData?.user_name && playerData?.user_name.toUpperCase()}
                            </CText>
                        </View>}
                        <View style={[styles.flexDirectionRow, { width: index == null ? 120 : 100, }]}>
                            <CText style="white" size="sm" weight="light">
                                {`${playerData?.first_name} ${playerData?.last_name}`}
                            </CText>
                            {(!isCompleted && showPercentage && percentage != null && percentage != 0) &&
                                <View style={[styles.flexDirectionRow, { height: 20 }]}>
                                    <View style={styles.dotView} />
                                    <CText style="primary" size="sm" weight="light">
                                        {percentage}%
                                    </CText>
                                </View>
                            }
                        </View>
                    </View>
                </TouchableOpacity>
            </>


        );
    };

const styles = StyleSheet.create({
    fullWidth: {
        width: "100%",
    },
    flexDirectionRow: {
        flexDirection: "row",
    },
    playerCellContainer: {
        alignItems: "center",
        padding: 20,
    },
    playerPhotoView: {
        width: 32,
        height: 32,
        borderRadius: 16,
        alignSelf: "center"
    },
    playerCellNameContainer: {
        paddingLeft: 10,
        justifyContent: "center",
    },
    borderView: {
        height: 1,
        width: "100%",
        backgroundColor: "#2C314B",
    },
    marginTop20: {
        marginTop: 20,
    },
    dotView: { width: 4, height: 4, borderRadius: 25, alignSelf: "center", backgroundColor: "white", marginHorizontal: 8 },
    gradientBorder: {
        height: Scale(38), width: Scale(38),
        borderRadius: Scale(100),
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
    },
    count: {
        alignSelf: "flex-start", paddingVertical: 1, paddingHorizontal: 6, marginHorizontal: 10, marginVertical: 5,
        borderRadius: 7,
    },
    countView: { width: 50, justifyContent: "center" }

});

export default CPlayerCell;