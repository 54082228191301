import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image
} from 'react-native';
import Icon from 'react-native-vector-icons/Entypo';
import { CText } from '../CText/CText';
import Scale from '../../Scale';
import { getStorageData } from 'framework/src/Utilities';
import { heartBordrIcon, heartFilledIcon } from '../../assets';

interface Props {
  testId?: any;
  onPress?: any;
  likeCount?: any;
  initialStatus?: any;
  isUsedInForum?: boolean;
}

interface S {
  onSelectHeart: boolean;
  addLike: number;
  isGuest: boolean;
}

export default class CLike extends Component<Props, S> {
  state = {
    onSelectHeart: this.props.initialStatus || false,
    addLike: this.props.likeCount,
    isGuest: false,
  };

  toggleHeart = () => {
    if (!this.state.isGuest) {
      this.setState({ onSelectHeart: !this.state.onSelectHeart });
      if (this.state.onSelectHeart === false) {
        this.setState({ addLike: this.state.addLike + 1 })
      } else {
        this.setState({ addLike: this.state.addLike - 1 })
      }

    }
    this.props.onPress(!this.state.onSelectHeart);
  };

  componentDidMount = async () => {
    const token = await getStorageData('USERTYPE');
    token === "guest" && this.setState({ isGuest: true });
    this.setState({ onSelectHeart: this.props.initialStatus });
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.initialStatus !== this.props.initialStatus) {
      this.setState({ onSelectHeart: this.props.initialStatus });
    }
  };

  render() {
    const { onSelectHeart } = this.state;
    return (
      <View style={styles.iconViewSty}>
        <TouchableOpacity
          testID={this.props.testId}
          onPress={this.toggleHeart}
          style={styles.iconViewSty}
        >
          <Image source={onSelectHeart ? heartFilledIcon : heartBordrIcon} style={{ height: Scale(20), width: Scale(20), resizeMode: "contain", marginRight: Scale(6) }} />
          <CText style={this.props.isUsedInForum ? "white" : "gray"} size={"xs"} weight={this.props.isUsedInForum ? "light" : "bold"} lineHeight={26}>
            {this.state.addLike}
          </CText>
        </TouchableOpacity>
      </View>
    );
  }
}

const styles: any = StyleSheet.create({
  iconViewSty: {
    flexDirection: 'row',
    alignItems: "center",
    justifyContent: "center"
  },
});
