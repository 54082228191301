Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Events";
exports.labelBodyText = "Events Body";
exports.acceptRequestEndPoint = "/events/requests/accepted";
exports.createEventEndPoint = "/events/events";
exports.GOOGLE_API_KEY = "AIzaSyAQu429K52drmir9450TvUYcy82ZL3QQ9I";
exports.GOOGLE_PACES_API_BASE_URL = "https://maps.googleapis.com/maps/api/place";
exports.locationText = "Location"
exports.eventNumber = "events found "
exports.viewDetails = "View details"
exports.overAllRanking = " OVERALL RANKING";
exports.player = "Player";
exports.la = "LA";
exports.fra = "FRA";
exports.asia = "Asia";
exports.midEast = "Mid East";
exports.tot = "Tot.";
exports.scoringsystem = "Scoring system";
exports.points = "Points";
exports.player = "Player";
exports.player = "Player";
// exports.getTermsCondsApiEndPoint = "/bx_block_events/events"
exports.eventNumber = "events found "
exports.getTermsCondsApiEndPoint = "bx_block_events/events/all_events"
exports.locationEndPoint = "/bx_block_events/events/all_locations"
exports.getOngoingEvent = "bx_block_events/events/ongoing_events"

exports.endPointApiOverAllRanking = "/bx_block_events/player_rank";
exports.contentTypeApiOverAllRanking = "application/json";
exports.methodTypeApiOverAllRanking = "GET";
exports.GOOGLE_PACES_API_BASE_URL =
  "https://maps.googleapis.com/maps/api/place";
exports.eventNumber = "events found ";

// Constants for All Event Detail Screen
exports.allEventDetailScreenHeaderTitle = "ALL EVENTS DETAILS";
exports.bookTicketsText = "Book tickets";
exports.playersText = "Players";
exports.getMethod = "GET";
exports.matchScheduleText = "Match schedule";
exports.eventDetailsApiURL = "bx_block_events/v1/events/";
exports.eventMatchesApiURL="bx_block_events/v1/event_schedules?id="
exports.eventPlayersApiURL="bx_block_events/v1/event_players?id="
exports.dashboradRankingUrl = "bx_block_events/player_group_rank?id=";
exports.rankingLabel="League Ranking"
// Customizable Area End
