//@ts-nocheck
import React, { Component } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Text,
  Image,
  Platform,
  ActivityIndicator
} from 'react-native';
import Scale from '../../Scale';
// import LinearGradient from 'react-native-linear-gradient';
import { getFontFamily } from 'framework/src/Utilities';

interface Props {
  onPress?: any;
  testID?: string;
  disabled?: boolean;
  children?: any;
  type?: string;
  style?: string;
  size?: string;
  iconSource?: any;
  iconStyle?: any;
  btnTxtStyle?: any;
  isLoading?: boolean;
  loaderColor?: string;
  loaderSize?: 'small' | 'large';
}

export default class CButton extends Component<Props> {
  getStyles() {
    const { type, style, size, btnTxtStyle } = this.props;
    return {
      containerStyles: {
        ...styles[type],
        ...styles[style],
        ...styles[size]
      },
      textStyles: {
        ...styles.text,
        ...styles[`text_${style}`],
        ...styles[`text_${size}`],
        ...btnTxtStyle
      }
    };
  }

  render() {
    const {
      children,
      onPress,
      testID,
      disabled,
      style,
      iconSource,
      iconStyle,
      isLoading,
      loaderColor,
      loaderSize
    } = this.props;
    const { containerStyles, textStyles } = this.getStyles();
    return style === 'primary' || style === "poll" ? (
      <TouchableOpacity
        testID={testID}
        disabled={disabled}
        onPress={onPress}
        hitSlop={{ left: -10, right: -10 }}
      >
        {/* <LinearGradient
          colors={['#FF002F', '#FF751B']}
          start={{ x: 0.25, y: 0.75 }}
          end={{ x: 1, y: 0.35 }}
          style={containerStyles}
        >
          {iconSource && <Image source={iconSource} style={iconStyle} />}
          <Text style={textStyles}>{children}</Text>
          {isLoading && <ActivityIndicator size={loaderSize} style={styles.loaderStyle} color={loaderColor} />}
        </LinearGradient> */}
      </TouchableOpacity>
    ) : (
      <TouchableOpacity
        testID={testID}
        disabled={disabled}
        onPress={onPress}
        style={containerStyles}
      >
        <Text style={textStyles}>{children}</Text>
      </TouchableOpacity>
    );
  }
}

const styles: any = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Scale(15),
    borderRadius: Scale(25),
    marginHorizontal: Scale(10)
  },
  poll: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Scale(7.5),
    borderRadius: Scale(58),
    marginHorizontal: Scale(16)
  },
  primary: {
    backgroundColor: 'rgba(255, 0, 47, 1)'
  },

  secondary: {
    backgroundColor: 'rgba(76, 80, 100, 1)'

    // borderStyle: "solid",
    // borderColor: "rgba(255, 255, 255, 1)"
  },
  bright: {
    backgroundColor: "#fff"
  },
  big: {
    borderRadius: 58,
    height: 58,
    padding: 16
  },
  medium: {
    borderRadius: 58,
    height: 44,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 16
  },
  small: {
    borderRadius: 58,
    height: 32,
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10
  },
  text: {
    fontFamily: getFontFamily('bold'),
    fontWeight: 'bold'
  },
  text_primary: {
    color: 'rgba(0, 0, 0, 1)'
  },
  text_secondary: {
    color: '#FFF'
  },
  text_big: {
    fontSize: 18,
    height: Scale(24),
    lineHeight: Scale(24)
  },
  text_bigger: {
    fontSize: 20,
    height: Scale(24),
    lineHeight: Scale(24)
  },
  text_medium: {
    fontSize: 18,
    height: Scale(24),
    lineHeight: Scale(24)
  },
  text_small: {
    fontSize: 18,
    height: Scale(24),
    lineHeight: Scale(24)
  },
  loaderStyle: { marginLeft: Scale(5) },
});
