// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  route: unknown;
  propData: unknown;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string;
  subscriptions: [];
  stateData: string;
}

interface SS {
  id: any;
}

export default class InapppurchasingController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: "",
      subscriptions: [],
      stateData: ""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const msgData = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ stateData: msgData });
    }
  }

  gotoSubscriptionListViewScreen(){
    const msgs = new Message(getName(MessageEnum.NavigationMessage));
    msgs.addData(getName(MessageEnum.NavigationTargetMessage), "InAppPurchaseList");
    msgs.addData(getName(MessageEnum.NavigationPropsMessage),this.props);
    this.send(msgs)
  }
}

// Customizable Area End
