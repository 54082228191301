import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Alert, Animated } from "react-native";
import { validateEmail } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isErrorMsg: boolean;
  termsCond: boolean;
  showErrorMsg: string;
  isValid: boolean;
  isSignUp: boolean;
  isCheckedBox: boolean;
  validPassword: boolean;
  showGUestPopup: boolean;
  animation: Animated.Value;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  emailCheckCallId: string = "";
  signUpCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isErrorMsg: false,
      showErrorMsg: "",
      termsCond: true,
      isSignUp: false,
      isValid: false,
      isCheckedBox: false,
      validPassword: false,
      showGUestPopup: false,
      animation: new Animated.Value(0),
    };
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.emailCheckCallId) {
          this.emailCheckApiSuccessCallBack(responseJson);
        }
        if (apiRequestCallId === this.signUpCallId) {
          this.signUpApiSuccessCallBack(responseJson);
        }
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevState.email != this.state.email) {
      let testEmail = validateEmail("Email", this.state.email);
      if (testEmail.message === "") {
        this.setState({ isValid: true }, () => {
          this.emailCheckerApi();
        });
      } else {
        this.setState({ isValid: false, isErrorMsg: false });
      }
    }
  }

  emailCheckApiSuccessCallBack = (responseJson: any) => {
    if (responseJson.message != "Valid email") {
      Alert.alert(responseJson.message);
    }
  };
  signUpApiSuccessCallBack = (responseJson: any) => {
    if (responseJson.message === "OTP has been sent to your email address.") {
      this.props.navigation.navigate("OTPinputCheck", {
        fromSignup: true,
        email: this.state.email
      });
    } else {
      Alert.alert(responseJson.message);
    }
  };

  onEmailChange = (text: string) => {
    this.setState({ email: text });
  };
  onPasswordChange = (text: string) => {
    this.setState({ password: text.trim() });
  };

  handlePasswordValidation = (isValid: boolean) => {
    this.setState({ validPassword: isValid });
  };
  emailCheckerApi = () => {
    const header = {
      "Content-Type": "application/json"
    };
    const httpBody = {
      user: {
        email: this.state.email
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.emailCheckCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.emailCheckApiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  signUpApi = () => {
    const header = {
      "Content-Type": "application/json"
    };
    const httpBody = {
      user: {
        email: this.state.email,
        password: this.state.password,
        is_terms_accepted: this.state.isCheckedBox
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpApiURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  handleGuestPopup = () => {
    Animated.timing(this.state.animation, {
      toValue: 0,
      duration: 300,
      useNativeDriver: false,
    }).start(() => {
      this.setState({ showGUestPopup: !this.state.showGUestPopup })
    });
  };
  handleGuestNavigation = () => {
    this.handleGuestPopup();
    this.props.navigation.navigate("GuestLogin");
  };

  slideUp = () => {
    Animated.timing(this.state.animation, {
      toValue: 1,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };
  // Customizable Area End
}
