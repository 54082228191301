import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  eventPlayerRanking: any;
  toggleOptions: any[];
  toggleOptionsForPastEvents: any[];
  activeToggleButton: number;
  eventId: any;
  token: string;
  isLoading: boolean;
  eventDetailsData: any
  eventMatchData: any[]
  eventPlayersData: any[]
  openModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllEventController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetEventDetailsId: any;
  apiGetPlayerRankingId: any;
  eventsDetailsId: string = "";
  matchDetailsId: string = "";
  matchPlayersId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      toggleOptions: [
        { id: 1, title: 'Players' },
        { id: 2, title: 'Match schedule' },
      ],
      toggleOptionsForPastEvents: [
        { id: 1, title: 'Players' },
        { id: 2, title: 'Schedule' },
        { id: 3, title: 'Ranking' }
      ],
      eventPlayerRanking: {},
      activeToggleButton: 1,
      isLoading: true,
      token: "",
      eventId: "",
      eventDetailsData: {},
      eventMatchData: [],
      eventPlayersData: [],
      openModal: false
      // Customizable Area End
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const eventId = message.getData(
        getName(MessageEnum.EventIdMessage)
      );
      this.setState({ eventId }, () => {
        this.getEventsDetails()
        this.getMatchDetails()
        this.getPlayersDetails()
        this.getPlayerGroupRankAPI();
      })
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.eventsDetailsId) {
          this.eventDetailsSuccessBlock(responseJson)
        }
        else if (apiRequestCallId === this.matchDetailsId) {
          this.eventmatchDetailsSuccessBlock(responseJson)
        }
        else if (apiRequestCallId === this.matchPlayersId) {
          this.eventmatchPlayersSuccessBlock(responseJson)
        }
        else if (apiRequestCallId === this.apiGetPlayerRankingId) {
          this.playerRankingSuccessBlock(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  eventmatchDetailsSuccessBlock = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        isLoading: false,
        eventMatchData: responseJson
      })
    }
  }
  eventDetailsSuccessBlock = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        isLoading: false,
        eventDetailsData: responseJson?.data?.attributes
      })
    }
  }
  eventmatchPlayersSuccessBlock = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        isLoading: false,
        eventPlayersData: responseJson
      })
    }
  }
  playerRankingSuccessBlock = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({ isLoading: false, eventPlayerRanking: responseJson });
    }
  }

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const token = await getStorageData("TOKEN");

    const header = {
      "Content-Type": contentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getEventsDetails = async () => {
    this.eventsDetailsId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.eventDetailsApiURL + this.state.eventId,
    })
  };
  getMatchDetails = async () => {
    this.matchDetailsId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.eventMatchesApiURL + this.state.eventId,
    })
  };
  getPlayersDetails = async () => {
    this.matchPlayersId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.eventPlayersApiURL + this.state.eventId,
    })
  };

  getPlayerGroupRankAPI = async () => {
    this.apiGetPlayerRankingId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.dashboradRankingUrl + this.state.eventId,
    })
  };

  // Customizable Area End
}
