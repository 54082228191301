Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Create an account";
exports.labelFirstName = 'Please, Enter your First Name!';
exports.lastName = 'Please, Enter your Last Name!';
exports.phoneNumber = 'Please, provide your phone number!';
exports.gender = 'Please, Select your gender!';
exports.homeCity = 'Please, Enter your city';
exports.min = "phone number too short";
exports.max = "phone number too long";
exports.homeCountry = "Select Country";
exports.ccode = "Select Country Code";
exports.createanAPIMethod = 'PUT';
exports.createAccountUrl = '/account_block/accounts/update';
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.apiEndPointAddDetail =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";

exports.errorCountryCode = "Please select country code";
exports.errorTitle = "Error";
exports.apiSendOtpContentType = "application/json";
exports.apiSendOtpEndPoint =
  "account/accounts/send_otp";

exports.errorMobileNotValid = "Phone number is not valid.";
exports.apiSendOtpMethod = "POST";

exports.placeHolderMobile = "Mobile";
exports.btnTxtSendOtp = "Send me otp";
exports.placeHolderSelectCountry = "Select Country";

exports.bodyText = "Value proposition: why users should sign up.";

exports.labelInfo =
  "We will send a 4 digit OTP to the number below.If the number is incorrect, Change it and resent OTP";
exports.gender = 'Gender';
exports.countryListApiUrl="bx_block_profile/countries"
exports.getMethod="GET"
exports.genderLabel="Gender"
exports.countryLabel="Home Country"
exports.cityLabel='Home City'
exports.phoneNumberLabel='Phone Number'
exports.firstNameLabel='First Name'
exports.lastNameLabel='Last Name'
exports.genderPlaceholder="Gender"
exports.countryPlaceholder="e.g. United Kingdom"
exports.cityPlaceholder='e.g. London'
exports.firstNamePlaceholder='e.g. John'
exports.lastNamePlaceholder='e.g. Doe'
// Customizable Area End