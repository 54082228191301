import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
export const configJSON = require("./config");
import * as yup from 'yup';
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert } from "react-native";
// import { NavigationActions } from 'react-navigation';
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  city: string;
  country: string;
  countryCode: any,
  show: any;
  countryCodes: any;
  disableButton: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AccountCreation03FormFilledController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createAnAccountApi: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      firstName: "",
      lastName: "",
      gender: "",
      phoneNumber: "",
      city: "",
      country: "",
      countryCode: "",
      show: '',
      countryCodes: '',
      disableButton: true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAnAccountApi) {
          this.handleAccountResponse(responseJson)
        }
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleAccountResponse = async (responseJson: any) => {
    if (responseJson.data) {
      // this.props.navigation.reset([NavigationActions.navigate({ routeName: 'BottomTab' })], 0)
    } else {
      Alert.alert('Something went wrong');
    }
  }
  handleCityChange = (handleChange: any, text: any) => {
    if (text.startsWith(' ')) {
      text = text.trimLeft();
    }
    handleChange("city")(text);
  };
  onSelect = (country: any) => {
    console.log("coutnry:", country)
    this.setState({ country: country });
  };
  createanAccountApi = async (values: any) => {
    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
      token: token
    };
    const attrs = {
      first_name: values.firstName,
      last_name: values.lastName,
      gender_id: parseInt(values.gender),
      full_phone_number: '+' + this.state.countryCode + values.phoneNumber,
      country_id: parseInt(values.country),
      city: values.city,
      is_profile_completed: true
    }

    const data = {
      attributes: attrs,
    };
    const httpBody = {
      data: data
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createAnAccountApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createAccountUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createanAPIMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };



  profile_Validation = () => {
    return yup.object().shape({
      firstName: yup.string()
        .test("alphabets", configJSON.errorFName, value => {
          return /^[A-Za-z ]+$/.test(value);
        })
        .required(configJSON.labelFirstName),
      lastName: yup.string()
        .test("alphabets", configJSON.errorLastName, value => {
          return /^[A-Za-z ]+$/.test(value);
        })
        .required(configJSON.lastName),
      phoneNumber: yup.string()
        .required(configJSON.phoneNumber)
        .min(10, configJSON.min)
        .max(10, configJSON.max)
        .test("digits", "Only Digits", value => {
          return /^[0-9]+$/.test(value);
        }),
      countryCode: yup.string()
        .required(configJSON.ccode),
      city: yup.string()
        .required(configJSON.homeCity),
      country: yup.string()
        .required(configJSON.homeCountry),
      gender: yup.string().required(configJSON.gender),
    });
  }
  // Customizable Area End
}
