import React from "react";
// Customizable Area Start
import {
    View,
    Image,
    TouchableOpacity,
    FlatList,
    StatusBar,
    Dimensions,
    StyleSheet,
    SafeAreaView,
    ScrollView,
    Platform
} from "react-native";
import EventOverAllRankingController, { configJSON, Props } from "./EventOverAllRankingController";
import { CAvatarPlayer, CBottomSheetContent, CHeader, CModal, CStatusBar, CText } from "../../../components/src/custom-components";
// Customizable Area End
import Scale, { verticalScale } from "../../../components/src/Scale";
import { crossIcon, scoringSystem } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
let screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;

export default class EventOverAllRanking extends EventOverAllRankingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    getBgColor = (index: any) => {
        switch (index) {
            case 1:
                return '#C6990F';
            case 2:
                return '#38761D';
            default:
                return 'transparent';
        }
    }
    renderCategories(scoreOfEvents: any, total: any) {
        return (
            <View style={{
                backgroundColor: '#1B1B3A',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                {scoreOfEvents && scoreOfEvents.length > 0 && scoreOfEvents.map((data: any, index: number) => {
                    const { type, value } = data;

                    return (
                        <View key={type ?? index} style={styles.scoreView}>
                            <CText size="sm" style="white" weight='light' numberOfLines={1}>
                                {value}
                            </CText>
                        </View>
                    )
                })}

            </View>)
    }
    renderProfileOverAllRanking = (data: { item: any, index: any }) => {
        return (
            <View style={styles.separator}>
                <View style={{ backgroundColor: '#1B1B3A', }}>
                    <CAvatarPlayer
                        id={data?.item?.attributes?.player_id}
                        navigation={this.props.navigation}
                        image={{ path: data.item.attributes.player_pic }}
                        title={data.item.attributes.player_name}
                        bgContainer={{ backgroundColor: this.getBgColor(data.item.attributes.top) }}
                    />

                </View>
            </View>
        )
    }

    renderEvents = (data: { item: any }) => {
        return (
            <View style={styles.eventsView}>
                <CText size="sm" style="white" weight='bold' numberOfLines={2}>
                    {data.item}
                </CText>
            </View>
        )
    }

    renderHeader = () => {
        return (
            <View style={styles.linearGradientContainer}>
                <CHeader
                    testID='headerView'
                    icons={[
                        { testID: 'btnBack', type: 'icon', icon: 'back', position: 'left', iconColor: '#FF751B', onPress: () => this.props.navigation.goBack() },
                        { type: 'text', title: `${configJSON.overAllRanking}`, position: 'center', titleStyle: 'primary', titleSize: 'sm', titleWeight: 'bold' },
                    ]} />
            </View>
        )
    }

    renderPlayerHeader = () => {
        return (
            <View>
                <View style={styles.playerView}>
                    <CText size="sm" style="white" weight='bold' numberOfLines={1}>
                        {configJSON.player}
                    </CText>
                </View>
                {this.renderProfileList()}

            </View>
        )
    }
    renderScoreHeader = () => {
        return (
            <View style={{ flexDirection: "row" }}>
                <ScrollView horizontal style={{ width: "44%" }} showsHorizontalScrollIndicator={false} >
                    <View>
                        {this.renderEventsHeader()}
                        {this.renderMainScoreList()}
                    </View>

                </ScrollView>
                {this.renderMainScoreTotal()}

            </View>
        )
    }
    renderMainScoreTotal = () => {
        return (
            <View>
                <View style={{ padding: 10, height: 64, justifyContent: "center" }}>
                    <CText size="sm" style="white" weight='bold' numberOfLines={2}>
                        {configJSON.tot}
                    </CText>
                </View>
                <FlatList
                    testID="totalRanks"
                    data={this.state.getOverAllRankData}
                    renderItem={this.renderTotalOverAllRanking}
                    style={styles.rankingList}
                    keyExtractor={(item: any) => item.id}
                    scrollEnabled={false}
                />
            </View>

        )
    }

    renderTotalOverAllRanking = (data: { item: any, index: any }) => {
        return (
            <View style={{
                borderTopWidth: 1,
                borderBottomWidth: 1,
                borderTopColor: '#2C314B', borderBottomColor: '#2C314B'
            }}>
                <View style={{
                    backgroundColor: '#1B1B3A',
                    height: 64,
                    justifyContent: "center",
                }}>
                    <View style={styles.viewTotal}>
                        <CText size="sm" style="white" weight='bold' numberOfLines={1}>
                            {data.item.attributes.total}
                        </CText>
                    </View>
                </View>
            </View>
        )
    }
    renderSeperatorViewForEventsHeader = () => <View style={{ width: screenWidth * 0.04, }} />

    renderEventsHeader = () => {
        return (
            <FlatList
                testID="allEvents"
                data={this.state.allEvents}
                renderItem={this.renderEvents}
                horizontal={true}
                ItemSeparatorComponent={this.renderSeperatorViewForEventsHeader}
                style={{ marginLeft: 20, height: 64 }}
            />
        )
    }
    renderScoreOverAllRanking = (data: { item: any, index: any }) => {
        return (
            <View style={styles.separator}>
                <View style={{
                    minWidth: screenWidth * 0.44,
                    backgroundColor: '#1B1B3A',
                    height: 64,
                    justifyContent: "center",
                    paddingLeft: 10
                }}>
                    {this.renderCategories(data.item.attributes.event_data, data.item.attributes.total)}
                </View>
            </View>
        )
    }
    renderProfileList = () => {
        return (
            <FlatList
                testID="profileRank"
                data={this.state.getOverAllRankData}
                renderItem={this.renderProfileOverAllRanking}
                style={styles.rankingList}
                keyExtractor={(item: any) => item.id}
                scrollEnabled={false}
            />
        )
    }
    renderMainScoreList = () => {
        return (
            <FlatList
                testID="scoreList"
                data={this.state.getOverAllRankData}
                renderItem={this.renderScoreOverAllRanking}
                style={styles.rankingList}
                keyExtractor={(item: any) => item.id}
                scrollEnabled={false}
            />
        )
    }
    renderScoringSystemView = () => {
        return (
            <TouchableOpacity testID="enableMdl" onPress={() => {
                this.setState({
                    visible: true
                });
            }} style={styles.scoringSystemView}>
                <View style={{ paddingRight: Scale(10) }}>
                    <CText size="base" style="white" weight='bold' numberOfLines={1}>
                        {configJSON.scoringsystem}
                    </CText>
                </View>
                <Image
                    style={styles.scoreSystemImg}
                    source={scoringSystem}
                />
            </TouchableOpacity>
        )
    }

    renderScoringSysDetails = (data: { item: any }) => {
        return (
            <>
                <View style={styles.modelStyle}>
                    <View style={styles.innerModelStyle}>
                        <View style={{ paddingRight: Scale(10) }}>
                            <CText size="sm" style="white" weight='light' numberOfLines={2}>
                                {data.item.attributes.scoring_option}
                            </CText>
                        </View>
                    </View>
                    <View style={styles.pointContainer}>
                        <CText size="sm" style="white" weight='bold' numberOfLines={1}>
                            {data.item.attributes.points}
                        </CText>
                    </View>
                </View>
                <View style={styles.divider} />
            </>
        )
    }

    renderScoringSystemModal = () => {
        return (
            <CModal
                testID="testModel"
                title={''}
                data={[{}]}
                onTouchOutside={() => {
                    this.setState({ visible: false });
                }}
                customListItem={false}
                mainView={
                    <CBottomSheetContent
                        scoringSys={configJSON.scoringsystem}
                        points={configJSON.points}
                        crossIcon={crossIcon}
                        scoringSystemList={this.state.systemScoring}
                        renderScoringSystem={this.renderScoringSysDetails}
                        onPressClose={() => {
                            this.setState({ visible: false });
                        }}
                    />
                }
                showBottomSheet={true}
                showDone
                visible={this.state.visible}
            />
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start

        return (

            <View style={styles.container}>

                <View style={{ flex: 1 }}>
                    <CStatusBar backgroundColor="#1B1B3A" barStyle="light-content" />
                    {this.renderHeader()}
                    <ScrollView contentContainerStyle={{ flexDirection: "row", }}>
                        {this.renderPlayerHeader()}
                        {this.renderScoreHeader()}
                    </ScrollView>
                    {this.renderScoringSystemView()}
                </View>

                {this.renderScoringSystemModal()}
            </View>

        );
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.handleAPICall();
        // Customizable Area End
    }

    // Customizable Area Start
    handleAPICall = async () => {
        const token = await getStorageData("TOKEN");
        this.setState({ token: token }, () => {
            this.getOverAllRank();
        });
    }
    // Customizable Area End
}

const styles = StyleSheet.create({
    SafeAreaViewSty: {
        flex: 1
    },
    viewTotal: {
        width: screenWidth * 0.1,
        paddingLeft: Scale(10),
    },
    container: {
        flex: 1,
        backgroundColor: "#000024",
    },
    linearGradientContainer: {
        paddingBottom: Scale(20),
        width: "100%",
        backgroundColor: "#1B1B3A",
    },
    headerContainer: {
        flexDirection: "row",
        marginTop: screenHeight * 0.064,
    },
    name: {
        fontSize: 22,
        fontWeight: "600",
        color: "#FF751B",
        backgroundColor: "transparent",
        top: -1.2,
    },
    backarrowContainer: {
        marginTop: screenHeight * 0.01,
    },
    backarrow: {
        height: screenHeight * 0.021,
        width: screenWidth * 0.045,
        tintColor: '#FF751B'
    },
    scoreSystemImg: {
        height: screenHeight * 0.025,
        width: screenWidth * 0.06,
        tintColor: "#FFFFFF"
    },
    eventsView: { width: screenWidth * 0.09, alignItems: "center", justifyContent: "center" },
    playerView: {
        width: screenWidth * 0.45,
        justifyContent: "center",
        height: 64,
        paddingHorizontal: 15
    },
    scoreView: {
        marginHorizontal: Scale(8.5),
        width: screenWidth * 0.09, alignItems: "center", justifyContent: "center"
    },
    playerText: {
        color: '#FFFFFF',
        fontSize: 15,
        fontWeight: 'bold'
    },
    scoreSystemText: {
        color: '#FFFFFF',
        fontSize: 15,
        paddingRight: 10
    },
    separator: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#2C314B',

    },
    scoringSystemView: {

        backgroundColor: '#000024',
        paddingVertical: Scale(15),
        paddingLeft: Scale(10),
        flexDirection: 'row',
        alignItems: "center",

    },
    rankingList: {
        // height: screenHeight * 0.7,
    },
    modelStyle: {
        flexDirection: 'row',
        paddingVertical: Scale(15),
    },
    innerModelStyle: {
        width: screenWidth * 0.75,
        paddingLeft: Scale(15),
        paddingRight: Scale(15)
    },
    nameStyle: {
        color: '#FFFFFF',
        paddingRight: Scale(10)
    },
    pointContainer: {
        width: screenWidth * 0.25,
    },
    pointTextColor: {
        color: '#FFFFFF',
        fontWeight: 'bold'
    },
    divider: {
        height: 1,
        backgroundColor: '#1B1B3A',
        marginHorizontal: Scale(15)
    },
    overAllRankingView: {
        width: screenWidth * 0.85,
        alignItems: 'center',
        top: -1.2,
    },
    bottomContainer: {
        height: screenHeight * 0.65,
        backgroundColor: '#010125',
        borderTopRightRadius: 20,
    },
    scoringSystemHeader: {
        marginHorizontal: Scale(5),
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: Scale(10),
        paddingVertical: Scale(15)
    },
    scoringSystemText: {
        color: '#FFFFFF',
        fontSize: 20,
        fontWeight: 'bold'
    },
    innerScoringSystemBox: {
        marginHorizontal: Scale(15),
        borderRadius: Scale(10),
        borderWidth: 1,
        borderColor: '#1B1B3A',
        marginTop: Scale(20),
    },
    scoringTableView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: Scale(10),
        marginBottom: Scale(10),
        marginLeft: Scale(15),
        marginRight: Scale(40),
        paddingVertical: Scale(10)
    },
    txtScoring: {
        color: '#FFFFFF',
        fontWeight: 'bold'
    },
    dividerListView: {
        height: 1,
        backgroundColor: '#2C314B'
    },
    headerLeftIcon: {
        marginRight: Scale(50),
        alignSelf: "center",
        height: verticalScale(32),
        width: Scale(35),
    },
});
