import React from "react";
import { StyleSheet, TouchableWithoutFeedback, View, Keyboard } from "react-native";
import SearchForumsController, { configJSON } from "./SearchForumsController";
import {
  CHeader,
  CSearchInput,
  CSearchList,
  CStatusBar,
  CText,
} from "../../../components/src/custom-components";
import Scale from "../../../components/src/Scale";

export default class SearchForums extends SearchForumsController {
  render() {
    const { searchList, errorMessage } = this.state;
    return (
      <TouchableWithoutFeedback testID="dismiss" onPress={() => Keyboard.dismiss()}>
        <View style={styles.container}>
          <CStatusBar />
          <View style={styles.headerView}>
            <CHeader
              testID="headerView"
              icons={[
                {
                  testID: "closeBtn",
                  type: "icon",
                  icon: "back",
                  position: "left",
                  iconColor: "#FF751B",
                  onPress: () => this.props.navigation.goBack()
                },
                {
                  type: "text",
                  title: `${configJSON.headerTitle}`,
                  position: "left",
                  titleStyle: "primary",
                  titleSize: "base",
                  titleWeight: "bold",
                  alignTitle: "left"
                }
              ]}
            />
            <CSearchInput
              testID={'searchTextInput'}
              value={this.state.searchQuery}
              onChangeText={(text: string) => {
                this.setSearchQuery(text);
              }}
              closeIconOnPress={() => this.clearQuery()}
              placeholder={'Search..'}
              multiline={false}
              numberOfLines={1}
              image={true}
            />
          </View>
          {Array.isArray(searchList) && searchList.length ? (
            <CSearchList
              listData={searchList}
              onPress={(threadId: number) =>
                this.navigateToThreadCommentsScreen(threadId)
              }
            />
          ) : (
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
              <CText style="white" size="base" weight="bold">{errorMessage}</CText>
            </View>
          )}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#000024'
  },
  headerView: {
    backgroundColor: '#1B1B3A',
    borderBottomRightRadius: Scale(32),
    paddingBottom: Scale(16)
  },
});
