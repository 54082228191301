import React from "react";
// Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Animated,
  Image
} from "react-native";
import { Overlay } from "react-native-elements";
import Icon from "react-native-vector-icons/Entypo";
import {
  CButton,
  CInput,
  CText,
  CTButton,
  CToast,
  CAppBackground,
  CStatusBar
} from "../../../components/src/custom-components";
import Scale from "../../../components/src/Scale";
import { utsHomeScreenLogo } from "../../../components/src/assets";
//@ts-ignore

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    let translateY = this.state.animation.interpolate({
      inputRange: [0, 1],
      outputRange: [300, 0]
    });
    return (
      <View style={styles.SafeAreaView}>
        <View style={styles.container}>
          <CAppBackground>
            <CStatusBar
              backgroundColor="rgba(0, 0, 36, 0)"
              barStyle="light-content"
            />
            <ScrollView
              contentContainerStyle={styles.scrollContainer}
              style={styles.subContainer}
            >
              <View style={{ justifyContent: "center", alignItems: "center", marginTop: Scale(45), marginBottom: Scale(64) }}>
                <Image source={utsHomeScreenLogo} style={{ width: Scale(220), height: Scale(91), resizeMode: "contain" }} />
              </View>
              <CInput
                testID={"txtInputEmail"}
                label={"Email"}
                placeholder={"example@example.com"}
                isValid={this.state.isErrorMsg}
                keyboardType="email-address"
                onChangeText={(text: string) => this.onEmailChange(text)}
                value={this.state.email}
                isValidEmail={this.state.isValid}
                type={"email"}
              />
              <CInput
                testID={"txtInputPassword"}
                label={"Password"}
                placeholder={"Enter your Password"}
                isValid={this.state.isErrorMsg}
                onChangeText={(text: string) => this.onPasswordChange(text)}
                value={this.state.password}
                type={"password"}
              />
              <CToast
                testID="toast"
                msg={this.state.showErrorMsg}
                type="fail"
                position="bottom"
                callback={() => this.setState({ showErrorMsg: "" })}
              />

              <CTButton
                testID="btnForgotPassword"
                onPress={() => this.props.navigation.navigate("ForgotPassword")}
                style={styles.forgotPwdText}
                color="primary"
                size="base"
                weight="bold"
              >
                Forgot password?
              </CTButton>
            </ScrollView>
            <View style={styles.loginContainer}>
              <CButton
                testID={"btnEmailLogIn"}
                type="button"
                disabled={
                  this.state.email === "" ||
                    this.state.password === "" ||
                    !this.state.isValid
                    ? true
                    : false
                }
                onPress={() => this.emailLogIn()}
                style={
                  this.state.email === "" ||
                    this.state.password === "" ||
                    !this.state.isValid
                    ? "secondary"
                    : "primary"
                }
                size="bigger"
              >
                Log in
              </CButton>
              <TouchableOpacity style={{
                marginHorizontal: Scale(10),
                marginTop: Scale(16),
                paddingVertical: Scale(16),
                borderWidth: Scale(1),
                borderColor: "#ffffff",
                borderRadius: Scale(58),
                alignItems: "center",
                justifyContent: "center"
              }}
                onPress={() =>
                  this.props.navigation.navigate("EmailAccountRegistration")
                }>
                <CText style="primary" size="base" weight="bold" lineHeight={24}>
                  Sign Up
                </CText>
              </TouchableOpacity>
              <View style={styles.orWrapper}>
                <View style={{ height: 1, flex: 1, backgroundColor: "#4C5064", marginRight: Scale(16) }} />
                <CText style="gray" size="sm" weight="bold" lineHeight={22}>
                  OR
                </CText>
                <View style={{ height: 1, flex: 1, backgroundColor: "#4C5064", marginLeft: Scale(16) }} />
              </View>

              <TouchableOpacity
                style={{ alignItems: "center", justifyContent: "center" }}
                onPress={() => {
                  this.handleGuestPopup();
                }}
              >
                <CText style="primary" size="base" weight="bold" lineHeight={24}>
                  LOGIN AS A GUEST
                </CText>
              </TouchableOpacity>
            </View>

            <Overlay
              //  @ts-ignore
              testID="overlay"
              isVisible={this.state.showGUestPopup}
              overlayStyle={styles.guestOverlayStyle}
              onShow={this.slideUp}
            >
              <Animated.View style={[{ transform: [{ translateY }] }]}>
                <View testID="guestPopupWrapper" style={{ backgroundColor: "#ffffff", borderTopRightRadius: Scale(24), }}>
                  <TouchableOpacity
                    testID="guestPopupLoginClose"
                    onPress={() => {
                      this.handleGuestPopup();
                    }}
                    style={styles.stickyIcon}
                  >
                    <Icon name="cross" size={24} color="#000024" />
                  </TouchableOpacity>
                  <View style={styles.popupMainWrapper}>
                    <View style={styles.popupTitleWrapper}>
                      <CText style="blue" size="xxl" weight="bold" textAlign="center">
                        Proceeding as a guest
                      </CText>
                    </View>
                    <View style={styles.popupSubTitleWrapper}>
                      <CText style="blue" size="base" weight="light" textAlign="center">
                        As a guest, you'll have access to view details for all
                        UTS events. Some features such as comments likes, polls,
                        and other interactive content, are reserved for
                        registered members only.
                      </CText>
                    </View>
                    <CButton
                      testID={"btnGuestLogIn"}
                      type="button"
                      onPress={() => {
                        this.handleGuestNavigation();
                      }}
                      style={"primary"}
                      size="big"
                    >
                      Continue as a guest
                    </CButton>
                  </View>
                </View>
              </Animated.View>
            </Overlay>
          </CAppBackground>
        </View>
      </View>
    );

    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  SafeAreaView: {
    flex: 1
  },
  container: {
    backgroundColor: "rgba(0, 0, 36, 1)",
    flex: 1
  },
  subContainer: { flex: 1, margin: Scale(10) },
  passwordCheck: {
    marginVertical: Scale(10),
    justifyContent: "space-between"
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)"
  },
  headerView: {
    backgroundColor: "rgba(0, 0, 0, 0)"
  },
  row: {
    flexDirection: "row",
    margin: Scale(10)
  },
  guestText: {
    position: "absolute",
    right: 0,
    top: Scale(10)
  },
  loginContainer: {
    marginHorizontal: Scale(10),
    paddingBottom: Scale(20),
    paddingTop: Scale(5),
    justifyContent: "center",
    marginBottom: Scale(10)
  },
  orWrapper: {
    flexDirection: "row",
    marginVertical: Scale(24),
    alignItems: 'center',
    marginHorizontal: Scale(10)
  },
  forgotPwdText: {
    alignSelf: "flex-end",
    marginRight: Scale(10),
    marginTop: Scale(20)
  },
  errorMsg: { color: "#000024" },
  termsCond: { flexDirection: "row", marginLeft: Scale(15) },
  mt5: { marginLeft: Scale(5) },
  mt60: { marginTop: Scale(60) },
  loginTextWrapper: { flex: 1, justifyContent: "center" },
  guestOverlayStyle: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "auto",
    backgroundColor: "transparent",
    borderTopRightRadius: Scale(24),
    elevation: 5
  },
  stickyIcon: {
    position: "absolute",
    top: 5,
    right: 5,
    zIndex: 100
  },
  justifyCenter: { justifyContent: "center" },
  popupMainWrapper: { padding: 24 },
  popupTitleWrapper: { marginTop: 24, marginBottom: 10 },
  popupSubTitleWrapper: { marginBottom: 40 },
  scrollContainer: { flexGrow: 1 }
});
// Customizable Area End
