import React from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  FlatList,
} from "react-native";
import { CText } from "../CText/CText";
import Scale from "../../Scale";

interface MyProps {
  listData: any;
  onPress: any;
}


const CSearchList = (props: MyProps) => {
  const renderItem = (data: any) => {
    const { item, index } = data
    return (
      <>
        <TouchableOpacity style={styles.touchableListItem} onPress={()=> props.onPress(item.id)}>
          <CText children={item?.title} style="white" size="sm16" weight="light" lineHeight={30}
          />
        </TouchableOpacity>
      </>
    )
  }

  return (
    <View style={styles.flex}>
      <FlatList
        testID='searchList'
        data={props?.listData}
        renderItem={({ item, index }) => renderItem({ item, index })}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  touchableListItem: {
    paddingHorizontal: Scale(24),
    paddingVertical: Scale(10),
    flex: 1,
    borderBottomColor:"#2C314B",
    borderWidth:Scale(1)
  },
  flex: {
    flex: 1
  }
})
export default CSearchList;