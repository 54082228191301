import React, { Component } from "react";
import { View, StyleSheet, Image } from "react-native";
import Scale from "../../Scale";
const person1 = require("../../assets/defaultProfile.png");

interface AvatarRequiredProps {
    image: string | null;
    isCenter: boolean;
}

export default class CProfileAvatar extends Component<AvatarRequiredProps> {

    getStyles() {
        const { isCenter } = this.props;
        return {
            containerStyles: {
                ...styles.avatarPlaceholderWrapper,
                ...styles[isCenter ? "viewPostion" : "editPostion"]
            },
            imageStyle: {
                ...styles.avatarView,
                ...styles[isCenter ? "viewPostion" : "editPostion"]
            }
        }
    }

    render() {
        const { image } = this.props;
        const { containerStyles, imageStyle } = this.getStyles()
        return (
            <>{
                image ?
                    <Image source={{ uri: image }} style={imageStyle} />
                    :
                    <View style={containerStyles}>
                        <View style={styles.avatarPlaceHolderView}>
                            <Image
                                style={styles.avatarPlaceholderImage}
                                source={person1}
                            />
                        </View>
                    </View>
            }
            </>
        )
    }
}

const styles = StyleSheet.create({
    avatarPlaceholderWrapper: {
        overflow: "hidden",
        height: 128, width: 128, borderRadius: 100, backgroundColor: "#1B1B3A", justifyContent: "center",
        alignItems: "center"
    },
    avatarPlaceHolderView: { height: 83, width: 83, borderRadius: 100, borderWidth: 5, borderColor: "#FF751B", alignItems: "center", justifyContent: "center" },
    avatarPlaceholderImage: { height: 40, width: 40, resizeMode: "contain" },
    editPostion: {
        marginTop: -Scale(100),
        marginLeft: Scale(20),
    },
    viewPostion: {
        marginTop: -Scale(85),
        alignSelf: "center",
    },
    avatarView: {
        width: Scale(128),
        height: Scale(128),
        borderRadius: Scale(100),
        justifyContent: "center",
        backgroundColor: "#1B1B3A",
        overflow: "hidden",
    },

});