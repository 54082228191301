import React, { useState } from 'react';
import { View, StyleSheet, FlatList, Dimensions } from 'react-native';
import { CText } from '../CText/CText';
import moment from 'moment';
import scale from "../../Scale";
import { getFontFamily } from 'framework/src/Utilities';
// import LinearGradient from 'react-native-linear-gradient';
import CPlayerCell from '../CPlayerCell/CPlayerCell';
import CTButton from '../CTButton/CTButton';
import CEventImage from '../CEventImage/CEventImage';
let screenWidth = Dimensions.get("window").width;

const CMatchSchedule =
  ({ matchData, navigation, isFromDashboard, testID, }:
    { matchData: any, navigation: any, isFromDashboard?: any, testID?: string, }) => {
    const [openModal, setOpenModal] = useState(false)
    const [predictBotUrl, setPredictBotUrl] = useState("")
    const renderQuarters = (item: any) => {

      return (
        <View style={[styles.flexDirectionRow, { height: 30, justifyContent: "center" }]}>
          {
            item.map((e: any) => {
              let selectedViewStyle;

              if (e.is_circle) {
                selectedViewStyle = e.won ? styles.scoreViewCircleA : null

              } else if (e.is_square) {
                selectedViewStyle = e.won ? styles.scoreViewInSquareA : styles.scoreViewInSquare
              }
              else {
                selectedViewStyle = styles.scoreView;
              }
              return (
                <>
                  {/* {(e.is_circle || e.is_square) && e.won ?
                    <LinearGradient colors={['#FF002F', '#FF751B']}
                      start={{ x: 0, y: 0.5 }} end={{ x: 1, y: 0.5 }}
                      style={[selectedViewStyle, styles.quarterView]}>
                      <CText textAlign="center" style="white" size="sm" weight={"bold"}>
                        {e.score}
                      </CText>
                    </LinearGradient>
                    : */}
                    <View style={[selectedViewStyle, styles.quarterView]}>
                      <CText textAlign="center" style={e.won ? "primary" : "white"} size="sm" weight={"bold"}>
                        {e.score}
                      </CText>
                    </View>
                    {/* } */}
                </>
              )
            })
          }
        </View>
      )
    }
    const handleCloseModal = () => {
      setOpenModal(false)
    }
    const renderMatchScheduleView = (item: any) => {
      const { attributes } = item;

      return (
        <>
          <View style={styles.dateViewContainer}>
            <CText style="white" size="sm" weight="bold">
              {moment(attributes?.date)
                .format("ddd, DD MMM YYYY")
                .toUpperCase()}
            </CText>
            {(isFromDashboard && attributes.is_live) &&
              <View style={styles.liveView}>
                <View style={styles.liveDot}>
                </View>
                <CText style="primary" size="base" weight="bold" >
                  LIVE
                </CText>
              </View>}
            {(isFromDashboard && attributes.predict_bot?.length != 0) &&
              <View style={styles.liveView}>
                <CTButton
                  onPress={() => { setOpenModal(true); setPredictBotUrl(attributes.predict_bot) }}
                  color="primary" size="sm" weight='bold' >Predictor Bot
                </CTButton>
              </View>}
            {attributes.completed &&
              <View style={{ width: screenWidth * 0.40, alignItems: "flex-end" }}>
                <CText style="white" size="xs" weight="light">
                  {attributes.court != null && attributes.court + ", " + attributes?.time}
                </CText>
              </View>}
          </View>
          {
            attributes.completed != true &&
            <>
              <View style={[styles.courtTimeViewContainer,]}>
                <View style={{ width: screenWidth * 0.60 }}>
                  <CText style="white" size="sm" weight="light">
                    {`${attributes?.court}${attributes.group ? "|" + attributes?.group : ''}`}
                  </CText>
                </View>
                <CText style="white" size="sm" weight="light">
                  {attributes.time}
                </CText>
              </View>
            </>}
          <View style={styles.matchView}>
            <View style={[styles.flexDirectionRow, styles.matchPlayersView]}>
              <CPlayerCell index={null} playerData={attributes.player_a.attributes} navigation={navigation} showPercentage={true} isCompleted={attributes.completed} percentage={attributes.player_a_percentage} />
            </View>
            <View style={[styles.flexDirectionRow, styles.matchPlayersView]}>
              {attributes.completed && renderQuarters(attributes.player_a.attributes.quarters)}
            </View>
          </View>
          <View style={styles.matchView}>
            <View style={[styles.flexDirectionRow, styles.matchPlayersView]}>
              <CPlayerCell index={null} playerData={attributes.player_b.attributes} navigation={navigation} showPercentage={true} isCompleted={attributes.completed} percentage={attributes.player_b_percentage} />
            </View>
            <View style={[styles.flexDirectionRow, styles.matchPlayersView]}>
              {attributes.completed && renderQuarters(attributes.player_b.attributes.quarters)}
            </View>
          </View>
        </>
      );
    };

    return (
      <View style={[styles.fullWidth, styles.marginTop20]}>
        <FlatList
          testID="matchScheduleList"
          data={matchData}
          renderItem={({ item }) => renderMatchScheduleView(item)}
        />
        <CEventImage modalState={openModal} url={predictBotUrl} onClick={handleCloseModal} />
      </View>
    );
  };

const styles = StyleSheet.create({
  fullWidth: {
    width: "100%",
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  playerCellContainer: {
    alignItems: "center",
    padding: 20,
  },
  marginTop20: {
    marginTop: 20,
  },
  dateViewContainer: {
    height: scale(40),
    paddingTop: scale(0),
    paddingHorizontal: scale(24),
    backgroundColor: "#2C314B",
    flexDirection: 'row',
    justifyContent: "space-between",
    alignItems: "center"
  },
  courtTimeViewContainer: {
    paddingTop: scale(8),
    paddingHorizontal: scale(24),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  matchPlayersView: {
    alignItems: "center",
    paddingHorizontal: scale(15),
    paddingBottom: scale(10),
    paddingTop: scale(10),
  },
  scoreView: { width: 22 },
  scoreViewCircleA: {
    backgroundColor: "#FF002F",
    borderRadius: 25,
    margin: 4,
    width: 21, height: 21
  },
  scoreViewInSquareA: {
    backgroundColor: "#FF002F",
    borderRadius: 3,
  },
  scoreViewInSquare: {
    backgroundColor: "#475569",
    borderRadius: 3,
  },
  ScoreViewTextA: { color: "#FF002F", },
  ScoreViewTextI: { color: "#fff" },
  matchScoreText: {
    color: "#fff",
    fontFamily: getFontFamily("bold"),
    fontSize: scale(14),
    marginHorizontal: 3

  },
  quarterView: { justifyContent: "center", alignItems: "center", marginLeft: 5, paddingHorizontal: 2 },
  liveView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  liveDot: { backgroundColor: "#FF6A1D", borderRadius: 10, height: 12, width: 12, marginRight: scale(10) },
  matchView: { flex: 1, flexDirection: "row", justifyContent: "space-between", },
  dotView: { width: 4, height: 4, borderRadius: 25, alignSelf: "center", backgroundColor: "white", marginHorizontal: 8 }
});

export default CMatchSchedule;


