import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import Orientation from 'react-native-orientation';
import SplashScreen from "react-native-splash-screen"
import { Platform } from "react-native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SplashscreenController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  loggedInUserDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);

    this.state = {
      // Customizable Area Start
      token: ""
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (
        apiRequestCallId &&
        responseJson &&
        !responseJson.errors &&
        !errorReponse
      ) {
        this.loggedInUserDataSuccessApiBlock(responseJson);
      } else if (responseJson.errors || errorReponse || errorReponse.errors) {
        if(Platform.OS === "web") {
          this.props.navigation.navigate("EmailAccountLoginBlock")
        } else {
          this.props.navigation.replace("EmailAccountLoginBlock");
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    if(Platform.OS !== "web") {
      Orientation.lockToPortrait(); // lock in portrait mode
    }
    let token = await getStorageData("TOKEN");
    this.setState({ token: token }, () => {
      setTimeout(() => {
        this.loggedInUserDataAPi();

      }, configJSON.timeout);
    });

    // Customizable Area End
  }

  // Customizable Area Start
  async componentWillUnmount(): Promise<void> {
    SplashScreen.hide();
  }

  loggedInUserDataSuccessApiBlock = async (responseJson: any) => {
    if (responseJson.data) {
      if (this.state.token !== null && this.state.token !== undefined) {
        if (
          responseJson.data.attributes &&
          (responseJson.data.attributes.is_profile_completed ||
            responseJson.data.attributes.user_type === "guest" ||
            responseJson.data.attributes.user_type === "player")
        ) {
          this.props.navigation.replace("BottomTab");
        } else {
          this.props.navigation.replace("PhoneNumberInput");
        }
      }
    }
  };

  loggedInUserDataAPi = async () => {
    const header = {
      "Content-Type": configJSON.contendType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.loggedInUserDataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loggedInUserDataURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
