import React from "react";
// Customizable Area Start
import { View, StyleSheet, TouchableOpacity } from "react-native";
import { backArrow, closeIcon } from "../../../components/src/assets";
import {
  CButton,
  CInput,
  CText,
  CTButton,
  CToast,
  CAppBackground,
  CStatusBar,
  CHeader,
  CCheckBox
} from "../../../components/src/custom-components";
import Scale from "../../../components/src/Scale";
import { configJSON } from "./GuestLoginController";

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import GuestLoginController, { Props } from "./GuestLoginController";

export default class GuestLogin extends GuestLoginController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <View style={styles.SafeAreaView}>
        <View style={styles.container}>
          <CAppBackground>
            <CStatusBar
              backgroundColor="rgba(0, 0, 36, 0)"
              barStyle="light-content"
            />
            <CHeader
              testID="headerView"
              icons={[
                {
                  testID: "btnHeader",
                  type: "icon",
                  icon: "back",
                  position: "left",
                  onPress: () => this.props.navigation.goBack()
                },
                {
                  type: "text",
                  title: `GUEST LOGIN`,
                  position: "left",
                  titleSize: "xl4",
                  titleWeight: "bold",
                  bottom: 10
                }
              ]}
            />
            <View style={styles.subContainer}>
              <View style={styles.p10}>
                <CText style="white" size="base" weight="light">{configJSON.guestLoginText}</CText>
              </View>
              <CInput
                testID={"txtInputEmail"}
                label={"Email"}
                placeholder={"example@example.com"}
                isValid={this.state.isErrorMsg}
                keyboardType="email-address"
                onChangeText={(text: string) => this.onEmailChange(text)}
                value={this.state.email}
                isValidEmail={this.state.isValid}
                type={"email"}
              />
            </View>
            <View style={styles.termsCond}>
              <CCheckBox
                testID="btnTermCond"
                isCheckedBox={this.state.isCheckedBox}
                onChange={() => this.toggleCheckBox()}
              />
              <View style={styles.termCondView}>
                <CText style="white" size="base" weight="light">
                  {"I agree to the "}
                  <CText style="primary" size="base" weight='bold' onPress={() => {
                    this.props.navigation.navigate("TermsConditions");
                  }}>
                    Terms & Conditions
                  </CText>
                  {" and "}
                  <CText style="primary" size="base" weight='bold' onPress={() => {
                    this.props.navigation.navigate("TermsConditions");
                  }} >
                    Privacy Policy
                  </CText>
                </CText>
              </View>
            </View>

            <CToast
              testID="toast"
              msg={this.state.showErrorMsg}
              type="fail"
              position="center"
              callback={() => { }}
              children={
                <View style={{ flexDirection: "row" }}>
                  <CText style="blue" size="base" weight='light' >{this.state.showErrorMsg}</CText>
                  <TouchableOpacity onPress={() => { this.props.navigation.navigate("EmailAccountLoginBlock") }}>
                    <CText style="primary" size="base" weight='bold' >{"Login"}</CText>
                  </TouchableOpacity>
                </View>
              }
              stay={true}
            />

            <View style={styles.loginContainer}>
              <CButton
                testID={"btnEmailLogIn"}
                type="button"
                disabled={
                  this.state.email === "" ||
                    !this.state.isValid ||
                    !this.state.isCheckedBox
                    ? true
                    : false
                }
                onPress={() => this.emailLogIn()}
                style={
                  this.state.email === "" || !this.state.isValid
                    ? "secondary"
                    : "primary"
                }
                size="bigger"
              >
                Continue as a guest
              </CButton>
            </View>
          </CAppBackground>
        </View>
      </View>
    );

    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  SafeAreaView: {
    flex: 1
  },
  container: {
    backgroundColor: "rgba(0, 0, 36, 1)",
    flex: 1
  },
  subContainer: { margin: 10 },
  passwordCheck: {
    marginVertical: 10,
    justifyContent: "space-between"
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0)"
  },
  headerView: {
    backgroundColor: "rgba(0, 0, 0, 0)"
  },
  row: {
    flexDirection: "row",
    margin: 10
  },
  guestText: {
    position: "absolute",
    right: 0,
    top: 10
  },
  loginContainer: {
    padding: 10,
    position: "absolute",
    bottom: 0,
    justifyContent: "center",
    width: "100%",
    marginBottom: 50
  },
  loginText: { alignSelf: "center", flexDirection: "row", marginTop: 15 },
  forgotPwdText: { alignSelf: "flex-end", marginRight: 20 },

  errorMsg: { color: "#000024" },
  termsCond: { flexDirection: "row", marginLeft: 15, alignItems: "flex-start" },
  loginTextWrapper: { flex: 1, justifyContent: "center" },
  guestOverlayStyle: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    borderTopRightRadius: Scale(24),
    elevation: 5
  },
  stickyIcon: {
    position: "absolute",
    top: 5,
    right: 5
  },
  p10: { padding: Scale(10) },
  termCondView: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: Scale(8)
  },
  termTxt: { marginLeft: Scale(5) }
});
// Customizable Area End
