import React from "react";
// Customizable Area Start
import { StyleSheet, View, ScrollView, FlatList, TouchableOpacity, TextInput } from "react-native";
import Scale from "../../Scale";
import { CModal, CModalContainer, CText } from "..";
// Customizable Area End

import CReportPopupController, { ReasonItem, S } from "./CReportPopupController";

export default class CReportPopup extends CReportPopupController {

  renderReasonsForReportView = ({ item }: { item: ReasonItem }) => {
    let selected = this.state.selectedReasons.includes(item.id)
    return <TouchableOpacity onPress={() => { this.handleReasonSelection(item.id) }}>
      <View style={selected ? [styles.reasonView, { borderWidth: 1, borderColor: 'rgba(255, 0, 47, 1)' }] : styles.reasonView}>
        <CText style="white" size="sm" weight="light">{item.name}</CText>
      </View>
    </TouchableOpacity>
  }

  render() {
    // Customizable Area Start
    const { type, setVisible } = this.props
    return (
      <CModal
        testID="modalPost"
        title={''}
        data={[{}]}
        onTouchOutside={setVisible}
        customListItem={false}
        mainView={
          <CModalContainer
            testID='modalCont'
            headerText={`Report this ${type}`}
            cancelText={'Clear'}
            submitText={'Submit'}
            closeBtnOnPress={() => { this.handleCloseBtnPress() }}
            clearBtnOnPress={() => { this.handleClearBtnPress() }}
            submitBtnOnPress={() => { this.reportPostComment() }}
            submitBtnDisable={this.handleValidation()}
            submitBtnStyle={this.handleValidation() ? "secondary" : 'primary'}
          >
            <ScrollView>
              <View style={{ paddingHorizontal: Scale(20), marginTop: Scale(25) }}>
                <CText style="white" size="base" weight="bold">Select a reason that applies</CText>
                <FlatList
                  testID="reasonReport"
                  data={this.state.reasonList}
                  renderItem={this.renderReasonsForReportView}
                  contentContainerStyle={{ flexWrap: "wrap", flexDirection: 'row' }}
                />
                <View style={{ marginTop: Scale(25) }}>
                  <CText style="white" size="base" weight="bold">Tell us what you don't like</CText>
                  <TextInput
                    testID="review"
                    maxLength={100}
                    multiline
                    onChangeText={text => {
                      this.setState({ reportText: text })
                    }}
                    style={styles.reportTextInput}
                    value={this.state.reportText}
                  />
                  <View style={styles.lengthCheckView}>
                    <CText style="gray" size="xs" weight="light">{`${this.state.reportText.length}/100`}</CText>
                  </View>
                </View>
              </View>
            </ScrollView>
          </CModalContainer>
        }
        showBottomSheet={false}
        showDone
        visible={this.props.visible}
      />
    );
    // Customizable Area End
  }
}
const styles = StyleSheet.create({
  // Customizable Area Start
  reasonView: {
    paddingVertical: Scale(12),
    paddingHorizontal: Scale(18),
    borderRadius: 20,
    backgroundColor: '#1B1B3A',
    marginRight: Scale(10),
    marginTop: Scale(13),
    alignItems: 'center',
    justifyContent: 'center'
  },
  reportTextInput: {
    height: Scale(120),
    marginTop: Scale(12),
    borderRadius: Scale(10),
    borderWidth: 1,
    borderColor: '#2C314B',
    maxHeight: Scale(150),
    color: 'white',
    textAlignVertical: 'top',
    paddingTop: Scale(15),
    paddingLeft: Scale(15),
  },
  lengthCheckView: {
    alignSelf: 'flex-end',
    marginTop: Scale(7)
  },
  // Customizable Area End
});
