Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.GET = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.POST = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "communityforum4";
exports.labelBodyText = "communityforum4 Body";
exports.btnExampleTitle = "CLICK ME";
exports.DELETE = "DELETE";
exports.getCommentsByThreadId = "/bx_block_threads/threads/";
exports.threadCommentLikeDislikeAPIEndPoint = "/bx_block_threads/thread_comment_likes";
exports.threadCommentLikeDislikeAPIContentType = "application/json";
exports.commentOnThreadEndPoint = "bx_block_threads/thread_comments";
exports.getReportReasonList="bx_block_content_management/reasons_list";
exports.reportableType="BxBlockThreads::ThreadComment"
exports.createCommentReportAPiEndPoint="bx_block_content_management/create_report"
exports.reportTitle= 'Report this comment';
exports.clear = 'Clear';
exports.submit= 'Submit';
exports.reasonTitle="Select a reason that applies";
exports.reviewTitle= "Tell us what you don't like";
exports.report = "Report"
exports.noCommentsText = "No comments available";
// Customizable Area End