export const ic_like = require('../assets/3228683491mdJKZyqmXS95CKAcokYmH.png')
export const ic_comment = require('../assets/3228683711mdJKZyqmXS95CKAcokYmH.png')
export const ic_share = require('../assets/658505501mdJKZyqmXS95CKAcokYmH.png')
export const ic_tick = require('../assets/group_verified.png')
export const ic_menu = require('../assets/658505621mdJKZyqmXS95CKAcokYmH.png')
export const ic_profile = require('../assets/3228683951mdJKZyqmXS95CKAcokYmH.png')
export const ic_pollprofile = require('../assets/group_avatar.png')
export const post = require('../assets/3228683971mdJKZyqmXS95CKAcokYmH.png')
export const ic_search = require('../assets/I658513373063320819446031mdJKZyqmXS95CKAcokYmH.png')
export const ic_bell = require('../assets/ic_bell.png')
export const ic_flag = require('../assets/flag.png')
