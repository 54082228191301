import moment from "moment";

export interface TimeDifference {
  dd: number;
  hh: number;
  mm: number;
}

export function calculateCountdown(time: string): TimeDifference {
  const currentTime = new Date().getTime();
  const endTime = new Date(time).getTime();

  if (currentTime >= endTime) {
    return { dd: 0, hh: 0, mm: 0 };
  } else {
    const timeDifference = endTime - currentTime;
    let dd = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    let hh = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let mm = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    return { dd, hh, mm };
  }
}

export function returnTime(timer: TimeDifference) {
  const { dd, hh, mm } = timer;
  let hours = hh < 10 ? `0${hh}` : hh
  let min = mm < 10 ? `0${mm}` : mm
  let day = dd === 1 ? "day" : "days"
  return !dd ? `${hours}:${min}` : `${dd} ${day}`
}

export function isPollActive(endDate: any): boolean {
  const endDateTime = moment(endDate);
  const now = moment();
  return endDateTime.isAfter(now);
}

export function getRelativePollTime(time: any): string {
  const utcDate = new Date(time);

  // Get the current time in milliseconds
  const currentTime = new Date().getTime();

  // Get the UTC time in milliseconds
  const utcTimeMs = utcDate.getTime();

  // Calculate the time difference in milliseconds
  const timeDifference = currentTime - utcTimeMs;

  // Convert the time difference to human-readable format
  const secondsPassed = Math.floor(timeDifference / 1000);
  const minutesPassed = Math.floor(secondsPassed / 60);
  const hoursPassed = Math.floor(minutesPassed / 60);
  const daysPassed = Math.floor(hoursPassed / 24);

  const timePassedString =
    daysPassed > 0
      ? `${daysPassed}d`
      : hoursPassed > 0
        ? `${hoursPassed}h`
        : minutesPassed > 0
          ? `${minutesPassed}m`
          : `now`;
  return timePassedString
}

export function getCommentCreatedTime(time: any): string {
  const currentTime = new Date().getTime();
  const startTime = new Date(time).getTime();

  const timeDifference = currentTime - startTime;
  const secondsPassed = Math.floor(timeDifference / 1000);
  const minutesPassed = Math.floor(secondsPassed / 60);
  const hoursPassed = Math.floor(minutesPassed / 60);
  const daysPassed = Math.floor(hoursPassed / 24);

  const timePassedString =
    daysPassed > 0
      ? `${daysPassed}d`
      : hoursPassed > 0
        ? `${hoursPassed}h`
        : minutesPassed > 0
          ? `${minutesPassed}m`
          : `now`;

  return timePassedString
}

export const checkIfTimePassed = (time: string) => {
  // Parse the given UTC time string to create a Date object
  const utcDate = new Date(time);

  // Get the current time in milliseconds
  const currentTime = new Date().getTime();

  // Get the UTC time in milliseconds
  const utcTimeMs = utcDate.getTime();

  // Check if the UTC time has passed
  return currentTime > utcTimeMs
};


export const formatedDate = (date: string) => {
  let inputDate = new Date(date);

  // Define month names
  let monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Get day, month, and year from the input Date object
  let day = inputDate.getDate();
  let monthIndex = inputDate.getMonth();
  let year = inputDate.getFullYear();

  // Format the date in the desired format
  let formattedDate = day + " " + monthNames[monthIndex] + ", " + year;

  return formattedDate;

}