import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { trimWhitespaceAndNewlines } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  commentData: any;
  token: string;
  loading: boolean;
  comment: string;
  commentId: any;
  reportPopupVisible: boolean;
  reportVisible: boolean;
  subCommentId: string;
  accountId: string;
  commentReportedToastMessage: string;
  reportToolTipVisible: any;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ReplyCommentController extends BlockComponent<Props, S, SS> {
  getSubCommentAPICallId: string = "";
  handleLikeAPICallId: string = "";
  createSubCommentAPICallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      commentData: [],
      token: "",
      loading: false,
      comment: "",
      commentId: "",
      reportPopupVisible: false,
      reportVisible: false,
      subCommentId: "",
      accountId: "",
      commentReportedToastMessage: "",
      reportToolTipVisible: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let commentId = this?.props?.navigation?.state?.params.commentId;
    const accountId = await getStorageData("ACCOUNT_ID")
    if(this.isPlatformWeb()){
      commentId = "95"
    }
    this.setState({ commentId: commentId, accountId })
    this.getSubCommentData();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSubCommentAPICallId && responseJson && !responseJson.errors) {
        this.setState({ commentData: responseJson?.data, loading: true });
      }

      if (apiRequestCallId === this.createSubCommentAPICallId && responseJson && !responseJson.errors) {
        this.getSubCommentData();
      }

      if (apiRequestCallId === this.handleLikeAPICallId && responseJson && !responseJson.errors) {
        this.getSubCommentData();
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  likeOnComment = async (item: any, status: boolean, isParent: boolean) => {
    let token = ""
    if(this.isPlatformWeb()){
       token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    }else{
      token = await getStorageData('TOKEN');
    }
    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const likeCommentApiRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const formData = new FormData();
    formData.append("id", item.id);
    formData.append("type", "comment");

    this.handleLikeAPICallId = likeCommentApiRequestMessage.messageId;

    likeCommentApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    likeCommentApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    likeCommentApiRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      status ? configJSON.postLikeEndPoint : configJSON.postDisLikeEndPoint
    );

    likeCommentApiRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      status ? configJSON.commentPOSTAPiMethod : configJSON.commentDELETEAPiMethod
    );

    runEngine.sendMessage(likeCommentApiRequestMessage.id, likeCommentApiRequestMessage);
    return true;
  };

  getSubCommentData = async () => {
    let token = ""
    if(this.isPlatformWeb()){
       token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    }else{
      token = await getStorageData('TOKEN');
    }
    const header = {
      "Content-Type": configJSON.commentsContentType,
      token: token,
    };
    const getSubComemntAPIMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSubCommentAPICallId = getSubComemntAPIMessage.messageId;
    getSubComemntAPIMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCommentEndPoint + "/" + this.state.commentId
    );

    getSubComemntAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getSubComemntAPIMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentsApiMethodType
    );

    runEngine.sendMessage(getSubComemntAPIMessage.id, getSubComemntAPIMessage);

    return true;
  }

  createSubComment = async () => {
    const comment = trimWhitespaceAndNewlines(this.state.comment)

    if (comment.length === 0) {
      return false;
    }

    let token = ""
    if(this.isPlatformWeb()){
       token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODU0LCJleHAiOjE2OTQ2OTgzMDB9.6n61yMD7nYYGs68gYHd4BrPF3WbbB_rRJSXEyZSgmZDbP8imrcyRPdHT-YPsWyWBLjHQfF3HUiNkX7ZdGZjU0g"
    }else{
      token = await getStorageData('TOKEN');
    }

    const header = {
      "Content-Type": "multipart/form-data",
      token: token
    };

    const formData = new FormData
    formData.append("id", this.state.commentId);
    formData.append("comment", comment);
    formData.append("type", "comment");

    const createSubCommentAPIRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createSubCommentAPICallId = createSubCommentAPIRequestMessage.messageId;
    createSubCommentAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCommentEndPoint
    );

    createSubCommentAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    createSubCommentAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    createSubCommentAPIRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.commentPOSTAPiMethod
    );
    runEngine.sendMessage(createSubCommentAPIRequestMessage.id, createSubCommentAPIRequestMessage);

    this.setState({ comment: "" });
  };

  onComment = (values: any) => {
    this.setState({ comment: trimWhitespaceAndNewlines(values) })
  }
  isCommentLengthValid() {
    return this.state.comment.trimEnd().length < 0;
  }

  handleReport = (userId: number) => {
    if (userId === Number(this.state.accountId)) {
      return false
    } else {
      return true
    }
  }

  // Customizable Area End
}
