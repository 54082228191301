import React, { Component } from "react";
// Customizable Area Start
import {
    View,
    ScrollView,
} from "react-native";
import styles from "./AllEventStyle";
import {
    CAppBackground,
    CStatusBar,
    CEventDetail,
    CHeader,
    CToggleComponent,
    CPlayerRanking,
    CPlayerList,
    CMatchSchedule,
    CModal,
    CEventImage
} from "../../../components/src/custom-components";
import Loader from "../../../components/src/Loader";
import { configJSON } from "./AddEventDetailController";
// Customizable Area End

import AllEventController, { Props } from "./AllEventController";

export default class AllEventDetailScreen extends AllEventController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    handleToggleChange = (selectedIndex: number) => {
        this.setState({ activeToggleButton: selectedIndex });
    }
    handleModal = () => {
        this.setState({ openModal: !this.state.openModal })
    }
    renderViewBasedOnSelectedToggleButton = () => {
        const { activeToggleButton, eventMatchData, eventPlayerRanking, eventPlayersData } = this.state;
        const { navigation } = this.props
        if (activeToggleButton === 2) {
            return <CMatchSchedule navigation={navigation} matchData={eventMatchData} isFromDashboard={false} />
        } else if (activeToggleButton === 3) {
            return <CPlayerRanking navigation={navigation} rankingData={eventPlayerRanking} />
        } else {
            return <CPlayerList navigation={navigation} playerData={eventPlayersData} />
        }
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { eventDetailsData, toggleOptions, toggleOptionsForPastEvents, isLoading, openModal } = this.state;

        return (
            <View style={styles.eventDetailContainer} >
                <CStatusBar
                    backgroundColor="rgba(0, 0, 36, 1)"
                    barStyle="light-content"
                />

                <View style={styles.mainContainerStyle}>
                    <CAppBackground>
                        <View style={styles.gray900Background}>
                            <CHeader
                                testID='headerView'
                                icons={[
                                    { testID: 'btnBack', type: 'icon', icon: 'back', position: 'left', iconColor: '#FF751B', onPress: () => this.props.navigation.goBack() },
                                    { type: 'text', title: `${configJSON.allEventDetailScreenHeaderTitle}`, position: 'center', titleStyle: 'primary', titleSize: 'sm', titleWeight: 'bold' },
                                    { type: 'icon', icon: 'eventHeaderImg', position: 'right', onPress: () => { this.handleModal() } },
                                ]} />
                        </View>
                        {this.state.isLoading ?
                            <Loader loading={isLoading} />
                            :
                            <ScrollView showsVerticalScrollIndicator={false}>
                                <CEventDetail eventDetailData={eventDetailsData} isFromDashboard={false} />
                                <View style={styles.toggleContainer}>
                                    <CToggleComponent
                                        array={eventDetailsData?.status == "past" ? toggleOptionsForPastEvents : toggleOptions}
                                        onSelect={this.handleToggleChange}
                                        customVerticalPadding
                                        customFont="base"
                                    />
                                    {this.renderViewBasedOnSelectedToggleButton()}
                                    <CEventImage modalState={openModal} url={eventDetailsData?.event_image} onClick={this.handleModal} />
                                </View>
                            </ScrollView>
                        }
                    </CAppBackground>
                </View>
            </View>
        );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
