import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
  Image
} from "react-native";
import Scale from "../../../components/src/Scale";
import { getStorageData } from "../../../framework/src/Utilities";
// @ts-ignore
// import { NavigationEvents } from "react-navigation";
// import LinearGradient from "react-native-linear-gradient";
import {
  CIcon,
  CStatusBar,
  CText
} from "../../../components/src/custom-components";
import { profileIconPlaceholder } from "../../events/src/assets";

import { formatedDate } from "../../utilities/src/TimeUtilities";
// Customizable Area End

import ViewPlayerProfileController from "./ViewPlayerProfileController";

export default class ViewPlayerProfile extends ViewPlayerProfileController {
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <View style={styles.mainWrapper}>
        {/* <NavigationEvents onWillFocus={() => this.handleAPICall()} /> */}
        <CStatusBar backgroundColor="#1B1B3A" barStyle="light-content" />

        <View style={styles.headerWrapper}>
          <View style={styles.headerSubWrapper}>
            <TouchableOpacity
              style={styles.backIconWrapper}
              testID="backButton"
              onPress={() => this.props.navigation.goBack()}
            >
              <CIcon
                name={"arrow-back"}
                size={Scale(24)}
                type={"MaterialIcons"}
                color={"#FF751B"}
              />
            </TouchableOpacity>
            <CText style="primary" size="sm" weight="bold">
              {"PLAYER INFO"}
            </CText>
          </View>
          {this.props.navigation?.state?.params.comesFrom === "profile" &&
            <TouchableOpacity
              testID="editBtn"
              onPress={() =>
                this.props.navigation.navigate("UserProfileBasicBlock", {
                  screenType: "player"
                })
              }
              style={styles.headerEditBtn}
            >
              <CIcon
                name={"square-edit-outline"}
                size={Scale(18)}
                type={"MaterialCommunityIcons"}
                color={"#000000"}
              />
            </TouchableOpacity>
          }
        </View>
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={styles.scrollWrapper}
        >
          <View>
            <ImageBackground

              source={this.state.userDetails.player_photo ? { uri: this.state.userDetails.player_photo } : profileIconPlaceholder}
              style={styles.backgroundImage}
            >
              <View style={styles.gradientContainer}>
                {/* <LinearGradient
                  colors={["rgba(0, 0, 36, 0)", "rgba(0,0,36,1)"]} // Adjust gradient colors and opacity
                  style={styles.gradient}
                >
                  <View style={styles.gradientUserNameWrapper}>
                    <CText
                      style="primary"
                      size="xl3"
                      weight="bold"
                      children={this.state.userDetails.user_name}
                      lineHeight={42}
                    />
                  </View>
                  <View style={styles.playerNameWrapper}>
                    <CText
                      style="white"
                      size="base"
                      weight="bold"
                      children={
                        this.state.userDetails.first_name +
                        " " +
                        this.state.userDetails.last_name
                      }
                      lineHeight={23}
                    />
                  </View>

                  <View style={styles.gradientSubWrapper}>
                    <View style={styles.gradientDetailWrapper}>
                      <CText
                        style="primary"
                        size="xl3"
                        weight="bold"
                        children={this.state.userDetails.age}
                        lineHeight={41}
                      />
                      <CText
                        style="white"
                        size="xxs"
                        weight="light"
                        children={`Age`}
                        lineHeight={22}
                      />
                    </View>

                    <View style={styles.gradientDetailWrapper}>
                      <CText
                        style="primary"
                        size="xl3"
                        weight="bold"
                        children={this.state.userDetails.uts_rank}
                        lineHeight={41}
                      />
                      <CText
                        style="white"
                        size="xxs"
                        weight="light"
                        children={`UTS Rank`}
                        lineHeight={22}
                      />
                    </View>

                    {this.state.userDetails.nationality_image && (
                      <View style={styles.gradientDetailWrapper}>
                        <Image
                          style={styles.flag}
                          source={{
                            uri: this.state.userDetails.nationality_image
                          }}
                        />
                        <CText
                          style="white"
                          size="xxs"
                          weight="light"
                          children={`Nationality`}
                          lineHeight={22}
                        />
                      </View>
                    )}
                  </View>
                </LinearGradient> */}
              </View>
            </ImageBackground>
            <View style={styles.descWrapper}>
              <CText
                style="lightGray"
                size="sm"
                weight="bold"
                children={`PLAYER DESCRIPTION`}
                lineHeight={18}
              />
            </View>

            <View style={styles.descSubWrapper}>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`Date of Birth`}
                  lineHeight={22}
                />
                <CText
                  style="white"
                  size="sm"
                  weight="bold"
                  children={formatedDate(this.state.userDetails.date_of_birth)}
                  lineHeight={22}
                />
              </View>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`Place of birth`}
                  lineHeight={22}
                />
                <CText
                  style="white"
                  size="sm"
                  weight="bold"
                  children={this.state.userDetails.place_of_birth}
                  lineHeight={22}
                />
              </View>
            </View>

            <View style={styles.descSubWrapper}>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`Country`}
                  lineHeight={22}
                />
                <View style={styles.flexRow}>
                  <CText
                    style="white"
                    size="sm"
                    weight="bold"
                    children={this.state.userDetails.country}
                    lineHeight={22}
                  />
                </View>
              </View>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`Nationality`}
                  lineHeight={22}
                />
                <View style={styles.flexRow}>
                  <CText
                    style="white"
                    size="sm"
                    weight="bold"
                    children={this.state.userDetails.nationality_name}
                    lineHeight={22}
                  />
                </View>
              </View>
            </View>

            <View style={styles.descSubWrapper}>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`Plays`}
                  lineHeight={22}
                />
                <CText
                  style="white"
                  size="sm"
                  weight="bold"
                  children={this.state.userDetails.plays}
                  lineHeight={22}
                />
              </View>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`Height (ft,in)`}
                  lineHeight={22}
                />
                <CText
                  style="white"
                  size="sm"
                  weight="bold"
                  children={this.state.userDetails.height}
                  lineHeight={22}
                />
              </View>
            </View>

            <View style={styles.descSubWrapper}>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`ATP Tour Title`}
                  lineHeight={22}
                />
                <CText
                  style="white"
                  size="sm"
                  weight="bold"
                  children={this.state.userDetails.atp_tour_title}
                  lineHeight={22}
                />
              </View>
              <View style={styles.flex1}>
                <CText
                  style="dullWhite"
                  size="xxs"
                  weight="bold"
                  children={`Career high ranking`}
                  lineHeight={22}
                />
                <CText
                  style="white"
                  size="sm"
                  weight="bold"
                  children={this.state.userDetails.career_high_ranking}
                  lineHeight={22}
                />
              </View>
            </View>

            <View
              style={{ paddingHorizontal: Scale(24), marginBottom: Scale(24) }}
            >
              <CText
                style="dullWhite"
                size="xxs"
                weight="bold"
                children={`UTS appearances`}
                lineHeight={22}
              />
              <CText
                style="white"
                size="sm"
                weight="bold"
                children={this.state.userDetails.uts_appearances}
                lineHeight={22}
              />
            </View>

            <View
              style={{ paddingHorizontal: Scale(24), marginBottom: Scale(24) }}
            >
              <CText
                style="dullWhite"
                size="xxs"
                weight="bold"
                children={`Stye and Personality`}
                lineHeight={22}
              />
              <CText
                style="white"
                size="sm"
                weight="bold"
                children={this.state.userDetails.style_and_personality}
                lineHeight={22}
              />
            </View>

            <View
              style={{ paddingHorizontal: Scale(24), marginBottom: Scale(24) }}
            >
              <CText
                style="dullWhite"
                size="xxs"
                weight="bold"
                children={`Career`}
                lineHeight={22}
              />
              <CText
                style="white"
                size="sm"
                weight="bold"
                children={this.state.userDetails.career}
                lineHeight={22}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleAPICall();
    // Customizable Area End
  }

  // Customizable Area Start
  handleAPICall = async () => {
    const token = await getStorageData("TOKEN");
    const userType = await getStorageData("USERTYPE");
    this.setState({ token: token, userType: userType }, () => {
      this.getPlayerProfile();
    });
  };
  // Customizable Area End
}
// Customizable Area Start
const styles = StyleSheet.create({
  mainWrapper: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 36, 0)"
  },
  scrollWrapper: {
    flex: 1,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    backgroundColor: "rgba(0, 0, 36, 1)",
    marginTop: -20
  },
  headerWrapper: {
    flexDirection: "row",
    padding: Scale(20),
    backgroundColor: "#1B1B3A",
    alignItems: "center",
    borderBottomRightRadius: 24,
    zIndex: 100
  },
  backIconWrapper: {
    marginRight: Scale(24)
  },
  playerImage: {
    height: "auto",
    width: "100%",
    resizeMode: "contain"
  },

  backgroundImage: {
    flex: 0.75, // Adjust the portion of the screen the image covers
    resizeMode: "cover" // Cover the specified portion of the screen
  },
  gradientContainer: {
    marginTop: Scale(400),
    flex: 0.25, // Adjust the portion of the screen the gradient covers
    justifyContent: "center",
    alignItems: "center"
  },
  gradient: {
    flex: 1, // Take the full height of the gradient container
    width: "100%"
  },
  text: {
    fontSize: 20,
    color: "white",
    textAlign: "center"
  },
  flag: {
    width: Scale(38),
    height: Scale(24),
    resizeMode: "contain",
    marginBottom: 12
  },
  headerSubWrapper: { flex: 1, flexDirection: "row", alignItems: "center" },
  headerEditBtn: {
    alignSelf: "flex-end",
    backgroundColor: "#ffffff",
    padding: 5,
    borderRadius: 32,
    alignItems: "center",
    justifyContent: "center"
  },
  gradientUserNameWrapper: {
    marginTop: Scale(75),
    marginBottom: Scale(10),
    alignItems: "center"
  },
  playerNameWrapper: { marginBottom: Scale(56), alignItems: "center" },
  gradientSubWrapper: {
    marginBottom: Scale(40),
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: Scale(24)
  },
  gradientDetailWrapper: {
    alignItems: "center",
    backgroundColor: "#2C314B",
    borderRadius: 16,
    paddingHorizontal: Scale(29),
    paddingVertical: Scale(17),
    justifyContent: "center"
  },
  flex1: { flex: 1 },
  flexRow: { flexDirection: "row" },
  descWrapper: {
    paddingHorizontal: Scale(24),
    paddingVertical: Scale(8),
    backgroundColor: "#2C314B",
    marginBottom: Scale(24)
  },
  descSubWrapper: {
    flexDirection: "row",
    paddingHorizontal: Scale(24),
    marginBottom: Scale(24)
  }
});
// Customizable Area End
