import PropTypes from "prop-types";
import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { BlockComponent } from "framework/src/BlockComponent";
import { TimeDifference, calculateCountdown, returnTime } from "../../../../blocks/utilities/src/TimeUtilities";
import Scale from "../../Scale";
import { CText } from "../CText/CText";
import CIcon from "../CIcon/CIcon";
import { timer } from "../../assets";

interface Props {
  time: string;
  isClosed?: boolean;
}

interface S {
  timer: TimeDifference;
}

interface SS { }

export default class CCountDownTimer extends BlockComponent<Props, S, SS> {
  static propTypes = {
    time: PropTypes.string.isRequired,
  };

  timerInterval: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      timer: { dd: 0, hh: 0, mm: 0 },
    };
  }

  async componentDidMount() {
    this.updateTimer();
    this.timerInterval = setTimeout(this.updateTimer, 1000);
  }

  async componentWillUnmount() {
    if (this.timerInterval) {
      clearTimeout(this.timerInterval);
    }
  }

  updateTimer = () => {
    const { time } = this.props;
    const countdown = calculateCountdown(time);
    this.setState({ timer: countdown });
    if (countdown.dd === 0 && countdown.hh === 0 && countdown.mm === 0) {
      clearTimeout(this.timerInterval!);
    } else {
      this.timerInterval = setTimeout(this.updateTimer, 1000);
    }
  };

  render() {
    return (
      <View style={styles.pollTimeContainer}>
        <View style={{ marginRight: Scale(7) }}>
          {/* <CIcon color="#009521" name="timer-outline" size={30} type="MaterialCommunityIcons" /> */}
          <Image source={timer} style={{ height: Scale(16), width: Scale(16), resizeMode: "contain", tintColor: this.props?.isClosed ? "#FF002F" : "#009521" }} />
        </View>
        <CText style="white" size="sm" weight='bold'>{this.props?.isClosed ? "Closed" : returnTime(this.state.timer)}</CText>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  pollTimeContainer: {
    justifyContent: "center",
    paddingHorizontal: 16,
    alignItems: "center",
    flexDirection: "row"
  },
});
