
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import _ from 'lodash';
let config = require('../../../framework/src/config')
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  searchQuery: string;
  searchList: any[];
  errorMessage: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchForums extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  searchThreadApiCallId: any;
  abortController: any;
  abortSignal: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    // Create an instance of AbortController
    this.abortController = new AbortController();
    this.abortSignal = this.abortController.signal;

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      loading: false,
      searchQuery: "",
      searchList: [],
      errorMessage: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();

  }

  setSearchQuery = (value: string) => {
    this.setState({ searchQuery: value, errorMessage: "" }, () => {
      if (value.length >= 3) {
        this.debouncedSearch();
      } else {
        this.setState({ searchList: [], errorMessage: "" })
      }
    })
  };

  debouncedSearch = _.debounce(async () => {
    const { searchQuery } = this.state;
    const token = await getStorageData("TOKEN");

    try {
      // Abort the previous fetch request if it exists
      this.abortController.abort();

      this.abortController = new AbortController();
      this.abortSignal = this.abortController.signal;

      const apiUrl = `${configJSON.searchThreadAPIEndpoint}${searchQuery}`;
      const headers = {
        token: token
      };
      const method = configJSON.GET
      const fullURL = apiUrl.indexOf('://') === -1 ? config.baseURL + '/' + apiUrl : apiUrl
      const fetchOptions = {
        signal: this.abortSignal,
        method,
        headers,
      };
      if (!searchQuery) {
        this.abortController.abort();
      }
      const response = await fetch(fullURL, fetchOptions);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseJson = await response.json();
      if (!responseJson.message) {
        this.setState({
          searchList: responseJson?.data,
        });
      } else {
        this.setState({ searchList: [], errorMessage: responseJson.message });
      }
    } catch (error: any) {
      if (error.name === 'AbortError') {
        console.log('Fetch request was aborted');
      } else {
        console.error('Fetch request failed:', error);
      }
    }
  }, 500);

  clearQuery = () => {
    this.setState({ searchQuery: "", searchList: [], errorMessage: "" })
  }

  navigateToThreadCommentsScreen = (threadId: number) => {
    this.props.navigation.navigate('Communityforum4', ({ threadId }))
  }

  // Customizable Area End
}
