import React, { Component, FC, } from "react";
import { View, StyleSheet, TouchableOpacity, Text } from "react-native";
import Scale from "../../Scale";

import { MentionInput, MentionSuggestionsProps, Suggestion } from "react-native-controlled-mentions";
import { getFontFamily } from "framework/src/Utilities";


interface Props {
  onChange?: any
  value: string
  style: any
  testID: string
  placeholder?: any;
  partTypes?: any;
  maxLength?: any
}
export default class CInputPost extends Component<Props> {
  render() {
    const { onChange, value, style, testID, partTypes, maxLength } = this.props;
    const users = [
      { id: '1', name: 'David Tabaka' },
      { id: '2', name: 'Mary' },
      { id: '3', name: 'Tony' },
      { id: '4', name: 'Mike' },
      { id: '5', name: 'Grey' },
    ];

    const hashtags = [
      { id: 'todo', name: 'todo' },
      { id: 'help', name: 'help' },
    ];

    const renderSuggestions: (
      suggestions: Suggestion[]
    ) => FC<MentionSuggestionsProps> = (suggestions) => ({
      keyword,
      onSuggestionPress,
    }: any) => {
      if (keyword == null) {
        return null;
      }

      return (
        <View>
          {suggestions
            .filter((one) =>
              one.name.toLocaleLowerCase().includes(keyword.toLocaleLowerCase())
            )
            .map((one) => (
              <TouchableOpacity
                key={one.id}
                onPress={() => onSuggestionPress(one)}
                style={{ padding: 12 }}>
                <Text style={{ color: "pink" }}>{one.name}</Text>
              </TouchableOpacity>
            ))}
        </View>
      );
    };

    const renderMentionSuggestions = renderSuggestions(users);

    const renderHashtagSuggestions = renderSuggestions(hashtags);
    return (
      <MentionInput
        // autoFocus
        testID={testID}
        value={value}
        onChange={onChange}
        partTypes={partTypes ?? [
          {
            trigger: '@',
            renderSuggestions: renderMentionSuggestions,
            textStyle: {
              fontFamily: getFontFamily('light'),
              color: '#FF751B'
            },
            isBottomMentionSuggestionsRender: false
          },
          {
            trigger: '#',
            allowedSpacesCount: 0,
            renderSuggestions: renderHashtagSuggestions,
            textStyle: {
              fontFamily: getFontFamily('light'),
              color: '#9BA2B7'
            },
            isBottomMentionSuggestionsRender: false
          },
          {
            pattern: /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]*[-a-zA-Z0-9@:%_\+\]~#?&\/=])*/gi,
            textStyle: {
              fontFamily: getFontFamily('light'),
              color: '#fff'
            },
          },
        ]}

        style={style ?? styles.preSty}
        placeholder={this.props.placeholder}
        placeholderTextColor={'#fff'}
        maxLength={maxLength ? maxLength : 100}
      />

    )
  }
}

const styles = StyleSheet.create({
  containerStyle: { flex: 1, width: "85%", },
  preSty: {
    fontFamily: getFontFamily('light'),
    color: "#fff", marginVertical: 10, padding: 5, backgroundColor: "#1B1B3A", borderRadius: 8, minHeight: "15%", borderWidth: 0.5, borderColor: "#fff",
  },
  imgStyle: { marginRight: Scale(8), alignSelf: 'flex-start', marginTop: Scale(5) },
  iconStyle: { marginLeft: Scale(6) },
  innerContainerStyle: { marginBottom: Scale(10), flexDirection: 'row', alignItems: 'center' },
  mainContainer: {
    flex: 1,
    backgroundColor: "rgba(0, 0, 36, 1)",
    alignItems: "center"
  },
  containerSty: {
    flex: 1,
    padding: 0,
    marginVertical: Scale(10),

  },
  inputContainerStyle: {
    marginTop: Scale(10),
    color: '#fff',
    borderBottomWidth: 0,
    fontSize: Scale(20),
    fontFamily: getFontFamily('light'),
  },
  inputStyle: {
    fontSize: Scale(20),
    fontFamily: getFontFamily('light'),
    color: "#fff",
  },
  sm: { height: Scale(32), width: Scale(32), borderRadius: Scale(32) },
  base: { height: Scale(44), width: Scale(44), borderRadius: Scale(44) },
  lg: { height: Scale(56), width: Scale(56), borderRadius: Scale(56) },
  xl: { height: Scale(132), width: Scale(132), borderRadius: Scale(132) },
  xxl: { height: Scale(156), width: Scale(156), borderRadius: Scale(156) },
});