import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Alert } from "react-native";
import {
  removeStorageData,
  resetStack,
  sendAPIRequest
} from "../../../framework/src/Utilities";
import { person1 } from "./assets";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
}

interface S {
  token: any;
  userDetails: any;
  isLoading: boolean;
  userType: any;
  showGuestPopup: boolean;
  userID: string;
}

interface SS {
  id: any;
}

export default class ViewUserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  userProfileGetApiCallId: any;
  logoutUserApiCallId: any;
  deleteUserAccountApiCallId: any;
  _unsubscribe: any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      userDetails: {},
      isLoading: false,
      userType: "guest",
      showGuestPopup: false,
      userID: ""
    };

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.userProfileGetApiCallId:
            this.getUserDetails(responseJson, errorReponse);
            break;
          case this.logoutUserApiCallId:
            this.logoutOrDeleteUserAccountRes(responseJson, errorReponse);
            break;
          case this.deleteUserAccountApiCallId:
            this.logoutOrDeleteUserAccountRes(responseJson, errorReponse);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  async componentWillUnmount() {
    if (this._unsubscribe) {
      this._unsubscribe();
    }
  }

  handleError = (responseJson: any) => {
    if (
      responseJson.errors &&
      responseJson.errors.length > 0 &&
      responseJson.errors[0].token
    ) {
      this.showAlert("Session Expired", "Please Log in again.");
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  };

  getUserDetails = (response: any, errorRes: any) => {
    if (!response.errors && response.data && response.data.attributes) {
      if (
        response.data.attributes !== null &&
        response.data.attributes !== undefined
      ) {
        this.setState({
          userID: response.data.id,
          userDetails: response.data.attributes,
          isLoading: false
        });
      }
    } else {
      //Check Error Response
      this.setState({ isLoading: false });
      this.handleError(response);
    }

    this.parseApiCatchErrorResponse(errorRes);
  };

  logoutOrDeleteUserAccountRes = (resData: any, errorRes: any) => {
    if (!resData.errors && resData.message) {
      Alert.alert("", resData.message);
      removeStorageData("TOKEN");
      removeStorageData("ACCOUNT_ID");
      resetStack("EmailAccountLoginBlock", this.props.navigation);
    } else {
      //Check Error Response
      this.handleError(resData);
    }
    this.setState({ isLoading: false });
    this.parseApiCatchErrorResponse(errorRes);
  };

  getUserProfile() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.state.token
    };
    const apiEndPoint = configJSON.GetUserProfileApiEndPoint;

    const method = configJSON.methodTypeApiGetUserProfile;
    this.userProfileGetApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }

  askLogout = () => {
    Alert.alert("Logout", "Are you sure you want to logout?", [
      { text: "Cancel", style: "cancel" },
      { text: "OK", onPress: () => this.logoutUser() }
    ]);
  };

  logoutUser = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const apiEndPoint = configJSON.logoutUserApiEndPoint;
    const method = configJSON.putMethodType;
    this.logoutUserApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  };

  askDeleteAccount = () => {
    Alert.alert("", "Are you sure you want to delete account?", [
      { text: "Cancel", style: "cancel" },
      { text: "OK", onPress: () => this.deleteUserAccount() }
    ]);
  };

  deleteUserAccount = () => {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token
    };
    const apiEndPoint = configJSON.deleteUserAccountApiEndPoint;
    const method = configJSON.deleteMethodType;
    this.deleteUserAccountApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  };

  handleGuestPollPopup = () => {
    this.setState({ showGuestPopup: !this.state.showGuestPopup });
    this.props.navigation.goBack();
  };
  handleGuestSignUpNavigation = () => {
    this.setState({ showGuestPopup: !this.state.showGuestPopup });
    this.props.navigation.replace("EmailAccountRegistration");
  };

  getURI = () => {
    return this.state.userDetails.photo
      ? { uri: this.state.userDetails.photo }
      : this.state.userDetails.player_photo
        ? { uri: this.state.userDetails.player_photo }
        : person1
  }
}
