import React, { Component } from 'react';
import {
    StyleSheet,
    Image,
    View,
} from "react-native";
import ImageZoom from 'react-native-image-pan-zoom';
import CModal from '../CModal/CModal';
interface Props {
    url?: any
    onClick?: any
    modalState: boolean
}
export default class CEventImage extends Component<Props> {
    render() {
        const { url, onClick, modalState } = this.props;
        return (
            <CModal
                testID="testModal"
                cscreenHeight={0}
                cscreenWidth={0}
                onTouchOutside={onClick}
                customListItem={false}
                customStyle={{ flex: 1 }}
                mainView={
                    // <ImageZoom
                    //     cropWidth={400}
                    //     cropHeight={800}
                    //     imageWidth={400}
                    //     imageHeight={500}
                    //     style={styles.zoomView}
                    //     onClick={onClick}
                    // >
                    //     <View style={styles.imageView}>
                    //         <Image
                    //             source={
                    //                 url ?
                    //                     { uri: url }
                    //                     : require('../../../../blocks/events/assets/poster.png')
                    //             }
                    //             resizeMode="contain"
                    //             style={styles.img} />
                    //     </View>
                    // </ImageZoom>
                    <></>
                }
                showBottomSheet={false}
                showDone
                visible={modalState}
            />
        )
    }
}

const styles: any = StyleSheet.create({
    imageView: { justifyContent: "center", alignItems: "center", height: "100%", width: "100%" },
    zoomView: { justifyContent: "center", alignSelf: "center" },
    img: { height: "100%", width: "100%" }
});
