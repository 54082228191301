import * as React from 'react';
import { StyleSheet, View, TouchableOpacity, Image, FlatList, ImageProps, ImageSourcePropType, ViewStyle, ImageStyle, Dimensions } from 'react-native';
import { CText } from '../CText/CText';
import Scale from '../../Scale';

interface Props {
    scoringSys?: String;
    points?: Number | String;
    crossIcon?: ImageProps | any;
    scoringSystemList?: any;
    renderScoringSystem?: any;
    onPressClose?: () => void;
    bottomContainerStyle?: ViewStyle;
    scoringSystemHeaderStyle?: ViewStyle;
    imgStyle?: ImageStyle;
    dividerListStyle?: ViewStyle;
    innerSysBoxStyle?: ViewStyle;
    scoringTableStyle?: ViewStyle;
}

let screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;

const CBottomSheetContent = ({
    scoringSys,
    points,
    crossIcon,
    scoringSystemList,
    renderScoringSystem,
    onPressClose,
    bottomContainerStyle,
    scoringSystemHeaderStyle,
    imgStyle,
    dividerListStyle,
    innerSysBoxStyle,
    scoringTableStyle
}: Props) => {
    const styles = StyleSheet.create({
        container: {
            height: screenHeight * 0.65,
            backgroundColor: '#010125',
            borderTopRightRadius: 20,
            ...bottomContainerStyle
        },
        scoringSysHeaderContainer: {
            marginHorizontal: Scale(5),
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: Scale(10),
            paddingVertical: Scale(15),
            ...scoringSystemHeaderStyle
        },
        imageStyle: {
            height: screenHeight * 0.025,
            width: screenWidth * 0.06,
            tintColor: "#FFFFFF",
            ...imgStyle
        },
        dividerListView: {
            height: 1,
            backgroundColor: '#2C314B',
            ...dividerListStyle
        },
        innerScoringSystemBox: {
            marginHorizontal: Scale(15),
            borderRadius: Scale(10),
            borderWidth: 1,
            borderColor: '#1B1B3A',
            marginTop: Scale(20),
            ...innerSysBoxStyle
        },
        scoringTableView: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: Scale(10),
            marginBottom: Scale(10),
            marginLeft: Scale(15),
            marginRight: Scale(40),
            paddingVertical: Scale(10),
            ...scoringTableStyle
        },

    });

    return (
        <View style={styles.container}>
            <View style={styles.scoringSysHeaderContainer}>
                <CText size="xl" style="white" weight='bold' numberOfLines={1}>
                    {scoringSys}
                </CText>
                <TouchableOpacity onPress={onPressClose}>
                    <Image
                        style={styles.imageStyle}
                        source={crossIcon}
                    />
                </TouchableOpacity>
            </View>
            <View style={styles.dividerListView} />
            <View style={styles.innerScoringSystemBox}>
                <View style={styles.scoringTableView}>
                    <CText size="base" style="white" weight='bold' numberOfLines={1}>
                        {scoringSys}
                    </CText>
                    <CText size="base" style="white" weight='bold' numberOfLines={1}>
                        {points}
                    </CText>
                </View>
                <View style={styles.dividerListView} />
                <FlatList
                    data={scoringSystemList}
                    renderItem={renderScoringSystem}
                />
            </View>
        </View>
    );
};

export default CBottomSheetContent;


