import React from "react";

import {
  StyleSheet,
  Text,
  View,
  // Customizable Area Start
  ActivityIndicator,
  TouchableOpacity,
  FlatList,
  TextInput,
  ScrollView,
  RefreshControl,
  Image,
  Linking,
  Alert
  // Customizable Area End
} from "react-native";

// Customizable Area Start
import Scale from "../../../components/src/Scale";
import CUTSPoll from "../../../components/src/custom-components/CUTSPoll/CUTSPoll.web";
import CNewsFeedCard from "../../../components/src/custom-components/CNewsFeedCard/CNewsFeedCard.web";
import { CGuestPopup, CStatusBar, CText, CToggleComponent, CModal, CModalContainer, CToast, CFooterIndicator, CBottomModal } from "../../../components/src/custom-components";
import { isPollActive } from "../../utilities/src/TimeUtilities";
import AddPost from "react-native-vector-icons/Feather"
import { NavigationEvents } from "react-navigation";
import { eventPlaceHolder, liveTV, utsHomeScreenLogo } from "../../../components/src/assets";
import * as Progress from 'react-native-progress';
import { Overlay } from "react-native-elements";
// import LinearGradient from "react-native-linear-gradient";
// Customizable Area End

import NewsFeedWebController, {
  Props,
  configJSON
} from "./NewsFeedWebController";

export default class NewsFeed extends NewsFeedWebController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderItem = (item: any) => {

    let renderContent;

    if (item.type === "poll" && !isPollActive(item.attributes?.start_date_time)) {
      renderContent = (
        <CUTSPoll
          testID={"gotoPollBtnId"}
          title={item.attributes?.title}
          description={item.attributes?.description}
          createdTime={item.attributes.created_at}
          updatedTime={item.attributes.end_date_time}
          onPress={() => {
            this.state.isGuestUser ?
              this.handleGuestPopup("Poll")
              : this.gotoPollScreen(item.id)
          }}
          account_photo={item?.attributes?.account_photo}
        />
      );
    } else if (item.type === "post") {
      renderContent = (
        <CNewsFeedCard
          post={item}
          testID={'testReportId'}
          likeTestId={'testSelectHeart'}
          commentTestId={'testSelectComment'}
          onPressComment={() => {
            this.state.isGuestUser ?
              this.handleGuestPopup("Comments")
              : this.props.navigation.navigate('Comments', ({ postId: item.id }))
          }}
          onSelectHeart={(status: boolean) => {
            this.state.isGuestUser
              ? this.handleGuestPopup("Like")
              : this.likeOnPost(item, status)
          }}
          onHeartInitialStatus={item.attributes.is_liked}
          onTapTooltip={() => { item.attributes?.is_own_post ? this.onPressTooltip(item.id, "delete") : this.onPressTooltip(item.id, "report") }}
          downloadfunction={(videoData: any, imageData: any) => {
            // this.state.isGuestUser
            //   ? this.handleGuestPopup("Like") : this.downloadAndShareImage(videoData, imageData, item)
          }}
        />
        
      );
    } else {
      renderContent = null;
    }
    return renderContent;
  };

  renderReportModal = () => {
    let newArray = [...this.state.getReasonsFromApi];
    let checkSelected = newArray.map((ele) => {
      return ele.isSelected
    });
    return (
      <CModal
        testID="modalPost"
        title={''}
        data={[{}]}
        onTouchOutside={() => this.renderReportModalHide()}
        customListItem={false}
        mainView={
          <CModalContainer
            testID='modalCont'
            headerText={'Report this post'}
            cancelText={'Clear'}
            submitText={'Submit'}
            closeBtnOnPress={() => this.renderReportModalHide()}
            clearBtnOnPress={() => this.renderReportModalHide()}
            submitBtnOnPress={() => checkSelected.includes(true) || this.state.reportReview ? this.reportThisPost() : <></>}
            submitBtnDisable={checkSelected.includes(true) || this.state.reportReview ? false : true}
            submitBtnStyle={checkSelected.includes(true) || this.state.reportReview ? 'primary' : 'secondary'}
          >
            <ScrollView>
              <View style={{ paddingHorizontal: Scale(20), marginTop: Scale(25) }}>
                <CText style="white" size="base" weight="bold">Select a reason that applies</CText>
                <FlatList
                  testID="reasonReport"
                  data={this.state.getReasonsFromApi}
                  renderItem={this.renderReasonsForReportView}
                  contentContainerStyle={{ flexWrap: "wrap", flexDirection: 'row' }}
                />
                <View style={{ marginTop: Scale(25) }}>
                  <CText style="white" size="base" weight="bold">Tell us what you don't like</CText>
                  <TextInput
                    testID="review"
                    maxLength={100}
                    multiline
                    onChangeText={text => {
                      this.setState({ reportReview: text })
                    }}
                    style={styles.reportTextInput}
                  />
                  <View style={styles.lengthCheckView}>
                    <CText style="gray" size="base" weight="bold">{`${this.state.reportReview.length}/100`}</CText>
                  </View>
                </View>
              </View>
            </ScrollView>
          </CModalContainer>
        }
        showBottomSheet={false}
        showDone
        visible={this.state.visible}
      />
    )
  }

  onPressTooltip = (postId: any, type: "report" | "delete") => {
    if (type === "delete") {
      this.setState({ postId: postId });
      Alert.alert('DELETE', 'Are you sure you want to delete this post?', [
        {
          text: 'Cancel',
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        { text: 'DELETE', onPress: () => { this.deletePost() } },
      ]);
    } else {
      this.setState({ visible: true, postId: postId });
    }
  }

  renderReportModalHide = () => {
    let newArray = [...this.state.getReasonsFromApi];
    let finalArray = newArray.map((ele, i) => {
      return { ...ele, isSelected: false };
    });
    this.setState({
      visible: false,
      getReasonsFromApi: finalArray
    });
  }

  renderReasonsForReportView = (props: any) => {
    return <TouchableOpacity onPress={() => {
      let newArray = [...this.state.getReasonsFromApi];
      let finalArray = newArray.map((ele, i) => {
        if (ele.id == props.item.id) {
          return { ...ele, isSelected: !ele.isSelected };
        }
        return { ...ele, };
      });
      this.setState({ getReasonsFromApi: finalArray });
    }}>
      <View style={props.item?.isSelected ? [styles.reasonView, { borderWidth: 1, borderColor: 'rgba(255, 0, 47, 1)' }] : styles.reasonView}>
        <CText style="white" size="sm" weight="bold">{props.item.name}</CText>
      </View>
    </TouchableOpacity>
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <View style={styles.container}>
        <CStatusBar backgroundColor="rgba(0, 0, 36, 0)" barStyle="light-content" />
        <View style={styles.headerView}>
          <View style={{ flex: 1 }}>
            <Image source={utsHomeScreenLogo} style={{ width: Scale(77), height: Scale(32), resizeMode: "contain" }} />
          </View>
          {this.state.hashTag.name !== "" && <button onClick={() => { this.openWebsiteLink() }}>
            <CText size="sm" style="primary" weight="bold" lineHeight={22}  >{this.state.hashTag.name}</CText>
          </button>}
          {/* <TouchableOpacity testID="openFltr"
            style={[{ marginLeft: Scale(8), backgroundColor: "transparent", padding: Scale(12), borderRadius: Scale(100) }, this.state.isFilterOpen && { backgroundColor: "#FFFFFF" }]}
            onPress={() => { this.setState({ isFilterOpen: !this.state.isFilterOpen }) }}
          >
            <CIcon name={"tune"} color={this.state.isFilterOpen ? "#FF751B" : "#FFFFFF"} size={25} type="MaterialCommunityIcons" />
          </TouchableOpacity> */}
        </View>
        <View style={{ flexDirection: "row" }}>
          <View style={{ flex: 1, }}>
            <CToggleComponent
              testID={'testSelectToggle'}
              array={this.state.tab}
              onSelect={this.handleTabSelect}
              customFont={"xs"}
            />
          </View>
          <TouchableOpacity style={{
            marginTop: 24, alignItems: "center",
            justifyContent: "center",
          }}
            onPress={() => { this.props.navigation.navigate("PlayerInteraction") }}
          >
            <div className="gradient-bg">
              <img src={liveTV} alt="Live TV" style={{ width: '13.13px', height: '12px', marginRight: '6px', objectFit: 'contain' }} />
              <CText size="sm" style="white" weight="light" lineHeight={Scale(22)}  >{"Live"}</CText>
            </div>
          </TouchableOpacity>
        </View>
        <View style={styles.newsFeedCard}>
          <View style={styles.newsFeedsContainer}>
            {/* Merge Engine UI Engine Code */}
            {/* <NavigationEvents testID="navFocus"
              onWillFocus={() => { this.state.selectedBtn === 1 ? this.onRefresh() : this.onutsRefresh() }} /> */}
<CText size="sm" style="primary" weight="bold" lineHeight={22}  >{"this sis testing with jerry"}</CText>
            <TouchableOpacity testID="goCommunity" onPress={() => {
              this.state.isGuestUser ?
                this.handleGuestPopup("Post") :
                this.props.navigation.navigate("CommunityForum")
            }}>
              <Image source={eventPlaceHolder} style={{ width: "100%", height: Scale(67), resizeMode: "cover", }} />
            </TouchableOpacity>
            
            {this.state.selectedBtn === 1 && (
              <FlatList
                overScrollMode={"never"}
                ListEmptyComponent={<ActivityIndicator />}
                contentContainerStyle={styles.pb40}
                data={this.state.newsFeedData}
                testID={configJSON.NewsFeedFlatList}
                renderItem={({ item }) => this.renderItem(item)}
                keyExtractor={(item: any) => item.id + Math.random()}
                onEndReached={this.handleLoadMore}
                onEndReachedThreshold={0.1}
                ListFooterComponent={() => <CFooterIndicator showIndicator={this.state.bottomLoader} />}
                refreshControl={<RefreshControl
                  refreshing={this.state.refreshing}
                  colors={['#9BA2B7']}
                  onRefresh={this.onRefresh} />}
                extraData={this.state.newsFeedData}
              />
            )}

            {this.state.selectedBtn === 2 && (
              <FlatList
                overScrollMode={"never"}
                ListEmptyComponent={<ActivityIndicator />}
                contentContainerStyle={styles.pb40}
                data={this.state.utsFeedData}
                testID={"list2"}
                renderItem={({ item }) => this.renderItem(item)}
                keyExtractor={(item: any) => item.id}
                initialNumToRender={10}
                windowSize={5}
                onEndReached={this.handleLoadMoreUTS}
                onEndReachedThreshold={0.1}
                ListFooterComponent={() => <CFooterIndicator showIndicator={this.state.bottomLoader} />}
                refreshControl={<RefreshControl
                  refreshing={this.state.refreshing}
                  colors={['#9BA2B7']}
                  onRefresh={this.onutsRefresh} />}
                extraData={this.state.utsFeedData}
              />
            )}
            {/* Merge Engine UI Engine Code */}
          </View>
          <TouchableOpacity
            style={styles.addPostView}
            onPress={() => {
              this.state.isGuestUser ?
                this.handleGuestPopup("Post") :
                this.props.navigation.navigate("PostCreation")
            }}
            testID="buttonEditPhoto">
            <AddPost name="plus" size={25} color={'#FF002F'} />
          </TouchableOpacity>
          <CToast
            testID="toastMsg"
            msg={this.state.isToastVisible}
            type='success'
            position="bottom"
            isWarning={false}
            toastPosition={{ bottom: 140 }}
            callback={() => this.setState({ isToastVisible: '' })} />
          <CGuestPopup
            testID="guestpopUp"
            isVisible={this.state.isGuestPopupOpen}
            isVisibleFunction={this.handleGuestPopup}
            onPressSignUpFunction={this.handleGuestSignUpNavigation}
            subject={this.state.guestActionType}
          />
        </View>
        {this.state.getReasonsFromApi.length > 0 && this.renderReportModal()}
        <Overlay isVisible={this.state.downloading} overlayStyle={styles.overlay}>
          <View style={{ alignItems: "center", marginBottom: Scale(25) }}>
            <CText style="primary" size="base" weight="light">Download in progress</CText>
            {/* <View style={{ marginTop: Scale(10) }}>
              <Progress.Bar
                progress={this.state.progress / 100}
                color="#FF002F"
              />
            </View> */}
          </View>
        </Overlay>
        <CBottomModal
          modalShow={this.state.showAppUpdateModal}
          title='App update required!'
          titleStyle='center'
          description='We have added new features to make your experience seamless.'
          descriptionStyle='center'
          buttonName='Update app'
          bottomButtonName={this.handleSoftUpdateBtn()}
          bottomButtonTop={false}
          showCloseButton={this.handleSoftUpdateCloseBtn()}
          buttonTestID='buttonId'
          onPressButton={() => this.handleAppUpdate()}
          closeTestID='closeIconID'
          onPressClose={this.handleSkipAppUpdateApi}
          bottomButtonTestID='bottomButtonId'
          onPressBottomButton={this.handleSkipAppUpdateApi}
        />
      </View>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    alignItems: 'center',
    height: "auto",
    width: "100%"
  },
  container: {
    flex: 1,
    backgroundColor: "#000024",
  },
  headerView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: Scale(20),
    marginHorizontal: Scale(20),
    alignItems: "center"
  },
  newsFeedCard: {
    flex: 1,
    elevation: 4,
    backgroundColor: "#000024",
    marginVertical: 12,
    // paddingVertical: 16,
    borderWidth: 1,
    borderRadius: 8
  },
  newsFeedsContainer: {
    flex: 1,
    // paddingHorizontal: Scale(20)
  },
  pb40: { paddingBottom: Scale(80) },
  addPostView: {
    width: Scale(56),
    height: Scale(56),
    borderRadius: Scale(16),
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: Scale(10),
    bottom: 80
  },
  reasonView: {
    paddingVertical: Scale(12),
    paddingHorizontal: Scale(18),
    borderRadius: 20,
    backgroundColor: '#1B1B3A',
    marginRight: Scale(10),
    marginTop: Scale(13),
    alignItems: 'center',
    justifyContent: 'center'
  },
  lengthCheckView: {
    alignSelf: 'flex-end',
    marginTop: Scale(7)
  },
  reportTextInput: {
    height: Scale(120),
    marginTop: Scale(12),
    borderRadius: Scale(10),
    borderWidth: 1,
    borderColor: '#2C314B',
    maxHeight: Scale(150),
    color: 'white',
    textAlignVertical: 'top',
    paddingTop: Scale(15),
    paddingLeft: Scale(15),
  },
  topCommunityImageView: {
    width: "100%",
    height: Scale(67),
    resizeMode: "cover",
    marginTop: Scale(16)
  }
});
// Customizable Area End
