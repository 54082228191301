import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";

interface IapListData {
  id: number | string,
  label: string,
  amount: number | string,
  offer: number | string,
  isSelected: boolean,
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  token: string;
  iapListData: IapListData[];
  refreshing: boolean;
  selectedIndex: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isLoading: false,
      token: '',
      refreshing: false,
      iapListData: [{ id: 0, label: 'Annual', amount: '$ 1080', offer: '50%', isSelected: false }, { id: 1, label: 'Monthly', amount: '$ 180', offer: '', isSelected: false }],
      selectedIndex: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.successResponse(message);
      this.errorResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({ token: await getStorageData('TOKEN') })
  }

  apiCommonResponseHandler = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    return { apiRequestCallId, responseJson, errorResponse }
  }

  successResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.apiCommonResponseHandler(message);
    if (responseJson && !responseJson.errors && !errorResponse) {
      if (apiRequestCallId === this.apiCallId) {
        console.log('successResponse >>>>> ', responseJson)
      }
    }
  }

  errorResponse = (message: Message) => {
    const { apiRequestCallId, responseJson, errorResponse } = this.apiCommonResponseHandler(message);
    if (responseJson?.errors || !errorResponse?.errors) {
      if (apiRequestCallId === this.apiCallId) {
        console.log('errorResponse >>>>> ', responseJson?.errors)
      }
    }
  }

  setSubscriptionData = (val: IapListData[]) => {
    this.setState({ iapListData: val })
  }

  isSubmitButtonDisabled = () => {
    let filtered = this.state.iapListData.filter((el, index) => { return el.isSelected === true; });
    return filtered.length == 0
  }

  onPaymentButtonClick = () => {
    let filtered = this.state.iapListData.filter((el, index) => { return el.isSelected === true; });
    console.log('Selected option is >> ', filtered[0]?.label)
  }

  onRestorePurchaseButtonClick = () => {
    console.log('onRestorePurchaseButtonClick >>>>> ')
  }

  goBack = () => {
    this.props.navigation.goBack()
  }

  // Customizable Area End
}
