import React, { Component } from "react";
import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';


interface IconProps {
    name: any;
    color: string;
    type: "FontAwesome" | "MaterialIcons" | "MaterialCommunityIcons" | "FontAwesome6";
    size: number;
    onPress?: any;
}

export default class CIcon extends Component<IconProps> {
    handleIconSet = (props: IconProps) => {
        switch (props.type) {
            case "FontAwesome":
                return (
                    <FontAwesomeIcon name={props.name} size={props.size} color={props.color} />
                );

            case "MaterialCommunityIcons":
                return (
                    <MaterialCommunityIcon name={props.name} size={props.size} color={props.color} onPress={props.onPress}/>
                );

            case "MaterialIcons":
                return (
                    <MaterialIcons name={props.name} size={props.size} color={props.color} />
                );

            default:
                break;
        }
    }
    render() {
        return (
            <>
                {this.handleIconSet(this.props)}
            </>
        )
    }
}
