import React from "react";
import { StyleSheet, View, Image, TouchableOpacity } from "react-native";
import { BlockComponent } from "framework/src/BlockComponent";
import { ic_pollprofile, ic_tick } from "../../../../blocks/newsfeed/src/assets";
import Scale from "../../Scale";
import CCountDownTimer from "../CCountDownTimer/CCountDownTimer";
import { CText } from "../CText/CText";
import { getRelativePollTime, isPollActive } from "../../../../blocks/utilities/src/TimeUtilities";
import CAvatar from "../CAvatar/CAvatar";
import { getFontFamily } from "framework/src/Utilities";
import CButton from "../CButton/CButton";

interface Props {
  testID?: any;
  title?: any;
  description?: any;
  createdTime?: any;
  updatedTime?: any;
  onPress?: any;
  polltestid?: any;
  account_photo?: string;
}

interface S { }

interface SS { }

export default class CUTSPoll extends BlockComponent<Props, S, SS> {
  render() {

    const { updatedTime, account_photo } = this.props;

    const isPollOpen = isPollActive(updatedTime);

    return (
      <View style={styles.newsFeedCardUTSPoll} testID={this.props.polltestid}>
        <View style={styles.pollCardHeaderViewUTSPoll}>
          <View style={styles.pollCardProfileViewUTSPoll}>
            <CAvatar
              image={{ size: 'base', path: account_photo }}
              icon={{ size: 'sm', path: { uri:'' }}}
              title={{ children: 'UTS', size: 'base', weight: 'bold', style: 'white' }}
              text={{ children: getRelativePollTime(this.props.createdTime), size: 'sm', weight: 'light', style: 'gray' }}
              isGradient
            />
          </View>
          <View style={styles.countDownTimeUTSPoll}>
            <CCountDownTimer time={updatedTime} isClosed={!isPollOpen} />
          </View>

        </View>
        <View style={{ paddingVertical: 10, paddingHorizontal: 15 }}>
          <CText style="white" size="xl" weight="bold">
            {this.props.title}
          </CText>
          <CText style="white" size="base" weight="light">
            {this.props.description}
          </CText>
        </View>
        <View style={styles.pollContainer}>

          <CButton
            testID={"btnGoToPOll"}
            type="poll"
            onPress={this.props.onPress}
            style={
              !isPollOpen
                ? "bright"
                : "primary"
            }
            size="bigger"
            btnTxtStyle={{ color: isPollOpen ? "#fff" : "#000" }}
          >
            {isPollOpen ? "Vote Now" : "View result"}
          </CButton>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  newsFeedCardUTSPoll: {
    flex: 1,
    elevation: 4,
    backgroundColor: "#1B1B3A",
    marginVertical: 12,
    paddingVertical: 16,
    borderWidth: 1,
    borderRadius: 8,
  },
  tickImageUTSPoll: {
    height: Scale(22),
    width: Scale(22),
    position: 'relative',
    left: 13,
    bottom: 2,
  },
  pollCardHeaderViewUTSPoll: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pollCardProfileViewUTSPoll: {
    flexDirection: 'row',
    marginLeft: 10,
    width: '50%',
  },
  userNameTextUTSPoll: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 18,
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: 24,
    letterSpacing: 0,
  },
  postingTimeStampUTSPoll: {
    color: "rgba(155, 162, 183, 1)",
    fontSize: 14,
    fontWeight: "300",
    fontFamily: getFontFamily("light"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: 18,
    letterSpacing: 0,
  },
  pollTitleUTSPoll: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 18,
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: 24,
    letterSpacing: 0,
  },
  descriptionTextUTSPoll: {
    color: "rgba(255, 255, 255, 1)",
    fontSize: 20,
    fontWeight: "300",
    fontFamily: getFontFamily("light"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: 26,
    letterSpacing: 0,
  },
  gotoPollBtnUTSPoll: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 1)',
    borderRadius: 58,
    justifyContent: "center",
    alignItems: "center",
    marginHorizontal: 20,
    paddingHorizontal: 16,
    paddingVertical: 10
  },
  gotoPollBtnTextUTSPoll: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: 18,
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    fontStyle: "normal",
    letterSpacing: 0,
  },
  countDownTimeUTSPoll: {
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closedTextContainer: {
    width: "24%",
    marginRight: 20,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  newsFeedView1UTSPoll: {
    height: 80,
    marginLeft: 10,
    paddingBottom: 8,
    flex: 1,
  },
  imageView1UTSPoll: {
    height: Scale(40),
    width: Scale(40),
  },
  closedText: {
    color: "white",
    fontSize: 18,
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    textAlign: "center",
    fontStyle: "normal",
    lineHeight: Scale(24),
  },
  pollContainer: { justifyContent: "center", }
});
