import React, { ReactNode, useRef, useState } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import {
    Menu,
    MenuOptions,
    MenuOption,
    MenuTrigger,
    renderers

} from 'react-native-popup-menu';
import Icon from 'react-native-vector-icons/Entypo';
import Info from "react-native-vector-icons/MaterialCommunityIcons";
import Scale from '../../Scale';

interface Icons {
    icon?: any;
    iconColor?: string,
    iconSize?: number,
    style?: any
};
interface CTooltipPropsType {
    children?: any;
    onTapTooltip?: any;
    menuOptionContainerStyle?: any;
    icons?: Icons[],
    fromRanking?: any
}

const CTooltip = ({
    children,
    onTapTooltip,
    menuOptionContainerStyle,
    icons,
    fromRanking
}: CTooltipPropsType) => {
    const menuRef: any = useRef(null);
    const [isToolTipSelected, setToolTipSelected] = useState<boolean>(false);

    const closeTooltip = () => {
        if (menuRef?.current) {
            menuRef?.current?.close();
        }
    }
    return (
        <Menu ref={menuRef}
            onBackdropPress={()=>setToolTipSelected(false)} >
            <MenuTrigger onPress={()=>setToolTipSelected(true)}
                children={
                    <View>
                        {icons?.map((item, index) => {
                            const { icon, iconColor, iconSize, style } = item;
                            return (
                                <>
                                    {
                                        icon == "dots-three-vertical" ?
                                            <View style={[styles.threeDots, { backgroundColor: isToolTipSelected ? '#2C314B' : '#1C1A3C' }]}>
                                                <Icon key={index} name={icon} size={iconSize} color={iconColor} />
                                            </View>
                                            : <Info key={index} name={icon} size={iconSize} color={iconColor} style={style} />
                                    }
                                </>
                            )
                        })}
                    </View>
                }
            />
            <MenuOptions
                optionsContainerStyle={menuOptionContainerStyle}>
                {fromRanking &&
                    <TouchableOpacity onPress={closeTooltip} style={{ left: 130 }}>
                        <Icon name="cross" size={24} color="#fff" />
                    </TouchableOpacity>}
                <MenuOption onSelect={()=>{
                    onTapTooltip()
                    setToolTipSelected(false)
                }} >
                    {children}
                </MenuOption>
            </MenuOptions>
        </Menu>
    );
};

export default CTooltip;

const styles = StyleSheet.create({
    closeIcon: { left: 100 },
    threeDots: { padding: Scale(8), borderRadius: 25 },
});