Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";
exports.postCreationTitle = "CREATE POST";
exports.editProfileMessage = "Edit profile picture";
exports.takePhoto = "Take photo";
exports.photoLibrary = "Photo Library";
exports.removePhoto = "Remove photo";
exports.addVideo = "Pick video"
exports.cancel = "Cancel";
exports.formDataContentDataType = "multipart/form-data"
exports.postCreationApiURL = "/bx_block_posts/posts"
exports.headerTitle = "CREATE POST"
// Customizable Area End