import React, { Component } from 'react';
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import Scale from '../../Scale';
import { CText } from '../CText/CText';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import Foundation from 'react-native-vector-icons/Foundation'
import { videoCameraIcon } from '../../../../blocks/communityforum/src/assets';
import { SvgXml } from 'react-native-svg';
import { eventHeaderImg } from '../../assets';
import Info from "react-native-vector-icons/MaterialCommunityIcons";
interface MultipleIcons {
  testID: string,
  icon: any;
  color?: string,
  size?: number,
  onPress: any,
  isOptionSelected?: boolean
};

interface Icons {
  testID?: string,
  type: string,
  icon?: any;
  iconColor?: string,
  iconSize?: number,
  position: string,
  onPress?: any,
  style?: any,
  title?: string,
  alignTitle?: string,
  titleStyle?: string,
  titleWeight?: string,
  titleSize?: string,
  multipleIcons?: MultipleIcons[]
  bottom?: number
  isIconSelected?: boolean
};

interface Props {
  testID: string,
  icons?: Icons[],
  headerColor?: string;
  borderBottomRightRadius?: number;
  sort?: () => void
  postOwner?: string
  postDate?: string
  commentCount?: number
}

export default class CHeader extends Component<Props> {
  getIcon = (icon: string, color: string = '#fff', size: number = Scale(24)) => {
    switch (icon) {
      case 'close':
        return <Icon name="close" size={size} color={color} />
      case 'back':
        return <MaterialIcons name="arrow-back" size={size} color={color} />
      case 'search':
        return <MaterialIcons name="search" size={size} color={color} style={styles.searchStyle} />
      case 'filter':
        return <Image source={require('./filter.png')} style={styles.filterIcon} />
      case 'sort':
        return <MaterialIcons name="sort" size={size} color={color} />
      case 'videoCamera':
        return <SvgXml xml={videoCameraIcon} style={styles.filterIcon} />
      case 'eventHeaderImg':
        return <Image source={eventHeaderImg} style={styles.filterIcon} />
      case 'info':
        return <Info name="information-outline" size={size} color={color} style={styles.infoIcon} />
      default: break;
    }
  }

  getIconsView = (item: any) => {
    const { position, icon, iconColor, iconSize, testID, onPress, multipleIcons, isIconSelected } = item;
    return (
      <View >
        {multipleIcons && position == 'right' ?
          <View style={styles.multipleIconView}>
            {multipleIcons?.map((mItem: MultipleIcons) => {
              const { testID, icon, color, size, onPress, isOptionSelected } = mItem;
              return (
                <TouchableOpacity
                  key={testID}
                  testID={testID}
                  style={[styles.multipleIcon, isOptionSelected && styles.selectedIcon]}
                  onPress={() => onPress()}
                  hitSlop={{ top: 20, bottom: 20, left: 10, right: 20 }}>
                  {this.getIcon(icon, color, size)}
                </TouchableOpacity>
              )
            })}
          </View>
          : <TouchableOpacity
            testID={testID}
            onPress={() => onPress()}
            style={isIconSelected ? styles.selectedIcon : {}}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}>
            {this.getIcon(icon, iconColor, iconSize)}
          </TouchableOpacity>
        }
      </View>
    )
  }

  render() {
    const { icons, testID, headerColor, borderBottomRightRadius, } = this.props;
    let isLeftIcon = icons?.findIndex((i) => i.position == 'left');
    let isRightIcon = icons?.findIndex((i) => i.position == 'right');
    return (
      <View testID={testID} style={[styles.container, !this.props.postOwner && styles.alignItemsCenter, { backgroundColor: headerColor ?? "transparent", borderBottomRightRadius: borderBottomRightRadius ?? 0 }]}>
        {isLeftIcon == -1 && <View style={styles.touchableView} />}
        {icons?.map((item) => {
          const { type, title, titleStyle, titleSize, titleWeight, alignTitle } = item;
          return (<View style={[!this.props.postOwner && styles.alignItemsCenter]} key={item.testID}>
            {type == 'text' ?
              <View style={[styles.headerLabel, alignTitle ? null : { alignItems: 'center',}]}>
                <View style={!this.props.postOwner && styles.alignItemsCenter}>
                  <CText numberOfLines={2} style={titleStyle ?? "white"} size={titleSize ?? "sm"} weight={titleWeight ?? "bold"} children={title} />
                  {this.props.postOwner && (
                    <View style={{ paddingTop: Scale(18) }}>
                      <CText numberOfLines={1} style={'lightGray'} size={"base"} weight={"light"} children={`By ${this.props.postOwner}`} />
                      <View style={{ flexDirection: 'row', alignItems: 'center', paddingTop: Scale(18) }}>
                        <CText numberOfLines={1} style={'lightGray'} size={"base"} weight={"light"} children={this.props.postDate} />
                        <View style={{ marginHorizontal: Scale(8), height: Scale(4), width: Scale(4), borderRadius: Scale(2), backgroundColor: "#ffffff", alignSelf: "center" }} />
                        <CText numberOfLines={1} style={'lightGray'} size={"base"} weight={"light"} children={`${this.props.commentCount} comments`} />
                      </View>
                    </View>
                  )}
                </View>
              </View>
              : this.getIconsView(item)
            }
          </View>)
        })}
        {this.props.sort && this.props.sort()}
        {isRightIcon == -1 && <View style={styles.touchableView} />}
      </View>
    )
  }
}

const styles: any = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: Scale(10),
    paddingHorizontal: Scale(20),
    paddingTop: Scale(20),
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  touchableView: {
    height: Scale(24),
    width: Scale(24),
  },
  filterIcon: {
    height: Scale(20),
    width: Scale(20),
    margin: Scale(10)
  },
  selectedIcon: {
    backgroundColor: "white",
    borderRadius: Scale(61),
    alignSelf: "center",
    justifyContent: 'center',
    alignItems: 'center'
  },
  infoIcon: {
    height: Scale(24),
    width: Scale(24),
    margin: Scale(10)
  },
  multipleIcon: {
    marginHorizontal: Scale(5)
  },
  multipleIconView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  alignItemsCenter: {
    alignItems: 'center'
  },
  searchStyle: {
    marginLeft: Scale(10)
  },
});
