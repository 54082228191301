import React, { Component } from 'react';
// import LinearGradient from 'react-native-linear-gradient';

interface Props {
	style?: any,
	children?: any,
	colors?: string[]
}

export default class CGradient extends Component<Props> {
	render() {
		const { style, children, colors = ['#FF002F', '#FF751B'] } = this.props
    return <></>
		// return (
    // <LinearGradient colors={colors}
		// 	start={{ x: 0.25, y: 0.75 }} end={{ x: 1, y: 0.35 }}
		// 	style={style}>{children}</LinearGradient>
    //   )
	}
}
