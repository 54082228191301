Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "subscriptionbilling2";
exports.labelBodyText = "subscriptionbilling2 Body";
exports.btnExampleTitle = "CLICK ME";

exports.subscriptionText = "SUBSCRIPTION"
exports.frontRowExperienceText = "Front Row experience";
exports.frontRowDescriptionText = "Step into a world of exclusive entertainment with our Front Row Experience subscription.";
exports.note1Text = "Ask questions directly to players";
exports.note2Text = "Become a part of an exciting community";
exports.chooseSubscriptionText = "Choose your subscription plan";
exports.restorePurchaseText = "Restore Purchase";
exports.continuePaymentText = "Continue to Payment";

exports.contentType = "application/json";
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiGetScreenData = "POST";
exports.apiGenerateOrderId = "POST";
exports.apiVerifyPayment = "POST";
exports.apiCancelPayment = "POST";

// Customizable Area End