import React, { Component } from "react";
import { View, StyleSheet, Image, TouchableOpacity } from "react-native";
import Scale from "../../Scale";
import { CText, CTextProps } from '../CText/CText'
import CIcon from "../CIcon/CIcon";

interface ImageProps {
    name: any;
    type: "FontAwesome" | "MaterialIcons" | "MaterialCommunityIcons" | "FontAwesome6";
    color: string;
}

// Required props
interface AvatarRequiredProps {
    title: CTextProps;
    subTitle?: CTextProps;
    icon: ImageProps;
    showNextArrow: boolean;
    onPressFunction: () => void;
    testID: string;
}

export default class CMenuItem extends Component<AvatarRequiredProps> {

    getStyles() {
        const { icon } = this.props;
        return {
            containerStyles: {
                ...styles.containerStyle,
            },
            innerContainerStyles: {
                ...styles.innerContainerStyle,
            },
        }
    }

    render() {
        const { title, subTitle, icon, testID } = this.props;
        const { containerStyles, innerContainerStyles } = this.getStyles()
        return (
            <View>
                <TouchableOpacity
                    testID={testID}
                    style={containerStyles}
                    onPress={this.props.onPressFunction}
                >
                    <View style={styles.optionIcon}>
                        <CIcon
                            name={icon.name}
                            size={20}
                            type={icon.type}
                            color={icon.color ? icon.color : "#FF751B"}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <View style={innerContainerStyles}>
                            <CText style={title.style} size={title.size} weight={title.weight} lineHeight={title.lineHeight} >{title.children}</CText>
                        </View>
                        {subTitle &&
                            <CText style={subTitle.style} size={subTitle.size} weight={subTitle.weight} lineHeight={subTitle.lineHeight}>{subTitle.children}</CText>
                        }
                    </View>
                    {this.props.showNextArrow &&
                        <View>
                            <CIcon
                                name={"chevron-right"}
                                size={15}
                                type={"FontAwesome"}
                                color={"#FFFFFF"}
                            />
                        </View>
                    }
                </TouchableOpacity>
                <View style={styles.separator} />
            </View>
        )
    }
}

const styles = StyleSheet.create({
    containerStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: Scale(20),
        paddingVertical: Scale(10)
    },
    innerContainerStyle: { marginBottom: Scale(5), flexDirection: 'row', alignItems: 'center' },
    optionView: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: Scale(20),
        paddingVertical: Scale(10)
    },
    optionIcon: {
        height: Scale(36),
        width: Scale(36),
        backgroundColor: '#FF751B26',
        borderRadius: Scale(36 / 2),
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: Scale(14),
    },
    separator: {
        height: 1,
        width: '100%',
        backgroundColor: '#4C5064'
    },
});