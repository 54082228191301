import React from 'react';
import { View, StyleSheet, FlatList, } from 'react-native';
import CPlayerCell from '../CPlayerCell/CPlayerCell';

const CPlayerList =
  ({ playerData, navigation }:
    { playerData: any, navigation: any }) => {

    const flatListItemSeparator = () => {
      return <View style={styles.borderView} />;
    };
    const renderPlayersView = (item: any) => {
      const { attributes } = item;
      return (
        <View style={[styles.flexDirectionRow, styles.playerCellContainer]}>
          <CPlayerCell index={null} playerData={attributes} navigation={navigation} showPercentage={false} />
        </View>
      );
    };
    return (
      <View style={styles.fullWidth}>
        <FlatList
          testID="playersList"
          data={playerData}
          renderItem={({ item }) => renderPlayersView(item)}
          ListHeaderComponent={flatListItemSeparator}
          ListFooterComponent={flatListItemSeparator}
          ItemSeparatorComponent={flatListItemSeparator}
          ListHeaderComponentStyle={styles.marginTop20}
        />
      </View>
    );
  };

const styles = StyleSheet.create({
  fullWidth: {
    width: "100%",
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  playerCellContainer: {
    alignItems: "center",
    padding: 20,
  },
  borderView: {
    height: 1,
    width: "100%",
    backgroundColor: "#2C314B",
  },
  marginTop20: {
    marginTop: 20,
  },
});

export default CPlayerList;