Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "bx_block_events/events/";
exports.dashboradRankingUrl = "bx_block_events/player_group_rank?id=";
exports.dashboarContentType = "application/json";
exports.getMethod = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = "";
exports.labelBodyText = "dashboard Body";
exports.ongoingEventDetailScreenHeaderTitle = "ONGOING EVENT DETAILS";
exports.eventDetailsApiURL = "bx_block_events/v1/events/";
exports.eventMatchesApiURL="bx_block_events/v1/event_schedules?id="
exports.eventPlayersApiURL="bx_block_events/v1/event_players?id="
// Customizable Area End
