import { StyleSheet } from "react-native";
import scale, { verticalScale } from "../../../components/src/Scale";

// Customizable Area Start
import { getFontFamily } from "framework/src/Utilities";
export default StyleSheet.create({
  container: {
    backgroundColor: "rgba(0, 0, 36, 1)",
    flex: 1,
  },

  eventBg: {
    height: scale(531),
    alignSelf: "flex-end",
    backgroundColor: "#000000",
    marginLeft: scale(105),
  },

  header: {
    marginTop: verticalScale(59),
    paddingHorizontal: scale(23),
    justifyContent: "space-between",
    flexDirection: "row",
  },

  backBtn: {
    marginTop: verticalScale(3),
    width: scale(17),
    height: scale(20),
  },

  addEventText: {
    color: "#ffffff",
    fontSize: scale(20),
    alignSelf: "center",
    fontWeight: "700",
  },

  logoImage: {
    width: scale(29),
    height: scale(21),
    marginTop: verticalScale(4),
  },
  locationView: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginRight: 20,
  },
  addressView: {
    paddingBottom: 20,
    paddingLeft: 20,
    marginTop: 10
  },
  eventCellView: {
    width: scale(380),
    borderRadius: scale(21),
    backgroundColor: "rgba(44, 49, 75, 1)",
    marginTop: verticalScale(15),
    marginLeft: 16,
    // paddingLeft: 20,
    paddingBottom: 10
  },
  posterImage: {
    justifyContent: "center",
    alignItems: "center",
    width: "100 %",
    borderRadius: 8,
    height: 200,
  },
  eventCellText: {
    fontSize: scale(14),
    color: "#ffffff",
    textAlign: "left",
  },

  eventCellImage: {
    width: scale(24),
    height: scale(20),
    marginLeft: scale(19),
  },

  forwardBtn: {
    width: scale(11),
    height: scale(15),
    marginRight: scale(20),
  },
  headerContainer: {
    width: "100%",
    marginTop: -verticalScale(20),
    backgroundColor: "rgba(27, 27, 58, 1)",
    borderBottomRightRadius: 16,
    paddingHorizontal: scale(20)
  },
  labelTitle: {
    width: 326,
    minWidth: 326,
    marginTop: 18,
    marginBottom: 14,
    minHeight: 40,
    height: 40,
    color: "rgba(255, 255, 255, 1)",
    fontSize: 32,
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: 40,
    letterSpacing: -0.16,
  },
  labelView: {
    flexDirection: "row",
    marginTop: verticalScale(50),
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: verticalScale(15),
  },
  championshipIcon: {
    height: 16,
    width: 16,
  },
  rankView:{ justifyContent: "flex-end", marginTop: 5, marginLeft: 5 },
  championshipButton: {
    marginLeft: -20,
    flexDirection:"row",
    alignItems:"center"
  },
  locationButton: {
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 5,
    marginBottom: 10,
    alignItems: 'center'
  },
  locationIcon: {
    marginRight: 10,
    width: 24,
    minWidth: 24,
    marginBottom: 0,
    minHeight: 24,
    height: 24,
    //backgroundColor: "rgba(0, 0, 0, 0)",

  },
  downArrowIcon: {
    marginLeft: -240,
    width: 30,
    minWidth: 24,
    marginBottom: 0,
    minHeight: 24,
    height: 30,
    marginTop: 6,
    resizeMode: "contain"
  },
  locationText: {
    marginLeft: 10,
    width: 326,
    minWidth: 326,
    marginTop: 0,
    marginBottom: 14,
    minHeight: 40,
    height: 40,
    color: "rgba(255, 255, 255, 1)",
    fontSize: 20,
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: 40,
    letterSpacing: -0.16,
  },
  eventNumberText: {
    marginLeft: 20,
    marginTop: 10
  },
  locationTxt: {
    color: "white",
    fontSize: 18,
    fontWeight: "700",
    fontFamily: "FuturaPT-Bold",
    textAlign: "left",
    fontStyle: "normal",
    marginLeft: 20,
  },
  dateText: {
    color: "white",
    fontSize: 16,
    fontWeight: "600",
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    fontStyle: "normal",
    marginLeft: 20,
    marginTop: 5
  },
  viewDetailsButton: {
    backgroundColor: "rgba(255, 117, 27, 1)",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center"
  },
  eventHeadingView: { paddingLeft: 20, marginTop: 10, width: 190 },
  dateView: { marginTop: 10 },
  btnView: { marginTop: 10, marginRight: 5 },
  viewDetailsText: {
    color: "black",
    fontSize: 18,
    fontWeight: "700",
    fontFamily: getFontFamily("bold"),
    fontStyle: "normal",
    paddingHorizontal: 20
  },
  addressText: {
    color: "white",
    fontSize: 16,
    fontWeight: "400",
    fontFamily: getFontFamily("light"),
    fontStyle: "normal",
    marginTop: 10,
    marginBottom: 15,
    marginLeft: 20
  },
  dropDownView: {
    backgroundColor: "rgba(44, 49, 75, 1)",
    marginLeft: 20,
    width: 250,
    height: 120,
    paddingHorizontal: 20,
    justifyContent: "center",
    borderRadius: 10,
    position: "absolute",
    marginTop: 140,
    zIndex: 1,
    paddingVertical: 10
  }
});
// Customizable Area End
