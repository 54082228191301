import React, { Component } from 'react';
import {
    StyleSheet,
    Image,
    View
} from "react-native";
import { resetPassword } from '../../assets';
import { CText } from '../CText/CText';
import CButton from '../CButton/CButton';
import CAppBackground from '../CAppBackground/CAppBackground';
import Scale, { verticalScale } from '../../Scale';

interface Props {
    mainHeading: string;
    subHeading: string;
    buttonText: string;
    testID: string;
    onPress: any
}

export default class CVerify extends Component<Props> {


    render() {
        const { mainHeading, subHeading, buttonText, onPress, testID } = this.props
        return (
            <>
                <View style={styles.subContainer}>
                    <Image source={resetPassword} resizeMode="contain" />
                    <View style={styles.textView}>
                        <CText style="white" size="xl3" weight="bold">
                            {mainHeading}
                        </CText>
                        <CText textAlign="center" style="white" size="x" weight="light">
                            {subHeading}
                        </CText>
                    </View>
                </View>
                <View style={styles.loginContainer}>
                    <CButton
                        testID={testID}
                        onPress={onPress}
                        type="button"
                        style={"primary"}
                        size="big"
                    >
                        {buttonText}
                    </CButton>
                </View>
            </>
        )
    }
}

const styles: any = StyleSheet.create({

    subContainer: {
        padding: 20,
        flex: 0.7,
        justifyContent: "center",
        alignItems: "center"
    },
    textView: { marginVertical: 40, alignItems: "center" },
    loginContainer: {
        position: "absolute",
        bottom: 0,
        justifyContent: "center",
        width: "100%",
        marginBottom: 50,
        paddingHorizontal: Scale(24)
    },
});




