import React, { Component } from 'react';
import { Text } from "react-native";
import Scale from '../../Scale';
import { getFontFamily } from 'framework/src/Utilities';

export type CTextProps = {
  children: any;
  style: string;
  size: string;
  weight: string;
  numberOfLines?: number;
  textAlign?: 'center' | 'left' | 'right' | 'auto',
  lineHeight?: number;
  transform?: 'capitalize' | 'lowercase' | 'none' | 'uppercase',
  onPress?: () => void;
}

class CText extends Component<CTextProps> {
  getStyles() {
    const { style, size, weight, textAlign, lineHeight, transform } = this.props;
    return lineHeight ? {
      textStyles: {
        ...styles[style],
        ...styles[size],
        ...styles[weight],
        ...styles[`textAlign_${textAlign}`],
        ...styles[`text_${transform}`],
        lineHeight: Scale(lineHeight)
      }
    } : {
      textStyles: {
        ...styles[style],
        ...styles[size],
        ...styles[weight],
        ...styles[`textAlign_${textAlign}`],
        ...styles[`text_${transform}`],
      }

    }
  }

  render() {
    const { children, numberOfLines } = this.props;
    const { textStyles } = this.getStyles()
    return <Text numberOfLines={numberOfLines} style={textStyles} onPress={this.props.onPress}>{children}</Text>
  }
}

export { CText }

export const styles: any = ({
  white: {
    color: "rgba(255, 255, 255, 1)",
  },
  primary: {
    color: '#FF751B',
  },
  red: {
    color: '#FF002F',
  },
  blue: {
    color: "#000024"
  },
  gray: {
    color: "#9BA2B7"
  },
  black: {
    color: "#000000"
  },
  lightGray: {
    color: "#CBD1E1"
  },
  disableTextColor: {
    color: '#4C5064'
  },
  placeholderColor: { color: "rgba(105,105,105,0.9)" },
  xl3: {
    fontSize: Scale(32),
  },
  xl4: {
    fontSize: Scale(34),
  },
  xl32: { fontSize: Scale(32), },
  xl: {
    fontSize: Scale(22),
  },
  x: {
    fontSize: Scale(20),
  },
  sm16: { fontSize: Scale(16), },
  sm: {
    fontSize: Scale(16),
  },
  xs: {
    fontSize: Scale(14),
  },
  base: {
    fontSize: Scale(18),
  },
  base18: { fontSize: Scale(18) },

  xxl: {
    fontSize: Scale(26),
  },
  bold: {
    fontFamily: getFontFamily("bold"),
  },
  light: {
    fontFamily: getFontFamily('light'),
  },
  lineHeight24: {
    lineHeight: Scale(24)
  },
  lineHeight18: {
    lineHeight: Scale(14)
  },
  textAlign_center: { textAlign: 'center' },
  textAlign_left: { textAlign: 'left' },
  textAlign_right: { textAlign: 'right' },
  textAlign_auto: { textAlign: 'auto' },
  xxs: {
    fontSize: Scale(12),
  },
  xxxs: {
    fontSize: Scale(10),
  },
  dullWhite: {
    color: "#FEF3C7"
  },
  text_capitalize: { textTransform: "capitalize" },
  text_lowercase: { textTransform: "lowercase" },
  text_none: { textTransform: "none" },
  text_uppercase: { textTransform: "uppercase" },
});
