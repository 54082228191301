import React from "react";
// Customizable Area Start
import { StyleSheet, Image, View } from "react-native";
import Scale from "../../Scale";
import { getFontFamily } from "framework/src/Utilities";
import { CText } from "..";
import Icon from "react-native-vector-icons/MaterialIcons";
import SelectDropdown from "react-native-select-dropdown";
import { GenderItem } from "../types";
// Customizable Area End

import CGenderPickerController from "./CGenderPickerController";

export default class CGenderPicker extends CGenderPickerController {
  render() {
    // Customizable Area Start
    return (
      <View
        style={{ paddingHorizontal: Scale(10), marginVertical: Scale(10) }}>
        <CText style="white" size="sm" weight='bold' >Gender</CText>
        {/* <SelectDropdown
          defaultValue={this.state.defaultValue}
          data={this.state.genderList}
          onSelect={(item: GenderItem) => {
            return this.props.onChangeGender(item)
          }}
          buttonStyle={styles.dropDownContainer}
          defaultButtonText={this.props.value ? this.state.defaultValue : "Gender"}
          buttonTextStyle={[styles.dropDownTxtContainer, { color: this.props.value ? "#fff" : "#4C5064", fontFamily: this.props.value ? getFontFamily("bold") : getFontFamily("light") }]}
          buttonTextAfterSelection={(selectedItem: any) => {
            return selectedItem.name;
          }}
          renderDropdownIcon={(isOpened: any) => {
            return <Icon name="arrow-drop-down" size={30} color="#747B92" />
          }}
          renderCustomizedRowChild={(item) =>
            <CText style="lightGray" size="sm" weight="light" children={item.name} />
          }
          dropdownIconPosition={'right'}
          dropdownStyle={styles.dropdnSty}
          rowStyle={styles.rowStyle}
          rowTextStyle={styles.rowTextStyle}
          selectedRowStyle={styles.rowSelectedStyle}
          selectedRowTextStyle={styles.rowSelectedTextStyle}
          showsVerticalScrollIndicator={false}
        /> */}
      </View>
    );
    // Customizable Area End
  }
}
const styles = StyleSheet.create({
  // Customizable Area Start
  container: {
    flex: 1,
    flexGrow: 1,
    padding: 16,
    backgroundColor: "#020a24",
  },
  errorText: {
    fontSize: Scale(12),
    color: "rgba(220, 96, 104, 1)",
    fontWeight: '700',
    marginTop: Scale(5)
  },
  headerTitle: {
    marginBottom: 32,
    fontSize: 32,
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    color: "#fff",
    marginVertical: 8,
  },
  dropdnIconSty: { marginRight: 5 },
  dropdnSty: {
    backgroundColor: '#2C314B',
    borderBottomWidth: 0,
    paddingHorizontal: Scale(10),
    borderRadius: Scale(8)
  },
  modalView: {
    width: "50%",
    height: 250,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  ImageStyleI13706181319447531945055: {
    marginLeft: 0,
    width: 24,
    minWidth: 24,
    marginTop: 0,
    marginBottom: 0,
    minHeight: 24,
    height: 24,
    backgroundColor: "rgba(0, 0, 0, 0)",
    flex: 1, resizeMode: 'contain'
  },

  imgInnerViewSty: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  label: {
    fontSize: 16,
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    color: "#fff",
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B1B3A",
    color: "#fff",
    marginTop: 5,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#747B92",
    borderRadius: 10,
    includeFontPadding: true,
    padding: 15,
  },
  dropDownContainer: {
    width: '100%',
    backgroundColor: "#1B1B3A",
    color: "#fff",
    marginTop: 5,
    borderWidth: 1,
    borderColor: "#747B92",
    borderRadius: 10,

  },
  dropDownTxtContainer: {
    fontSize: 16,
    textAlign: "left",
  },
  rowStyle: {
    width: '100%',
    backgroundColor: "#2C314B",
    borderBottomWidth: 0,
    paddingHorizontal: Scale(10)
  },
  rowTextStyle: {
    fontSize: 16,
    textAlign: "left",
    color: "#747B92",
    backgroundColor: "#2C314B",
  },
  rowSelectedStyle: {
    backgroundColor: "#4C5064",
    width: '100%',
    borderRadius: 12,
    padding: 8,
  },
  rowSelectedTextStyle: {
    backgroundColor: "#4C5064",
  },
  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },
  button: {
    width: '100%',
    height: 60,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
    backgroundColor: "#4C5064",

  },
  buttonEnabled: {
    backgroundColor: 'transparent',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#2196F3',
  },
  buttonDisabled: {
    backgroundColor: '#D3D3D3',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D3D3D3',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  phoneInputContainer: {
    borderWidth: 1,
    borderColor: '#747B92',
    borderRadius: 10,
    width: '100%',
    backgroundColor: "#1B1B3A",
  },
  phoneInputTextContainer: {
    paddingVertical: 6,
    borderRadius: 10,
    borderColor: '#747B92',
    backgroundColor: "#1B1B3A",
  },
  phoneInputText: {
    fontSize: 16,
    color: "#fff",
  },
  flagButton: {
    width: "40%",
    justifyContent: "center",
  },
  countryPickerButton: {

  },
  closeButtonImage: {
    tintColor: 'red',
    width: 20,
    height: 20,
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  keyboardPadding: { flex: 1 },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  viewcon1: { flex: 1 },
  itemView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  unlockView: {
    borderWidth: 1,
    borderColor: '#747B92',
    borderRadius: Scale(16),
    paddingHorizontal: Scale(8),
    paddingVertical: Scale(4)
  }
  // Customizable Area End
});