import React, { Component } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { CText } from '../CText/CText';

interface myProps {
  array?: any;
  onSelect?: any;
  testID?: any;
  customVerticalPadding?: boolean;
  customFont?: any;
}

interface State {
  selectedTab: number;
}

class CToggleComponent extends Component<myProps, State> {
  constructor(props: myProps) {
    super(props);
    this.state = {
      selectedTab: 1
    };
  }

  handleTabPress = (itemId: number) => {
    this.setState({ selectedTab: itemId });
    this.props.onSelect(itemId);
  };

  render() {
    const { array, testID, customVerticalPadding, customFont } = this.props;
    const { selectedTab } = this.state;

    return (
      <View style={styles.tabView}>
        {array.map((item: any) => (
          <TouchableOpacity
            testID={testID}
            key={item.id}
            onPress={() => this.handleTabPress(item.id)}
            style={[
              styles.tabButtonActive,
              {
                backgroundColor: item.id === selectedTab ? "white" : "#1B1B3A",
                width: array.length === 2 ? "47%" : "30%",
                paddingVertical: customVerticalPadding ? 10 : 6,
              }
            ]}
          >
            <CText
              style={item.id === selectedTab ? 'red' : 'white'}
              size={customFont || "xl"}
              weight="bold"
            >
              {item.title}
            </CText>
          </TouchableOpacity>
        ))}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  tabView: {
    marginTop: 24,
    zIndex: 1,
    backgroundColor: "#1B1B3A",
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "space-evenly",
    borderRadius: 50,
    padding: 5,
  },
  tabButtonActive: {
    borderRadius: 50,
    alignItems: "center",
    justifyContent: 'center',
    paddingHorizontal: 5,
  },
});

export default CToggleComponent;
