import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  StatusBar
} from "react-native";
import { edit, person1 } from "./assets";
import Scale from "../../../components/src/Scale";
import { configJSON } from "./UserProfileBasicController";
import { getFontFamily, getStorageData } from "../../../framework/src/Utilities";
import CCoverPhoto from "../../../components/src/custom-components/CCoverPhoto/CCoverPhoto";
import Loader from "../../../components/src/Loader";
// @ts-ignore
// import { NavigationEvents } from "react-navigation";
import {
  CGuestPopup,
  CMenuItem,
  CProfileAvatar,
  CStatusBar,
  CText
} from "../../../components/src/custom-components";
// Customizable Area End

import ViewUserProfileController from "./ViewUserProfileController";

export default class ViewUserProfile extends ViewUserProfileController {
  render() {
    // Customizable Area Start
    return (
      //Required for all blocks
      <KeyboardAvoidingView testID="avoidingView"
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.flex1}
      >
        {/* Required for all blocks */}
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <StatusBar translucent barStyle="light-content" />
          {/* <NavigationEvents onWillFocus={() => this.handleAPICall()} /> */}
          <TouchableWithoutFeedback
            testID="hideKeyBoard"
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            {this.state.isLoading ? (
              <>
                <CStatusBar
                  backgroundColor="rgba(0, 0, 36, 0)"
                  barStyle="light-content"
                />
                <Loader loading={this.state.isLoading} />
              </>
            ) : (
              <View>
                <CCoverPhoto
                  coverPhoto={this.state.userDetails.background_image}
                  coverPhotoHeight={Scale(180)}
                  icons={
                    this.state.userDetails.user_type !== "player"
                      ? [
                          {
                            testID: "editProfile",
                            path: edit,
                            position: "topRight",
                            style: "bright",
                            onPress: () =>
                              this.props.navigation.navigate(
                                "UserProfileBasicBlock",
                                { screenType: "user" }
                              )
                          }
                        ]
                      : []
                  }
                />

                <View style={styles.marginBottom111}>
                  <CProfileAvatar
                    image={this.getURI() === person1 ? null : this.getURI().uri}
                    isCenter={true}
                  />
                  <View style={styles.profilePicView}>
                    <View style={styles.nameSpacing}>
                      <CText
                        style="white"
                        size="xxl"
                        weight="bold"
                        children={`${this.state.userDetails.first_name} ${
                          this.state.userDetails.last_name
                        }`}
                      />
                    </View>
                    {this.state.userDetails.user_type === "player" ? (
                      <TouchableOpacity
                        testID="viewProfile"
                        onPress={() => {
                          this.props.navigation.navigate("ViewPlayerProfile", {
                            comesFrom: "profile",
                            id: this.state.userID
                          });
                        }}
                      >
                        <CText
                          style="primary"
                          size="sm"
                          weight="bold"
                          children={"View Profile"}
                        />
                      </TouchableOpacity>
                    ) : (
                      <CText
                        style="white"
                        size="sm"
                        weight="light"
                        children={this.state.userDetails.email}
                      />
                    )}
                  </View>

                  {this.state.userDetails.user_type === "player" && (
                    <View style={styles.gradientSubWrapper}>
                      <View style={styles.gradientDetailWrapper}>
                        <CText
                          style="primary"
                          size="xl3"
                          weight="bold"
                          children={this.state.userDetails.age}
                          lineHeight={41}
                        />
                        <CText
                          style="white"
                          size="xxs"
                          weight="light"
                          children={`Age`}
                          lineHeight={22}
                        />
                      </View>

                      <View style={styles.gradientDetailWrapper}>
                        <CText
                          style="primary"
                          size="xl3"
                          weight="bold"
                          children={this.state.userDetails.uts_rank}
                          lineHeight={41}
                        />
                        <CText
                          style="white"
                          size="xxs"
                          weight="light"
                          children={`UTS Rank`}
                          lineHeight={22}
                        />
                      </View>

                      {this.state.userDetails.nationality_image && (
                        <View style={styles.gradientDetailWrapper}>
                          <Image
                            style={styles.flag}
                            source={{
                              uri: this.state.userDetails.nationality_image
                            }}
                          />
                          <CText
                            style="white"
                            size="xxs"
                            weight="light"
                            children={`Nationality`}
                            lineHeight={22}
                          />
                        </View>
                      )}
                    </View>
                  )}

                    {/* <View style={styles.marginBottom42}>
                      <Text style={styles.titleLabel}>
                        {configJSON.account}
                      </Text>
                      <View style={styles.separator} />
                      <CMenuItem
                        icon={{
                          name: "attach-money",
                          type: "MaterialIcons",
                          color: ""
                        }}
                        onPressFunction={() => {
                          this.props.navigation.navigate("InAppPurchaseDetailes");
                        }}
                        showNextArrow={false}
                        title={{
                          children: configJSON.subscription,
                          style: "white",
                          size: "base",
                          weight: "bold",
                          lineHeight: 24
                        }}
                        subTitle={{
                          children: configJSON.allAccess,
                          style: "white",
                          size: "sm",
                          weight: "light",
                          lineHeight: 24
                        }}
                        testID={"subscriptionItem"}
                      />
                    </View> */}

                  <View style={styles.marginBottom42}>
                    <Text style={styles.titleLabel}>
                      {configJSON.accountActions}
                    </Text>
                    <View style={styles.separator} />
                    <CMenuItem
                      icon={{
                        name: "exit-to-app",
                        type: "MaterialIcons",
                        color: ""
                      }}
                      onPressFunction={() => {
                        this.askLogout();
                      }}
                      showNextArrow={false}
                      title={{
                        children: "Log out",
                        style: "white",
                        size: "base",
                        weight: "bold",
                        lineHeight: 24
                      }}
                      testID={"logoutItem"}
                    />
                    <CMenuItem
                      icon={{
                        name: "delete-outline",
                        color: "#FF002F",
                        type: "MaterialCommunityIcons"
                      }}
                      onPressFunction={() => this.askDeleteAccount()}
                      showNextArrow={false}
                      title={{
                        children: "Delete account",
                        style: "red",
                        size: "base",
                        weight: "bold",
                        lineHeight: 24
                      }}
                      testID={"deleteAccountItem"}
                    />
                  </View>
                </View>
              </View>
            )}
            {/* Customizable Area End */}
            {/* Merge Engine UI Engine Code */}
          </TouchableWithoutFeedback>
        </ScrollView>
        <CGuestPopup testID="guestpopUp"
          isVisible={this.state.showGuestPopup}
          isVisibleFunction={this.handleGuestPollPopup}
          onPressSignUpFunction={this.handleGuestSignUpNavigation}
          subject={"Profile"}
        />
      </KeyboardAvoidingView>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleAPICall();
    // Customizable Area End
  }

  // Customizable Area Start
  handleAPICall = async () => {
    const token = await getStorageData("TOKEN");
    const userType = await getStorageData("USERTYPE");
    if (userType === "guest") {
      this.setState({ isLoading: true, showGuestPopup: true });
    } else {
      this.setState(
        {
          token: token,
          userType: userType
        },
        () => {
          this.getUserProfile();
        }
      );
    }
  };
  // Customizable Area End
}
// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    backgroundColor: "#000024"
  },
  image: {
    height: Scale(190),
    width: "100%",
    backgroundColor: "#0085FF",
    paddingHorizontal: Scale(20),
    marginBottom: Scale(130)
  },
  headerView: {
    flexDirection: "column",
    alignItems: "flex-end"
  },
  editViewStyle: {
    marginTop: Scale(40),
    width: Scale(32),
    height: Scale(32),
    borderRadius: Scale(32 / 2),
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center"
  },
  editImageIcon: {
    height: Scale(24),
    width: Scale(24)
  },
  avatarView: {
    width: Scale(128),
    height: Scale(128),
    borderRadius: Scale(100),
    justifyContent: "center",
    backgroundColor: "#E2E6F0",
    overflow: "hidden",
    marginTop: -Scale(85),
    alignSelf: "center"
  },
  profilePicView: {
    alignSelf: "center",
    alignItems: "center",
    marginBottom: Scale(30)
  },
  nameSpacing: {
    marginTop: Scale(20),
    marginBottom: Scale(3)
  },
  nameTxt: {
    marginTop: Scale(20),
    fontSize: Scale(26),
    color: "#fff",
    fontFamily: getFontFamily("bold")
  },
  emailTxt: {
    marginTop: Scale(8),
    fontSize: Scale(16),
    color: "#CBD1E1",
    fontFamily: getFontFamily("light")
  },
  titleLabel: {
    fontSize: Scale(16),
    fontWeight: "700",
    color: "#9BA2B7",
    fontFamily: getFontFamily("bold"),
    marginBottom: Scale(10),
    marginHorizontal: Scale(20)
  },
  separator: {
    height: 1,
    width: "100%",
    backgroundColor: "#4C5064"
  },
  optionView: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: Scale(20),
    paddingVertical: Scale(10)
  },
  optionIcon: {
    height: Scale(36),
    width: Scale(36),
    backgroundColor: "#FF751B26",
    borderRadius: Scale(36 / 2),
    justifyContent: "center",
    alignItems: "center"
  },
  optionIconImage: {
    height: Scale(25),
    width: Scale(25)
  },
  titleTxt: {
    fontSize: Scale(18),
    fontWeight: "700",
    color: "#fff",
    fontFamily: getFontFamily("bold"),
    marginLeft: Scale(15)
  },
  premiumImgBGStyle: {
    marginHorizontal: Scale(24),
    borderRadius: 8,
    backgroundColor: "#FF751B",
    tintColor: "#FF751B",
    height: "auto",
    width: "auto",
    marginBottom: Scale(32)
  },
  flag: {
    width: Scale(38),
    height: Scale(24),
    resizeMode: "contain",
    marginBottom: 12
  },
  profileGuestOverlayStyle: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "auto",
    backgroundColor: "white",
    borderTopRightRadius: Scale(24),
    elevation: 5
  },
  profilePopupStickyIcon: {
    position: "absolute",
    top: 5,
    right: 5
  },
  profilePopupTextWrapper: { padding: 24 },
  profileMainGuestTitle: {
    marginTop: 24,
    marginBottom: 10,
    alignItems: "center"
  },
  profileSubGuestTitle: { marginBottom: 40 },
  profileGuestNotnow: { marginVertical: 24, alignItems: "center" },
  flex1: { flex: 1 },
  marginBottom111: { marginBottom: Scale(111) },
  userDetailWrapper: {
    marginBottom: Scale(24),
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: Scale(24)
  },
  playerItemWrapper: {
    alignItems: "center",
    backgroundColor: "#2C314B",
    borderRadius: 16,
    paddingHorizontal: Scale(29),
    paddingVertical: Scale(17)
  },
  alignCenter: { alignItems: "center" },
  marginBottom42: { marginBottom: Scale(42) },
  avatarPlaceholderWrapper: {
    overflow: "hidden",
    marginTop: -Scale(85),
    alignSelf: "center",
    alignItems: "center",
    height: 128,
    width: 128,
    borderRadius: 100,
    backgroundColor: "#1B1B3A",
    justifyContent: "center"
  },
  avatarPlaceHolderView: {
    height: 83,
    width: 83,
    borderRadius: 100,
    borderWidth: 5,
    borderColor: "#FF751B",
    alignItems: "center",
    justifyContent: "center"
  },
  avatarPlaceholderImage: { height: 40, width: 40, resizeMode: "contain" },
  gradientSubWrapper: {
    marginBottom: Scale(40),
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: Scale(24)
  },
  gradientDetailWrapper: {
    alignItems: "center",
    backgroundColor: "#2C314B",
    borderRadius: 16,
    paddingHorizontal: Scale(29),
    paddingVertical: Scale(17),
    justifyContent: "center"
  },
  flexRow: { flexDirection: "row" }
});
// Customizable Area End
