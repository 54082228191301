import React from 'react';
import Modal from 'react-native-modal';
import {
  StyleSheet,
  StyleProp,
  ViewStyle,
  SafeAreaView,
  Dimensions,
} from 'react-native';
import Scale from '../../Scale';


export interface Props {
  ref?: any;
  title?: string;
  data?: Array<any>;
  callback?: () => void;
  onTouchOutside: () => void;
  visible: boolean;
  allowMultiple?: false;
  showDone?: true;
  onDone?: () => void;
  mainView?: any;
  viewStyle?: ViewStyle;
  customStyle?: ViewStyle;
  isNewDesign?: true;
  showBottomSheet?: any;
  customListItem?: false;
  listItem?: () => void;
  closeAction?: () => void;
  testID?: string
  cscreenWidth?: any
  cscreenHeight?: any
}
let screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;

const CModal = ({
  ref,
  title = '',
  data = [],
  callback = () => { },
  onTouchOutside = () => { },
  visible = false,
  allowMultiple = false,
  showDone = true,
  onDone = () => { },
  mainView = null,
  viewStyle,
  customStyle,
  isNewDesign = true,
  showBottomSheet = true,
  customListItem = false,
  listItem = () => { },
  closeAction = undefined,
  testID = '',
  cscreenWidth,
  cscreenHeight,
  ...props
}: Props) => {
  const styles = StyleSheet.create({
    modelTitle: {
      fontSize: 22,
      color: 'white',
      paddingHorizontal: Scale(20),
      paddingTop: Scale(20),
      paddingBottom: isNewDesign ? Scale(20) : Scale(10),
    },
    styleView: viewStyle ?? {
      backgroundColor: 'white',
      borderRadius: isNewDesign ? Scale(10) : Scale(0),
    },
    modelStyle: customStyle
      ? customStyle
      : showBottomSheet
        ? {
          justifyContent: 'flex-end',
          margin: Scale(0),
        }
        : {
          justifyContent: 'center',
        },
  });

  const renderView = () => {
    return mainView;
  };

  return (
    // <Modal
    //   testID={testID}
    //   ref={ref}
    //   isVisible={visible}
    //   deviceHeight={cscreenHeight ?? screenHeight}
    //   deviceWidth={cscreenWidth ?? screenWidth}
    //   onBackButtonPress={onTouchOutside}
    //   onBackdropPress={onTouchOutside}
    //   style={styles.modelStyle}
    //   {...props}>
    //   <SafeAreaView>{renderView()}</SafeAreaView>
    // </Modal>
    <></>
  );
};

export default CModal;