import React, { createRef } from "react";
import { StyleSheet, View, Image, TouchableWithoutFeedback, TouchableOpacity } from "react-native";
import { BlockComponent } from "framework/src/BlockComponent";
import { ic_profile } from "../../../../blocks/newsfeed/src/assets";
import Scale from "../../Scale";
import { CText } from "../CText/CText";
import { ListItem } from "react-native-elements";
import { getCommentCreatedTime } from "../../../../blocks/utilities/src/TimeUtilities";
import CLike from "../CLike/CLike";
import CComment from "../CComment/CComment";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { Menu, MenuTrigger, MenuOptions, MenuOption } from "react-native-popup-menu";

interface Props {
  testId?: any;
  account_photo?: any;
  created_at?: any;
  userName?: any;
  commentText?: any;
  onPress?: any;
  postId?: any;
  likeCount?: any;
  handleIsLiked?: any;
  onSelectHeart?: any;
  onHeartInitialStatus?: any;
  commentCount?: number;
  isCommentCountVisible: boolean;
  onPressComment?: () => void;
  timerPosition: "name" | "top" | "bottom";
  btnVisible: boolean;
  paddingLeft?: "paddingLeft24" | "paddingLeft48";
  backgroundColor?: "Gray" | "LightGray";
  onBtnPressOpenMenu?: () => void;
  onBtnPressCloseMenu?: () => void;
  onPressFullView?: () => void;
  toolTipVisible?: boolean;
  onTapToolTip?: any;
  menuItemText?: string;
  menuItemIcon?: string;
  menuItemTextStyle?: string;
  isUsedInForumScreen?: boolean;
  separator?: boolean;
  titleFont?: string;
  itemId?: any;
}

interface S {
  timer: string;
  commentCount: number;
}

interface SS { }

export default class CCommentsView extends BlockComponent<Props, S, SS> {

  timerInterval: NodeJS.Timeout | null = null;
  menuRef = createRef<Menu>();

  constructor(props: Props) {
    super(props);
    this.state = {
      timer: "",
      commentCount: this.props.commentCount ?? 0
    };
  }

  async componentDidMount() {
    this.updateTimer();
    this.timerInterval = setTimeout(this.updateTimer, 1000);
  }

  async componentWillUnmount() {
    if (this.timerInterval) {
      clearTimeout(this.timerInterval);
    }
  }

  updateTimer = () => {
    const { created_at } = this.props;
    const countdown = getCommentCreatedTime(created_at);
    this.setState({ timer: countdown });
    this.timerInterval = setTimeout(this.updateTimer, 1000);
  };

  getStyles() {
    const { paddingLeft, backgroundColor } = this.props;
    return paddingLeft && backgroundColor ?
      {
        containerStyle: {
          ...styles["containerComments"],
          ...styles[paddingLeft],
          ...styles[backgroundColor],
        }

      } :
      {
        containerStyle: {
          ...styles["containerComments"],
          ...styles["paddingLeft24"],
          ...styles["Gray"],
        }

      }
  }

  render() {
    const { containerStyle } = this.getStyles()
    const commentTextWithoutNewlines = this.props.commentText.replace(/\n/g, '');
    return (
      <TouchableWithoutFeedback onPress={this.props.onPressFullView} >
        <View style={styles.container}>
          <ListItem
            bottomDivider={this.props.separator}
            containerStyle={containerStyle}
            leftElement={
              <View style={styles.profileImgContainer}>
                <Image source={this.props.account_photo ? { uri: this.props.account_photo } : ic_profile} style={styles.profileImg} />
              </View>
            }
            title={
              <View style={styles.titleViewSty}>
                <View style={styles.nameTimeViewSty}>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <CText style="white" size={this.props.titleFont ? this.props.titleFont : "xl"} weight="bold">{this.props.userName}</CText>
                    {this.props.timerPosition === "name" &&
                      <>
                        <View style={{ marginHorizontal: Scale(10), height: Scale(4), width: Scale(4), borderRadius: Scale(2), backgroundColor: "#ffffff", alignSelf: "center" }} />
                        <CText style="white" size="xs" weight="light">{this.state.timer}</CText>
                      </>
                    }
                  </View>
                  {this.props.timerPosition === "top" ?
                    <CText style="white" size="base" weight="light">{this.state.timer}</CText>
                    : this.props.btnVisible === true ?
                      <></>
                      : null
                  }
                </View>
                <CText style="white" size="base" weight="light" >{commentTextWithoutNewlines}</CText>
                <View style={styles.iconViewSty}>
                  <View style={{ flexDirection: "row", flex: 1 }}>
                    <CLike
                      testId={this.props.testId}
                      onPress={this.props.onSelectHeart}
                      initialStatus={this.props.onHeartInitialStatus}
                      likeCount={this.props.likeCount}
                      isUsedInForum={this.props.isUsedInForumScreen}
                    />
                    {this.props.isCommentCountVisible &&
                      <View style={{ marginLeft: Scale(30), justifyContent: "center", alignItems: "center" }}>
                        <CComment commentCount={this.props.commentCount} onPress={this.props.onPressComment} isUsedInForum={this.props.isUsedInForumScreen} />
                      </View>}
                  </View>
                  {this.props.timerPosition === "bottom" &&
                    <CText style="white" size="xs" weight="light">{this.state.timer}</CText>
                  }
                </View>
              </View>
            }
          />
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerComments: {
    paddingVertical: Scale(15),
    paddingRight: Scale(24),
  },
  Gray: {
    backgroundColor: "#000024",
  },
  LightGray: {
    backgroundColor: "#1B1B3A",
  },
  paddingLeft24: {
    paddingLeft: Scale(24),
  },
  paddingLeft48: {
    paddingLeft: Scale(48),
  },
  profileImgContainer: {
    width: Scale(50),
    height: Scale(50),
    borderRadius: Scale(25),
    overflow: 'hidden',
    alignSelf: 'flex-start'
  },
  profileImg: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 50
  },
  titleViewSty: {
    flex: 1,
    marginTop: Scale(-5),
  },
  nameTimeViewSty: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: Scale(5),
  },
  iconViewSty: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: Scale(10)
  },
  likeCountText: {
    fontSize: Scale(14),
    color: 'white',
    marginLeft: Scale(5),
  },
  threeDotView: {
    width: Scale(24),
    height: Scale(24),
  },
  optionContainerMenu: {
    marginTop: Scale(20),
    backgroundColor: '#2C314B',
    borderColor: '#000000',
    borderRadius: 10,
    borderWidth: 1,
    paddingTop: Scale(10),
    paddingBottom: Scale(10)
  },
  innerTooltipView: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconFlag: {
    marginLeft: Scale(10),
    marginRight: Scale(10)
  },
});