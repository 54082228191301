// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationApiPostMethodType = "POST";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";
exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.subscription = "Subscription";
exports.currentPlan = "Current Plan ";
exports.price = "₹00.00 ";
exports.continue = "Continue";
exports.proceedToPay = "Buy Now";
exports.noSubscribedText = "You Don't have Subscribed Yet!";
exports.buySubscription = "Buy Subscription";
exports.daysLeft = "days left" ;
exports.validationIOSReceiptApiEndPoint = "bx_block_subscriptions/verify_app_store_receipt";
exports.storeReceiptApiEndPoint = "bx_block_inapppurchasing/store_transaction_details";
exports.packageNameInBody = "com.ContactPro1";
exports.sharedSecretCode = "774dd4205ffd437bbfe71d63594b9c02";
exports.getSubscriptionDetailsEndPoint = "bx_block_payments/subscription_details";
exports.fetchRemainingDaysEndPoint = "bx_block_payments/remaining_days_for_subscription";
exports.planNameTag = "Plan Name :- ";
exports.descTag = "Description :- "
exports.loginToken = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTQzMSwiZXhwIjoxNzEyMzkyODQyLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.4XX2_rdbKblPQ1I7KHlZQpOJu5VVg7mg9nHCKxOPn6Qlyucfw1GWcqxkT3mAiWWhGoOQR0swnt0orZ1P09_Slg";

exports.subscription = "Subscription"
exports.cancelSubscription = "Cancel Subscription"
exports.updatePlan = "Update Plan"
exports.cancelSubscriptionTitle = "Are you sure you want to cancel your subscription?"
exports.cancelSubscriptionDescription = "Cancelling your subscription means losing access to the Front Row Experience"
exports.notNow = "Not now"
exports.cancelSubscriptionToastMessage = "Your subscription has been successfully canceled. You will continue to have access until "
exports.choosePlan = "Choose Plan"
exports.monthly = "Monthly"
exports.yearly = "Yearly"
exports.currentPlan = "Current Plan"
exports.nextBillingPlan = "Next billing date:"
exports.continueToPayment = "Continue to  payment"
exports.benefit1 = "Ask questions directly to players";
exports.benefit2 = "Become a part of an exciting community";
exports.frequency = "Frequency:"
exports.expirationDate = "Expiration date:"
exports.billingDate = "Billing date:"
exports.nextBillingDate = "Next billing date:"
exports.amount = "Amount:"
exports.updateSubscriptionToastMessage = "Your subscription has been successfully updated!"

// Customizable Area End
