import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React, { RefObject } from "react";
import BottomSheet from "@gorhom/bottom-sheet";
import { sendAPIRequest } from "../../../framework/src/Utilities";
// import ImagePicker from "react-native-image-crop-picker";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  edtEmailEnabled: boolean;
  userProfileData: any;
  isLoading: boolean;
  avatarPicked: any;
  editType: "profile" | "bg" | "playerProfile";
  bgPath: string;
  userType: string;
  genderType: string;
  // Customizable Area End

}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiCallMessageUpdateProfileRequestId: any;
  userProfileGetApiCallId: any;
  verifyUpdatedEmailapiCallId: any;
  userAttr: any;
  bottomSheetRef: RefObject<BottomSheet>;
  snapPoints: string[];
  updateUserPhotoApiCallId: string = "";
  removeUserPhotoApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.bottomSheetRef = React.createRef();
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      token: "",
      edtEmailEnabled: true,
      userProfileData: {},
      isLoading: false,
      avatarPicked: null,
      editType: "profile",
      bgPath: "",
      userType: "",
      genderType: ""
    };

    this.snapPoints = ["0.1%", "43%"];

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.userProfileGetApiCallId:
            this.getUserDataRes(responseJson, errorReponse);
            break;
          case this.apiCallMessageUpdateProfileRequestId:
            this.getUpdateProfileRequestRes(responseJson);
            break;
          case this.verifyUpdatedEmailapiCallId:
            this.getVerifyUpdateEmailRes(responseJson);
            break;
          case this.updateUserPhotoApiCallId:
            this.getUpdateUserPhotoRequestRes(responseJson);
            break;
          case this.removeUserPhotoApiCallId:
            this.getRemovedUserPhotoRequestRes(responseJson);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getUserDataRes = (responseJson: any, errorReponse: any) => {
    if (
      !responseJson.errors &&
      responseJson.data &&
      responseJson.data.attributes
    ) {
      //On User Profile Success

      this.userAttr = responseJson.data.attributes;

      if (this.userAttr !== null && this.userAttr !== undefined) {
        this.setState({
          userProfileData: this.userAttr,
          isLoading: false,
          edtEmailEnabled: true
        });
      }
    } else {
      //Check Error Response
      this.setState({ isLoading: false });
      if (
        responseJson.errors &&
        responseJson.errors.length > 0 &&
        responseJson.errors[0].token
      ) {
        this.showAlert("Session Expired", "Please Log in again.");
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    this.parseApiCatchErrorResponse(errorReponse);
  };

  getUpdateProfileRequestRes = (responseJson: any) => {
    if (responseJson != null && responseJson.errors === undefined) {
      //On Change Phone Validation Success

      this.showAlert("Success", "Profile updated successfully.");
      this.getUserProfile();
    } else {
      this.setState({ isLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  };

  getVerifyUpdateEmailRes = (responseJson: any) => {
    if (
      responseJson != null &&
      responseJson.errors === undefined &&
      responseJson.data &&
      responseJson.data.attributes &&
      responseJson.data.attributes.email
    ) {
      this.showAlert("Success", "Email updated successfully.");
      this.setState({ edtEmailEnabled: false });
      this.getUserProfile();
    } else {
      this.setState({ isLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  };

  getUpdateUserPhotoRequestRes = (responseJson: any) => {
    if (responseJson != null && responseJson.errors === undefined) {
      // on update user photo successfully

      this.showAlert("Success", this.state.editType === "bg" ? "Background photo updated successfully." : "User photo updated successfully.");
      this.getUserProfile();
    } else {
      this.setState({ isLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  };

  getRemovedUserPhotoRequestRes = (responseJson: any) => {
    if (responseJson !== null && responseJson.errors === undefined) {
      this.showAlert("Success", this.state.editType === "bg" ? "Background photo removed successfully." : "User photo removed successfully.");
      this.getUserProfile();
    } else {
      this.setState({ isLoading: false });
      this.parseApiErrorResponse(responseJson);
    }
  };

  validateAndUpdateProfile = (values: any) => {
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUserPhoto,
      token: this.state.token
    };
    let userID: string = this.state.userProfileData.account_id || ""
    const data = new FormData();
    if (this.state.userType !== "player") {
      data.append(`profile[first_name]`, values.firstName);
      data.append(`profile[last_name]`, values.lastName);
      data.append(`profile[gender_id]`, values.gender);
      data.append(`profile[phone_number]`, values.phoneNumber);
      data.append(`profile[country_code]`, values.countryCode);
      data.append(`profile[city]`, values.city);
      data.append(`profile[country_id]`, values.country);
      data.append(`profile[email]`, values.email);
    } else {
      data.append(`profile[first_name]`, values.firstName);
      data.append(`profile[last_name]`, values.lastName);
      data.append(`profile[user_name]`, values.gameName);
      data.append(`profile[country_id]`, values.country);
      data.append(`profile[email]`, values.email);
    }

    const apiEndPoint = configJSON.updateProfileApiEndpoint + userID;
    const method = configJSON.apiUpdateUserType;
    this.apiCallMessageUpdateProfileRequestId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header,
      body: data
    });
  };

  getUserProfile() {
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: this.state.token
    };
    const apiEndPoint = configJSON.GetUserProfileApiEndPoint;
    const method = configJSON.methodTypeApiGetUserProfile;
    this.userProfileGetApiCallId = sendAPIRequest(apiEndPoint, {
      method,
      headers: header
    });
  }

  verifyUpdatedEmail = (email: string) => {
    if (this.state.edtEmailEnabled) {
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: this.state.token
      };
      const httpBody = {
        email: email
      };

      const apiEndPoint = configJSON.verifyUpdatedEmailApiEndPoint;
      const method = configJSON.apiUpdateUserType;
      this.verifyUpdatedEmailapiCallId = sendAPIRequest(apiEndPoint, {
        method,
        headers: header,
        body: httpBody
      });
    }
  };

  onEditPhoto = (type: "profile" | "bg" | "playerProfile") => {
    this.hideKeyboard();
    this.setState({ editType: type });
    if (this.bottomSheetRef.current) {
      this.bottomSheetRef.current.expand();
    }
  };

  onChosseImage = () => {
    // ImagePicker.openPicker({
    //   width: 300,
    //   height: 400,
    //   mediaType: "photo"
    // }).then(async image => {
    //   console.log(image);
    //   this.requestUpdateUserProfile(image);
    // });
  };

  openCamera = () => {
    // ImagePicker.openCamera({
    //   width: 300,
    //   height: 400,
    //   mediaType: "photo"
    // }).then(async image => {
    //   console.log(image);
    //   this.requestUpdateUserProfile(image);
    // });
  };

  requestUpdateUserProfile = async (pickerImageResponse: any) => {
    this.closeBottomSheet();
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUserPhoto,
      token: this.state.token
    };

    const data = new FormData();
    data.append(
      `profile[${this.state.editType === "bg" ? "background_image" : "photo"}]`,
      JSON.parse(
        JSON.stringify({
          uri: pickerImageResponse.path,
          type: "image/png",
          name: "image"
        })
      )
    );

    const updateImageRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateUserPhotoApiCallId = updateImageRequestMessage.messageId;
    updateImageRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updatePhotoApiEndpoint + this.state.userProfileData.account_id
    );

    updateImageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    updateImageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    updateImageRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(
      updateImageRequestMessage.id,
      updateImageRequestMessage
    );
  };

  requestRemoveUserProfile = async () => {
    this.closeBottomSheet();
    this.setState({ isLoading: true });
    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUserPhoto,
      token: this.state.token
    };

    const data = new FormData();
    data.append(
      `${this.state.editType === "bg"
        ? "remove_background_image"
        : "remove_photo"
      }`,
      "true"
    );

    const removePhotoRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeUserPhotoApiCallId = removePhotoRequestMessage.messageId;
    removePhotoRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeImageApiEndpoint
    );

    removePhotoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    removePhotoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    removePhotoRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethodType
    );

    runEngine.sendMessage(
      removePhotoRequestMessage.id,
      removePhotoRequestMessage
    );
  };

  getAvatarUri = () => {
    if (this.state.avatarPicked) {
      return { uri: this.state.avatarPicked.uri };
    }
    if (this.state.userProfileData.photo) {
      return { uri: this.state.userProfileData.photo };
    }
    return { uri: null };
  };

  closeBottomSheet = () => {
    this.bottomSheetRef.current!!.close();
  };

  // Customizable Area End

}
