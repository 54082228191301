Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First Name";
exports.errorFName = "First Name must contain only alphabets";
exports.lastName = "Last Name";
exports.errorLastName = "Last Name must contain only alphabets";
exports.gameName = "Game Name";
exports.errorGameName = "Game Name must contain only alphabets";
exports.gender = "Gender";
exports.phoneNumber = "Phone Number";
exports.city = "City";
exports.country = "Country";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.email = "Change Email"
exports.labelEmail = "Email";
exports.errorValidEmail = "Enter a valid email";
exports.errorEmailRequired = "Email is required";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.editBG = "Edit Background";
exports.GetUserProfileApiEndPoint = "bx_block_profile/user_profile";
exports.verifyUpdatedEmailApiEndPoint = "/account_block/accounts/change_email_address";
exports.updateProfileApiEndpoint = "/bx_block_profile/profiles/";
exports.verifyLabel = "Verify";
exports.saveLabel = "Save";
exports.putMethodType = "PUT";
exports.logoutUserApiEndPoint = "/account_block/accounts/logout";
exports.deleteUserAccountApiEndPoint = "/account_block/accounts/delete";
exports.deleteMethodType = "DELETE";
exports.accountActions = "ACCOUNT ACTIONS";
exports.logout = "Log out";
exports.deleteAccount = "Delete account";
exports.editAvatar = "Edit photo";
exports.editProfileMessage = "Edit profile picture";
exports.takePhoto = "Take photo";
exports.photoLibrary = "Photo Library";
exports.removePhoto = "Remove photo";
exports.cancel = "Cancel";

exports.contentTypeApiUpdateUserPhoto = 'multipart/form-data; ';
exports.updatePhotoApiEndpoint = '/bx_block_profile/profiles/';
exports.changePasswordHeaderLabel = "CHANGE PASSWORD";
exports.changePasswordApiEndpoint = "/bx_block_profile/password_update";
exports.PUTMethod = "PUT";
exports.methodTypeApiGetPlayerProfile = "GET";
exports.removeImageApiEndpoint = "/bx_block_profile/delete_image";

exports.account = "ACCOUNT";
exports.subscription = "Subscription";
exports.allAccess = "All access";
exports.genderLabel="Gender"
exports.Nationality="Nationality"
// Customizable Area End

