Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.timeout = 4000
exports.bottomLine = "Powered by "
exports.percentageText = "20%"
exports.contendType = "application/json"
exports.loggedInUserDataURL = "/account_block/accounts/logged_user"
exports.getMethod = "GET"
// Customizable Area End