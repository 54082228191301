import React, { ReactNode } from 'react';
import { Dimensions, Image, Keyboard, StyleProp, StyleSheet, TouchableOpacity, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';
import Scale from '../../Scale';
import { CText } from '../CText/CText';
import { crossIcon } from '../../assets';
import CButton from '../CButton/CButton';

interface CModalContainerPropsType {
    children: ReactNode;
    style?: StyleProp<ViewStyle>;
    headerText?: any;
    cancelText?: any;
    submitText?: any;
    closeBtnOnPress?: any;
    clearBtnOnPress?: any;
    submitBtnOnPress?: any;
    submitBtnDisable?: any;
    submitBtnStyle?: any;
    testID?:string;
}

let screenWidth = Dimensions.get("window").width;
let screenHeight = Dimensions.get("window").height;

const CModalContainer = ({
    children,
    style,
    headerText,
    cancelText,
    submitText,
    closeBtnOnPress,
    clearBtnOnPress,
    submitBtnOnPress,
    submitBtnDisable,
    submitBtnStyle
}: CModalContainerPropsType) => {
    return (
        <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
            <View style={[styles.CModalContainer, style]}>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginHorizontal: Scale(20),
                    marginVertical: Scale(20),
                    alignItems: 'center'
                }}>
                    <CText size="xl" style="white" weight='bold' numberOfLines={1}>
                        {headerText}
                    </CText>
                    <TouchableOpacity onPress={closeBtnOnPress}>
                        <Image
                            style={{
                                height: screenHeight * 0.025,
                                width: screenWidth * 0.06,
                                tintColor: "#FFFFFF",
                            }}
                            source={crossIcon}
                        />
                    </TouchableOpacity>
                </View>
                <View style={{ height: 1, backgroundColor: '#2C314B' }}></View>
                {children}
                <View style={{
                    flexDirection: 'row',
                    justifyContent: "flex-end",
                    marginHorizontal: Scale(10),
                    marginVertical: Scale(20)
                }}>
                    <CButton
                        type="button"
                        size="big"
                        btnTxtStyle={{color:'#FF002F'}}
                        onPress={clearBtnOnPress}
                    > {cancelText} </CButton>
                    <CButton
                        type="button"
                        size="big"
                        style={submitBtnStyle}
                        onPress={submitBtnOnPress}
                        btnTxtStyle={{color:'#FFFFFF'}}
                        disabled={submitBtnDisable}
                    > {submitText} </CButton>
                </View>
            </View>
        </TouchableWithoutFeedback>
    );
};


export default CModalContainer;

const styles = StyleSheet.create({
    CModalContainer: {
        width: screenHeight * 0.45,
        maxHeight: screenHeight * 0.7,
        backgroundColor: '#010125',
        borderRadius: Scale(10),
        borderColor: 'white',
        borderWidth: 1,
        alignSelf:'center'
    },
});