import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: { "id": string, "type": string, "attributes": { "total_candidates": number, "sub_attributres": { "type": string, "quantity": string }[] } };
  token: string;
  errorMsg: string;
  loading: boolean;
  activeToggleButton: any;
  toggleOptions: any[];
  eventID: any,
  eventPlayerRanking: any
  isLoading: boolean
  eventDetailsData: any
  eventMatchData: any[]
  eventPlayersData: any[]
  openModal: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  apigetPlayerGroupRankId: string = "";
  eventsDetailsId: string = "";
  matchDetailsId: string = "";
  matchPlayersId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: { "id": "0", "type": "candidate", "attributes": { "total_candidates": 0, "sub_attributres": [{ "type": "Interview with client", "quantity": "0" }] } },
      errorMsg: "",
      token: "",
      loading: false,
      eventID: "",
      eventDetailsData: [],
      eventMatchData: [],
      eventPlayersData: [],
      eventPlayerRanking: {},
      activeToggleButton: 1,
      toggleOptions: [
        { id: 1, title: 'Players' },
        { id: 2, title: 'Schedule' },
        { id: 3, title: 'Ranking' }
      ],
      isLoading: true,
      openModal: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.handleAPICall()
    // Customizable Area End
  }
  // Customizable Area Start


  handleAPICall = async () => {
    const token = await getStorageData("TOKEN");
    let eventId = this.props.navigation.state.params.eventId
    this.setState({ token: token, eventID: eventId, });

    this.getEventsDetails()
    this.getMatchDetails()
    this.getPlayersDetails()
    this.getPlayerGroupRankAPI()
  }




  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getEventsDetails = async () => {
    this.eventsDetailsId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.eventDetailsApiURL + this.state.eventID,
    })
  };
  getMatchDetails = async () => {
    this.matchDetailsId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.eventMatchesApiURL + this.state.eventID,
    })
  };
  getPlayersDetails = async () => {
    this.matchPlayersId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.eventPlayersApiURL + this.state.eventID,
    })
  };
  getPlayerGroupRankAPI = async () => {
    this.apigetPlayerGroupRankId = await this.apiCall({
      contentType: configJSON.dashboarContentType,
      method: configJSON.getMethod,
      endPoint: configJSON.dashboradRankingUrl + this.state.eventID,
    })
  };
  eventDetailsSuccessBlock = (responseJson: any) => {
    if (responseJson?.data) {
      this.setState({
        isLoading: false,
        eventDetailsData: responseJson?.data?.attributes
      })
    }
  }
  eventmatchDetailsSuccessBlock = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        isLoading: false,
        eventMatchData: responseJson
      })
    }
  }

  eventmatchPlayersSuccessBlock = (responseJson: any) => {
    if (responseJson?.length) {
      this.setState({
        isLoading: false,
        eventPlayersData: responseJson
      })
    }
  }

  playerRankingSuccessBlock = (responseJson: any) => {
    if (responseJson?.data) {

      this.setState({ isLoading: false, eventPlayerRanking: responseJson });
    }
  }
  // Customizable Area End
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )

      message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.eventsDetailsId) {
          this.eventDetailsSuccessBlock(responseJson)
        }
        else if (apiRequestCallId === this.matchPlayersId) {
          this.eventmatchPlayersSuccessBlock(responseJson)
        }
        else if (apiRequestCallId === this.matchDetailsId) {
          this.eventmatchDetailsSuccessBlock(responseJson)
        }
        else if (apiRequestCallId === this.apigetPlayerGroupRankId) {
          this.playerRankingSuccessBlock(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start



  // Customizable Area End

}
