import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allEventData: any;
  onGoingEventData: any;
  isShowMap: boolean;
  toggleValue: boolean | undefined;
  eventNumber: string;
  labelTitleText: string;
  locationText: string;
  viewDetails: string;
  upcomingEventData: any;
  isDropDown: boolean;
  locationList: any;
  locationSelected: boolean;
  allEventsTotalCount: string;
  ongoingTotalCount: string;
  tab: { id: number, title: string }[];
  selectedBtn: number;
  // Customizable Area End
}

interface SS {
  id: any;
}
export default class EventsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiPhoneLoginCallId: any;
  private readonly errorTitle = "Error";
  getTermsCondsCallId: string = "";
  getAllEventsCallId: string = "";
  getAllLocationCallId: string = "";
  getOngoingEventsCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage)
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ]);

    this.state = {
      // Customizable Area Start
      isShowMap: false,
      allEventData: [],
      onGoingEventData: [],
      upcomingEventData: [],
      toggleValue: false,
      eventNumber: configJSON.eventNumber,
      labelTitleText: configJSON.labelTitleText,
      locationText: configJSON.locationText,
      viewDetails: configJSON.viewDetails,
      isDropDown: false,
      locationList: [],
      locationSelected: false,
      allEventsTotalCount: "",
      ongoingTotalCount: "",
      tab: [
        { id: 1, title: 'All Events' },
        { id: 2, title: 'Ongoing' }
      ],
      selectedBtn: 1
      // Customizable Area End
    };
  }
  // Customizable Area Start

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      )

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      )
      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      )

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getAllEventsCallId) {
          this.setState({ allEventData: responseJson.data })
        } else if (apiRequestCallId === this.getAllLocationCallId) {
          this.setState({
            locationList: responseJson.locations
          })
        } else if (apiRequestCallId === this.getOngoingEventsCallId) {
          this.setState({ upcomingEventData: responseJson.data })
        }
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  }

  getAllEvents = async () => {
    const token = await getStorageData('TOKEN');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllEventsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsCondsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOngoingEvents = async () => {
    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOngoingEventsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOngoingEvent
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getLocation = async () => {
    const token = await getStorageData('TOKEN');
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllLocationCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.locationEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    this.getAllEvents()
    this.getLocation()
    this.getOngoingEvents()
  }

  handleDropDown = () => {
    if (this.state.isDropDown === false) {
      this.setState({ isDropDown: true, locationSelected: false })
    } else {
      this.setState({ isDropDown: false })
    }
  }

  handleRedirection = (status: string, id: string) => {
    if (status == "ongoing") {
      this.props.navigation.navigate("Dashboard", { eventId: id })
    } else {
      const msg: Message = new Message(
        getName(MessageEnum.NavigationEventDetailScreenMessage)
      );
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      msg.addData(getName(MessageEnum.EventIdMessage), id);
      this.send(msg);
    }
  }
  // Customizable Area End
}
