import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
  Platform,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Keyboard,
} from "react-native";
import { Formik } from "formik";
import Scale from "../../../components/src/Scale";
import CountryCodeComponent from "../../../components/src/CustomCountryCode/CustomCountryCode";
import { CAppBackground, CStatusBar, CText, CInput, CButton, CGenderPicker, CCountryDropDown } from "../../../components/src/custom-components";
import { CountryItem, GenderItem } from "../../../components/src/custom-components/types";
import { getFontFamily } from "framework/src/Utilities";
// Customizable Area End

import AccountCreation03FormFilledController, {
  configJSON,
  Props,
} from "./AccountCreation03FormFilledController.web";

export default class AccountCreation03FormFilled extends AccountCreation03FormFilledController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const initialValues = {
      firstName: "",
      lastName: "",
      phoneNumber: '',
      gender: "",
      city: "",
      country: "",
    };
    return (
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={styles.keyboardPadding}>
        <CAppBackground>
          <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
            <CStatusBar backgroundColor="rgba(0, 0, 36, 0)" barStyle="light-content" />
            <TouchableWithoutFeedback testID={"Background"} style={styles.subContainer} onPress={Keyboard.dismiss} >
              <View style={styles.viewcon1}>
                <View style={styles.heading}>
                  <CText style="white" size="xl3" weight='bold' >Create an account</CText>
                </View>
                <View style={styles.SafeAreaView}>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={this.profile_Validation}
                    onSubmit={(values) => {
                      this.createanAccountApi(values)
                    }}
                    validateOnBlur={true}
                    validateOnChange={true}
                  >
                    {({ values, handleChange, handleBlur, errors, handleSubmit, setFieldValue, touched, dirty, isValid }) => (
                      <>
                        <CInput
                          label={configJSON.firstNameLabel}
                          value={values.firstName}
                          placeholder={configJSON.firstNamePlaceholder}
                          onChangeText={(text: any) => {
                            handleChange("firstName")(text.trim())
                          }}
                          errorText={''}
                          onBlur={handleBlur('firstName')}
                          testID={"txtInputFirstName"}
                        />
                        <CInput
                          label={configJSON.lastNameLabel}
                          value={values.lastName}
                          placeholder={configJSON.lastNamePlaceholder}
                          onChangeText={(text: any) => { handleChange("lastName")(text.trim()) }}
                          errorText={''}
                          onBlur={handleBlur('lastName')}
                          testID={"txtInputLasttName"}
                        />

                        <CGenderPicker
                          label={configJSON.genderLabel}
                          testId="GenderDropDown"
                          onChangeGender={(data: GenderItem) => {
                            handleChange("gender")(data.id.toString())
                          }}
                          value={values.gender}
                        />

                        <View style={styles.countryDropdn}>
                          <CountryCodeComponent
                            testID={"county-code-select"}
                            lable={configJSON.phoneNumberLabel}
                            selectDropdown={''}
                            values={values.phoneNumber}
                            onChangeText={handleChange("phoneNumber")}
                            onBlur={handleBlur('phoneNumber')}
                            onseleted={(data: any) => { this.setState({ countryCode: data.countryCode }) }}
                            countryCode={(data: any) => { setFieldValue("countryCode", data) }} />
                        </View>

                        <CInput
                          label={configJSON.cityLabel}
                          value={values.city}
                          placeholder={configJSON.cityPlaceholder}
                          onChangeText={(text: any) => this.handleCityChange(handleChange, text)}
                          errorText={''}
                          onBlur={handleBlur('city')}
                          testID={"txtInputCity"} />

                        <CCountryDropDown
                          label={configJSON.countryLabel}
                          testId="CountryDropDown"
                          onChangeCountry={(data: CountryItem) => {
                            handleChange("country")(data.id.toString())
                          }}
                          value={values.country}
                        />
                        <View style={styles.btnView}>
                          <CButton
                            onPress={handleSubmit}
                            testID={'ceate-acc-testID'}
                            disabled={(!isValid || !dirty)}
                            type="button"
                            style={(!isValid || !dirty) ? "secondary" : "primary"}
                            size="big">
                            Create
                          </CButton>
                        </View>
                      </>)}
                  </Formik>
                </View>
              </View>
            </TouchableWithoutFeedback>
          </ScrollView>
        </CAppBackground>
      </KeyboardAvoidingView>
    );
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    // Customizable Area End
  }
}
const styles = StyleSheet.create({
  // Customizable Area Start
  SafeAreaView: {
    flex: 1,
  },
  container: {
    flex: 1,
  },
  subContainer: {
    padding: Scale(20)
  },
  errorText: {
    fontSize: Scale(12),
    color: "rgba(220, 96, 104, 1)",
    fontWeight: '700',
    marginTop: Scale(5)
  },
  headerTitle: {
    marginBottom: 32,
    fontSize: 32,
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    color: "#fff",
    marginVertical: 8,
  },
  heading: { marginHorizontal: 10, marginBottom: 15 },
  modalView: {
    width: "50%",
    height: 250,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  ImageStyleI13706181319447531945055: {
    marginLeft: 0,
    width: 24,
    minWidth: 24,
    marginTop: 0,
    marginBottom: 0,
    minHeight: 24,
    height: 24,
    backgroundColor: "rgba(0, 0, 0, 0)",
    flex: 1, resizeMode: 'contain'
  },

  imgInnerViewSty: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  label: {
    fontSize: 16,
    fontFamily: getFontFamily("bold"),
    textAlign: "left",
    color: "#fff",
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
  },
  bgInput: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#1B1B3A",
    color: "#fff",
    marginTop: 5,
    marginBottom: 16,
    borderWidth: 1,
    borderColor: "#747B92",
    borderRadius: 10,
    includeFontPadding: true,
    padding: 15,
  },
  dropDownContainer: {
    width: '100%',
    backgroundColor: "#1B1B3A",
    color: "#fff",
    marginTop: 5,
    borderWidth: 1,
    borderColor: "#747B92",
    borderRadius: 10,
    includeFontPadding: true,
    paddingHorizontal: 15,
    padding: 30,
  },
  dropDownTxtContainer: {
    fontSize: 16,
    textAlign: "left",
    color: "#747B92",

  },
  rowStyle: {
    width: '100%',
    backgroundColor: "#2C314B",
    borderBottomWidth: 0,
  },
  rowTextStyle: {
    fontSize: 16,
    textAlign: "left",
    color: "#747B92",
    backgroundColor: "#2C314B",
  },
  rowSelectedStyle: {
    backgroundColor: "#4C5064",
    width: '100%',
    borderRadius: 12,
    padding: 8,
  },
  rowSelectedTextStyle: {
    backgroundColor: "#4C5064",
  },
  inputWeb: {
    flex: 1,
    flexDirection: "row",
    marginTop: 24,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    includeFontPadding: true,
    borderBottomWidth: 1,
  },
  button: {
    width: '100%',
    height: 60,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
    backgroundColor: "#4C5064",

  },
  buttonEnabled: {
    backgroundColor: 'transparent',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#2196F3',
  },
  buttonDisabled: {
    backgroundColor: '#D3D3D3',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#D3D3D3',
  },
  buttonText: {
    color: 'white',
    fontWeight: 'bold',
  },
  phoneInputContainer: {
    borderWidth: 1,
    borderColor: '#747B92',
    borderRadius: 10,
    width: '100%',
    backgroundColor: "#1B1B3A",
  },
  phoneInputTextContainer: {
    paddingVertical: 6,
    borderRadius: 10,
    borderColor: '#747B92',
    backgroundColor: "#1B1B3A",
  },
  phoneInputText: {
    fontSize: 16,
    color: "#fff",
  },
  flagButton: {
    width: "40%",
    justifyContent: "center",
  },
  countryPickerButton: {

  },
  closeButtonImage: {
    tintColor: 'red',
    width: 20,
    height: 20,
  },

  bgPasswordInput: {
    flex: 1,
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    minHeight: 40,
    includeFontPadding: true,
    marginTop: 10,
    paddingLeft: 0,
  },
  passwordShowHide: {
    alignSelf: "center",
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderWidth: Platform.OS === "web" ? 0 : 1,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    paddingLeft: 5,
    paddingRight: 5,
    zIndex: -1,
  },
  imgPasswordShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {},
  keyboardPadding: { flex: 1, },
  btnLegalTermsAndCondition: { color: "#6200EE" },
  btnLegalPrivacyPolicy: { color: "#6200EE", marginLeft: "auto" },
  leagalText: { marginTop: 10 },
  viewcon1: { flex: 1, padding: Scale(20) },
  countryDropdn: { zIndex: 1, marginHorizontal: 9 },
  btnView: { marginTop: Scale(40) }
  // Customizable Area End
});