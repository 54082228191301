import React, { Component } from "react";
import { View, StyleSheet, Image } from "react-native";
import Scale from "../../Scale";
import { CText, CTextProps } from "../CText/CText";
import { SvgXml } from "react-native-svg";

interface ImageProps {
    type: 'svg' | 'image',
    path: any,
    style?: any
}

interface Props {
    image?: ImageProps;
    title: CTextProps;
    text?: CTextProps | null;
    titleStyle?: any;
    containerStyle?: any;
}

export default class CCenterImageAndText extends Component<Props> {

    getStyles() {
        const { image, titleStyle, containerStyle } = this.props;
        return {
            imageStyles: {
                ...styles.imgStyle,
                ...image?.style,
            },
            containerStyles: {
                ...styles.containerStyle,
                ...containerStyle
            },
            titleStyle: {
                ...styles.mv20,
                ...titleStyle
            },
        }
    }

    render() {
        const { image, title, text } = this.props;
        const { imageStyles, containerStyles, titleStyle, } = this.getStyles()
        return <View style={containerStyles}>
            {image?.type == 'image' ? <Image source={image?.path} style={imageStyles} /> : <SvgXml xml={image?.path} style={imageStyles} />}
            <View style={titleStyle}>
                {title && <CText style={title.style} size={title.size} weight={title.weight} textAlign={title.textAlign} >{title.children}</CText>}
            </View>
            {text && <CText style={text.style} size={text.size} weight={text.weight} textAlign={text.textAlign} >{text.children}</CText>}
        </View>
    }
}

const styles = StyleSheet.create({
    containerStyle: { alignItems: 'center' },
    mv20: {
        marginVertical: Scale(20),
        marginHorizontal: Scale(10)
    },
    imgStyle: {
        height: Scale(50),
        width: Scale(50),
    },
});