import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { Alert } from "react-native";
import { isEmail, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  emailID: string;
  isValid: boolean;
  showErrorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiForgotPasswordCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      emailID: "",
      isValid: false,
      showErrorMsg: ""
    };
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>) {
    if (prevState.emailID != this.state.emailID) {
      let testEmail = isEmail("Email", this.state.emailID);
      if (testEmail.message == "") {
        this.setState({ isValid: true });
      } else {
        this.setState({ isValid: false });
      }
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.apiForgotPasswordCallId) {
          if (responseJson.meta) {
            setStorageData("VERIFY_TOKEN", responseJson.meta.token);
            this.props.navigation.replace("OTPinputCheck", {
              email: this.state.emailID,
              fromSignup: false,
            });
          } else {
            this.setState({ showErrorMsg: "Invalid email address. Please try again." })
            // Alert.alert(responseJson.errors[0].profile);
          }
        }
      }
    }
  }

  onEmailChange = (text: string) => {
    this.setState({ emailID: text });
  };

  forgotPasswordAPI = () => {
    const header = {
      "Content-Type": "multipart/form-data"
    };

    let formData = new FormData();
    formData.append("email", this.state.emailID);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiForgotPasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordOTP
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  // Customizable Area End
}
