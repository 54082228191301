// Customizable Area Start
import React from "react";

import {
  StyleSheet,
  Text,
  TouchableOpacity,
  SafeAreaView
} from "react-native";
import Scale from "../../../components/src/Scale";

// Merge Engine - import assets - Start
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start
let artBoardHeightOrg = 667;
let artBoardWidthOrg = 375;
// Merge Engine - Artboard Dimension  - End

import InapppurchasingController, {
  Props,
  configJSON,
} from "./InapppurchasingController";

export default class Inapppurchasing extends InapppurchasingController {
  constructor(props: Props) {
    super(props);
  }


  render() {
    // Merge Engine - render - Start
    return (
      <SafeAreaView style={styles.mainContainer}>
      <TouchableOpacity 
      testID="bugSubscriptionButton"
      style={styles.button} 
      onPress={() => this.gotoSubscriptionListViewScreen()}
      >
        <Text style={styles.welcomeText}>{configJSON.buySubscription}</Text>
      </TouchableOpacity>
    </SafeAreaView>
    );
    // Merge Engine - render - End
  }
}

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex : 1 
  },
  button: {
    borderWidth: 0.5,
    width: "50%",
    height: 50,
    justifyContent: "center",
    alignItems :  'center',
    backgroundColor: "#000000",
    borderRadius: 10,
  },
  welcomeText: {
    fontSize: Scale(18),
    letterSpacing: Scale(2),
    marginLeft: Scale(20),
    color: "white",
  }
});

// Customizable Area End
