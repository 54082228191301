Object.defineProperty(exports, "__esModule", {
    value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.postAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NewsFeed";
exports.targetedFeedEndpoint = "bx_block_newsfeed/news_feeds";
exports.labelBodyText = "NewsFeed Body";
exports.btnExampleTitle = "CLICK ME";
exports.NewsFeedFlatList = 'NewsFeedFlatList'
exports.allPostsEndPoint = 'bx_block_posts/all_posts';
exports.showPostsEndPoint = 'bx_block_posts/posts';
exports.activeFeedsEndPoint = 'bx_block_posts/active_feeds';
exports.postLikeEndPoint = 'bx_block_like/likes';
exports.postDisLikeEndPoint = 'bx_block_like/destroy';
exports.getApiMethod = 'GET'
exports.filterTabs = [{
   title: 'New',
}, {
   title: 'Trending',
}, {
   title: 'Discover',
}, {
   title: 'Players',
}]
exports.activeFeedsApiContentType = "application/json";
exports.activeFeedsApiEndPoint = '/bx_block_newsfeed/active_feeds?per_page=10&page=';
exports.activeUtsFeedApiEndPoint = '/bx_block_newsfeed/active_feeds_admin?per_page=10&page=';
exports.getReasonsListApiEndPoint = '/bx_block_content_management/reasons_list';
exports.reportThisPostApiEndPoint = '/bx_block_content_management/create_report';
exports.reportableType = "BxBlockPosts::Post";
exports.activeFeedsApiMethod = 'GET';
exports.getHashTagApiEndPoint = "/bx_block_hashtags/uts_tags"
exports.appUpdateEndPoint = 'bx_block_appupdates/get_latest_update'
exports.skipAppUpdateEndPoint = "bx_block_appupdates/update_not_show"
exports.postDeleteEndPoint = "bx_block_posts/posts/"
exports.postDelAPIMethod = "DELETE"
// Customizable Area End
