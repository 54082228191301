import React, { useState } from 'react';
import { View, StyleSheet, Dimensions, FlatList, } from 'react-native';
import Scale from '../../Scale';
import { CText } from '../CText/CText';
import CTooltip from '../CTooltip/CTooltip';
import CPlayerCell from '../CPlayerCell/CPlayerCell';
let screenWidth = Dimensions.get("window").width;
const CPlayerRanking =
  ({ rankingData, navigation, testID }:
    { rankingData: any, navigation: any, testID?: string }) => {
    const [openModal, setOpenModal] = useState(false)
    const renderSeperatorViewForEventsHeaders = () => <View style={{ width: screenWidth * 0.04, }} />
    const renderRankingTableDynamicHeaders = (data: { item: any }) => {
      return (
        <View
          style={{ width: screenWidth * 0.060 }}>
          <CText size="sm" style="white" weight='bold'>
            {data.item.header_key.toUpperCase()}
          </CText>
        </View>
      )
    }

    const renderRankingPlayerWise = (data: any) => (
      <View style={{ width: screenWidth * 0.1 }}>
        <CText size="sm" style="white" weight='light' numberOfLines={1}>
          {data}
        </CText>
      </View>
    )

    const renderPlayerRakingInnerView = (item: any, index: any) => {
      const { data } = item;
      return (
        <View style={[styles.flexDirectionRow, styles.rankingPlayerView]}>
          <View style={[styles.flexDirectionRow, { width: screenWidth * 0.52 }]}>
            <CPlayerCell index={index} playerData={data?.attributes} navigation={navigation} showPercentage={false} />
          </View>
          {renderRankingPlayerWise(item.match_played)}
          {renderRankingPlayerWise(item.won)}
          {renderRankingPlayerWise(item.quater_average)}
          {renderRankingPlayerWise(item.average_points)}
        </View>
      )
    }

    const renderHeader = () => {
      return (
        <CTooltip
          onTapTooltip={openModal}
          fromRanking={true}
          icons={[
            { icon: 'information-outline', iconColor: '#CBD1E1', iconSize: 20, style: { marginRight: Scale(18), marginTop: 2, } }
          ]}
          menuOptionContainerStyle={styles.modalMainContainer}>
          {rankingData?.headers?.map((e: any) => (
            <View style={styles.modalSubContainer}>
              <View style={styles.headerKey}>
                <CText style="white" size="sm" weight="light">
                  {`${e.header_key.toUpperCase()} -`}
                </CText>
              </View>
              <View style={styles.headerText}>
                <CText style="white" size="sm" weight="bold">
                  {`${e.header_text}`}
                </CText>
              </View>
            </View>
          ))}
        </CTooltip>
      )
    }

    const renderRankingTabOuterView = (item: any) => {
      const { attributes } = item;
      return (
        <>
          <View style={styles.dateViewContainer}>
            <View >
              <CText style="white" size="sm" weight="bold" >
                {attributes?.name.toUpperCase()}
              </CText>
            </View>
          </View>
          <View style={styles.playerContainer}>
            <View style={styles.playerView}>
              <CText size="sm" style="white" weight='bold' numberOfLines={1}>
                Player
              </CText>
            </View>
            {renderHeader()}
            <FlatList
              data={rankingData?.headers}
              renderItem={renderRankingTableDynamicHeaders}
              horizontal={true}
              ItemSeparatorComponent={renderSeperatorViewForEventsHeaders}
            />
          </View>
          <FlatList
            style={styles.marginBottom20}
            data={attributes.players}
            renderItem={({ item, index }) => renderPlayerRakingInnerView(item, index)}
            keyExtractor={(item: any) => item.id}
          />
        </>
      );
    };

    return (
      <View style={[styles.fullWidth, styles.marginTop20]}>
        <FlatList
          testID="rankingList"
          data={rankingData?.data}
          renderItem={({ item }) => renderRankingTabOuterView(item)}
          keyExtractor={(item: any) => item.id}
        />
      </View>
    );
  };

const styles = StyleSheet.create({
  flexDirectionRow: {
    flexDirection: "row",
  },
  playerPhotoView: {
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    borderColor: '#FF002F',
  },
  playerCellNameContainer: {
    paddingLeft: 10,
    justifyContent: "center",
    width: 100,
  },
  playerContainer: {
    flexDirection: 'row',
    height: Scale(44),
    backgroundColor: "#1B1B3A",
    paddingTop: 0,
    alignItems: "center",
    paddingHorizontal: 24,
    justifyContent: "center"

  },
  playerView: {
    width: screenWidth * 0.42,
  },
  rankingPlayerView: {
    alignItems: "center",
    paddingHorizontal: 20,
    borderBottomColor: "#2C314B",
    borderTopColor: "#2C314B",
    borderWidth: 1,
    paddingVertical: 10
  },
  marginBottom20: {
    marginBottom: 20
  },
  dateViewContainer: {
    height: Scale(40),
    paddingVertical: 8,
    paddingHorizontal: 24,
    backgroundColor: "#2C314B",
  },
  fullWidth: {
    width: "100%",
  },
  marginTop20: {
    marginTop: 20,
  },
  modal: { justifyContent: "center", alignItems: "flex-end", top: 120, left: 20 },
  modalMainContainer: { position: "absolute", alignSelf: "flex-end", marginTop: Scale(35), padding: 10, borderRadius: 10, backgroundColor: "#2C314B", borderWidth: 0.5, width: 180, height: 230, },
  closeIcon: { left: 100 },
  modalSubContainer: { justifyContent: "space-between", alignSelf: "center", marginVertical: 7, flexDirection: "row" },
  headerKey: { alignItems: "flex-start", width: 50 },
  headerText: { alignItems: "flex-start", width: 70 },
  count: {
    alignSelf: "flex-start", paddingHorizontal: 6, marginHorizontal: 10, marginVertical: 5,
    borderRadius: 8, paddingTop: 0,
  },

});

export default CPlayerRanking;
