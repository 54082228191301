import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { sendAPIRequest } from "../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    labelTitleText: string;
    viewDetails: string;
    visible: boolean;
    token: any;
    userDetails: {},
    getOverAllRankData: any;
    allEvents: any;
    systemScoring: any;
    // Customizable Area End
}

interface SS {
    id: any;
}
export default class EventOverAllRankingController extends BlockComponent<Props, S, SS> {

    playerOverAllRankGetApiCallId: string = "";
    _unsubscribe: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            getOverAllRankData: [],
            allEvents: [],
            systemScoring: [],
            token: "",
            userDetails: {},
            labelTitleText: configJSON.labelTitleText,
            viewDetails: configJSON.viewDetails,
            visible: false
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("on recieive in EventOverAllRanking==>" + JSON.stringify(message));

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponseOverAllRanking = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.playerOverAllRankGetApiCallId) {
                    this.getOverAllRankDetails(responseJson, errorReponseOverAllRanking);
                }
            }
        }
        // Customizable Area End
    }

    handleError = (resError: any) => {
        if (
            resError.errors &&
            resError.errors.length > 0 &&
            resError.errors[0].token
        ) {
            this.showAlert("Session Expired", "Please Log in again.");
        } else {
            this.parseApiErrorResponse(resError);
        }
    }

    getOverAllRankDetails = (response: any, errorRes: any) => {
        if (
            !response.errors &&
            response.data
        ) {
            if (response.data !== null && response.data !== undefined) {
                this.setState({
                    getOverAllRankData: response.data,
                    allEvents: response.events,
                    systemScoring: response.system_scorings,
                });
            }
        } else {
            //Check Error Response
            this.handleError(response);
        }

        this.parseApiCatchErrorResponse(errorRes);
    }

    getOverAllRank() {
        const header = {
            "Content-Type": configJSON.contentTypeApiOverAllRanking,
            token: this.state.token
        };

        const apiEndPoint = configJSON.endPointApiOverAllRanking
        const method = configJSON.methodTypeApiOverAllRanking
        this.playerOverAllRankGetApiCallId = sendAPIRequest(apiEndPoint, {
            method,
            headers: header
        });
    }
}
