import React from 'react';
// Customizable Area Start
import { Text, View, TouchableOpacity, Image, StyleSheet, FlatList, TextStyle } from 'react-native';
import Scale from './Scale';
import { getFontFamily } from 'framework/src/Utilities';

export type DataType = {
  label: string,
  isSelected: boolean,
  index: number,
  id: number | string,
  value: number
}

interface CheckBoxProps {
  data: DataType[];
  returnData: (value: DataType[]) => void
  isHorizontal?: boolean
  textStyle?: TextStyle
  isMultiple?: boolean
  isError?: any
  error?: string
  testID: string
}

const CheckBox = (props: CheckBoxProps) => {

  function onPressCheckBox(e: any, i: any) {
    let itemsArray = [...props.data];
    if (props?.isMultiple) {
      itemsArray[i].index = i;
      itemsArray[i].isSelected = !itemsArray[i].isSelected;
      props.returnData(itemsArray)
    } else {
      itemsArray.map((item, index) => {
        if (index == i) {
          item.isSelected = true;
          item.index = index;
        } else {
          item.isSelected = false;
          item.index = index;
        }
        return item;
      })
      props.returnData(itemsArray)
    }
  };

  return (
    <>
      <FlatList
        data={props?.data}
        contentContainerStyle={styles.contentHorizontalStyle}
        numColumns={1}
        testID={props?.testID}
        renderItem={(val: any) => {
          return (
            <>
              <View style={styles.itemContainer}>
                <TouchableOpacity onPress={() => onPressCheckBox(val?.item, val?.index)} >
                  <Image style={styles.checkBoxStyle} resizeMode='contain'
                    source={val?.item?.isSelected ? require("./selected_rd.png") : require("./unselected_rd.png")} />
                </TouchableOpacity>
                <Text style={[styles.textStyle329468087, props.textStyle]}>{val?.item?.label}</Text>
              </View>
            </>
          )
        }}
      />
      {props.isError && <Text style={styles.error}>{props?.error}</Text>}
    </>
  );
};


const styles = StyleSheet.create({
  checkBoxStyle: {
    height: Scale(20),
    width: Scale(20),
  },
  error: {
    fontSize: Scale(15),
    color: "red",
    marginLeft: Scale(7),
    marginTop: Scale(4),
  },
  itemLabelStyle: {
    fontSize: Scale(16),
  },
  contentHorizontalStyle: {
    // justifyContent: 'space-between',
    // paddingHorizontal: Scale(20),
    // paddingVertical: Scale(20),
  },
  contentStyle: {
    paddingHorizontal: Scale(0),
    paddingVertical: Scale(10),
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: Scale(45),
    paddingBottom: Scale(20),
  },
  textStyle329468087: {
    zIndex: 1,
    marginLeft: Scale(8),
    color: "rgba(255, 255, 255, 1)",
    fontSize: Scale(18),
    fontFamily: getFontFamily("light"),
    textAlign: "left",
    fontStyle: "normal",
    lineHeight: Scale(24),
  },
})

// Customizable Area End

export default React.memo(CheckBox);