import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  seconds: any;
  oneTimeOTP: any;
  errorMessage: any;
  token: string
}

export interface SS {
  id: any;
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  sendOtpCallID: string = "";
  countdownInterval: any;
  apiResendOTPCallId: string = "";
  signUpResendOtpApiCallId: string = "";
  signUpOtpVerifyCallId: string = "";
  otpInput: any;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);

    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      toMessage: "",
      isFromForgotPassword: false,
      seconds: 60,
      oneTimeOTP: "",
      errorMessage: "",
      token: "",
    };
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }
  async componentDidMount(): Promise<void> {
    const token = await getStorageData("VERIFY_TOKEN");

    this.setState({ token: token })
    this.startCountdown();
  }
  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      this.setState(prevState => {
        const newSeconds = prevState.seconds === 0 ? 60 : prevState.seconds - 1;
        if (newSeconds === 0) {
          this.clearCountdown();
        }
        return { seconds: newSeconds };
      });
    }, 1000);
  };
  clearCountdown = () => {
    clearInterval(this.countdownInterval);
  };

  handleOtpVerify = () => {
    let fromSignUp = this.props.navigation.state.params.fromSignup;
    fromSignUp ? this.signUpOtpVerifyApi() : this.OTPVerify();
  }
  clearText = () => {
    this.otpInput?.clear();
  }
  handleResendOtp = () => {
    this.setState({ errorMessage: "" })
    let fromSignUp = this.props.navigation.state.params.fromSignup;
    if (fromSignUp) {
      this.signUpResendOtpApi()
    } else {
      this.resendOTPAPI();
    }
    this.clearText()
    this.startCountdown();
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.sendOtpCallID) {
        this.sendOtpApiSuccessBlock(responseJson);
      }
      if (apiRequestCallId === this.apiResendOTPCallId) {
        this.resendOtpApiSuccessBlock(responseJson);
      }
      if (apiRequestCallId === this.signUpResendOtpApiCallId) {
        this.signResendOtpApiSuccessBlock(responseJson);
      }
      if (apiRequestCallId === this.signUpOtpVerifyCallId) {
        this.signUpOtpVerifyApiSuccessBlock(responseJson);
      }
    }
  }

  sendOtpApiSuccessBlock = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      setStorageData("VERIFY_TOKEN", responseJson.token);
      this.props.navigation.replace("NewPassword");
    } else {
      this.setState({ errorMessage: "Invalid code. Double-check & retry." });
    }
  };
  resendOtpApiSuccessBlock = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      setStorageData("VERIFY_TOKEN", responseJson.token);
    }
  };
  signResendOtpApiSuccessBlock = (responseJson: any) => {
    if (responseJson && !responseJson.error) {
      setStorageData("VERIFY_TOKEN", responseJson.token);
    }
  };
  signUpOtpVerifyApiSuccessBlock = (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      setStorageData("TOKEN", responseJson.meta.token);
      setStorageData('USERTYPE', responseJson.data.attributes.user_type);
      this.props.navigation.replace("PhoneNumberInput");
    } else {
      this.setState({ errorMessage: "Invalid code. Double-check & retry." });
    }
  };
  signUpResendOtpApi = () => {
    let email = this.props.navigation.state.params.email;
    const header = {
      "Content-Type": "application/json"
    };
    const httpBody = {
      user: {
        email: email
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpResendOtpApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpResentOtpURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  signUpOtpVerifyApi = () => {
    let email = this.props.navigation.state.params.email;
    const header = {
      "Content-Type": "application/json"
    };
    const httpBody = {
      user: {
        email: email,
        otp: this.state.oneTimeOTP
      },
      device_token:
        "212323293ff32808dad9bf673b52779ff10a3ee0c1ca277cee58ba7a3f868c9bc6c932",
      device_id: "hjhjhsjhx"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.signUpOtpVerifyCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpVerifyOtpURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  resendOTPAPI = () => {
    const header = {
      "Content-Type": "multipart/form-data"
    };

    let formData = new FormData();
    formData.append("token", this.state.token);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiResendOTPCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordResendOTP
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  OTPVerify = async () => {
    const header = {
      "Content-Type": "multipart/form-data"
    };
    let formData = new FormData();
    formData.append("data[attributes][token]", this.state.token);
    formData.append("data[attributes][otp_code]", this.state.oneTimeOTP);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendOtpCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerifyUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
}
