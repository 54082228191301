import React, { Component } from 'react';
import { StyleSheet, Image, View, FlatList } from 'react-native';
import Scale from '../../Scale';
import CAvatar from '../CAvatar/CAvatar';
import CGradientCheckBox from '../CGradientCheckBox/CGradientCheckBox';
import { profileIconPlaceholder } from '../../../../blocks/events/src/assets';
import { CText } from '../CText/CText';

interface ImageProps {
  type: 'svg' | 'image',
  path: any,
  style?: any
}

interface Props {
  modalShow?: boolean;
  onPressClose?: () => void;
  closeTestID?: string;
  onPressButton?: () => void;
  buttonTestID?: string;
  onPressBottomButton?: () => void;
  bottomButtonTestID?: string;
  image?: ImageProps;
  children?: any;
  renderView?: any;
  filterListData?: any;
  onPressCheckBox?: any;
  handleLoadMoreData?: any;
  onRefreshData?: any
  openedFromForum?: boolean;
  onMomentumScrollBegin?: any;
}

interface S {
  // Customizable Area Start
  itemViewHeight: any;
  // Customizable Area Ends
}

export default class CFilterListItem extends Component<Props, S> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      itemViewHeight: 0
    }
    // Customizable Area End
  }

  render() {
    return (
      <View>
        <FlatList
          testID='filterList'
          data={this.props.filterListData}
          ListEmptyComponent={
            <View style={styles.emptyDataView}>
              <CText style='primary' weight='bold' size='base' children={'No data found'} />
            </View>}
          renderItem={({ item, index }: { item: any, index: number }) => {
            return (
              <>
                <View style={styles.filterItemContainer} onLayout={(event) =>
                  this.setState({ itemViewHeight: event.nativeEvent.layout.height })}>
                  <View>
                    {this.props.openedFromForum ?
                      <CAvatar
                        isGradient
                        openFromForum={this.props.openedFromForum}
                        image={{ size: 'sm', path: item?.attributes?.photo || Image.resolveAssetSource(profileIconPlaceholder).uri }}
                        title={{ children: item?.type === 'admin_user' ? item?.attributes?.full_name : item?.attributes?.user_name, size: 'sm', weight: 'bold', style: 'primary' }}
                        text={{ children: item?.type === 'admin_user' ? "" : item?.attributes?.full_name, size: 'sm', weight: 'light', style: 'white' }}
                      />
                      :
                      <CAvatar
                        image={{ size: 'sm', path: item?.attributes?.photo_url || Image.resolveAssetSource(profileIconPlaceholder).uri }}
                        title={{ children: item?.attributes?.user_name, size: 'sm', weight: 'bold', style: 'primary' }}
                        text={{ children: item?.attributes?.full_name, size: 'sm', weight: 'light', style: 'white' }}
                      />
                    }
                  </View>
                  <View style={styles.checkBoxViewContainer}>
                    <CGradientCheckBox testID="checkBox" isCheckedBox={item?.isSelected} onPress={() => this.props.onPressCheckBox(index)} />
                  </View>
                </View>
                <View style={styles.sepratorView} />
              </>
            )
          }}
          style={{ maxHeight: this.props.filterListData.length !== 0 ? this.state.itemViewHeight * 7 + Scale(10) : Scale(500) }}
          extraData={this.props.filterListData}
          keyExtractor={(item, index) => index.toString()}
          initialNumToRender={10}
          windowSize={5}
          onEndReached={() => this.props.handleLoadMoreData()}
          onEndReachedThreshold={0.1}
          refreshControl={this.props.onRefreshData()}
          onMomentumScrollBegin={this.props.onMomentumScrollBegin}
        />
      </View>
    )
  }
}

const styles: any = StyleSheet.create({
  bottomButtonView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingVertical: Scale(10)
  },
  filterItemContainer: {
    flexDirection: 'row',
    paddingHorizontal: Scale(10),
    paddingTop: Scale(20),
    paddingBottom: Scale(10),
    justifyContent: 'space-between',
  },
  clearButtonText: { paddingRight: Scale(20) },
  sepratorView: { borderWidth: Scale(1), borderColor: 'gray' },
  checkBoxViewContainer: { justifyContent: 'center', right: 0 },
  emptyDataView: {
    height: Scale(500),
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  }
});