import React from 'react';
import { View, StyleSheet, ImageBackground, Linking, Alert, Dimensions } from 'react-native';
import Scale, { verticalScale } from '../../Scale';
import CButton from '../CButton/CButton';
import { CText } from '../CText/CText';
import moment from 'moment';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import { ticketIcon } from '../../../../blocks/events/src/assets';
let screenWidth = Dimensions.get("window").width;

const CEventDetail =
  ({ eventDetailData, isFromDashboard = false }:
    { eventDetailData: any, isFromDashboard: boolean }) => {
    const currentDate = moment();
    const eventStartDate = moment(eventDetailData?.event_start_date);
    const eventEndDate = moment(eventDetailData?.event_end_date);
    const eventTicketUrl = eventDetailData?.ticket_url;
    const daysLeft = eventStartDate.diff(currentDate, "days");
    const handleBookTickets = () => {
      if (eventTicketUrl) {
        Linking.canOpenURL(eventTicketUrl).then((supported) => {
          if (supported) {
            Linking.openURL(eventTicketUrl);
          } else {
            alert("Not able to open the link at the moment. Please try again after some time");
          }
        });
      } else {
        Alert.alert("", "Booking is yet to open. Please try later!");
      }
    }
    return (
      <View style={styles.eventDescriptionContainer}>
        <ImageBackground
          source={eventDetailData?.event_pic_resize ? { uri: eventDetailData?.event_pic_resize } : require('../../../../blocks/events/assets/poster.png')}
          style={styles.eventPosterView}
        >
          <View style={styles.eventPosterContainer}>
            {eventDetailData?.status == "upcoming" ?
              daysLeft >= 1 &&
              <View style={styles.daysLeftView}>
                <CText style="primary" size="sm" weight="bold">
                  {daysLeft > 1 ? `${daysLeft} days ` : `${daysLeft} day `}
                  left!
                </CText>
              </View>
              : null}
          </View>
        </ImageBackground>
        <View style={styles.padding20}>

          <CText style="primary" size="xl32" weight="bold" textAlign='left'>
            {eventDetailData?.name}
          </CText>

          <View
            style={[
              styles.flexDirectionRow,
              styles.paddingBottom15,
              styles.marginTop12,
              { width: screenWidth * 0.85 }
            ]}
          >
            <Icon name="calendar-range" size={Scale(20)} style={styles.dateImageStyle} color={'#FFF'} />
            <CText style="white" size="base" weight="light">
              {`${eventStartDate.format('dddd')}, ${eventStartDate.format("MMMM Do")} - ${eventEndDate.format("MMMM Do")}, ${eventEndDate.format("YYYY")}`}
            </CText>
          </View>
          {!isFromDashboard && <View
            style={[styles.flexDirectionRow, styles.paddingBottom15]}
          >
            <Icon name="clock-outline" size={Scale(20)} style={styles.dateImageStyle} color={'#FFF'} />
            <CText style="white" size="base" weight="light">
              {eventDetailData?.time}
            </CText>
          </View>}
          <View
            style={[styles.flexDirectionRow]}
          >
            <Icon name="map-marker-outline" size={Scale(20)} style={styles.dateImageStyle} color={'#FFF'} />
            <CText style="white" size="base" weight="light">
              {eventDetailData?.address}
            </CText>
          </View>
          {
            eventDetailData.status != "past" &&
            <View style={styles.buttonContainer}>
              <CButton
                testID="bookTicketsBtn"
                style="primary"
                type="button"
                iconSource={ticketIcon}
                iconStyle={styles.iconView}
                size="44"
                onPress={() => {
                  handleBookTickets()
                }}
              >
                Book tickets
              </CButton>
            </View>}
          {/* : null} */}
        </View >
      </View >
    );
  };

const styles = StyleSheet.create({
  eventDescriptionContainer: {
    backgroundColor: "#1B1B3A",
    borderBottomRightRadius: 16,
  },
  eventPosterView: {
    width: "100%",
    height: verticalScale(250),
  },
  eventPosterContainer: {
    position: "absolute",
    top: 14,
    left: 0,
    right: 15,
    bottom: 0,
    alignItems: "flex-end",
  },
  daysLeftView: {
    height: verticalScale(32),
    backgroundColor: "#1B1B3A",
    borderRadius: 26,
    paddingTop: 6,
    paddingHorizontal: 12,
  },
  padding20: {
    paddingHorizontal: 20,
    marginTop: 10,
    marginBottom: 15,
  },
  paddingBottom15: {
    paddingBottom: 10,
    alignItems: "center",
  },
  marginTop12: {
    marginTop: 12,
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  dateImageStyle: {
    marginRight: 10,
  },
  iconView: { width: 20, height: 16, marginRight: 10 },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 10
  }
});

export default CEventDetail;
