export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const locationImg = require("../assets/location_img.png");
export const bellIcon = require("../assets/bell.png");
export const circleIcon = require("../assets/circle.png");
export const crossIcon = require("../assets/cross.png");
export const logo = require("../assets/logo.png");
export const navigationImage = require("../assets/navigation_image.png");
export const squeezemeinBigPicture = require("../assets/right_side_image.png");
export const leftAArrow = require("../assets/left-arrow.png");
export const eyeOpen = require("../assets/eye_open.png");
export const eyeClose = require("../assets/eye_close.png");
export const countryCodeSearchIcon = require("../assets/countryCodeSearchIcon.png");
export const errorSymbol = require("../assets/error_symbol.png");
export const uploadImage = require("../assets/15da93e33c8b83393d6f7f5954ecf688193f1a05.png");
export const arrowRight = require("../assets/7babb14689614af566964262b58849b94e61eb0d.png");
export const togleFalse = require("../assets/togleFalse.png")
export const togleTrue = require("../assets/togleTrue.png")
export const cancelIcon = require("../assets/cancel_icon.png");
export const rightBlackAArrow = require("../assets/right-arrow-black.png");
export const rightWhiteAArrow = require("../assets/right-arrow-white.png");



