import React, { Component } from 'react';
import { SafeAreaView, StatusBar, StyleSheet, View } from "react-native";

interface CStatusBarProps {
  backgroundColor?: string;
  barStyle?: 'default' | 'light-content' | 'dark-content';
}

class CStatusBar extends Component<CStatusBarProps> {
  render() {
    const { backgroundColor, barStyle } = this.props;
    return (
      <View style={[styles.statusBar, { backgroundColor }]}>
        <SafeAreaView>
          <StatusBar translucent backgroundColor={backgroundColor || "#1B1B3A"} barStyle={barStyle || "light-content"} />
        </SafeAreaView>
      </View>
    )
  }
}

export { CStatusBar }
const STATUSBAR_HEIGHT = StatusBar.currentHeight;

const styles: any = StyleSheet.create({
  statusBar: {
    height: STATUSBAR_HEIGHT,
  },
});
