import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, Image, View } from 'react-native';
import Scale from '../../Scale';
import { Overlay } from 'react-native-elements';
import { CText } from '../CText/CText';
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import CButton from '../CButton/CButton';
// import { SvgXml } from 'react-native-svg';

interface ImageProps {
  type: 'svg' | 'image',
  path: any,
  style?: any
}

interface Props {
  modalShow: boolean;
  onPressClose?: () => void;
  closeTestID?: string;
  buttonName: string;
  onPressButton?: () => void;
  bottomButtonTop?: boolean;
  buttonTestID?: string;
  bottomButtonName?: string;
  onPressBottomButton?: () => void;
  bottomButtonTestID?: string;
  title: string;
  titleStyle?: 'center' | 'left';
  description?: string;
  descriptionStyle?: 'center' | 'left' | 'auto' | 'right';
  image?: ImageProps;
  children?: any;
  showCloseButton?: boolean
}

export default class CBottomModal extends Component<Props> {
  getStyles() {
    const { titleStyle, descriptionStyle } = this.props;
    return {
      titleStyles: {
        ...styles.popupTitleWrapper,
        ...styles[`text_${titleStyle}`],
      },
      descriptionStyles: {
        ...styles.popupSubTitleWrapper,
        ...styles[`text_${descriptionStyle}`],
      }
    };
  }

  render() {
    const { showCloseButton, modalShow, onPressClose, closeTestID, onPressButton, buttonTestID, onPressBottomButton, buttonName,
      bottomButtonTestID, title, description, image, children, bottomButtonTop, bottomButtonName, descriptionStyle, titleStyle } = this.props;
    const { titleStyles, descriptionStyles } = this.getStyles();

    return (
      <Overlay
        isVisible={modalShow}
        overlayStyle={styles.modalContainer} >
        <>
          {
            showCloseButton &&
            <TouchableOpacity testID={closeTestID} onPress={onPressClose} style={styles.closeIcon}
              hitSlop={{ top: 20, right: 20, left: 20, bottom: 20 }}>
              <Icon name="close" size={Scale(30)} color="#000024" />
            </TouchableOpacity>}

          <View style={styles.popupMainWrapper}>

            {image && <>
              {image?.type === 'image' && <Image style={styles.image} source={image?.path} resizeMode='contain' />
                // :
                //   <SvgXml xml={image?.path} style={styles.image} />
              }
            </>}

            <View style={titleStyles}>
              <CText style="blue" size="xl" weight='bold' textAlign={titleStyle}>{title}</CText>
            </View>

            <View style={descriptionStyles}>
              <CText style="blue" size="base" weight='light' textAlign={descriptionStyle}>{description}</CText>
            </View>

            {children}

            {Boolean(bottomButtonTop && bottomButtonName) &&
              <TouchableOpacity testID={bottomButtonTestID} onPress={onPressBottomButton} style={styles.bottomButtonStyle}>
                <CText style="red" size="base" weight='bold'>{bottomButtonName}</CText>
              </TouchableOpacity>}

            <CButton
              testID={buttonTestID}
              type="button"
              onPress={onPressButton}
              style={"primary"}
              size="big" ><CText style='white' size='base' weight='bold'>{buttonName}</CText></CButton>

            {Boolean(!bottomButtonTop && bottomButtonName) &&
              <TouchableOpacity testID={bottomButtonTestID} onPress={onPressBottomButton} style={styles.bottomButtonBottomStyle}>
                <CText style="red" size="base" weight='bold'>{bottomButtonName}</CText>
              </TouchableOpacity>}

          </View>
        </>
      </Overlay>
    )
  }
}

const styles: any = StyleSheet.create({
  modalContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: "auto",
    backgroundColor: 'white',
    borderTopRightRadius: Scale(24),
    elevation: 5,
  },
  closeIcon: { position: 'absolute', top: Scale(15), right: Scale(15), zIndex: 100 },
  justifyCenter: { justifyContent: "center" },
  popupMainWrapper: { paddingHorizontal: Scale(20), paddingTop: Scale(40), paddingBottom: Scale(30) },
  popupTitleWrapper: { marginTop: Scale(10), marginBottom: Scale(10) },
  popupSubTitleWrapper: { marginBottom: Scale(40) },
  text_center: { alignItems: 'center' },
  text_left: { alignItems: 'flex-start' },
  bottomButtonStyle: { paddingBottom: Scale(20), alignItems: 'center', justifyContent: 'center', alignSelf: 'center' },
  bottomButtonBottomStyle: { paddingTop: Scale(20), alignItems: 'center', justifyContent: 'center', alignSelf: 'center' },
  image: { height: Scale(80), width: Scale(80), marginBottom: Scale(20), alignSelf: 'center' },
});
